import {useMemo, ReactNode, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {formatLocaleDate as format} from '@/util/locale';
import useClasses from './NavigationBanner.styles';
import useNavigationBanner from './useNavigationBanner';
import {useGetUserInfo} from '@/api/app';
import {
    Button,
    Link,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarIntent,
} from '@fluentui/react-components';
import MedeinaVariables from '@/util/variables';
import {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';
import {Dismiss20Filled} from '@fluentui/react-icons';

export default function NavigationBanner() {
    const classes = useClasses();
    const {t} = useTranslation('common');
    const {t: tTours} = useTranslation('tours');
    const {data: userInfo} = useGetUserInfo();
    const bannerState = useNavigationBanner();
    const {isVisible, isUntrustedApi, isFidelisApi, isInfo, isWarning} = bannerState;

    const navigate = useNavigate();

    // State to control the visibility of the banner
    const [isBannerVisible, setIsBannerVisible] = useState(isVisible);

    // Effect to update the banner visibility based on the banner state
    useEffect(() => {
        setIsBannerVisible(isVisible);
    }, [isVisible]);

    // Determine the banner message.
    const message = useMemo<ReactNode>(() => {
        const isAdmin = Boolean(userInfo?.isAdmin);

        // For API endpoint overrides.
        if (isUntrustedApi) {
            return `The portal is currently communicating with ${
                isFidelisApi ? 'the Fidelis' : 'an untrusted'
            } backend service. If you are not doing ${
                isFidelisApi ? 'Fidelis' : 'local'
            } development, please close the page.`;
        }
        // For free trial expiration.
        else if (bannerState.freeTrialExpiryDate && (isInfo || isWarning)) {
            let expiryMessage = null;
            const expiresAt = format(bannerState.freeTrialExpiryDate, 'MMMM d');
            let intent: MessageBarIntent = 'info';

            if (bannerState.isFreeTrialExpired) {
                expiryMessage = tTours(
                    `freeTrialExpirationMessage.ExpiredTrialEndMessageFor${
                        isAdmin ? 'Admin' : 'Analyst'
                    }`,
                    {expiresAt},
                );
                intent = 'warning';
            } else if (bannerState.isFreeTrialExpiringTomorrow) {
                expiryMessage = tTours(
                    `freeTrialExpirationMessage.GenericTrialEndingMessageIn1Day${
                        isAdmin ? 'Admin' : 'Analyst'
                    }`,
                    {expiresAt},
                );
                intent = 'warning';
            } else if (bannerState.isFreeTrialExpiringIn7Days) {
                expiryMessage = tTours(
                    `freeTrialExpirationMessage.GenericTrialEndingMessageIn7Days${
                        isAdmin ? 'Admin' : 'Analyst'
                    }`,
                    {expiresAt},
                );
                intent = 'info';
            }

            if (expiryMessage) {
                return (
                    <MessageBar key={intent} intent={intent}>
                        <MessageBarBody data-testid="free-trial-expiration-message">
                            {`${expiryMessage} `}
                            <Link
                                data-test-id="read-more-about-security-compute-units-link"
                                target="_blank"
                                href={MedeinaVariables.SecurityComputeUnitsUri}
                            >
                                {t('LearnMore')}
                            </Link>
                        </MessageBarBody>
                        <MessageBarActions
                            containerAction={
                                <Dismiss20Filled onClick={() => setIsBannerVisible(false)} />
                            }
                        >
                            <Button
                                as="a"
                                data-test-id="purchase-units-button"
                                onClick={() => navigate('/owner-settings')}
                            >
                                {t('Purchase units')}
                            </Button>
                        </MessageBarActions>
                    </MessageBar>
                );
            }
        }
        return null;
    }, [
        userInfo?.isAdmin,
        isUntrustedApi,
        bannerState.freeTrialExpiryDate,
        bannerState.isFreeTrialExpired,
        bannerState.isFreeTrialExpiringTomorrow,
        bannerState.isFreeTrialExpiringIn7Days,
        isInfo,
        isWarning,
        isFidelisApi,
        tTours,
        classes.icon,
        t,
        navigate,
    ]);

    return (
        <>
            {isBannerVisible && message && (
                <div data-testid={isUntrustedApi ? 'untrusted-warning' : 'navigation-banner'}>
                    <div className={classes.message}>{message}</div>
                </div>
            )}
        </>
    );
}
