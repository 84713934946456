const USAGE_MONITORING_PREFIX = 'UsageMonitoring';
const USAGE_MONITORING_PREFIX_WORKSPACES = 'WorkspacesUsageMonitoring';

const CAPACITY_BUCKETS_PREFIX = `${USAGE_MONITORING_PREFIX}.CapacityBuckets`;

export const USAGE_DATA_TRUNCATION_DECIMAL_COUNT = 3;

export const OVERAGE_SCU_COST = 6;

export const CAPACITY_LIMIT_TYPES = {
    BELOW_THRESHOLD: `${CAPACITY_BUCKETS_PREFIX}.BelowThreshold`,
    ABOVE_THRESHOLD: `${CAPACITY_BUCKETS_PREFIX}.AboveThreshold`,
    THROTTLED: `${CAPACITY_BUCKETS_PREFIX}.Throttled`,
    WITHIN_PROVISIONED_LIMITS: `${CAPACITY_BUCKETS_PREFIX}.WithinProvisionedLimits`,
    WITHIN_OVERAGE_LIMITS: `${CAPACITY_BUCKETS_PREFIX}.WithinOverageLimits`,
    EXCEEDED_OVERAGE: `${CAPACITY_BUCKETS_PREFIX}.ExceededOverage`,
    OVERAGE_AVAILABLE: `${CAPACITY_BUCKETS_PREFIX}.OverageAvailable`,
};

export const CAPACITY_BUCKETS = {
    BELOW_THRESHOLD: `${CAPACITY_LIMIT_TYPES.BELOW_THRESHOLD}.BucketText`,
    ABOVE_THRESHOLD: `${CAPACITY_LIMIT_TYPES.ABOVE_THRESHOLD}.BucketText`,
    THROTTLED: `${CAPACITY_LIMIT_TYPES.THROTTLED}.BucketText`,
    ASSIGNED_CAPACITY: `${CAPACITY_BUCKETS_PREFIX}.AssignedCapacity`,
    OVERAGE_LIMIT: `${CAPACITY_BUCKETS_PREFIX}.OverageLimit`,
    OVERAGE_AVAILABLE: `${CAPACITY_LIMIT_TYPES}.OverageAvailable`,
    WITHIN_PROVISIONED_LIMITS: `${CAPACITY_LIMIT_TYPES.WITHIN_PROVISIONED_LIMITS}.BucketText`,
    WITHIN_OVERAGE_LIMITS: `${CAPACITY_LIMIT_TYPES.WITHIN_OVERAGE_LIMITS}.BucketText`,
    EXCEEDED_OVERAGE: `${CAPACITY_LIMIT_TYPES.EXCEEDED_OVERAGE}.BucketText`,
};

export const CAPACITY_THRESHOLD_PERCENTAGE = 0.9;
export const UNIT_SINGLE_TEXT = `${USAGE_MONITORING_PREFIX}.UnitSingleText`;
export const UNIT_PLURAL_TEXT = `${USAGE_MONITORING_PREFIX}.UnitPluralText`;
export const USAGE_MONITORING_TITLE = `${USAGE_MONITORING_PREFIX}.UsageMonitoringDashboardTitle`;
export const USAGE_MONITORING_DESCRIPTION = `${USAGE_MONITORING_PREFIX}.UsageMonitoringDashboardDescription`;
export const USAGE_MONITORING_DESCRIPTION_WORKSPACES = `${USAGE_MONITORING_PREFIX_WORKSPACES}.UsageMonitoringDashboardDescription`;
export const USAGE_MONITORING_DESCRIPTION_OVERAGE = `${USAGE_MONITORING_PREFIX}.UsageMonitoringDashboardDescriptionForOverage`;
export const USAGE_MONITORING_DESCRIPTION_WORKSPACES_OVERAGE = `${USAGE_MONITORING_PREFIX}.UsageMonitoringDashboardDescriptionForOverageMWS`;
export const LAST_UPDATED = `${USAGE_MONITORING_PREFIX}.LastUpdatedAtText`;
export const LEARN_MORE_LINK = `${USAGE_MONITORING_PREFIX}.LearnMoreLinkText`;
export const UNITS_PER_HOUR = `${USAGE_MONITORING_PREFIX}.UnitsPerHourText`;
export const BASE_UNITS_PER_HOUR = `${USAGE_MONITORING_PREFIX}.BaseUnitsPerHourText`;
export const BAR_CHART_HOVERCARD_USAGE = `${USAGE_MONITORING_PREFIX}.BarChartHoverCardUsage`;
export const BAR_CHART_BASE_UNITS_USAGE = `${USAGE_MONITORING_PREFIX}.BaseUnitsUsage`;
export const BAR_CHART_HIGH_DEMAND_UNITS_USAGE = `${USAGE_MONITORING_PREFIX}.HighDemandUnitsUsage`;
export const BAR_CHART_ABOVE_OVERAGE_UNITS_USAGE = `${USAGE_MONITORING_PREFIX}.AboveOverageUnitsUsage`;
export const BAR_CHART_PROVISIONED = `${USAGE_MONITORING_PREFIX}.ProvisionedUnits`;
export const PAGE_LOADING = `${USAGE_MONITORING_PREFIX}.PageLoading`;
export const USAGE_DATA_LOADING = `${USAGE_MONITORING_PREFIX}.UsageLoading`;
export const WORKSPACE_USAGE = `${USAGE_MONITORING_PREFIX}.WorkspaceUsage`;
export const NONE = `${USAGE_MONITORING_PREFIX}.None`;
export const CAPACITIES_SUBHEADING = `${USAGE_MONITORING_PREFIX}.CapacitiesSubheading`;
export const DATETIME_FORMAT = {
    ISO_STRING: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
    TABLE_COLUMN_DATE: 'MMM dd, hh:mm a',
    BARCHART_XAXIS_TICKS: 'EEE',
    BARCHART_HOVERCARD_TIMEFRAME: 'h:mm a',
    BARCHART_HOVERCARD_DAYNAME: 'EEEE',
};

export const ButtonLabels = {
    CHANGE_CAPACITY: 'ButtonLabels.Change',
    DATE_DROPDOWN: 'UsageMonitoring.DateDropdownFilterButtonText',
    CANCEL: 'ButtonLabels.Cancel',
    APPLY: 'ButtonLabels.Apply',
    REFRESH: 'ButtonLabels.Refresh',
    CHANGE_UNITS: 'UsageMonitoring.ChangeUnitsButton',
    VIEW_AZURE_BILLING: 'UsageMonitoring.ViewAzureResourcesButton',
};
const tableHeaderKeyPrefix = `${USAGE_MONITORING_PREFIX}.TableHeaderValues`;

export const TableHeaders = {
    DATE: `${tableHeaderKeyPrefix}.Date`,
    UNIT_USAGE: `${tableHeaderKeyPrefix}.UnitUsage`,
    UNIT_STATUS: `${tableHeaderKeyPrefix}.UnitStatus`,
    INITIATED_BY: `${tableHeaderKeyPrefix}.InitiatedBy`,
    SESSION_ID: `${tableHeaderKeyPrefix}.SessionId`,
    CATEGORY: `${tableHeaderKeyPrefix}.Category`,
    TYPE: `${tableHeaderKeyPrefix}.Type`,
    PLUGINS: `${tableHeaderKeyPrefix}.Plugins`,
    COPILOT_EXPERIENCE: `${tableHeaderKeyPrefix}.CopilotExperience`,
};

const DROP_DOWN_VALUES_PREFIX = `${USAGE_MONITORING_PREFIX}.DateDropdownValues`;

export const DateDropdownValues = {
    LAST_24_HOURS: `${DROP_DOWN_VALUES_PREFIX}.Last24Hours`,
    LAST_3_DAYS: `${DROP_DOWN_VALUES_PREFIX}.Last3Days`,
    LAST_7_DAYS: `${DROP_DOWN_VALUES_PREFIX}.Last7Days`,
    LAST_MONTH: `${DROP_DOWN_VALUES_PREFIX}.LastMonth`,
    CUSTOM_DATE_RANGE: `${DROP_DOWN_VALUES_PREFIX}.CustomDateRange`,
};

const DATE_PICKER_LABELS_PREFIX = `${USAGE_MONITORING_PREFIX}.DatePickerLabels`;

export const DatePickerLabels = {
    HEADER: `${DATE_PICKER_LABELS_PREFIX}.Header`,
    START_DATE_PLACEHOLDER: `${DATE_PICKER_LABELS_PREFIX}.StartDatePlaceholder`,
    END_DATE_PLACEHOLDER: `${DATE_PICKER_LABELS_PREFIX}.EndDatePlaceholder`,
    RANGE_LABEL_BETWEEN: `${DATE_PICKER_LABELS_PREFIX}.RangeLabelBetween`,
    RANGE_LABEL_AND: `${DATE_PICKER_LABELS_PREFIX}.RangeLabelAnd`,
    RANGE_ERROR_MESSAGE: `${DATE_PICKER_LABELS_PREFIX}.RangeErrorMessage`,
};

const API_RESPONSE_DISPLAYS_PREFIX = `${USAGE_MONITORING_PREFIX}.ApiResponseDisplays`;
const API_RESPONSE_DISPLAYS_PREFIX_WORKSPACES = `${USAGE_MONITORING_PREFIX}.WorkspacesApiResponseDisplays`;
const API_RESPONSE_DISPLAYS_NO_DIMENSIONAL = `${API_RESPONSE_DISPLAYS_PREFIX}.NoDimensionalData`;

export const ApiResponseDisplays = {
    NO_DATA: {
        title: `${API_RESPONSE_DISPLAYS_PREFIX}.NoData.Title`,
        subtitle: `${API_RESPONSE_DISPLAYS_PREFIX}.NoData.Subtitle`,
    },
    NO_DATA_V2: {
        title: `${API_RESPONSE_DISPLAYS_PREFIX}.NoDataV2.Title`,
        subtitle: `${API_RESPONSE_DISPLAYS_PREFIX}.NoDataV2.Subtitle`,
    },
    ERROR: {
        title: `${API_RESPONSE_DISPLAYS_PREFIX}.Error.Title`,
        subtitle: `${API_RESPONSE_DISPLAYS_PREFIX}.Error.Subtitle`,
    },
    NO_DIMENSIONAL_DATA: {
        title: `${API_RESPONSE_DISPLAYS_NO_DIMENSIONAL}.Title`,
        subtitle: `${API_RESPONSE_DISPLAYS_NO_DIMENSIONAL}.Subtitle`,
    },
    NO_DATA_WORKSPACES: {
        subtitle: `${API_RESPONSE_DISPLAYS_PREFIX_WORKSPACES}.NoData.Subtitle`,
    },
};

export const AriaLabels = {
    NO_DATA: `${USAGE_MONITORING_PREFIX}.AriaLabels.NoData`,
    NO_DATA_WORKSPACES: `${USAGE_MONITORING_PREFIX_WORKSPACES}.AriaLabels.NoData`,
    ERROR: `${USAGE_MONITORING_PREFIX}.AriaLabels.Error`,
    NO_DIMENSIONAL_DATA: `${USAGE_MONITORING_PREFIX}.AriaLabels.NoDimensionalData`,
    USAGE_DATA_LOADING: `${USAGE_MONITORING_PREFIX}.AriaLabels.UsageDataLoading`,
    DATA_LOADED: `${USAGE_MONITORING_PREFIX}.AriaLabels.UsageDataLoaded`,
    CHANGE_CAPACITY: `${USAGE_MONITORING_PREFIX}.AriaLabels.ChangeUnits`,
    VIEW_AZURE_RESOURCES: `${USAGE_MONITORING_PREFIX}.AriaLabels.ViewAzureResourcesButton`,
};

const ScuAllocationPrefix = `${USAGE_MONITORING_PREFIX}.ScuAllocation`;
const ScuAllocationLabelProvisioned = `${ScuAllocationPrefix}.Provisioned`;
const ScuAllocationOverage = `${ScuAllocationPrefix}.Overage`;
const ScuAllocationLabelTotal = `${ScuAllocationPrefix}.TotalOverage`;
const UnitConsumptionPrefix = `${ScuAllocationPrefix}.UnitConsumption`;
const UnitConsumed = `${ScuAllocationPrefix}.NoOfUnits`;

export const ScuAllocationLabels = {
    UnitsAvailableSingular: `${UnitConsumptionPrefix}.Singular`,
    UnitsAvailablePlural: `${UnitConsumptionPrefix}.Plural`,
    ProvisionedHeader: `${ScuAllocationLabelProvisioned}.Header`,
    ProvisionedFooter: `${ScuAllocationLabelProvisioned}.Footer`,
    OverageHeader: `${ScuAllocationOverage}.Header`,
    OverageFooter: `${ScuAllocationOverage}.Footer`,
    TotalOverageHeader: `${ScuAllocationLabelTotal}.Header`,
    TotalOverageFooter: `${ScuAllocationLabelTotal}.Footer`,
    TotalUnitsSingular: `${UnitConsumed}.Singular`,
    TotalUnitsPlural: `${UnitConsumed}.Plural`,
};

export const MILLI_SECONDS_IN_HOUR = 1000 * 60 * 60;

export const DEFAULT_PRISTINE_SEARCH_FILTERS = true;

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_PAGE_NUMBER = 1;

export const DEFAULT_FILTER_STATE = [];

export const DEFAULT_DATA_ORDER = 'descending';

export const DEFAULT_HOURLY_BAR_ACTIVE = false;

export const WindowEvents = {
    USAGE_FILTER_UPDATED: 'usage-filter-updated',
};

export const FilterConfiguration = `${USAGE_MONITORING_PREFIX}.Filter`;

export const UsageMonitoringFilterLabels = {
    Tooltip: `${FilterConfiguration}.ToolTip`,
    Title: `${FilterConfiguration}.Title`,
    DisabledTooltip: `${FilterConfiguration}.DisabledTooltip`,
};

export const FilterPanelLabels = {
    SelectUsers: `${FilterConfiguration}.SelectUsers`,
    Remove: `${FilterConfiguration}.Remove`,
    Selected: `${FilterConfiguration}.Selected`,
    Reset: `${FilterConfiguration}.Reset`,
};

export const FilterPanelMultiSelectLabels = {
    SearchPlugin: `${FilterConfiguration}.SearchPlugin`,
};

export const CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE = {
    LAST_90_DAYS: 90,
    LAST_30_DAYS: 30,
};

export const FilterPanelCategoryLabelPrefix = `${USAGE_MONITORING_PREFIX}.FilterHeaderValues`;

export const FILTER_PANEL_CATEGORY_LABELS = {
    CopilotExperience: `${FilterPanelCategoryLabelPrefix}.CopilotExperience`,
    PluginsUsed: `${FilterPanelCategoryLabelPrefix}.PluginsUsed`,
    Users: `${FilterPanelCategoryLabelPrefix}.Users`,
    InvocationType: `${FilterPanelCategoryLabelPrefix}.InvocationType`,
    InvocationCategory: `${FilterPanelCategoryLabelPrefix}.InvocationCategory`,
};

export const FILTER_PILLS_NUMBER = {
    SMALL_MEDIUM_SCREEN: 0,
    LARGE_SCREEN: 1,
    X_LARGE_SCREEN: 2,
    ABOVE_X_LARGE_SCREEN: 3,
};

const SELECTED_FILTER_PILLS_PREFIX = `${USAGE_MONITORING_PREFIX}.SelectedFilterPills`;

export const SelectedFilterPillLabelsPrefix = `${SELECTED_FILTER_PILLS_PREFIX}`;

export const SingleSelectedFilterPillLabelsPrefix = `${SELECTED_FILTER_PILLS_PREFIX}.SingleSelection`;

export const MultiSelectedFilterPillLabelsPrefix = `${SELECTED_FILTER_PILLS_PREFIX}.MultipleSelection`;

export const SelectedFilterPillAriaLabelsPrefix = `${SELECTED_FILTER_PILLS_PREFIX}.AriaLabels`;

export const SINGLE_SELECTED_FILTER_PILL_LABELS = {
    CopilotExperience: `${SingleSelectedFilterPillLabelsPrefix}.CopilotExperience`,
    PluginUsed: `${SingleSelectedFilterPillLabelsPrefix}.PluginUsed`,
    User: `${SingleSelectedFilterPillLabelsPrefix}.User`,
    InvocationType: `${SingleSelectedFilterPillLabelsPrefix}.InvocationType`,
    InvocationCategory: `${SingleSelectedFilterPillLabelsPrefix}.InvocationCategory`,
};

export const MULTI_SELECTED_FILTER_PILL_LABELS = {
    CopilotExperiences: `${MultiSelectedFilterPillLabelsPrefix}.CopilotExperiences`,
    PluginsUsed: `${MultiSelectedFilterPillLabelsPrefix}.PluginsUsed`,
    Users: `${MultiSelectedFilterPillLabelsPrefix}.Users`,
    InvocationTypes: `${MultiSelectedFilterPillLabelsPrefix}.InvocationTypes`,
    InvocationCategories: `${MultiSelectedFilterPillLabelsPrefix}.InvocationCategories`,
};

const ExportButtonPrefix = `${USAGE_MONITORING_PREFIX}.ExportUsage`;
export const USAGE_EXPORT_BUTTON = `${ExportButtonPrefix}.ExportButtonText`;
export const USAGE_EXPORT_LABEL = `${ExportButtonPrefix}.ExportButtonLabel`;
export const USAGE_EXPORT_IN_PROGRESS = `${ExportButtonPrefix}.Exporting`;
export const USAGE_EXPORT_ERROR = `${ExportButtonPrefix}.ExportError`;
