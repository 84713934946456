import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentEvaluationResults.styles';
import {Subtitle1, Badge, Spinner} from '@fluentui/react-components';
import {Evaluation} from '../../evaluations';
import {useGetEvaluation} from '@/api/evaluations';
import ExportEvaluator from '../../evaluations/ExportEvaluator';
import {MedeinaCopyButton} from '@/components/ui/Buttons/MedeinaCopyButton';
import {MedeinaTelemetryEvent} from '@/api/telemetry';
import {useGetSession} from '@/api/sessions';
import {StatusLabel} from '@sfe/react';

interface AgentEvaluationResultsProps {
    sessionId: string;
    promptId: string;
    evaluationId: string;
}

export default function AgentEvaluationResults(props: AgentEvaluationResultsProps) {
    const {sessionId, promptId, evaluationId} = props;
    const classes = useClasses();
    const {t: tAgents} = useTranslation('agents');
    const {t: tSession} = useTranslation('session');

    // Fetch session data to determine if user has permission to view
    const {
        data: session,
        isLoading: isSessionLoading,
        isError: isSessionError,
    } = useGetSession({sessionId: sessionId});

    const {data: evaluation} = useGetEvaluation({
        sessionId: sessionId,
        promptId: promptId,
        evaluationId: evaluationId,
        enabled: !!evaluationId && !!session,
    });

    const isLoading = useMemo(() => {
        return isSessionLoading || !session;
    }, [isSessionLoading, session]);

    return (
        <div className={classes.root}>
            {!isSessionError ? (
                <>
                    {isLoading ? (
                        <Spinner
                            labelPosition="below"
                            label={tAgents('AgentRun.Body.Loading')}
                            data-testid="agent-loading-spinner"
                            className={classes.loading}
                        />
                    ) : (
                        <>
                            <div className={classes.resultsHeader}>
                                <div
                                    className={classes.resultsHeaderTitle}
                                    data-testid="agent-evaluation-header-title"
                                >
                                    <Subtitle1>{tAgents('AgentRun.Body.Header')}</Subtitle1>{' '}
                                    <Badge appearance="filled" size="medium" color="informative">
                                        {tAgents('AgentRun.Body.Badge')}
                                    </Badge>
                                </div>
                                <div
                                    className={classes.resultsHeaderActions}
                                    data-testid="agent-evaluation-header-actions"
                                >
                                    <ExportEvaluator
                                        sessionId={sessionId}
                                        promptId={promptId}
                                        evaluationId={evaluationId}
                                        isDisabled={false}
                                        isSummaryExport={false}
                                        enableCopy={false}
                                    />
                                    <MedeinaCopyButton
                                        copyText={evaluation?.result?.content ?? ''}
                                        eventName={MedeinaTelemetryEvent.Evaluations.ExportAsCopy}
                                        tooltipText={tSession(
                                            'EvaluationFeedbacks.AriaLabels.CopyResponse',
                                        )}
                                        isDisabled={false}
                                    />
                                    {/** Comment out until feedback work is ready in PuP */}
                                    {/* <Divider
                                        vertical
                                        className={classes.resultsHeaderActionsDivider}
                                    />
                                    <Button appearance="subtle" icon={<ThumbLike24Regular />} />
                                    <Button appearance="subtle" icon={<ThumbDislike24Regular />} /> */}
                                </div>
                            </div>
                            <div className={classes.results} data-testid="agent-evaluation-results">
                                <Evaluation
                                    sessionId={sessionId}
                                    promptId={promptId}
                                    evaluationId={evaluationId}
                                    isAgentEvaluation={true}
                                />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className={classes.error} data-testid="agent-evaluation-error">
                    <StatusLabel status="failed" text={tSession('SessionNotFoundOrNoPermission')} />
                </div>
            )}
        </div>
    );
}
