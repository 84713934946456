import {useEffect, useState} from 'react';
import useClasses from './DataOptIn.styles';
import {
    useGetWorkspaceByName,
    useGetWorkspaceSettings,
    useUpdateWorkspaceSettings,
} from '@/api/workspaces';
import {useUpdateWorkspace} from '@/api/workspaces';
import {ErrorIcon, OpenIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {CustomSwitchTransition} from '../../ui/Switch/switch.types';
import ProgressiveSwitch from '../../ui/Switch';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {Workspace, WorkspaceSettings} from '@/api/workspaces/workspaces.types';
import {getCurrentWorkspaceCache} from '@/components/sections/workspaces/WorkspacesProvider';
import {SwitchOptions} from './DataOptIn.types';

export default function DataOptInSwitch(options: SwitchOptions) {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    // Get the workspace name from the cache
    const workspaceCache = getCurrentWorkspaceCache();
    const workspaceName = workspaceCache?.name;
    const [toggleValue, setToggleValue] = useState<boolean>(false);
    const [errorTag, setErrorTag] = useState<boolean>(false);

    const isWorkspacesEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    /** Keeps track of which toggle value on the workspace object we'll be updating */
    const updateGAPropertyName =
        options.type === 'product' ? 'isAllowProductImprovement' : 'isAllowModelImprovement';
    const updatePropertyName =
        options.type === 'product' ? 'allowProductImprovement' : 'allowModelImprovement';
    /** Change tracking logic for visuals */
    const [updateConsentState, setUpdateConsentState] = useState<CustomSwitchTransition>({
        isChanging: false,
        isChangeSuccessful: false,
        hasChangeFailed: false,
    });

    /** GA data lookup, uses workspace object directly */
    const {
        data: workspaceGA,
        isFetched: isWorkspaceGAFetched,
        isSuccess: isWorkspaceGASuccess,
        isError: isWorkspaceGAError,
        isLoading: isWorkspaceGALoading,
    } = useGetWorkspaceByName(workspaceName ?? '', {
        enabled: !!workspaceName,
    });
    const {mutate: updateGAWorkspace, isLoading: isWorkspaceGAUpdating} = useUpdateWorkspace();

    /** Multiworkspaces, uses workspaceSettings */
    const {
        data: workspaceSettings,
        isFetched: workspaceSettingsFetched,
        isSuccess: workspaceSettingsSuccess,
        refetch: refetchWorkspace,
        isError: workspaceSettingsError,
        isLoading: isWorkspaceSettingsLoading,
    } = useGetWorkspaceSettings(undefined, {
        enabled: Boolean(isWorkspacesEnabled),
    });
    const {mutate: updateWorkspaceSettings, isLoading: isWorkspaceSettingsUpdating} =
        useUpdateWorkspaceSettings({
            enabled: Boolean(isWorkspacesEnabled),
        });

    useEffect(() => {
        if (!isWorkspacesEnabled && isWorkspaceGASuccess) {
            setToggleValue(
                (workspaceGA?.workspaceOptInConfig?.[
                    options.type === 'product'
                        ? 'isAllowProductImprovement'
                        : 'isAllowModelImprovement'
                ] ?? '') === 'true',
            );
        }
    }, [isWorkspaceGAFetched]);

    const getUpdateWorkspaceRequest = (
        currentWorkspace: Workspace,
        propertyToUpdate: 'product' | 'model',
    ) => {
        return {
            isAllowProductImprovement:
                propertyToUpdate === 'product'
                    ? (!toggleValue).toString()
                    : currentWorkspace?.workspaceOptInConfig?.isAllowProductImprovement ?? 'false',
            isAllowModelImprovement:
                propertyToUpdate === 'model'
                    ? (!toggleValue).toString()
                    : currentWorkspace?.workspaceOptInConfig?.isAllowModelImprovement ?? 'false',
        };
    };

    const updatePreferences = () => {
        setUpdateConsentState({
            isChanging: true,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
        if (isWorkspacesEnabled) {
            if (!workspaceSettings) {
                console.error('Workspace settings not found');
                setErrorTag(true);
                return;
            }
            const updateSettings: WorkspaceSettings = {
                ...workspaceSettings,
                workspaceId: workspaceSettings?.workspaceId ?? '',
                tenantId: workspaceSettings?.tenantId ?? '',
                freRequirements: {
                    ...workspaceSettings.freRequirements,
                    [updatePropertyName]: !toggleValue,
                },
            };
            updateWorkspaceSettings(
                {
                    workspaceSettings: updateSettings,
                    targetWorkspace: workspaceGA,
                },
                {
                    onSuccess: (data) => {
                        setUpdateConsentState({
                            isChanging: false,
                            isChangeSuccessful: true,
                            hasChangeFailed: false,
                        });
                        setToggleValue(
                            Boolean(data?.freRequirements?.[updatePropertyName]) ?? false,
                        );
                        setErrorTag(false);
                        refetchWorkspace();
                    },
                    onError: () => {
                        setUpdateConsentState({
                            isChanging: false,
                            isChangeSuccessful: false,
                            hasChangeFailed: true,
                        });
                        setErrorTag(true);
                    },
                },
            );
        } else {
            updateGAWorkspace(
                {
                    name: workspaceName ?? '',
                    workspaceOptInConfig: getUpdateWorkspaceRequest(
                        workspaceGA as Workspace,
                        options.type,
                    ),
                },
                {
                    onSuccess: (data) => {
                        setUpdateConsentState({
                            isChanging: false,
                            isChangeSuccessful: true,
                            hasChangeFailed: false,
                        });
                        setToggleValue(
                            data?.workspaceOptInConfig?.[updateGAPropertyName] === 'true',
                        );
                        setErrorTag(false);
                    },
                    onError: () => {
                        setUpdateConsentState({
                            isChanging: false,
                            isChangeSuccessful: false,
                            hasChangeFailed: true,
                        });
                        setErrorTag(true);
                    },
                },
            );
        }
    };

    const label =
        options.type === 'product'
            ? t('ownerSettings.organizationData.ImproveSecuritySwitchLabel')
            : t('ownerSettings.organizationData.TrainSecurityCopilotSwitchLabel');

    const ariaLabel =
        options.type === 'product'
            ? t('ownerSettings.organizationData.ariaLabel.ProductImprovement')
            : t('ownerSettings.organizationData.ariaLabel.ModelTraining');

    const isLoading = isWorkspacesEnabled ? isWorkspaceSettingsLoading : isWorkspaceGALoading;
    const isUpdating = isWorkspacesEnabled ? isWorkspaceSettingsUpdating : isWorkspaceGAUpdating;

    // Set the toggle value from the initial load of the respective Get endpoint
    useEffect(() => {
        if (isWorkspacesEnabled && workspaceSettingsSuccess) {
            setToggleValue(
                Boolean(workspaceSettings?.freRequirements?.[updatePropertyName]) ?? false,
            );
        } else if (isWorkspaceGASuccess) {
            setToggleValue(
                (workspaceGA?.workspaceOptInConfig?.[updateGAPropertyName] ?? '') === 'true',
            );
        }
    }, [isWorkspaceGASuccess, workspaceSettingsSuccess]);

    return (
        <>
            <ProgressiveSwitch
                data-test-id={`data-sharing-switch-${options.type}`}
                label={label}
                labelPosition="before"
                aria-label={ariaLabel}
                checked={toggleValue}
                onClick={(ev) => updatePreferences()}
                state={updateConsentState}
                disabled={options?.disabled || isUpdating}
            />
            {errorTag && (
                <div>
                    <div className={classes.errorTag}>
                        <span>
                            <ErrorIcon filled />
                        </span>
                        <span>{t('ownerSettings.organizationData.ErrorMessage')}</span>
                    </div>
                </div>
            )}
        </>
    );
}
