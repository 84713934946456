import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentPlugins.styles';
import {Body1, Image, Skeleton, SkeletonItem, Tooltip} from '@fluentui/react-components';
import {useGetSkillsets} from '@/api/skills';
import {useGetSkillsetCustomIcon} from '@/api/skills';
import {DismissCircle20Filled} from '@fluentui/react-icons';
import {StatusLabel} from '@sfe/react';
import {AgentDetailsList, AgentDetailsListItem} from '@sfe/react-agent-details';

interface PluginIconProps {
    skillset: string;
    icon: string;
    enabled: boolean;
}

interface AgentPluginsProps {
    requiredSkillsets: Array<string>;
    setAllPluginsEnabled?: Dispatch<SetStateAction<'loading' | 'enabled' | 'disabled'>>;
}

const PluginIcon = (props: PluginIconProps) => {
    const {skillset, icon, enabled} = props;
    const classes = useClasses();
    const {t: tAgents} = useTranslation('agents');

    const {data: iconPath, isLoading: isLoadingIcon} = useGetSkillsetCustomIcon(skillset, icon);

    return (
        <>
            {!isLoadingIcon && (
                <span data-testid="plugin-icon">
                    {enabled ? (
                        <Image
                            fit="default"
                            className={classes.image}
                            alt={tAgents('Agent.Details.Plugins.Image')}
                            aria-hidden
                            src={iconPath || ''}
                        />
                    ) : (
                        <Tooltip
                            content={tAgents('Plugins.Disabled')}
                            relationship="description"
                            positioning="above-start"
                            withArrow
                        >
                            <DismissCircle20Filled
                                className={classes.dismissColor}
                                data-testid="dismiss-icon"
                            />
                        </Tooltip>
                    )}
                </span>
            )}
        </>
    );
};

export default function AgentPlugins(props: AgentPluginsProps) {
    const {requiredSkillsets, setAllPluginsEnabled} = props;
    const classes = useClasses();
    const {t: tAgents} = useTranslation('agents');

    const {
        data: skillsets,
        isLoading: isSkillsetsLoading,
        isError: isSkillsetsError,
    } = useGetSkillsets();

    useEffect(() => {
        if (!setAllPluginsEnabled) {
            return;
        }
        if (isSkillsetsLoading) {
            setAllPluginsEnabled('loading');
        }
        if (!isSkillsetsLoading && skillsets) {
            let allEnabled: 'enabled' | 'disabled' = 'enabled';

            // TODO: This is a temporary for PrP.
            // for (const plugin of requiredSkillsets) {
            //     const matchedSkillset = skillsets.value.find(
            //         (skillset) => skillset.name === plugin,
            //     );

            //     if (matchedSkillset && !matchedSkillset.enabled) {

            //         allEnabled = 'disabled';
            //         break;
            //     }
            // }

            setAllPluginsEnabled(allEnabled);
        }
    }, [isSkillsetsLoading, skillsets, requiredSkillsets, setAllPluginsEnabled]);

    if (requiredSkillsets.length === 0)
        return <AgentDetailsListItem>{tAgents('PlaceholderData')}</AgentDetailsListItem>;

    return (
        <>
            {isSkillsetsLoading && (
                <Skeleton appearance="translucent" animation="pulse" className={classes.skeleton}>
                    <SkeletonItem />
                </Skeleton>
            )}
            {!isSkillsetsLoading && isSkillsetsError && (
                <StatusLabel status="failed" text={tAgents('Plugins.Error')} />
            )}
            {!isSkillsetsLoading && !isSkillsetsError && skillsets && (
                <AgentDetailsList>
                    {requiredSkillsets.map((plugin: string, index: number) => {
                        const matchedSkillset = skillsets.value.find(
                            (skillset) => skillset.name === plugin,
                        );
                        return (
                            matchedSkillset && (
                                <AgentDetailsListItem
                                    key={index}
                                    data-testid={matchedSkillset.displayName}
                                    icon={
                                        <PluginIcon
                                            skillset={matchedSkillset.name}
                                            icon={matchedSkillset.icon ?? ''}
                                            // TODO: Enabled is always true only for PrP
                                            enabled={true}
                                        />
                                    }
                                >
                                    <Body1>
                                        {matchedSkillset.displayName || matchedSkillset.name}
                                    </Body1>
                                </AgentDetailsListItem>
                            )
                        );
                    })}
                </AgentDetailsList>
            )}
        </>
    );
}
