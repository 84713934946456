// ResourceGroupSelector.jsx
import React, {useEffect, useState} from 'react';
import {
    Combobox,
    Option,
    OptionGroup,
    Popover,
    PopoverTrigger,
    PopoverSurface,
    Label,
    PopoverProps,
    mergeClasses,
    Link,
    Input,
    Button,
    useId,
} from '@fluentui/react-components';
import useClasses from './ResourceGroupSelector.styles';
import useGetUserResourceGroups from '@/api/user/useGetUserResourceGroups';
import {ResourceGroup} from '@/api/user/user.types';
import {useTranslation} from 'react-i18next';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {MedeinaErrorLabel} from '@/components/ui/Buttons/MedeinaErrorLabel';
import {useViewportSize} from '@/components/ui/Grid';
import {ParagraphContent, TextSubtitle2} from '@/components/ui/Text';
import ResourceGroupInput from './SetupFields/ResourceGroup';

interface ResourceGroupSelectorProps {
    disabled: boolean;
    selectedSubscription: string;
    onResourceGroupSelect: (resourceGroupId: string, isNewResourceGroup: boolean) => void; // Optional callback
}
const ResourceGroupSelector: React.FC<ResourceGroupSelectorProps> = ({
    disabled = false,
    selectedSubscription,
    onResourceGroupSelect,
}) => {
    const classes = useClasses();
    const {t} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');
    const defaultLabelId = useId('azure-resource-group');
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [resourceGroupName, setResourceGroupName] = useState('');
    const [selectedResourceGroupOption, setSelectedResourceGroupOption] = useState<string[]>([]);
    const [resourceGroupFilterInput, setResourceGroupFilterInput] = useState<string>('');
    const [newResourceGroup, setNewResourceGroup] = useState<ResourceGroup>();
    const [isNewResourceGroup, setIsNewResourceGroup] = useState<boolean>(false);
    const {data: listResourceGroups} = useGetUserResourceGroups({
        subscriptionId: selectedSubscription,
        options: {
            enabled: !!selectedSubscription,
        },
    });
    const [dropdownResourceGroups, setDropdownResourceGroups] = useState<ResourceGroup[]>([]);

    const {sm: isSmallScreen} = useViewportSize();

    const labelSize = isSmallScreen ? 'small' : 'medium';

    const popoverSize = isSmallScreen ? 'small' : 'medium';

    const buttonSize = isSmallScreen ? 'small' : 'medium';

    const paragraphContent = (
        <ParagraphContent>{t('adminV2Fre.setup.ResourceGroupSubTitle')}</ParagraphContent>
    );

    const headingContent = <TextSubtitle2>{commonLabel('CreateResourceGroup')}</TextSubtitle2>;

    const handleResourceGroupOptionChange = (data: any) => {
        const resourceName = data?.optionText?.replace(/ \(new\)$/, '');
        setSelectedResourceGroupOption(data.selectedOptions);
        setResourceGroupName(data.optionText ?? '');
        setIsNewResourceGroup(newResourceGroup?.name === resourceName);
        onResourceGroupSelect(resourceName, isNewResourceGroup);
    };

    const handleResourceGroupInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResourceGroupFilterInput(event.target.value);
        setResourceGroupName(event.target.value);
    };

    const handleOpenChange: PopoverProps['onOpenChange'] = (e, data) =>
        setIsPopoverOpen(data.open || false);

    const handleOkResourceGroupClick = (data: string) => {
        const inputResourceGroup = {
            name: data,
            location: '',
            managedBy: 'Azure Security Center',
            tags: '',
            id: '',
            type: 'Microsoft.Resources/resourceGroups',
        };
        // Update the dropdown list
        setDropdownResourceGroups((prev) => [
            {...inputResourceGroup, name: `(new) ${data}`},
            ...prev.filter((rg) => rg.name !== data && !rg.name.startsWith('(new)')),
        ]);
        setNewResourceGroup(inputResourceGroup);
        listResourceGroups?.push(inputResourceGroup);
        setResourceGroupName(`(new) ${inputResourceGroup.name}`);
        setSelectedResourceGroupOption([`(new) ${data}`]);
        setIsNewResourceGroup(true);
        setIsPopoverOpen(false);
        onResourceGroupSelect(data ?? '', true);
    };

    useEffect(() => {
        const filteredResourceGroups = (listResourceGroups || [])
            .filter((rg) => rg.name.toLowerCase().includes(resourceGroupFilterInput.toLowerCase()))
            .sort((a, b) => a.name.localeCompare(b.name));

        setDropdownResourceGroups(filteredResourceGroups);
    }, [resourceGroupFilterInput, listResourceGroups]);

    useEffect(() => {
        // Reset states related to resource group selection
        setResourceGroupName('');
        setSelectedResourceGroupOption([]);
        setIsNewResourceGroup(false);
    }, [selectedSubscription]);

    return (
        <div className={classes.field}>
            <div className={mergeClasses(classes.leftAlignedContainer, classes.label)}>
                <MedeinaInfoLabel
                    labelContent={
                        <Label
                            id={defaultLabelId}
                            data-test-id="resource-group-label"
                            required
                            size={labelSize}
                        >
                            {t('adminV2Fre.setup.ResourceGroup')}
                        </Label>
                    }
                    infoContent={t('adminV2Fre.setup.ResourceGroupInfo')}
                    size="small"
                />
            </div>
            <div className={classes.leftAlignedContainer}>
                <Combobox
                    placeholder={t('adminV2Fre.setup.SelectResourceGroup')}
                    className={classes.dropdownSelector}
                    input={{className: classes.comboInput}}
                    required
                    aria-label={t('adminV2Fre.setup.ariaLabel.ResourceGroupDropdown')}
                    data-testid="resource-group-dropdown"
                    // we disable the field if there is no subscription ID or if the disabled prop is true
                    disabled={!selectedSubscription || disabled}
                    value={resourceGroupName}
                    onChange={handleResourceGroupInputChange}
                    onOptionSelect={(event: any, data: any) => {
                        handleResourceGroupOptionChange(data);
                    }}
                    selectedOptions={selectedResourceGroupOption}
                >
                    <OptionGroup className={classes.comboOptionGroup}>
                        {dropdownResourceGroups?.map((rg) => (
                            <Option key={rg.id} value={rg.name}>
                                {rg.name || rg.id}
                            </Option>
                        ))}
                    </OptionGroup>
                </Combobox>
            </div>
            {
                <Popover
                    open={isPopoverOpen}
                    positioning="below-start"
                    withArrow
                    onOpenChange={handleOpenChange}
                    trapFocus
                    size={popoverSize}
                >
                    <PopoverTrigger disableButtonEnhancement>
                        <Label className={mergeClasses(classes.infoLabel)}>
                            <Link
                                className={classes.resourceGroupLinkStyle}
                                disabled={!selectedSubscription}
                            >
                                {t('adminV2Fre.setup.CreateResourceGroup')}
                            </Link>
                        </Label>
                    </PopoverTrigger>

                    <PopoverSurface>
                        <ResourceGroupInput
                            dropdownResourceGroups={dropdownResourceGroups}
                            handleOkResourceGroupClick={handleOkResourceGroupClick}
                            onClose={() => {
                                setIsPopoverOpen(false);
                            }}
                            newResourceGroup={newResourceGroup}
                        />
                    </PopoverSurface>
                </Popover>
            }
        </div>
    );
};

export default ResourceGroupSelector;
