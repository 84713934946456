import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {GetWorkspaceByNameResponse} from '.';
import {DEFAULT_API_RETRY_COUNT, RESOURCE_SCOPES} from '../api.constants';
import useFetch from '../useFetch';
import securityGraphApi from '@/api/securityGraph.api';
import {isGeoPodEnabled} from '@/components/sections/workspaces/WorkspacesProvider';

export default function useGetWorkspaceByName(
    name: string,
    useQueryOptions?: Pick<
        UseQueryOptions<GetWorkspaceByNameResponse>,
        'enabled' | 'refetchOnMount'
    >,
) {
    const {customFetch} = useFetch();
    const geoPodEnabled = isGeoPodEnabled();
    const url = securityGraphApi({
        path: geoPodEnabled ? `` : `/account/workspaces/${name}`,
        version: '2023-12-01-preview',
    });

    return useQuery<GetWorkspaceByNameResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['workspaces', name],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async () =>
            await customFetch<GetWorkspaceByNameResponse>(url, {
                scopes: RESOURCE_SCOPES.FIDELIS,
                method: 'GET',
            }),
        ...useQueryOptions,
    });
}
