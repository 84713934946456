import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {AvailableTrial, AvailableTrialsResponse} from './trial.types';
import {ErrorResponse} from '../api.types';

export default function useGetAvailableTrials(
    useQueryOptions?: Pick<UseQueryOptions<AvailableTrialsResponse>, 'enabled'>,
) {
    const {customFetch} = useFetch();

    return useQuery<AvailableTrialsResponse>(['availabletrials'], {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        queryFn: async () => {
            const result = await customFetch<Response>(
                `/availabletrials`,
                {
                    method: 'GET',
                },
                true,
            );

            if (!result.ok) {
                const error: ErrorResponse = {
                    code: result.status,
                    message: result.statusText,
                };

                throw error;
            }

            const data = await result.json();
            return data as AvailableTrialsResponse;
        },
        retry: 0,
        ...useQueryOptions,
    });
}
