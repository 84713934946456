import {useQuery} from '@tanstack/react-query';
import {AgentTriggerRun, AgentTriggerRequest} from './agents.types';
import useFetch from '@/api/useFetch';

export default function useGetAgentTriggerRuns({
    agentId,
    triggerName,
    requestParams,
}: AgentTriggerRequest) {
    const {customFetch} = useFetch();

    return useQuery({
        queryKey: ['agents', agentId, 'triggers', triggerName, 'runs', requestParams],
        refetchOnWindowFocus: false,
        enabled: !!agentId && !!triggerName,
        queryFn: async () => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<{values: Array<AgentTriggerRun>}>(
                `/agents/${agentId}/triggers/${triggerName}/runs?${queryParams.toString()}`,
                {method: 'GET'},
            );
        },
        retry: false,
        refetchIntervalInBackground: false,
        refetchInterval: 10000,
    });
}
