// make hook calls here and pass them into role assignment child component
import {RoleAssignment} from '.';
import {useGetRoleMembers} from '@/api/rbac';
import {useWorkspaceState} from '../workspaces/workspaceStateProvider';
import {PolicyWorkspace} from '@/api/rbac/rbac.types';
import {usePolicyManager} from './rbac';

/** Get Workspace Policy and feed into Role Assigment */
export default function RoleAssignmentParent() {
    const {workspaceName} = useWorkspaceState();
    const {
        data: dataShareResponse,
        isLoading: dataShareLoading,
        isFetched: dataShareFetched,
        isSuccess: dataShareIsSuccessful,
        isError: dataShareError,
        isRefetching: dataShareRefetching,
    } = useGetRoleMembers(workspaceName || '', undefined, {enabled: !!workspaceName});
    const policyManager = usePolicyManager({
        workspacePolicy: dataShareResponse as PolicyWorkspace,
        workspaceName: dataShareResponse?.properties?.entity?.referenceName as string,
    });

    return (
        <RoleAssignment
            dataShareResponse={dataShareResponse ?? ({} as PolicyWorkspace)}
            dataShareLoading={dataShareLoading}
            dataShareFetched={dataShareFetched}
            dataShareIsSuccessful={dataShareIsSuccessful}
            dataShareError={dataShareError}
            dataShareRefetching={dataShareRefetching}
            policyManager={policyManager}
        />
    );
}
