import GroupItem from '../Group/GroupItem';
import RoleItem from '../RoleItem/RoleItem';
import UserItem from '../UserItem/UserItem';
import {MemberListItemProps, MemberType} from '../rbac.types';

export default function MemberListItem(props: MemberListItemProps) {
    return (
        <>
            {props.type === MemberType.User && (
                <UserItem
                    name={props.name}
                    email={props.email || ''}
                    id={props.memberId}
                ></UserItem>
            )}
            {props.type === MemberType.Group && (
                <GroupItem
                    name={props.name}
                    memberCount={props.memberCount || 0}
                    id={props.memberId}
                ></GroupItem>
            )}
            {props.type === MemberType.Role && (
                <RoleItem
                    name={props.name}
                    id={props.memberId}
                    hideLink={props.hideLinks}
                ></RoleItem>
            )}
            {props.type === MemberType.ServicePrincipal && (
                <UserItem
                    name={props.name}
                    email={props.email || ''}
                    id={props.memberId}
                ></UserItem>
            )}
        </>
    );
}
