import {useMemo} from 'react';
import {AgentExecution, AgentTriggerRequest, AgentTriggerRun} from './agents.types';
import useGetAgentTriggerRun from './useGetAgentTriggerRun';
import useGetAgentTriggerExecution from './useGetAgentTriggerExecution';

type AgentTriggerRunDetailsResponse = {
    triggerRun: AgentTriggerRun | undefined;
    triggerExecution: AgentExecution | undefined;
    isLoading: boolean;
    isError: boolean;
    isFailedOrPendingRun: boolean;
};

/**
 * Hook for retrieving all details (trigger run, execution, status) around an agent trigger run.
 */
export default function useGetAgentTriggerRunDetails({
    agentId,
    triggerName,
    runId,
    requestParams,
}: AgentTriggerRequest): AgentTriggerRunDetailsResponse {
    const {
        data: triggerRun,
        isLoading: isTriggerRunLoading,
        isError: isTriggerRunError,
    } = useGetAgentTriggerRun({
        agentId: agentId,
        triggerName: triggerName,
        runId: runId,
        requestParams,
    });

    const {
        data: triggerExecution,
        isLoading: isTriggerExecutionLoading,
        isError: isTriggerExecutionError,
    } = useGetAgentTriggerExecution({
        agentId: agentId,
        triggerName: triggerName,
        executionId: triggerRun?.executionId,
        requestParams,
    });

    const isLoading = useMemo(() => {
        return Boolean(
            isTriggerRunLoading || (triggerRun?.executionId && isTriggerExecutionLoading),
        );
    }, [isTriggerExecutionLoading, isTriggerRunLoading, triggerRun]);

    const isError = useMemo(() => {
        return Boolean(isTriggerRunError || (triggerRun?.executionId && isTriggerExecutionError));
    }, [isTriggerExecutionError, isTriggerRunError, triggerRun]);

    const isFailedOrPendingRun = useMemo(() => {
        return Boolean(
            !triggerRun?.sessionId ||
                !triggerRun?.evaluationId ||
                !triggerRun?.promptId ||
                triggerRun?.status === 'Failed',
        );
    }, [triggerRun]);

    return {
        triggerRun: triggerRun,
        triggerExecution: triggerExecution,
        isFailedOrPendingRun,
        isLoading,
        isError,
    };
}
