import {useMutation, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {RESOURCE_SCOPES} from '../api.constants';
import {GetWorkspaceCfSByNameResponse, Workspace, WorkspaceSettings} from './workspaces.types';
import securityGraphApi from '@/api/securityGraph.api';
interface CreateWorkspaceSettingsParams {
    workspaceSettings: WorkspaceSettings;
    targetWorkspace?: Workspace;
}
export default function useCreateWorkspaceSettings(
    useQueryOptions?: Pick<
        UseQueryOptions<GetWorkspaceCfSByNameResponse>,
        'enabled' | 'refetchOnMount'
    >,
) {
    const {customFetch} = useFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({workspaceSettings, targetWorkspace}: CreateWorkspaceSettingsParams) => {
            if (useQueryOptions?.enabled === false) {
                return {
                    mutate: () => {},
                    data: undefined,
                    isFetched: false,
                    isLoading: false,
                    error: undefined,
                    isError: false,
                    isSuccess: false,
                    refetch: () => {},
                };
            }
            const getUrl = () =>
                securityGraphApi({
                    path: '/securitycopilot/WorkspaceSettings',
                    version: '',
                    targetWorkspace: targetWorkspace,
                });
            try {
                return await customFetch<GetWorkspaceCfSByNameResponse>(
                    getUrl(),
                    {
                        method: 'POST',
                        body: workspaceSettings,
                        scopes: RESOURCE_SCOPES.FIDELIS,
                    },
                    true,
                );
            } catch (e) {
                throw e;
            }
        },
        onSuccess: (data, variables, context) => {},
        onSettled: (data, error, variables, context) => {
            queryClient.removeQueries(['workspaces']);
        },
        onError: (error, variables) => {},
        retry: 0,
    });
}
