import {
    Spinner,
    useToastController,
    Toast,
    ToastTitle,
    useId,
    Toaster,
    ToastTrigger,
    ToastFooter,
    Link,
    Button,
    ToastPosition,
} from '@fluentui/react-components';
import {Intent, ToastNotificationsProps, ToastPositionUI} from './ToastNotification.types';
import useClasses from './ToastNotification.styles';
import {DismissIcon} from '../icons';
import {useEffect} from 'react';

export default function ToastNotification({
    intent,
    message,
    sessionId = undefined,
    position = undefined,
    timeout = null,
    horizontal = 10,
    vertical = 33,
}: ToastNotificationsProps) {
    const classes = useClasses();
    const toasterId = useId('toaster');
    const {dispatchToast} = useToastController(toasterId);
    const defaultTimeout = 5000;
    const defaultPosition = ToastPositionUI.Top_End;
    useEffect(() => {
        const toastPosition = !!position ? position : defaultPosition;
        const toastTimeout = !!timeout ? timeout : defaultTimeout;
        const evaluatedSessionId = sessionId ? sessionId : undefined;
        notify(intent, message, evaluatedSessionId, toastPosition, toastTimeout);
    }, [intent, position, timeout, sessionId]);

    const notify = (
        intent: string,
        message: string,
        sessionId: string | undefined,
        toastPosition: ToastPosition,
        toastTimeout: number,
    ) => {
        switch (intent) {
            case Intent.Progress:
                dispatchToast(
                    <Toast className={classes.root}>
                        <ToastTitle
                            data-testid="toast-progress"
                            media={<Spinner size="tiny" />}
                            action={
                                <ToastTrigger>
                                    <Button
                                        appearance="subtle"
                                        aria-label="close"
                                        icon={<DismissIcon />}
                                    />
                                </ToastTrigger>
                            }
                        >
                            {message}
                        </ToastTitle>
                    </Toast>,
                    {position: toastPosition, timeout: toastTimeout},
                );
                break;
            case Intent.Error:
                dispatchToast(
                    <Toast className={classes.root}>
                        <ToastTitle
                            data-testid="toast-failed"
                            action={
                                <ToastTrigger>
                                    <Button
                                        appearance="subtle"
                                        aria-label="close"
                                        icon={<DismissIcon />}
                                    />
                                </ToastTrigger>
                            }
                        >
                            {message}
                        </ToastTitle>
                    </Toast>,
                    {position: toastPosition, intent, timeout: toastTimeout},
                );
                break;
            case Intent.Info:
            case Intent.Warning:
                break;
            case Intent.Success:
                dispatchToast(
                    <Toast className={classes.root}>
                        <ToastTitle
                            data-testid="toast-success"
                            action={
                                <ToastTrigger>
                                    <Button
                                        appearance="subtle"
                                        aria-label="close"
                                        icon={<DismissIcon />}
                                    />
                                </ToastTrigger>
                            }
                        >
                            {message}
                        </ToastTitle>
                        {sessionId && (
                            <ToastFooter>
                                <Link href={`/sessions/${sessionId}`}>Open session</Link>
                            </ToastFooter>
                        )}
                    </Toast>,
                    {pauseOnHover: true, position: toastPosition, intent, timeout: toastTimeout},
                );
        }
    };

    return (
        <div className={classes.toast}>
            <Toaster toasterId={toasterId} limit={1} offset={{horizontal, vertical}} />
        </div>
    );
}
