import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentRunInputHeader.styles';
import {
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionPanel,
    Subtitle1,
    Body1,
    Body1Strong,
} from '@fluentui/react-components';
import {AgentExecution} from '@/api/agents';

interface AgentRunInputHeaderProps {
    fetchSkillInputs: AgentExecution['fetchSkillInputs'] | undefined;
}

export default function AgentRunInputHeader(props: AgentRunInputHeaderProps) {
    const {fetchSkillInputs} = props;
    const classes = useClasses();

    const {t: tAgents} = useTranslation('agents');

    const inputs = useMemo(() => {
        return Object.entries(fetchSkillInputs || {}).map(([key, value]) => {
            return {
                label: key,
                value: value,
            };
        });
    }, [fetchSkillInputs]);

    if (!inputs || inputs.length === 0) return null;

    return (
        <div className={classes.inputHeader}>
            <Accordion collapsible>
                <AccordionItem value="1">
                    <AccordionHeader expandIconPosition="end" className={classes.accordionHeader}>
                        <Subtitle1>{tAgents('AgentRun.Body.InputHeader')}</Subtitle1>
                    </AccordionHeader>
                    <AccordionPanel
                        className={classes.accordionPanel}
                        data-testid="agent-run-inputs-panel"
                    >
                        <div className={classes.inputs}>
                            {inputs.map((input, index) => (
                                <div key={index} className={classes.inputItem}>
                                    <Body1Strong>{input.label}</Body1Strong>
                                    <Body1>{input.value ?? tAgents('PlaceholderData')}</Body1>
                                </div>
                            ))}
                        </div>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
}
