import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export const evaluationResultProperties = {
    ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXXXL),
};

export default makeStyles({
    root: {
        width: '100%',
    },
    unknownEvaluation: {
        ...shorthands.padding(0, 0),
    },
    evaluation: {
        ...evaluationResultProperties,
    },
    cancelEvaluation: {
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXXL),
        backgroundColor: tokens.colorNeutralBackground1,
        display: 'flex',
        justifyContent: 'center',
    },
    loadingAgent: {
        ...shorthands.padding(tokens.spacingVerticalS, 0),
        display: 'flex',
        justifyContent: 'center',
    },
    cancelbutton: {
        marginLeft: tokens.spacingHorizontalS,
    },
    divider: {
        ...shorthands.padding('0px', tokens.spacingHorizontalXXL),
        '::before': {
            ...shorthands.borderColor(tokens.colorNeutralBackground5),
            ...shorthands.borderWidth('2px'),
            left: 0,
        },
        '::after': {
            ...shorthands.borderColor(tokens.colorNeutralBackground5),
            ...shorthands.borderWidth('2px'),
            right: 0,
        },
        backgroundColor: tokens.colorNeutralBackground1,
    },
});
