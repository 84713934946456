import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components';
import useClasses from './UpdateCapacityConfirmation.styles';
import {DismissIcon, ExternalLinkIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {ARIA_LABELS, CONTROL_LABELS, STATIC_CONTENT} from '../ChangeCapacity.constants';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import useAzureCapacityDependencyLinkParts from '@/util/useAzureCapacityDependencyLinkParts';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {OverageState} from '@/api/capacities';
import {useEffect, useState} from 'react';

interface UpdateCapacityConfirmationProps {
    isUpdateCapacityConfirmDialogOpen: boolean;
    onUpdateCapacityConfirmDialogClose: () => void;
    capacityValueToUpdate: number;
    isOverageEnabled?: boolean;
    overageCapacity: number;
    overageState?: OverageState;
    isOverageNull?: boolean;
}

export default function UpdateCapacityConfirmation(props: UpdateCapacityConfirmationProps) {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    const [confirmationTitleText, setConfirmationTitleText] = useState<string>('');
    const {
        isUpdateCapacityConfirmDialogOpen,
        onUpdateCapacityConfirmDialogClose,
        capacityValueToUpdate,
        overageCapacity,
        isOverageEnabled,
        isOverageNull,
    } = props;

    useEffect(() => {
        if (isOverageEnabled && !isOverageNull) {
            if (OverageState.None === props.overageState) {
                setConfirmationTitleText(
                    t(STATIC_CONTENT.UPDATE_CAPACITY_SUCCESS_HEADER_OVERAGE_NONE, {
                        numUnits: capacityValueToUpdate,
                    }),
                );
            } else if (OverageState.Unlimited === props.overageState) {
                setConfirmationTitleText(
                    t(STATIC_CONTENT.UPDATE_CAPACITY_SUCCESS_HEADER_OVERAGE_NO_LIMIT, {
                        numUnits: capacityValueToUpdate,
                    }),
                );
            } else {
                setConfirmationTitleText(
                    t(STATIC_CONTENT.UPDATE_CAPACITY_SUCCESS_HEADER_OVERAGE, {
                        numUnits: capacityValueToUpdate,
                        overageLimit: overageCapacity,
                    }),
                );
            }
        } else {
            const dynamicText =
                capacityValueToUpdate > 1
                    ? t(STATIC_CONTENT.UPDATE_CAPACITY_SUCCESS_HEADER_UNITS_MULTIPLE)
                    : t(STATIC_CONTENT.UPDATE_CAPACITY_SUCCESS_HEADER_UNIT_SINGLE);
            setConfirmationTitleText(
                t(STATIC_CONTENT.UPDATE_CAPACITY_SUCCESS_HEADER, {
                    numUnits: capacityValueToUpdate,
                    unitsText: dynamicText,
                }),
            );
        }
    }, [props.overageState, isOverageEnabled, t, capacityValueToUpdate, overageCapacity]);

    const {capacityId} = useWorkspaceState();
    const tenantId = getCurrentTenantId();
    const {getBillingTabUrlOnSubscriptionPage} = useAzureCapacityDependencyLinkParts({
        capacityId: capacityId || '',
        tenantId,
    });

    return (
        <Dialog
            open={isUpdateCapacityConfirmDialogOpen}
            onOpenChange={onUpdateCapacityConfirmDialogClose}
        >
            <DialogSurface>
                <DialogTrigger disableButtonEnhancement>
                    <Button
                        data-test-id="update-capacity-dialog-close-button"
                        className={classes.closeButton}
                        appearance="transparent"
                        aria-label={t(ARIA_LABELS.CLOSE_UPDATE_CAPACITY_DIALOG)}
                    >
                        <DismissIcon className={classes.dismissIcon} />
                    </Button>
                </DialogTrigger>
                <DialogBody>
                    <DialogTitle className={classes.dialogContentSectionHeader}>
                        {confirmationTitleText}
                    </DialogTitle>
                    <DialogContent className={classes.dialogContentSection}>
                        {t(STATIC_CONTENT.MODAL_INFO_TEXT)}
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger>
                            <Button appearance="primary">{t(CONTROL_LABELS.DONE)}</Button>
                        </DialogTrigger>
                        <Button
                            as="a"
                            icon={<ExternalLinkIcon />}
                            iconPosition="after"
                            appearance="secondary"
                            className={classes.manageBillingButton}
                            href={getBillingTabUrlOnSubscriptionPage()}
                            target="_blank"
                        >
                            {t(CONTROL_LABELS.MANAGE_BILLING_AZURE)}
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
