import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import MedeinaVariables from '@/util/variables';
import getEffectiveLanguage from '@/util/locale/getLanguage';
import {hasStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import MedeinaFeatures from '@/util/features';

const effectiveLanguage = getEffectiveLanguage();
const EnableLocalization = hasStaticFeatureFlag(MedeinaFeatures.EnableLocalization);
const ShowAppLanguage = hasStaticFeatureFlag(MedeinaFeatures.ShowAppLanguage);
const DisableLocalization = hasStaticFeatureFlag(MedeinaFeatures.DisableLocalization);

const isLocalizationEnabled = (EnableLocalization || ShowAppLanguage) && !DisableLocalization;
const lng = isLocalizationEnabled ? effectiveLanguage : 'en';

i18n.use(HttpApi)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        //resources,
        fallbackLng: 'en',
        // stored user preference > user language > browser language (supported) > default language
        lng: lng,
        ns: [
            'common',
            'mysessions',
            'session',
            'promptbar',
            'plugins',
            'promptbooks',
            'tours',
            'admin',
            'timezones',
            'agents',
            'pluginBuilder',
        ],
        defaultNS: 'common',
        // backend: {
        //     loadPath: '/languages/{{lng}}/{{ns}}.json',
        // },
        // TODO: Lift this out of the config
        // while we are developing we will only serve the english version and it will be mapped to the directory '/strings' instead of 'en
        backend: {
            loadPath: (lng: Array<string>, ns: Array<string>) => {
                // if the feature flag is enabled, we will serve the language strings from the language directory
                // this is only for development purposes while we are working on the translations
                if (!isLocalizationEnabled) {
                    return `${MedeinaVariables.PortalUri}/languages/strings/${ns}.json`;
                } else {
                    if (lng[0] === 'en') {
                        return `${MedeinaVariables.PortalUri}/languages/strings/${ns}.json`;
                    }
                    return `${MedeinaVariables.PortalUri}/languages/${lng}/${ns}.json`;
                }
            },
        },
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
