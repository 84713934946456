import useManageUsageDashboardState from './useManageUsageDashboardState';
import useManageUsageDashboardDataSources from './useManageUsageDashboardDataSources';
import {OverageCapacityDisplayData} from './UsageDashboard.types';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {useMemo} from 'react';
import {formatUsageMetric} from './UsageDashboard.utils';

interface ManageUsageDashboardProps {
    capacityName: string;
}

export default function useManageUsageDashboard(props: ManageUsageDashboardProps) {
    let isOverageEnabled = useFeatureFlag(MedeinaFeatures.EnableUsageDashboardOverage);
    let isNewMswUiEnabled = useFeatureFlag(MedeinaFeatures.EnableNewMswOverageUx);
    let isAgentUsageDashboardEnabled = useFeatureFlag(MedeinaFeatures.EnableAgentUsageDashboard);

    const {
        getState,
        updateTimePeriod,
        updatePageNumber,
        updateFilters,
        drillDownByDateRange,
        removeDateRangeDrillDown,
        needsFacetRefresh,
        needsHourlyDataRefresh,
        needsDimensionalDataRefresh,
        updateSortDirection,
    } = useManageUsageDashboardState();

    const {
        hourlyDataFilteredModel,
        hourlyDataUnfilteredModel,
        dimensionalDataModel,
        facetDataModel,
        isDataFacetsError,
        isHourlyCapacityUsageError,
        isDimensionalDataError,
        hourlyCapacityUsageDataUpdatedAt,
        isDimensionalDataLoading,
        dataFacetsLoading,
        hourlyCapacityUsageLoading,
        isAggregateDataFetched,
        fetchingMultipleDataSets,
    } = useManageUsageDashboardDataSources({
        capacityName: props.capacityName,
        state: getState(),
        isOverageEnabled: Boolean(isOverageEnabled),
        isAgentUsageDashboardEnabled: Boolean(isAgentUsageDashboardEnabled),
    });

    const isUsageDataLoading =
        dataFacetsLoading || hourlyCapacityUsageLoading || isDimensionalDataLoading;

    const pageLoading =
        needsDimensionalDataRefresh &&
        needsFacetRefresh &&
        needsHourlyDataRefresh &&
        isUsageDataLoading;

    const pageError = isDataFacetsError || isHourlyCapacityUsageError || isDimensionalDataError;

    const isFilteredSearchState: boolean | null = useMemo(() => {
        return getState()?.searchFilters?.length > 0;
    }, [getState()?.searchFilters]);

    const lastUpdatedAt = hourlyCapacityUsageDataUpdatedAt || new Date();

    const usageMetrics: OverageCapacityDisplayData = useMemo(() => {
        let metrics: OverageCapacityDisplayData = {
            provisionedUnitsAvailable: 0,
            provisionedUnitsUsed: 0,
            overageUnitsLimit: 0,
            overageUnitsUsed: 0,
            overageInCurrentBilingPeriod: 0,
        };

        if (
            Boolean(isOverageEnabled) &&
            hourlyDataUnfilteredModel &&
            (hourlyDataUnfilteredModel?.value?.length ?? 0) > 0
        ) {
            //get the last record
            const lastRecord =
                hourlyDataUnfilteredModel?.value[hourlyDataUnfilteredModel.value.length - 1];

            if (lastRecord) {
                metrics = {
                    provisionedUnitsAvailable: formatUsageMetric(lastRecord.assignedCapacity),
                    provisionedUnitsUsed: formatUsageMetric(lastRecord.provisionedSCUUsed ?? 0),
                    overageUnitsLimit: formatUsageMetric(lastRecord.overageLimit ?? 0),
                    overageUnitsUsed: formatUsageMetric(lastRecord.overageSCUUsed ?? 0),
                    overageInCurrentBilingPeriod: parseFloat(
                        (lastRecord.overageInBillingPeriod || 0).toFixed(2),
                    ),
                };
            }
        }

        return metrics;
    }, [hourlyDataUnfilteredModel]);

    return {
        getState,
        hourlyDataFilteredModel,
        dimensionalDataModel,
        facetDataModel,
        updateTimePeriod,
        updatePageNumber,
        updateFilters,
        updateSortDirection,
        drillDownByDateRange,
        removeDateRangeDrillDown,
        pageLoading,
        isEvaluationAggregateDataLoading: !isAggregateDataFetched,
        shouldRefreshFullVisualization: fetchingMultipleDataSets,
        pageError,
        lastUpdatedAt,
        dateRangeOption: getState()?.dateText ?? '',
        usageMetrics,
        isOverageEnabled: isOverageEnabled,
        isNewMswUiEnabled,
        isFilteredSearchState,
        isAgentUsageDashboardEnabled: isAgentUsageDashboardEnabled,
    };
}
