import {useCallback} from 'react';
import {Body1, Button, DialogActions, DialogContent, DialogTitle} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {motion} from 'framer-motion';
import {DismissIcon} from '@/components/ui/icons';
import AgentConfigurationDialogOverlay from '../AgentConfigurationDialogOverlay/AgentConfigurationDialogOverlay';
import AgentSettingsConfiguration from './AgentSettingsConfiguration';
import useAgentSettingsConfigurationForm from './useAgentSettingsConfigurationForm';
import {AgentScopeTypes, usePatchAgent, type Agent, type AgentDefinition} from '@/api/agents';
import useAgentConfigurationDialogClasses from '../../AgentConfigurationDialog.styles';

export interface AgentSettingsConfigurationDialogContentProps {
    agentDefinition: AgentDefinition;
    agent: Agent | undefined;
    mode: 'create' | 'edit';
    onClose?: () => void;
    onNext?: () => void;
    isFinalDialogStep?: boolean;
}

export default function AgentSettingsConfigurationDialogContent({
    agentDefinition,
    agent,
    mode,
    onClose,
    onNext,
    isFinalDialogStep = false,
}: AgentSettingsConfigurationDialogContentProps) {
    const {t} = useTranslation(['common', 'agents']);
    const dialogClasses = useAgentConfigurationDialogClasses();
    const {
        formValues,
        setFormValues,
        failedValidationSettings,
        validateSettings,
        processFormValues,
    } = useAgentSettingsConfigurationForm(agentDefinition, agent);

    const {
        mutateAsync: updateAgent,
        isLoading: isUpdateAgentLoading,
        isError: isUpdateAgentError,
        isSuccess: isUpdateAgentSuccess,
    } = usePatchAgent({
        scope: AgentScopeTypes.WORKSPACE,
    });

    const updateAgentAsync = useCallback(async () => {
        if (agent) {
            const {settings, triggerSettings} = processFormValues();

            await updateAgent({
                agentId: agent.agentId,
                body: {
                    settings: Object.fromEntries(settings),
                    triggers: [
                        {
                            name: agent.triggers?.[0]?.name,
                            settings: Object.fromEntries(triggerSettings),
                            ...(mode === 'create' && {enabled: true, pollPeriodSeconds: 0}),
                        },
                    ],
                    ...(mode === 'create' && {enabled: true}),
                },
            });
        }
    }, [agent, mode, processFormValues, updateAgent]);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleNext = useCallback(async () => {
        if (!validateSettings()) {
            return;
        }

        await updateAgentAsync();
        onNext?.();
    }, [validateSettings, updateAgentAsync, onNext]);

    return (
        <>
            {isUpdateAgentLoading && (
                <AgentConfigurationDialogOverlay showUpdateAgentLoading onClose={handleClose} />
            )}
            {isUpdateAgentError && (
                <AgentConfigurationDialogOverlay
                    showUpdatingError
                    showCancelButton
                    showTryAgainButton
                    onClose={handleClose}
                    onTryAgain={updateAgentAsync}
                />
            )}
            {!isUpdateAgentLoading && !isUpdateAgentError && !isUpdateAgentSuccess && (
                <>
                    <DialogTitle
                        as="h1"
                        className={dialogClasses.title}
                        action={
                            <Button
                                autoFocus
                                appearance="subtle"
                                icon={<DismissIcon />}
                                onClick={handleClose}
                                aria-label={t('common:ButtonLabels.Close')}
                            />
                        }
                    >
                        {mode === 'create'
                            ? t('agents:AgentConfiguration.CreateTitle')
                            : t('agents:AgentConfiguration.EditTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{delay: 0, duration: 0.3}}
                            exit={{opacity: 0, transition: {duration: 0.3}}}
                        >
                            <Body1>{t('agents:AgentConfiguration.SettingsDescription')}</Body1>
                            <AgentSettingsConfiguration
                                agentDefinition={agentDefinition}
                                formValues={formValues}
                                setFormValues={setFormValues}
                                failedValidationSettings={failedValidationSettings}
                            />
                        </motion.div>
                    </DialogContent>
                    <DialogActions>
                        <Button appearance="secondary" onClick={handleClose}>
                            {t('common:ButtonLabels.Cancel')}
                        </Button>
                        <Button appearance="primary" onClick={handleNext}>
                            {isFinalDialogStep
                                ? t('common:ButtonLabels.Finish')
                                : t(`common:ButtonLabels.Next`)}
                        </Button>
                    </DialogActions>
                </>
            )}
        </>
    );
}
