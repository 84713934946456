import {IUsageParameterDisplayConfigurator} from '../UsageParametersDisplay.types';
import parameterDisplayConfiguratorsUtils from './parameterDisplayConfigurators.utils';

export class MultiColumnConfigurator implements IUsageParameterDisplayConfigurator {
    private parameterDisplayConfiguratorsUtils;

    constructor(classes: Record<string, string>) {
        this.parameterDisplayConfiguratorsUtils = parameterDisplayConfiguratorsUtils(classes);
    }

    generateStructuredUsageParameterElements(elementsToRender: JSX.Element[]): JSX.Element[] {
        let lengthOfElements = elementsToRender.length;
        let elementSet: JSX.Element[] = [];
        let elementRender: JSX.Element[] = [];
        let elements = [...elementsToRender];

        while (lengthOfElements > 0) {
            let currentElement = elements.shift() as JSX.Element;
            elementSet.push(currentElement);
            if (elementSet.length === 2) {
                elementRender.push(
                    this.parameterDisplayConfiguratorsUtils.generateRowDisplayElement(elementSet),
                );
                elementSet = [];
            }
            lengthOfElements -= 1;
        }

        if (elementSet.length > 0) {
            elementRender.push(
                this.parameterDisplayConfiguratorsUtils.generateRowDisplayElement(elementSet),
            );
        }
        return elementRender;
    }
}
