/**
 * Props for the BottomRow component.
 *
 * @interface BottomRowProps
 * @property {UsageParametersDisplayConfiguration} displayConfiguration - Configuration object for displaying usage parameters.
 * @property {boolean} isOverageEnabled - Flag indicating if overage is enabled.
 */

/**
 * BottomRow component displays a row with change units components and a change capacity dialog.
 *
 * @param {BottomRowProps} props - The properties for the BottomRow component.
 * @returns {JSX.Element} The rendered BottomRow component.
 *
 * @component
 * @example
 * const displayConfiguration = {
 *   hideAll: false,
 *   showChangeUnitsComponents: true
 * };
 * const isOverageEnabled = true;
 *
 * return (
 *   <BottomRow
 *     displayConfiguration={displayConfiguration}
 *     isOverageEnabled={isOverageEnabled}
 *   />
 * );
 */
import {useState} from 'react';
import ChangeUnitsButtonPalette from '../../ChangeCapacityDialog/ChangeUnitsButtonPalette';
import {UsageParametersDisplayConfiguration} from '../UsageDashboard.types';
import ChangeCapacity from '../../ChangeCapacityDialog/ChangeCapacity';

export interface BottomRowProps {
    displayConfiguration: UsageParametersDisplayConfiguration;
    isOverageEnabled: boolean;
    selectedCapacity?: string;
    areTrialsEnabled: boolean;
}
export default function BottomRow(props: BottomRowProps) {
    const [isChangeCapacityDialogOpen, setIsChangeCapacityDialogOpen] = useState(false);
    const [shouldFocusChangeUnitsButton, setShouldFocusChangeUnitsButton] = useState(false);

    const performActionsOnCapacityDialogClosure = () => {
        setIsChangeCapacityDialogOpen(false);
        setShouldFocusChangeUnitsButton(true);
    };

    return (
        <>
            {!props.displayConfiguration.hideAll && (
                <>
                    {props.displayConfiguration.showChangeUnitsComponents && (
                        <ChangeUnitsButtonPalette
                            shouldFocusChangeUnitsButton={shouldFocusChangeUnitsButton}
                            onChangeUnitsClick={() => {
                                setIsChangeCapacityDialogOpen(true);
                                setShouldFocusChangeUnitsButton(false);
                            }}
                            showBillingButton={false}
                            primaryButtonAppearance="secondary"
                        ></ChangeUnitsButtonPalette>
                    )}
                    {props.displayConfiguration.showChangeUnitsComponents && (
                        <ChangeCapacity
                            isChangeCapacityDialogOpen={isChangeCapacityDialogOpen}
                            onChangeCapacityDialogClose={performActionsOnCapacityDialogClosure}
                            isOverageEnabled={props.isOverageEnabled}
                            selectedCapacity={props.selectedCapacity}
                            areTrialsEnabled={props.areTrialsEnabled}
                        />
                    )}
                </>
            )}
        </>
    );
}
