import useClasses from './OrganizationData.styles';
import {useTranslation} from 'react-i18next';
import {useGetUserInfo} from '@/api/app';
import {useGetTenantInfo} from '@/api/tenant';
import PluginSettings from '@/components/admin/PluginSettings';
import FileSettings from '@/components/admin/FileSettings';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import AuditLoggingSettings from './AuditLoggingSettings';
import {DataOptIn} from './DataOptIn';

interface OrganizationDataProps {
    workspaceName: string;
}

export default function OrganizationData(props: OrganizationDataProps) {
    const classes = useClasses();
    const {data: tenantInfo, isSuccess: isTenantInfoSuccess} = useGetTenantInfo();
    const userPluginManagementAllowed = useFeatureFlag(
        MedeinaFeatures.IsUserPluginManagementAllowedByAdminEnabled,
    );

    const isAuditLoggingEnabled = useFeatureFlag(MedeinaFeatures.AuditLoggingOptingEnabled);

    const {data: userInfo} = useGetUserInfo();
    const isAdmin = userInfo?.isAdmin ?? false;
    const isGlobalAdmin = userInfo?.isGlobalAdmin ?? false;

    return (
        <div className={classes.root}>
            <div>
                <DataOptIn />
            </div>
            {!userPluginManagementAllowed && (
                <PluginSettings
                    isAdmin={isAdmin}
                    isGlobalAdmin={isGlobalAdmin}
                    tenantInfo={tenantInfo}
                />
            )}
            {isAuditLoggingEnabled && <AuditLoggingSettings includeInfoLabel={true} />}

            <FileSettings tenantInfo={tenantInfo} isAdmin={isAdmin} isGlobalAdmin={isGlobalAdmin} />
        </div>
    );
}
