import {useState, useMemo, useEffect, useCallback, ChangeEvent} from 'react';
import {
    Field,
    FieldProps,
    Checkbox,
    CheckboxOnChangeData,
    Label,
    Link,
} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {ValidationState, FieldValidationState} from '@/components/ui/forms';
import MedeinaVariables from '@/util/variables';

interface TermsConditionProps {
    value?: boolean;
    required?: boolean;
    onChange?: (checked: boolean) => void;
    fieldProps?: FieldProps;
    disabled?: boolean;
}

export default function TermsCondition(props: TermsConditionProps) {
    const {value: propValue, fieldProps, required, disabled, onChange} = props;
    // translation related functions by namespace
    const {t} = useTranslation('admin');

    // the value of the component is handled externally, update the internal value only when the prop value changes
    useEffect(() => {
        if (propValue !== value) {
            setValue(propValue || false);
        }
        // only trigger change when the prop value changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propValue]);

    const [value, setValue] = useState<boolean>(propValue || false);
    const [validationState, setValidationState] = useState<FieldValidationState>({});

    // When the input changes and we have a value AND we are preventing duplicates
    // use the workspace data to update the validation state of the input
    const handleChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>, data: CheckboxOnChangeData) => {
            const checked = data.checked;
            if (!disabled) {
                if (onChange) {
                    onChange(typeof checked !== 'string' ? checked : false);
                }
            }
        },
        [disabled, onChange],
    );

    const fieldValidationProps = useMemo(() => {
        if (validationState.error) {
            return {
                validationState: 'error' as ValidationState,
                validationMessage: validationState.message,
            };
        }
        // if there's no error return a blank object that will be spread over Field
        return {};
    }, [validationState]);

    const checkboxLabel = useMemo(() => {
        return (
            <Label>
                {t('common.TermsConditionsLabel')}{' '}
                <Link
                    href={MedeinaVariables.TermsAndConditionsUri}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('common.ViewTermsAndConditions')}
                </Link>
            </Label>
        );
    }, []);

    return (
        <Field {...fieldValidationProps} required={required}>
            <Checkbox
                data-testid="terms-conditions-checkbox"
                checked={value}
                label={checkboxLabel}
                labelPosition="after"
                aria-label={t('ownerSettings.organizationData.ariaLabel.TermsCondition')}
                onChange={handleChange}
            />
        </Field>
    );
}
