import {Capacity} from './capacities.types';
import {parseISO} from 'date-fns';

export default function useIsCapacityExpired() {
    return (capacity: Capacity) => {
        if (capacity.properties?.expiresAt) {
            const expiryDate: Date = new Date(capacity.properties.expiresAt);
            const isoString = expiryDate.toISOString();
            const date = parseISO(isoString);
            return Date.now() > date.getTime();
        }
        return false;
    };
}
