import useClasses from './UsageDashboard.styles';
import {Button, mergeClasses, Spinner, Tab, TabList} from '@fluentui/react-components';
import VStackedBarChart from './BarChart/VStackedBarChart';
import {
    ApiResponseDisplays,
    AriaLabels,
    PAGE_LOADING,
    USAGE_DATA_LOADING,
    CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE,
    CAPACITIES_SUBHEADING,
} from './UsageDashboard.constants';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import useIsOwner from '@/api/app/useGetIsOwner';
import {useGetCapacities, useGetCapacityByName} from '@/api/capacities';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {ApiResponseDisplay} from './ApiResponseDisplay/ApiResponseDisplay';
import {subDays} from 'date-fns';
import {useTranslation} from 'react-i18next';
import DataTableV2 from './DataTable/DataTableV2';
import {ParagraphContent} from '@/components/ui/Text';
import {GridContainer, useViewportSize} from '@/components/ui/Grid';
import UsageDashboardFilterContainer from './Filter/UsageDashboardFilterContainer';
import useManageUsageDashboard from './useManageUsageDashboard';
import {DateFilterChangedProps} from './DateFilterDropdown/DateFilterDropdown.types';
import UsageDashboardDataExport from './UsageDashboardDataExport';
import useNavActions from '@/components/Navigation/useNavActions';
import DateFilterDropdownV2 from './DateFilterDropdown/DateFilterDropdownV2';
import {AnimatePresence, motion} from 'framer-motion';
import useDataTableV2Transitions from './DataTable/useDataTableV2Transitions';
import {convertFacetsToSelectedFilters} from './UsageDashboard.utils';
import {
    Capacity,
    KeyValueFilterDetails,
    OverageState,
    SelectedSearchFacet,
} from '@/api/capacities/capacities.types';
import {SearchCategory} from './UsageDashboard.types';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {Workspace, useGetWorkspaces} from '@/api/workspaces';
import SelectedFilterPillsSection from './SelectedFilterPillsSection';
import React from 'react';
import UsageDashboardHeading from './UsageDashboardHeading';
import CapacityAllocationSection from './CapacityAllocationSection';
import CentralDashboardContainer from './UsageParametersDisplay/CentralDashboardContainer';
import CapacityTabList from './CapacityTabList';
import {useWorkspacesContext} from '../../workspaces/WorkspacesProvider/WorkspacesContext';
import useGetAvailableCapacities from '@/api/capacities/useGetAvailableCapacities';

export default function UsageDashboardV2() {
    const classes = useClasses();
    const {sm} = useViewportSize();
    const {t: tAdmin} = useTranslation('admin');
    const {isOwner} = useIsOwner();

    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const areTrialsEnabled = useFeatureFlag(MedeinaFeatures.EnableTrials) as boolean;

    const [filterStartDate, setFilterStartDate] = useState<Date>(subDays(new Date(), 1));
    const [isFilterUpdated, setIsFilterUpdated] = useState<boolean>(false);
    const [shouldFocusFilterButton, setShouldFocusFilterButton] = useState<boolean>(false);
    const {transitionVariant} = useDataTableV2Transitions();

    const {capacityName, isProviderLoaded, isWorkspaceCapacityEAP} = useWorkspaceState();

    // new capacity button ref
    const newCapacityButtonRef = useRef<HTMLButtonElement>(null);

    const [isCreateCapacityDialogOpen, setIsCreateCapacityDialogOpen] = useState<boolean>(false);
    // for create capacity dialog
    // To get the capacity name from workspace
    const [selectedCapacity, setSelectedCapacity] = useState<string>(capacityName || '');
    useEffect(() => {
        if (isWorkspacesTestingEnabled) {
            setSelectedCapacity(capacityName || '');
        }
    }, [capacityName, isWorkspacesTestingEnabled]);
    const {
        data: capacity,
        isFetching: isGetCapacityNameFetching,
        isError: isGetCapacityNameError,
        isFetched: isGetCapacityNameFetched,
    } = useGetCapacityByName(isWorkspacesTestingEnabled ? selectedCapacity : capacityName || '', {
        enabled: !!capacityName || Boolean(isWorkspacesTestingEnabled),
    });
    const capacityUnits = useMemo(() => {
        return capacity?.properties?.numberOfUnits ?? 0;
    }, [capacity]);
    const {
        getState,
        hourlyDataFilteredModel,
        dimensionalDataModel,
        facetDataModel,
        updateTimePeriod,
        updatePageNumber,
        updateFilters,
        drillDownByDateRange,
        removeDateRangeDrillDown,
        pageLoading,
        isEvaluationAggregateDataLoading,
        pageError,
        lastUpdatedAt,
        dateRangeOption,
        updateSortDirection,
        shouldRefreshFullVisualization,
        usageMetrics,
        isOverageEnabled,
        isFilteredSearchState,
        isNewMswUiEnabled,
        isAgentUsageDashboardEnabled,
    } = useManageUsageDashboard({
        capacityName: isWorkspacesTestingEnabled ? selectedCapacity : capacityName || '',
    });

    const state = getState();

    const isCapacityUsageError =
        (isWorkspacesTestingEnabled ? !selectedCapacity : false) ||
        !capacityName ||
        isGetCapacityNameError ||
        pageError;
    const isCapacityUsageDataPresent =
        !pageLoading && !pageError && (hourlyDataFilteredModel?.value?.length ?? 0) > 0;

    // This will remove a flicker of loading state when the data is loaded for the first time
    const shouldShowLoadingState =
        !isProviderLoaded ||
        pageLoading ||
        (shouldRefreshFullVisualization && isEvaluationAggregateDataLoading);

    const showChangeUnitsComponents = isProviderLoaded && !isWorkspaceCapacityEAP;

    const resolvedOverageState = capacity?.properties?.overageState ?? OverageState.None;

    // Check if the page is loaded completely. This takes into account all the dependencies
    // and ensures that the page is loaded only when all the data is fetched.
    const isDashboardLoaded = useMemo((): boolean => {
        return isProviderLoaded && isGetCapacityNameFetched && !pageLoading;
    }, [isGetCapacityNameFetched, pageError, pageLoading, isProviderLoaded]);

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const onPageRefresh = () => {
        const stateValues = getState();
        updateTimePeriod(stateValues.startDate, stateValues.endDate, stateValues.dateText);
    };

    const getApiStatusClasses = useCallback(() => {
        if (shouldShowLoadingState || isCapacityUsageError || !isCapacityUsageDataPresent) {
            return mergeClasses(classes.apiStatusMessage, classes.apiStatusWithContent);
        } else {
            return mergeClasses(classes.apiStatusMessage, classes.apiStatusEmpty);
        }
    }, [
        shouldShowLoadingState,
        isCapacityUsageError,
        pageLoading,
        hourlyDataFilteredModel,
        classes.apiStatusMessage,
        classes.apiStatusWithContent,
        classes.apiStatusEmpty,
    ]);

    const dateRangeOptionChanged = (option: DateFilterChangedProps) => {
        updateTimePeriod(option.startDate, option.endDate, option.title);
        setFilterStartDate(option.startDate);
    };

    const shouldShowUsageDashboardV2Page = isOwner;

    const {setNavActions} = useNavActions();

    // logic to get the capacity name from workspace
    const {data: workspacesData, isFetched: workspacesFetched} = useGetWorkspaces();

    // get capacity list
    const {data: data} = useGetCapacities({});
    const capacities: {value: Capacity[]} = useMemo(() => data || {value: []}, [data]);

    const workspace = useMemo(() => {
        if (workspacesFetched) {
            if (!workspacesData?.value) return null;
            if (selectedCapacity === '') {
                return workspacesData?.value?.find(
                    (workspace: Workspace) => workspace?.capacity?.referenceName === capacityName,
                );
            } else {
                return workspacesData?.value?.find(
                    (workspace: Workspace) =>
                        workspace?.capacity?.referenceName === selectedCapacity,
                );
            }
        }
    }, [capacityName, selectedCapacity, workspacesData?.value, workspacesFetched]);
    const [workspaceDisplayName, setWorkspaceDisplayName] = useState<string | null>(
        workspace?.name ?? '',
    );
    useEffect(() => {
        if (workspace) {
            setWorkspaceDisplayName(workspace.name);
        } else {
            setWorkspaceDisplayName(null);
        }
    }, [selectedCapacity, workspace, workspacesFetched]);

    useEffect(() => {
        const actions = [];
        const stateValues = getState();
        actions.push(<UsageDashboardDataExport {...stateValues}></UsageDashboardDataExport>);
        setNavActions(actions);
    }, [getState]);

    const copilotExperienceMap = useMemo(() => {
        return (
            facetDataModel?.value?.find(
                (value: any) => value.category === SearchCategory.CopilotExperience,
            )?.values ?? []
        );
    }, [facetDataModel]);

    const getAriaLiveText = useCallback((): string => {
        if (!pageLoading && isCapacityUsageError) {
            return tAdmin(AriaLabels.ERROR);
        } else if (!isCapacityUsageDataPresent && !pageLoading) {
            if (isWorkspacesTestingEnabled) {
                return tAdmin(AriaLabels.NO_DATA_WORKSPACES);
            } else {
                return tAdmin(AriaLabels.NO_DATA);
            }
        } else if (!isEvaluationAggregateDataLoading && dimensionalDataModel?.value?.length == 0) {
            return tAdmin(AriaLabels.NO_DIMENSIONAL_DATA);
        } else if ((dimensionalDataModel?.value?.length ?? 0) > 0) {
            return tAdmin(AriaLabels.DATA_LOADED);
        } else if (pageLoading || isEvaluationAggregateDataLoading) {
            return tAdmin(AriaLabels.USAGE_DATA_LOADING);
        } else return '';
    }, [
        isCapacityUsageDataPresent,
        pageLoading,
        isCapacityUsageError,
        isEvaluationAggregateDataLoading,
        dimensionalDataModel,
    ]);
    // multi-workspaces
    const {
        workspaces,
        ownedWorkspaces: ownedWorkspacesfromContext,
        isFetched: isFetchedWorkspacesContext,
    } = useWorkspacesContext();

    const {
        data: availableCapacitiesResponse,
        isFetched: isFetchedAvailableCapacities,
        refetch: refetchAvailableCapacities,
    } = useGetAvailableCapacities(
        ownedWorkspacesfromContext,
        workspaces ?? [],
        capacities.value ?? [],
    );
    const unattachedCapacities: Capacity[] =
        availableCapacitiesResponse?.unattachedCapacities || [];

    useEffect(() => {
        if (capacities?.value && isFetchedWorkspacesContext) {
            refetchAvailableCapacities();
        }
    }, [ownedWorkspacesfromContext, workspaces, capacities?.value]);

    const handleSwitchCapacitySuccess = () => {
        window.location.reload();
    };

    // check if the overage state is enabled and the workspace capacity EAP is disabled
    const shouldRenderOverageState = isOverageEnabled && !isWorkspaceCapacityEAP;
    // The graph mode is determined by the overage state being enabled or not
    // The existing graph mode should be displayed for EAP customers, and not the overage one
    const graphMode = shouldRenderOverageState ? 'overage' : 'v2';

    return (
        <>
            {shouldShowUsageDashboardV2Page && (
                <>
                    <div className={classes.visuallyHidden}>
                        <div aria-live="assertive">{getAriaLiveText()}</div>
                    </div>
                    <GridContainer className={classes.gridClasses}>
                        <div className={classes.root}>
                            <UsageDashboardHeading
                                lastUpdatedAt={lastUpdatedAt}
                                isOverageEnabled={Boolean(isOverageEnabled)}
                                isNewMswUiEnabled={Boolean(isNewMswUiEnabled)}
                            />
                            {isWorkspacesTestingEnabled && (
                                <CapacityTabList
                                    capacities={capacities.value}
                                    selectedCapacity={selectedCapacity}
                                    setSelectedCapacity={setSelectedCapacity}
                                    disconnectedCapacities={unattachedCapacities}
                                    isCreateCapacityDialogOpen={isCreateCapacityDialogOpen}
                                    setIsCreateCapacityDialogOpen={setIsCreateCapacityDialogOpen}
                                    newCapacityButtonRef={newCapacityButtonRef}
                                />
                            )}
                            {!isDashboardLoaded && selectedCapacity && (
                                <div className={classes.loadingContainer}>
                                    <Spinner
                                        labelPosition="below"
                                        aria-live="polite"
                                        aria-label={tAdmin(PAGE_LOADING)}
                                        size="medium"
                                        label={`${tAdmin(PAGE_LOADING)}...`}
                                        data-testid="page-loading-spinner"
                                    />
                                </div>
                            )}
                            {isDashboardLoaded && (
                                <div
                                    className={
                                        isNewMswUiEnabled ? classes.dataDisplayContainer : ''
                                    }
                                >
                                    <div
                                        className={mergeClasses(
                                            classes.metaDataContainer,
                                            sm ? classes.smDashboardActivityContainer : '',
                                        )}
                                    >
                                        {isNewMswUiEnabled && (
                                            <CentralDashboardContainer
                                                areTrialsEnabled={areTrialsEnabled}
                                                copilotExperienceMap={
                                                    copilotExperienceMap as KeyValueFilterDetails[]
                                                }
                                                dateFilterOptions={{
                                                    dateRangeOption: dateRangeOption,
                                                    onOptionChanged: dateRangeOptionChanged,
                                                    showDateFilterDropDown:
                                                        !pageLoading && !isCapacityUsageError,
                                                }}
                                                displayConfiguration={{
                                                    hideAll: shouldShowLoadingState,
                                                    showChangeUnitsComponents:
                                                        showChangeUnitsComponents,
                                                    enableBillingInCurrentHour: false,
                                                }}
                                                filterContainerProps={{
                                                    onFilterUpdated: updateFilters,
                                                    searchFacetData: facetDataModel?.value ?? [],
                                                    setPristineSearchFilters:
                                                        state.pristineSearchFilters,
                                                    shouldShowFilterContainer:
                                                        !pageLoading &&
                                                        !isCapacityUsageError &&
                                                        isCapacityUsageDataPresent,
                                                }}
                                                filterPillsOptions={{
                                                    isHourlyBarActive: state.isHourlyBarActive,
                                                    onFilterPillsUpdated: updateFilters,
                                                    showSection: !isCapacityUsageError,
                                                }}
                                                isEvaluationAggregateDataLoading={
                                                    isEvaluationAggregateDataLoading
                                                }
                                                isLimitedOverageState={
                                                    resolvedOverageState === OverageState.Limited
                                                }
                                                isOverageEnabled={Boolean(isOverageEnabled)}
                                                searchFilters={state.searchFilters}
                                                usageDisplayDetails={usageMetrics}
                                                workspaceDetails={{
                                                    name: workspaceDisplayName || '',
                                                    hideWorkspaceDetails: false,
                                                }}
                                                selectedCapacity={selectedCapacity}
                                            ></CentralDashboardContainer>
                                        )}
                                        {!isNewMswUiEnabled && (
                                            <CapacityAllocationSection
                                                areTrialsEnabled={areTrialsEnabled}
                                                isOverageDisplayEnabled={Boolean(isOverageEnabled)}
                                                overageState={resolvedOverageState}
                                                showChangeUnitsComponents={
                                                    showChangeUnitsComponents
                                                }
                                                showUnitValues={!shouldShowLoadingState}
                                                disableChangeCapacity={isGetCapacityNameFetching}
                                                assignedCapacityUnits={capacityUnits}
                                                usageDisplayDetails={usageMetrics}
                                                dateRangeOption={dateRangeOption}
                                                dateRangeOptionChanged={dateRangeOptionChanged}
                                                isPageLoading={pageLoading}
                                                isCapacityUsageError={isCapacityUsageError}
                                                isCapacityUsageDataPresent={
                                                    isCapacityUsageDataPresent
                                                }
                                                areSearchFiltersPristine={
                                                    state.pristineSearchFilters
                                                }
                                                searchFilters={state.searchFilters}
                                                filterDate={facetDataModel?.value ?? []}
                                                onFiltersUpdate={(
                                                    filters: SelectedSearchFacet[],
                                                ) => {
                                                    updateFilters(filters);
                                                }}
                                                copilotExperienceMap={
                                                    copilotExperienceMap as KeyValueFilterDetails[]
                                                }
                                                isHourlyBarActive={state.isHourlyBarActive}
                                                isEvaluationAggregateDataLoading={
                                                    isEvaluationAggregateDataLoading
                                                }
                                                workspaceDisplayName={workspaceDisplayName || ''}
                                                selectedCapacity={selectedCapacity}
                                            ></CapacityAllocationSection>
                                        )}
                                        {!isNewMswUiEnabled && !isOverageEnabled && (
                                            <div className={classes.actionBtnsV2}>
                                                <div className={classes.leftControlsElement}>
                                                    {/* The 'isVisible' prop is used to conditionally hide the component without resetting the state to default of Last24Hours*/}
                                                    <DateFilterDropdownV2
                                                        className={classes.dateFilterDropdown}
                                                        isVisible={
                                                            !pageLoading && !isCapacityUsageError
                                                        }
                                                        onOptionChanged={dateRangeOptionChanged}
                                                        selectedOptionText={dateRangeOption}
                                                        minSearchStartDate={
                                                            CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE.LAST_90_DAYS
                                                        }
                                                    />
                                                    {!isCapacityUsageError && (
                                                        <div className={classes.rightAlign}>
                                                            <SelectedFilterPillsSection
                                                                isOverageEnabled={Boolean(
                                                                    isOverageEnabled,
                                                                )}
                                                                copilotExperienceMap={
                                                                    copilotExperienceMap as KeyValueFilterDetails[]
                                                                }
                                                                isHourlyBarActive={
                                                                    state.isHourlyBarActive
                                                                }
                                                                isEvaluationAggregateDataLoading={
                                                                    isEvaluationAggregateDataLoading
                                                                }
                                                                searchFilters={state.searchFilters}
                                                                shouldFocusFilterButton={
                                                                    shouldFocusFilterButton
                                                                }
                                                                onFiltersUpdated={(filters) => {
                                                                    updateFilters(filters);
                                                                }}
                                                                onFilterButtonClick={() => {
                                                                    setIsFilterOpen(true);
                                                                    setShouldFocusFilterButton(
                                                                        false,
                                                                    );
                                                                }}
                                                            ></SelectedFilterPillsSection>
                                                        </div>
                                                    )}
                                                    {!pageLoading &&
                                                        !isCapacityUsageError &&
                                                        isCapacityUsageDataPresent && (
                                                            <>
                                                                <UsageDashboardFilterContainer
                                                                    filterData={
                                                                        facetDataModel?.value ?? []
                                                                    }
                                                                    isOpen={isFilterOpen}
                                                                    onFilterClose={() => {
                                                                        setIsFilterOpen(false);
                                                                        setShouldFocusFilterButton(
                                                                            true,
                                                                        );
                                                                    }}
                                                                    onFiltersUpdate={(filters) => {
                                                                        setShouldFocusFilterButton(
                                                                            true,
                                                                        );
                                                                        updateFilters(filters);
                                                                    }}
                                                                    shouldResetFilters={
                                                                        state.pristineSearchFilters
                                                                    }
                                                                    selectedFilters={convertFacetsToSelectedFilters(
                                                                        state.searchFilters,
                                                                    )}
                                                                ></UsageDashboardFilterContainer>
                                                            </>
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className={getApiStatusClasses()}>
                                        {shouldShowLoadingState ? (
                                            <motion.div
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                                variants={transitionVariant}
                                                key="ParentContainer"
                                            >
                                                <Spinner
                                                    labelPosition="below"
                                                    aria-live="polite"
                                                    aria-label={tAdmin(USAGE_DATA_LOADING)}
                                                    size="medium"
                                                    label={`${tAdmin(USAGE_DATA_LOADING)}`}
                                                />
                                            </motion.div>
                                        ) : isCapacityUsageError ? (
                                            <ApiResponseDisplay
                                                requiresFullWidth={true}
                                                title={tAdmin(ApiResponseDisplays.ERROR.title)}
                                                subtitle={tAdmin(
                                                    ApiResponseDisplays.ERROR.subtitle,
                                                )}
                                                onRefreshButtonClick={onPageRefresh}
                                                isError
                                            />
                                        ) : (
                                            !isCapacityUsageDataPresent && (
                                                <ApiResponseDisplay
                                                    requiresFullWidth={true}
                                                    title={tAdmin(
                                                        ApiResponseDisplays.NO_DATA_V2.title,
                                                    )}
                                                    subtitle={tAdmin(
                                                        ApiResponseDisplays.NO_DATA_V2.subtitle,
                                                    )}
                                                    isError={false}
                                                />
                                            )
                                        )}
                                    </div>
                                    <AnimatePresence mode="wait">
                                        {isCapacityUsageDataPresent &&
                                            !pageLoading &&
                                            !isCapacityUsageError &&
                                            !shouldRefreshFullVisualization && (
                                                <>
                                                    <motion.div
                                                        initial="initial"
                                                        animate="animate"
                                                        exit="exit"
                                                        variants={transitionVariant}
                                                        key="ParentContainer"
                                                    >
                                                        <VStackedBarChart
                                                            version={graphMode}
                                                            dataTestId={'bar-chart'}
                                                            isDisplayingFilteredData={
                                                                isFilteredSearchState
                                                            }
                                                            data={
                                                                hourlyDataFilteredModel?.value ?? []
                                                            }
                                                            onGraphBarClick={(
                                                                startDate,
                                                                endDate,
                                                            ) => {
                                                                drillDownByDateRange(
                                                                    startDate,
                                                                    endDate,
                                                                );
                                                            }}
                                                            onGraphBarBlur={() => {
                                                                removeDateRangeDrillDown(
                                                                    filterStartDate,
                                                                    new Date(),
                                                                );
                                                            }}
                                                        />
                                                    </motion.div>
                                                    <DataTableV2
                                                        shouldScrolltoTableOnDataRefresh={
                                                            !state.needsHourlyDataRefresh &&
                                                            state.needsDimensionalDataRefresh
                                                        }
                                                        data={dimensionalDataModel?.value ?? []}
                                                        onPageNumberChange={(pageNumber) => {
                                                            //Code to subscribe to the page number change will come in a future PR
                                                            updatePageNumber(pageNumber);
                                                            setIsFilterUpdated(false);
                                                        }}
                                                        // The actual total page count will come in a forthcoming PR
                                                        totalDataCount={
                                                            dimensionalDataModel?.count ?? 0
                                                        }
                                                        resetPagination={isFilterUpdated}
                                                        isTableLoading={
                                                            isEvaluationAggregateDataLoading
                                                        }
                                                        sortDirection={state.sortDirection}
                                                        onDateSortChanging={async (
                                                            sortDirection,
                                                        ) => {
                                                            updateSortDirection(sortDirection);
                                                        }}
                                                        currentPage={state.pageNumber}
                                                        selectedFilters={state.searchFilters}
                                                        updateSearchFilters={(filters) => {
                                                            updateFilters(filters);
                                                        }}
                                                        isAgentUsageDashboardEnabled={Boolean(
                                                            isAgentUsageDashboardEnabled,
                                                        )}
                                                        dataTestId={'data-table'}
                                                    />
                                                </>
                                            )}
                                    </AnimatePresence>
                                </div>
                            )}
                        </div>
                    </GridContainer>
                </>
            )}
        </>
    );
}
