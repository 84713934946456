export enum MemberType {
    User = 'User',
    Group = 'Group',
    Role = 'Role',
    ServicePrincipal = 'ServicePrincipal',
}

export interface MemberItemProps {
    id: string;
    name: string;
}
export type RemoveMemberType = {
    User: RemoveProps[];
    Group: RemoveProps[];
    Role: RemoveProps[];
    ServicePrincipal: RemoveProps[];
};

export type RemoveProps = {
    id: string;
    canBeDeleted: boolean;
};

export interface MemberListItemProps {
    key: string;
    id: string;
    memberId: string;
    name: string;
    email?: string;
    type: MemberType;
    role: RoleType;
    memberCount?: number;
    hideLinks?: boolean;
}

export type MemberItemData = {
    id: string;
    role: string;
    type: MemberType;
};

export type RoleObjectIdData = {
    [key in RoleType]?: OptionalMemberTypeList;
};

export type OptionalMemberTypeList = Partial<{
    [key in MemberType]: string[];
}>;

export enum RoleType {
    Contributor = 'Contributor',
    Owner = 'Owner',
}
