import {
    OptionGroup,
    mergeClasses,
    useFocusFinders,
    Dropdown,
    Option,
    Field,
    FieldProps,
    Label,
} from '@fluentui/react-components';
import {useEffect, useRef, useState} from 'react';
import useAppClasses from '@/components/App.styles';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {useTranslation} from 'react-i18next';
import useGetLocationSelectionData from '@/components/sections/tours/admin-ga/stops/LocationSelection/useGetLocationSelectionData';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';

interface StorageLocationProps {
    value?: string;
    onOptionSelect?: (selectedLocation: any) => void;
    fieldProps?: FieldProps;
    required?: boolean;
}

/*
This component is used to capture the Location for a tenant that will serve as the `configuredGeo`
for the Fidelis Account. A set of selected geos is provided to the user on the basis of the logged in
tenant. The user can select one of the geos from the dropdown and choose to continue ahead. During
which the selected geo will be saved as the `configuredGeo` for the account.
*/
export default function LocationSelection(props: StorageLocationProps) {
    const {value: propValue, fieldProps, required} = props;
    const [locationSelection, setLocationSelection] = useState<string>('');
    const appStyles = useAppClasses();
    const tourClasses = useTourClasses();

    useEffect(() => {
        if (propValue !== locationSelection) {
            setLocationSelection(propValue || '');
        }
    }, [propValue]);

    const {t} = useTranslation('admin');

    const {locationItems, isDataLoading} = useGetLocationSelectionData({
        onSupportedGeosFetchError: () => {},
    });
    return (
        <Field {...fieldProps} required={required}>
            <MedeinaInfoLabel
                labelContent={
                    <Label data-test-id="data-storage-location-name-label" required={required}>
                        {t('Workspaces.Form.DataStorageLocation')}
                    </Label>
                }
                infoContent={t('Workspaces.Form.DataStorageLocationTooltip')}
            />
            <Dropdown
                className={mergeClasses(tourClasses.dropDown)}
                aria-label={t('Workspaces.Form.DataStorageLocation')}
                data-testid="location-dropdown"
                onOptionSelect={(_, data) => {
                    setLocationSelection(data.optionValue ?? '');
                    props.onOptionSelect?.(data.optionValue);
                }}
                value={locationSelection}
                selectedOptions={locationSelection ? [locationSelection] : []}
                listbox={{className: appStyles.listBoxHeight}}
            >
                <OptionGroup>
                    {locationItems?.map(({key, value}: any) => {
                        return (
                            <Option key={key} value={value} data-testid="location-option">
                                {value}
                            </Option>
                        );
                    })}
                </OptionGroup>
            </Dropdown>
        </Field>
    );
}
