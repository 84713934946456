const featureFlags: Array<string> = [
    'Purview',
    'EnableCreatePromptbook',
    'EnableFetchOwnerForPromptbook',
    'UrlScan',
    'CrowdSecTI',
    'GreyNoiseEnterprise',
    'GreyNoiseCommunity',
    'CIRCL',
    'EnableNL2KQLPlannerNLStructuredOutput',
    'EnableNL2KQLDefenderSentinelSkill',
    'EnableDefenderIdentitySummary',
    'EnableGAIAGateway',
    'EnableIncidentSummaryCaching',
    'EnableDefenderDeviceSummary',
    'EnableIncidentEntitiesV2',
    'EnableSentinelIncidentSummaryV2',
    'EnableWAF',
    'EnableStructuredOutputForWAFSkills',
    'EnableCustomUserException',
    'EnableOptInDefangUrlForWAFSkills',
    'EnableScriptAnalyzerMitreTechniques',
    'EnableServiceNow',
    'EnableSplunk',
    'AdditionalInstructionsForNoPlaceholderValues',
    'EnableDefangAndCodeblockUrls',
    'EnableValidateFinalResponse',
    'AddInlineSources',
    'SkillDescriptorConversionTool',
    'EnableSkillEmbeddingServiceThrottling',
    'EnableRestrictCFRScope',
    'EnableContextPromptInjection',
    'EnableMultiStepPlannerByTenant',
    'IntuneProduction',
    'IntuneCVERiskAnalysis',
    'IntuneCVERemediation',
    'EnableDirectSkillsInPromptbook',
    'EnablePromptbookContinueOnFailure',
    'AUTOPATCH',
    'AUTOPATCH0FINESKILLS',
    'AUTOPATCH0MULTIREGION',
    'AUTOPATCH4FORCEUNIFIED',
    'AzureAiSearch',
    'FileUploads',
    'EnablePurviewDynamicPromptingIndexValidation',
    'AskMsDocs',
    'EnableAskMsDocsStreaming',
    'IsUserPluginManagementAllowedByAdminEnabled',
    'EnableAdvancedPluginFiltering',
    'EASMDocumentationSkills',
    'EASMCombinedWorkspaceSkills',
    'EASMUse1PAppId',
    'EASMTenantVendorMapping',
    'EASMArbitraryQuerySkill',
    'EASMRiskReportingSkill',
    'EASMFacetSkill',
    'EnableMDTIKeywordSearchForCVEs',
    'EnableMDTIIndicatorSkills',
    'EnableMDTIMDVMCveEnrichment',
    'EnableSonarSandboxAnalysisSkill',
    'EnableEntraUsersSkillV1',
    'EnableEntraLifecycleWorkflowCompareVersion',
    'EnableEntraLifecycleWorkflowsInsights',
    'EnableEntraAppScenarios',
    'EnableSignInLogsSkillV1',
    'EnableGSATrafficLogsSkill',
    'EnableGSAUserAccessToDestinationSkill',
    'EnableEntraNullSkill',
    'EnableGPUProxy',
    'EnableCloudPCSkills',
    'EnableAzureFirewall',
    'EnableAzureWorkbooks',
    'EnableKbFallback',
    'Enable4oEndpoint',
    'EnableNaturalExceptionBubbling',
    'EnableCombinedController',
    'IsNunatakClientEnabled',
    'KnowledgeTemplate',
    'Enable1pRbac',
    'AuditLoggingOptingEnabled',
    'EnableOnboardingWithRoles',
    'EnableDataGovernanceRoles',
    'EnableUsageDashboardOverage',
    'GeoPodUrlEnabled',
    'EnableUserPreferencesGlobalContainer',
    'RequestScopedSessionCaching',
    'PodScopedGeoAuthorization',
    'EnableSurfaceSMPTenant',
    'EnableSurfaceSMPForAll',
    'EnablePromptLibrary',
    'EnableNL2APISkillset',
    'FidelisProvisioned!',
];

export default featureFlags;
