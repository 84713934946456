import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {Agent, AgentsRequestQueryParameters} from './agents.types';
import {PageResponse} from '../api.types';

// Cache for 10 minutes.
const CACHE_TIME = 10 * 60 * 1000;

export default function useGetAgents(requestParams: AgentsRequestQueryParameters) {
    const {customFetch} = useFetch();
    return useQuery({
        cacheTime: CACHE_TIME,
        staleTime: CACHE_TIME,
        refetchOnWindowFocus: false,

        queryKey: ['agents', requestParams],
        queryFn: async () => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<PageResponse<Agent>>(`/agents?${queryParams.toString()}`, {
                method: 'GET',
            });
        },
    });
}
