import {makeStyles, shorthands} from '@fluentui/react-components';

export default makeStyles({
    root: {
        alignItems: 'center',
    },
    toast: {
        ...shorthands.borderRadius('20px'),
    },
});
