import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    container: {
        width: '100vw',
        height: 'calc(-84px + 100vh)',
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        color: 'black',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
    },
    modalItem: {
        marginBottom: '10px',
    },
    navigation: {
        marginRight: '64px',
    },
});
