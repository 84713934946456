import {ToastPosition} from '@fluentui/react-components';

export interface ToastNotificationsProps {
    intent: string;
    message: string;
    sessionId?: string;
    position?: ToastPosition | undefined;
    timeout?: number | null;
    horizontal?: number;
    vertical?: number;
}

export enum Intent {
    Progress = 'progress',
    Error = 'error',
    Success = 'success',
    Warning = 'warning',
    Info = 'info',
}

export enum ToastPositionUI {
    Top_End = 'top-end',
    Top_Start = 'top-start',
    Bottom_End = 'bottom-end',
    Bottom_Start = 'bottom-start',
}
