import {IUsageParameterDisplayConfigurator} from '../UsageParametersDisplay.types';
import parameterDisplayConfiguratorsUtils from './parameterDisplayConfigurators.utils';

export class SingleRowConfigurator implements IUsageParameterDisplayConfigurator {
    private parameterDisplayConfiguratorsUtils;

    constructor(classes: Record<string, string>) {
        this.parameterDisplayConfiguratorsUtils = parameterDisplayConfiguratorsUtils(classes);
    }
    generateStructuredUsageParameterElements(elementsToRender: JSX.Element[]): JSX.Element[] {
        let elementRender: JSX.Element[] = [];
        elementsToRender.forEach((element) => {
            elementRender.push(
                this.parameterDisplayConfiguratorsUtils.generateUnitDisplayElement(element),
            );
        });

        return elementRender;
    }
}
