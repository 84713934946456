import {useMemo} from 'react';
import {Agent, AgentDefinition, AgentIdentity, AgentRequest} from './agents.types';
import useGetAgent from './useGetAgent';
import useGetAgentDefinition from './useGetAgentDefinition';
import useGetAgentIdentity from './useGetAgentIdentity';

type AgentDetailsResponse = {
    agent: Agent | undefined;
    identity: AgentIdentity | undefined;
    definition: AgentDefinition | undefined;
    isLoading: boolean;
    isError: boolean;
};

/**
 * Hook for retrieving all details (agent, identity, definition) around an agent.
 */
export default function useGetAgentDetails({
    agentId,
    requestParams,
}: AgentRequest): AgentDetailsResponse {
    const {
        data: agent,
        isLoading: isAgentLoading,
        isError: isAgentError,
    } = useGetAgent({agentId, requestParams});

    const {
        data: agentDefinition,
        isLoading: isAgentDefinitionLoading,
        isError: isAgentDefinitionError,
    } = useGetAgentDefinition({agentDefinitionName: agent?.agentDefinitionName});

    const {
        data: agentIdentity,
        isLoading: isAgentIdentityLoading,
        isError: isAgentIdentityError,
    } = useGetAgentIdentity({agentId, requestParams});

    const isLoading = useMemo(() => {
        return isAgentLoading || isAgentDefinitionLoading || isAgentIdentityLoading;
    }, [isAgentLoading, isAgentDefinitionLoading, isAgentIdentityLoading]);

    const isError = useMemo(() => {
        return isAgentError || isAgentDefinitionError || isAgentIdentityError;
    }, [isAgentError, isAgentDefinitionError, isAgentIdentityError]);

    return {
        agent: agent,
        identity: agentIdentity,
        definition: agentDefinition,
        isLoading,
        isError,
    };
}
