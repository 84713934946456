import React, {useMemo} from 'react';
import {useGetAgent, AgentScopeTypes, useGetAgentTriggerRun} from '@/api/agents';
import {formatLocaleDate as format} from '@/util/locale';

interface AgentRunBreadcrumbProps {
    agentId: string;
    runId: string;
}

// Custom breadcrumb component for the runId segment of the breadcrumb
function AgentRunBreadcrumb(props: AgentRunBreadcrumbProps) {
    const {agentId, runId} = props;

    const {data: agent} = useGetAgent({agentId, requestParams: {scope: AgentScopeTypes.WORKSPACE}});
    const {data: triggerRun} = useGetAgentTriggerRun({
        agentId,
        triggerName: agent?.triggers?.[0]?.name,
        runId,
        requestParams: {scope: AgentScopeTypes.WORKSPACE},
    });

    const breadcrumb = useMemo(() => {
        return triggerRun?.startTime ? format(triggerRun.startTime, 'M/d/y, h:mm a') : runId;
    }, [runId, triggerRun]);

    return <>{breadcrumb}</>;
}

export default AgentRunBreadcrumb;
