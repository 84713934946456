import {useCallback, useMemo, useState, useEffect, useRef} from 'react';
import {
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    createTableColumn,
    TableColumnDefinition,
    DataGridProps,
    TableCellLayout,
    Text,
    Skeleton,
    SkeletonItem,
    type SortDirection,
    type TableRowId,
} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {AgentActivityItem} from './AgentActivityTable.types';
import useClasses from './AgentActivityTable.styles';
import {StatusLabel} from '@sfe/react-status-label';
import {formatLocaleDate as format} from '@/util/locale';
import {AgentTriggerRun} from '@/api/agents';

interface AgentActivityTableProps {
    setSelectedRun: (run: AgentTriggerRun | undefined) => void;
    runs: Array<AgentTriggerRun>;
}

export default function AgentActivityTable(props: AgentActivityTableProps) {
    const {setSelectedRun, runs = []} = props;
    const classes = useClasses();
    const {t: tAgents} = useTranslation('agents');

    const [sortState, setSortState] = useState<{
        sortColumn: keyof AgentActivityItem;
        sortDirection: SortDirection;
    }>({
        sortColumn: 'startTime',
        sortDirection: 'descending',
    });
    const [displayedRuns, setDisplayedRuns] = useState<Array<AgentTriggerRun>>([]);
    const [page, setPage] = useState<number>(1);

    const rowsPerPage = 20;
    const hasNextPage = useMemo(() => page < Math.ceil(runs.length / rowsPerPage), [runs, page]);

    const sortRuns = useCallback(
        (unsortedRuns: Array<AgentTriggerRun>) => {
            // Sort the runs based on the selected column and direction.
            const {sortColumn, sortDirection} = sortState;
            return [...unsortedRuns].sort((a, b) => {
                const aValue = a[sortColumn];
                const bValue = b[sortColumn];
                if (aValue && bValue) {
                    const comparison = aValue.localeCompare(bValue);
                    return sortDirection === 'ascending' ? comparison : -comparison;
                }
                return 0;
            });
        },
        [sortState],
    );

    const handleDisplayRunsByPage = useCallback(
        (runs: Array<AgentTriggerRun>) => {
            // Update the displayed runs based on the current page.
            const runsByPage: Array<AgentTriggerRun> = runs.slice(0, page * rowsPerPage);
            setDisplayedRuns(runsByPage);
        },
        [page],
    );

    // Update the displayed runs when the page or sort state changes.
    useEffect(() => {
        const sortedRuns = sortRuns(runs);
        handleDisplayRunsByPage(sortedRuns);
    }, [runs, page, sortState, handleDisplayRunsByPage, sortRuns]);

    const columns: TableColumnDefinition<AgentActivityItem>[] = useMemo(
        () => [
            createTableColumn<AgentActivityItem>({
                columnId: 'startTime',
                compare: (a, b) => {
                    return a.startTime.localeCompare(b.startTime);
                },
                renderHeaderCell: () => {
                    return tAgents('Agent.Activity.TableHeaderStartTimeText');
                },

                renderCell: (item) => (
                    <TableCellLayout>
                        <Text>{item.startTime && format(item.startTime, 'M/d/y, h:mm a')}</Text>
                    </TableCellLayout>
                ),
            }),
            createTableColumn<AgentActivityItem>({
                columnId: 'endTime',
                compare: (a, b) => {
                    return a.endTime.localeCompare(b.endTime);
                },
                renderHeaderCell: () => {
                    return tAgents('Agent.Activity.TableHeaderEndTimeText');
                },

                renderCell: (item) => (
                    <TableCellLayout>
                        <Text>{item.endTime && format(item.endTime, 'M/d/y, h:mm a')}</Text>
                    </TableCellLayout>
                ),
            }),
            createTableColumn<AgentActivityItem>({
                columnId: 'status',
                compare: (a, b) => {
                    return a.status.localeCompare(b.status);
                },
                renderHeaderCell: () => {
                    return tAgents('Agent.Activity.TableHeaderStatusText');
                },

                renderCell: (item) => (
                    <TableCellLayout>
                        <StatusLabel
                            status={
                                item.status === 'Completed'
                                    ? 'success'
                                    : item.status === 'Failed'
                                    ? 'failed'
                                    : item.status === 'InProgress'
                                    ? 'inProgress'
                                    : 'pending'
                            }
                            text={
                                item.status === 'Completed'
                                    ? tAgents('Agent.Completed')
                                    : item.status === 'Failed'
                                    ? tAgents('Agent.Failed')
                                    : item.status === 'InProgress'
                                    ? tAgents('Agent.InProgress')
                                    : tAgents('Agent.Pending')
                            }
                        />
                    </TableCellLayout>
                ),
            }),
        ],
        [tAgents],
    );

    const renderRow = useCallback(
        ({item, rowId}: {item: AgentActivityItem; rowId: TableRowId}) => (
            <DataGridRow key={rowId} selectionCell={null} data-testid={rowId}>
                {({renderCell}) => <DataGridCell>{renderCell(item)}</DataGridCell>}
            </DataGridRow>
        ),
        [],
    );

    const onSelectionChange: DataGridProps['onSelectionChange'] = useCallback(
        (e: any, data: any) => {
            const [selectedRunId] = data.selectedItems;
            // Find the selected run using the runId of the row and update the state.
            const selectedRow = runs.find((item) => item.runId === selectedRunId);
            setSelectedRun(selectedRow);
        },
        [setSelectedRun, runs],
    );

    const onSortChange: DataGridProps['onSortChange'] = useCallback(
        (e: any, nextSortState: any) => {
            setSortState(nextSortState);
        },
        [setSortState],
    );

    //To fetch the next set of runs once user scrolls down to bottom of the grid body.
    const observer = useRef<IntersectionObserver | null>(null);
    const lastElementRef = useCallback(
        (node: HTMLDivElement) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNextPage) {
                    setPage((prevPage) => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [hasNextPage, setPage],
    );

    useEffect(() => {
        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, []);

    return (
        <DataGrid
            className={classes.grid}
            items={displayedRuns}
            columns={columns}
            sortable
            focusMode="composite"
            data-testid={'agent-activity-table'}
            selectionAppearance="neutral"
            selectionMode="single"
            subtleSelection
            sortState={sortState}
            onSelectionChange={onSelectionChange}
            onSortChange={onSortChange}
            getRowId={(agent: AgentTriggerRun) => {
                return agent.runId;
            }}
        >
            <DataGridHeader>
                <DataGridRow selectionCell={null}>
                    {({renderHeaderCell}: any) => (
                        <DataGridHeaderCell className={classes.gridHeaderCell}>
                            {renderHeaderCell()}
                        </DataGridHeaderCell>
                    )}
                </DataGridRow>
            </DataGridHeader>
            <div className={classes.gridBody}>
                <DataGridBody data-testid={'agent-activity-table-body'}>{renderRow}</DataGridBody>
                {hasNextPage && (
                    <Skeleton>
                        <SkeletonItem
                            className={classes.skeleton}
                            appearance="translucent"
                            animation="pulse"
                            ref={lastElementRef}
                            data-testid="agent-activity-row-skeleton"
                        ></SkeletonItem>
                    </Skeleton>
                )}
            </div>
        </DataGrid>
    );
}
