import {TourControls, TourStageContainer, useTourContext} from '@/components/ui/Tour';
import {
    Link,
    mergeClasses,
    Label,
    useId,
    OptionGroup,
    Option,
    Combobox,
    useFocusFinders,
} from '@fluentui/react-components';
import useCreateFirstWorkspace from '@/api/workspaces/useCreateFirstWorkspace';
import {ClipboardIcon} from '@/components/ui/icons';
import useAppClasses from '@/components/App.styles';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import useClasses from './LocationSelection.styles';
import {useState, useRef, useEffect} from 'react';
import {motion} from 'framer-motion';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {useTranslation} from 'react-i18next';
import {TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from '../AdminTour.types';
import SetupInProgress from '../SetupInprogress';
import useManageTourTransition from '../useManageTourTransition';
import {TextH1} from '@/components/ui/Text';
import {useViewportSize} from '@/components/ui/Grid';
import WorkspaceNameField from '../SetupFields/WorkspaceName';
import useGetLocationSelectionData from '../LocationSelection/useGetLocationSelectionData';
import {useSetupAndProvisionFidelisConstructs} from '@/api/capacities';
import {useManageTenantInfo} from '@/api/tenant';
import {writeToSessionStorage} from '@/util/sessionStorage.util';
import {WORKSPACE_NAMESPACE} from '@/components/sections/workspaces/WorkspacesProvider/workspaces.constants';
import {WorkspaceSessionData} from '@/components/sections/workspaces/WorkspacesProvider';
import {CreateGeoWorkspaceRequest} from '@/api/workspaces/workspaces.types';
import useCreateWorkspace from '@/api/workspaces/useCreateWorkspace';

export default function WorkspaceInfo() {
    const tour = useTourContext();
    const {setTourErrorV2, setTourStep} = useTourContext();
    const tourClasses = useTourClasses();
    const classes = useClasses();
    const {t} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');
    const workspaceInfoRootRef = useRef<HTMLDivElement>(null);
    const {sm: isSmallScreen} = useViewportSize();
    const labelSize = isSmallScreen ? 'small' : 'medium';
    const workspaceNameLabelId = useId('workspace-name');
    const [workspaceName, setWorkspaceName] = useState<string>('');
    const appStyles = useAppClasses();
    const [locationSelection, setLocationSelection] = useState<string>('');
    const [isDataProcessing, setIsDataProcessing] = useState(false);
    const {locationItems, isDataLoading} = useGetLocationSelectionData({
        onSupportedGeosFetchError: () => {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.SelectLocation,
            });
        },
    });

    const handleBackClick = () => {
        setTourStep(TOUR_STEPS.Welcome);
    };
    // call workspace provider, current / preferred workspaces
    const {provisionAccountAndGeoWorkspace} = useSetupAndProvisionFidelisConstructs({
        //workspaceName,
        shouldPreLoadWorkspace: false,
        //API response handlers
        onWorkspaceProvisionError: (error) => {
            setIsDataProcessing(false);
            debugger;
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.SelectLocation,
            });
        },
        onAccountCreationError: () => {
            setIsDataProcessing(false);
            debugger;
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.SelectLocation,
            });
        },
        onInitialAccountAndWorkspaceProvisioningSuccess: async () => {
            // Continuation of the provisioning process post account and workspace validation and creation
            await actionsAfterAccountSetup();
        },
        // if we are in this screen we are in multi-workspaces
        isWorkspacesTestingEnabled: true,
    });
    const {findFirstFocusable} = useFocusFinders();
    useEffect(() => {
        if (workspaceInfoRootRef?.current) {
            const firstFocusable = findFirstFocusable(workspaceInfoRootRef.current);
            firstFocusable?.focus();
        }
    }, [workspaceInfoRootRef]);

    const {getStepAfterLocationSelection} = useManageTourTransition();
    const actionsAfterAccountSetup = async () => {
        try {
            // initializeTenantFreInfo();
            const nextStep = await getStepAfterLocationSelection();
            setTourStep(nextStep);
        } catch (error) {
            debugger;
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.SelectLocation,
            });
        } finally {
            setIsDataProcessing(false);
        }
    };
    async function handleContinueClick() {
        setIsDataProcessing(true);
        const workspaceData: CreateGeoWorkspaceRequest = {
            name: workspaceName,
            dataStorageLocation: locationSelection,
        };
        const workspaceDataSessionData = {
            name: workspaceName,
            dataLocation: locationSelection,
        };
        writeToSessionStorage<WorkspaceSessionData>(WORKSPACE_NAMESPACE, workspaceDataSessionData);
        // createWorkspace(workspaceData);
        try {
            await provisionAccountAndGeoWorkspace({
                selectedGeo: locationSelection,
                workspaceName,
            });
        } catch (error) {
            setIsDataProcessing(false);
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.SelectLocation,
            });
        }

        // check the result of the provisioning
    }
    return (
        <>
            {!isDataLoading && (
                <motion.div
                    key="workspace-info"
                    variants={overlayAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <TourStageContainer ref={workspaceInfoRootRef}>
                        <TourStageContainer.StageContent>
                            {isDataProcessing && (
                                <SetupInProgress
                                    heading={t('adminV2Fre.locationSelection.SetupInProgress')}
                                    subHeading={t('adminV2Fre.locationSelection.Polite')}
                                ></SetupInProgress>
                            )}
                            {!isDataProcessing && (
                                <>
                                    <div className={mergeClasses(classes.icon)}>
                                        <ClipboardIcon />
                                    </div>
                                    <div className={mergeClasses(tourClasses.tourSectionSpacing)}>
                                        <TextH1>{t('adminV2Fre.workspaceInfo.Title')}</TextH1>
                                    </div>
                                    <div
                                        className={mergeClasses(
                                            tourClasses.tourXLargeSectionSpacing,
                                        )}
                                    >
                                        {t('adminV2Fre.workspaceInfo.SupplementaryContent')}
                                        <div>
                                            <Link
                                            // TODO: Uncomment this when the link is available
                                            // href={MedeinaVariables.AzurePortalUrl} target="_blank"
                                            >
                                                {t('adminV2Fre.workspaceInfo.LearnMoreLinkText')}
                                            </Link>
                                        </div>
                                    </div>
                                    <div
                                        className={mergeClasses(
                                            tourClasses.tourXLargeSectionSpacing,
                                        )}
                                    >
                                        <div className={mergeClasses(classes.field)}>
                                            <WorkspaceNameField
                                                setWorkspaceName={setWorkspaceName}
                                                workspaceName={workspaceName}
                                                labelSize={labelSize}
                                                data-testid="workspace-name-form"
                                            />
                                        </div>
                                        <div className={classes.field}>
                                            <div
                                                className={mergeClasses(
                                                    classes.leftAlignedContainer,
                                                    classes.label,
                                                )}
                                            >
                                                <Label
                                                    id={workspaceNameLabelId}
                                                    data-testid="data-storage-location-label"
                                                    size={labelSize}
                                                >
                                                    {t(
                                                        'adminV2Fre.workspaceInfo.DataStorageLocation',
                                                    )}
                                                </Label>
                                            </div>
                                            <div className={classes.dropDownContainer}>
                                                <Combobox
                                                    className={mergeClasses(tourClasses.dropDown)}
                                                    placeholder={t(
                                                        'adminV2Fre.locationSelection.DataStorageLocationPlaceholder',
                                                    )}
                                                    aria-label={t(
                                                        'adminV2Fre.locationSelection.DataStorageLocation',
                                                    )}
                                                    data-test-id="location-dropdown"
                                                    onOptionSelect={(_, data) => {
                                                        setLocationSelection(
                                                            data.optionValue ?? '',
                                                        );
                                                    }}
                                                    value={locationSelection}
                                                    selectedOptions={
                                                        locationSelection ? [locationSelection] : []
                                                    }
                                                    listbox={{className: appStyles.listBoxHeight}}
                                                    required
                                                >
                                                    <OptionGroup>
                                                        {locationItems?.map(({key, value}: any) => {
                                                            return (
                                                                <Option key={key} value={value}>
                                                                    {value}
                                                                </Option>
                                                            );
                                                        })}
                                                    </OptionGroup>
                                                </Combobox>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </TourStageContainer.StageContent>
                        <TourStageContainer.FooterContent>
                            {!isDataProcessing && (
                                <TourControls
                                    spanFullScreen={true}
                                    previousButton={commonLabel('Back')}
                                    nextButton={commonLabel('Continue')}
                                    handlePrevious={handleBackClick}
                                    handleNext={handleContinueClick}
                                    isNextButtonDisabled={!workspaceName || !locationSelection}
                                    showPrevious={!tour.currentStop?.isFirstStop}
                                ></TourControls>
                            )}
                        </TourStageContainer.FooterContent>
                    </TourStageContainer>
                </motion.div>
            )}
        </>
    );
}
