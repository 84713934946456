import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, 'auto'),
        maxWidth: '85%',
        wordBreak: 'break-word',
        minWidth: '650px',
    },
    title: {
        ...shorthands.margin(tokens.lineHeightHero700, 0, tokens.lineHeightBase100),
        fontSize: tokens.spacingHorizontalXXXL,
        fontWeight: tokens.fontWeightRegular,
    },
    subtitle: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightRegular,
        textAlign: 'justify',
        paddingBottom: tokens.spacingVerticalXL,
    },
    container: {
        backgroundColor: tokens.colorNeutralBackground3,
        width: '100%',
        minHeight: '60vh',
        borderRadius: tokens.borderRadiusXLarge,
        ...shorthands.padding(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXL),
    },
    sectionTitle: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
    },
    sectionDescription: {
        fontSize: tokens.fontSizeBase100,
    },
    cardHolder: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap(tokens.spacingHorizontalL),
        ...shorthands.margin(tokens.spacingVerticalL, 0),
        flexWrap: 'wrap',
    },
    card: {
        width: '350px',
        height: '250px',
    },
    cardPreview: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    cardFooter: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardIcon: {
        maxHeight: '140px',
        maxWidth: '140px',
    },
});
