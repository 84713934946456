import {useMutation} from '@tanstack/react-query';
import MedeinaVariables from '@/util/variables';
import useFetch from '../useFetch';
import {GetRoleByNameResponse, PolicyWorkspace} from './rbac.types';
import {useTranslation} from 'react-i18next';
import {EVERYONE_IDENTIFIER, GDAP_IDENTIFIER, GDAP} from './rbac.constants';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {PARTNER_ROLES_ID} from '@/components/admin/rbac/rbac.constants';
import {getHasRecommendedRoles} from './getHasRecommendedRoles';

interface useGetRoleMemberDetailsProps {
    workspacePolicy: PolicyWorkspace;
    onRecommendedRolesDetected?: (hasRecommendedRoles: boolean) => void;
}
export default function useGetRoleMemberDetails({
    workspacePolicy,
    onRecommendedRolesDetected,
}: useGetRoleMemberDetailsProps) {
    const {customFetch} = useFetch();
    const {t} = useTranslation('admin');
    const is1pRbacEnabled = useFeatureFlag(MedeinaFeatures.Enable1pRbac);

    return useMutation({
        mutationFn: async (body: any) => {
            const url = `${MedeinaVariables.ApiUri}/graphData/details`;

            // Only `principal.microsoft.id` can have `*` as a role ID. Circumvent object creation for this case.
            const doesEveryoneExist: boolean = body?.IamItems?.User?.includes(EVERYONE_IDENTIFIER);

            const doesGdapExist: boolean = body?.IamItems?.Role?.includes(GDAP_IDENTIFIER);

            // Remove * and / from the list of users
            if (doesEveryoneExist) {
                body.IamItems.User = body.IamItems.User.filter(
                    (id: string) => id !== EVERYONE_IDENTIFIER,
                );
            }

            if (doesGdapExist) {
                body.IamItems.Role = body.IamItems.Role.filter(
                    (id: string) => id !== GDAP_IDENTIFIER,
                );
            }

            const resp = await customFetch<GetRoleByNameResponse>(url, {
                method: 'POST',
                body: body,
            });

            // Add Everyone to the list of users
            if (doesEveryoneExist) {
                resp.details.User.push({
                    id: EVERYONE_IDENTIFIER,
                    displayName: t('RoleAssignment.Everyone'),
                    mail: t('RoleAssignment.EveryoneInfo'),
                });
            }

            if (doesGdapExist) {
                resp.details.Role.push({
                    id: GDAP_IDENTIFIER,
                    displayName: GDAP,
                });
            }

            // add recommended roles check 1pRbacEnabled - pass back the hasRecommendedRoles
            if (is1pRbacEnabled) {
                const hasRecommendedRoles = getHasRecommendedRoles(workspacePolicy);
                if (hasRecommendedRoles) {
                    onRecommendedRolesDetected?.(hasRecommendedRoles);
                    const rolesObject = t('RoleAssignment.AddRoles.RolesList', {
                        returnObjects: true,
                    }) as {[key: string]: string};

                    const recommendedRoles: string[] = Object.values(rolesObject);

                    const rolesListDisplayName = t('RoleAssignment.AddRoles.DisplayName');
                    resp.details.Role.push({
                        id: PARTNER_ROLES_ID,
                        displayName: rolesListDisplayName,
                        mail: `Group • ${recommendedRoles.length.toString()} roles`,
                        canBeDeleted: true,
                    });
                }
            }
            return resp;
        },
        onSuccess: (_, variables) => {},
    });
}
