import {useFeatureFlag} from '@/api/user';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {ErrorMessage} from './errors.types';
import MedeinaVariables from '@/util/variables';
import MedeinaFeatures from '@/util/features';

// Values are mapped to string keys for easier translation
// common.json
const ERROR_MESSAGES: {[key: number]: ErrorMessage} = {
    429: {
        learnMoreText: 'Errors.LearnMoreCapacityMaxedOut',
        message: 'Errors.CapacityMaxedOut',
        learnMoreUri: MedeinaVariables.ManageCapacityLearnUri,
    },
    403: {
        message: 'Errors.NoPermissionForFeature',
    },
    // ... Add other status codes as needed
};

const MULTIWORKSPACES_ERROR_MESSAGES: {[key: number]: ErrorMessage} = {
    429: {
        learnMoreText: 'Errors.LearnMoreCapacityMaxedOut',
        message: 'Errors.CapacityMaxedOut',
        learnMoreUri: MedeinaVariables.ManageCapacityLearnUri,
    },
    403: {
        message: 'Errors.NoPermissionForFeature',
    },
    // ... Add other status codes as needed
};

export function getErrorMessage(
    statusCode: number,
    isMultiWorkspaceEnabled: boolean = false,
): ErrorMessage {
    const errorLookup = isMultiWorkspaceEnabled ? MULTIWORKSPACES_ERROR_MESSAGES : ERROR_MESSAGES;
    if (statusCode && errorLookup[statusCode]) {
        return errorLookup[statusCode];
    }

    // Default error message
    return {message: 'Errors.DefaultPromptSubmitError'};
}

// Returns a localized version of the error message based on the status code
export function useErrorMessages() {
    const {t} = useTranslation('common');
    const isMultiWorkspaceEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const getLocaleMessage = useCallback(
        (statusCode: number, response?: Response) => {
            const errorMessage = getErrorMessage(statusCode, Boolean(isMultiWorkspaceEnabled));
            errorMessage.message = t(errorMessage.message);
            if (errorMessage.learnMoreText) {
                errorMessage.learnMoreText = t(errorMessage.learnMoreText);
            }
            // Default error message
            return errorMessage;
        },
        [isMultiWorkspaceEnabled, t],
    );

    return {
        getErrorMessage: getLocaleMessage,
    };
}
