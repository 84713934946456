import {isUntrustedApi, isFidelisApi} from '@/api/api';
import {NavigationBannerExpirationState, NavigationBannerState} from './Navigation.types';
import {useMemo} from 'react';
import useGetEapExpired from '@/api/app/useGetEapExpired';
import {parseISO} from 'date-fns';

const _7_DAYS = 7 * 24 * 60 * 60 * 1000;
const _24_HOURS = 24 * 60 * 60 * 1000;

const DEFAULT_RESPONSE = {
    freeTrialExpiryDate: null,
    isFreeTrialExpiringIn7Days: false,
    isFreeTrialExpiringTomorrow: false,
    isFreeTrialExpired: false,
};

export default function useNavigationBanner(): NavigationBannerState {
    // Determine EAP expiry date states.
    const {data: expiryData, isError} = useGetEapExpired();

    const expiryState = useMemo<NavigationBannerExpirationState>(() => {
        if (expiryData?.expiryDate) {
            try {
                const expiryDate: Date = new Date(expiryData?.expiryDate);
                const isoString = expiryDate.toISOString();
                const date = parseISO(isoString);
                const now = Date.now();
                const today = new Date();
                today.setHours(0);
                today.setMinutes(0);
                today.setSeconds(0);
                today.setMilliseconds(0);

                const isFreeTrialExpiringTomorrow = date.getTime() - now <= _24_HOURS;
                const isFreeTrialExpiringIn7Days =
                    !isFreeTrialExpiringTomorrow && date.getTime() - now < _7_DAYS;

                const result = {
                    freeTrialExpiryDate: date,
                    isFreeTrialExpiringIn7Days,
                    isFreeTrialExpiringTomorrow,
                    isFreeTrialExpired: now > date.getTime(),
                };

                return result;
            } catch {
                return DEFAULT_RESPONSE;
            }
        } else {
            return DEFAULT_RESPONSE;
        }
    }, [expiryData]);

    const isWarning = useMemo<boolean>(
        () =>
            Boolean(
                isUntrustedApi ||
                    expiryState.isFreeTrialExpired ||
                    expiryState.isFreeTrialExpiringTomorrow,
            ),
        [expiryState],
    );

    const isInfo = useMemo<boolean>(
        () => Boolean(expiryState.isFreeTrialExpiringIn7Days),
        [expiryState],
    );

    const isVisible = useMemo<boolean>(() => isWarning || isInfo, [isWarning, isInfo]);

    return {...expiryState, isVisible, isWarning, isInfo, isUntrustedApi, isFidelisApi};
}
