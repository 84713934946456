import {Field, Input} from '@fluentui/react-components';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import type {
    AgentConfigurationSettings,
    ExtendedInputOnChangeData,
} from '../AgentSettingsConfiguration';
import useClasses from './AgentSettingsField.styles';

interface AgentSettingsFieldProps {
    config: AgentConfigurationSettings;
    formValues: Map<string, string>;
    handleInputChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        data: ExtendedInputOnChangeData,
    ) => void;
    hasFailedValidation: (id: string) => boolean;
    getValidationMessage: (id: string, name: string) => string;
}

export default function AgentSettingsField({
    config,
    formValues,
    handleInputChange,
    hasFailedValidation,
    getValidationMessage,
}: AgentSettingsFieldProps) {
    const classes = useClasses();

    return (
        <Field
            className={classes.field}
            label={{
                className: classes.configText,
                children: config.description ? (
                    <MedeinaInfoLabel
                        labelContent={config.label ?? config.name}
                        infoContent={config.description}
                    />
                ) : (
                    config.label ?? config.name
                ),
            }}
            required={config.required ?? false}
            validationState={hasFailedValidation(config.id) ? 'error' : 'none'}
            validationMessage={getValidationMessage(config.id, config.name)}
        >
            <Input
                aria-label={config.label ?? config.name}
                type="text"
                value={formValues.get(config.id) ?? ''}
                key={config.id}
                autoFocus={false}
                size="large"
                appearance="outline"
                className={classes.textareaWrapper}
                placeholder={config.hintText ?? ''}
                onChange={(event, data) => handleInputChange(event, {...data, id: config.id})}
            />
        </Field>
    );
}
