import {useMemo, useState} from 'react';
import {mergeClasses} from '@griffel/react';
import {SelectedSearchFacet, KeyValueFilterDetails} from '@/api/capacities';
import {DateFilterChangedProps} from '../DateFilterDropdown/DateFilterDropdown.types';
import DateFilterDropdownV2 from '../DateFilterDropdown/DateFilterDropdownV2';
import UsageDashboardFilterContainer from '../Filter/UsageDashboardFilterContainer';
import SelectedFilterPillsSection from '../SelectedFilterPillsSection';
import {CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE} from '../UsageDashboard.constants';
import {
    DashboardFilterContainerProps,
    SelectedFilterPillConfiguration,
} from '../UsageDashboard.types';
import {convertFacetsToSelectedFilters} from '../UsageDashboard.utils';
import useParameterClasses from './UsageParameterDisplay.styles';
import useDashboardClasses from '../UsageDashboard.styles';
import {useViewportSize} from '@/components/ui/Grid';

interface RightColumnProps {
    isOverageEnabled: boolean;
    onOptionChanged: (option: DateFilterChangedProps) => void;
    showDateFilterDropDown: boolean;
    dateRangeOption: string;
    searchFilters: SelectedSearchFacet[];
    //TODO: This needs to be thought of from a non-evaluation parlance
    isEvaluationAggregateDataLoading: boolean;
    copilotExperienceMap: KeyValueFilterDetails[];
    filterContainerProps: DashboardFilterContainerProps;
    filterPillsOptions: SelectedFilterPillConfiguration;
}

export default function RightColumn(props: RightColumnProps) {
    const classes = useDashboardClasses();
    const parameterClasses = useParameterClasses();
    const {isSmallScreen} = useViewportSize();
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [shouldFocusFilterButton, setShouldFocusFilterButton] = useState<boolean>(false);

    const dateFilterClass = useMemo(
        () => mergeClasses(classes.dateFilterDropdown, classes.dateFilterTransparentBackground),
        [classes],
    );

    const rightColumnStyles = useMemo(() => {
        const breakToNewLineForSmallScreen = isSmallScreen && props.searchFilters.length > 1;
        return mergeClasses(
            parameterClasses.rightColumnRoot,
            breakToNewLineForSmallScreen ? parameterClasses.rightColumnSmallScreen : '',
        );
    }, [parameterClasses, isSmallScreen, props.searchFilters.length]);

    return (
        <div className={rightColumnStyles}>
            <DateFilterDropdownV2
                className={dateFilterClass}
                isVisible={props.showDateFilterDropDown}
                onOptionChanged={props.onOptionChanged}
                selectedOptionText={props.dateRangeOption}
                minSearchStartDate={CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE.LAST_90_DAYS}
            />
            {props.filterPillsOptions?.showSection && (
                <>
                    <SelectedFilterPillsSection
                        isOverageEnabled={Boolean(props.isOverageEnabled)}
                        isOverageMSWUIEnabled={true}
                        copilotExperienceMap={props.copilotExperienceMap}
                        isHourlyBarActive={props.filterPillsOptions.isHourlyBarActive}
                        isEvaluationAggregateDataLoading={props.isEvaluationAggregateDataLoading}
                        searchFilters={props.searchFilters}
                        shouldFocusFilterButton={shouldFocusFilterButton}
                        onFiltersUpdated={props.filterPillsOptions.onFilterPillsUpdated}
                        onFilterButtonClick={() => {
                            setIsFilterOpen(true);
                            setShouldFocusFilterButton(false);
                        }}
                    ></SelectedFilterPillsSection>
                </>
            )}
            {props.filterContainerProps?.shouldShowFilterContainer && (
                <>
                    <UsageDashboardFilterContainer
                        filterData={props.filterContainerProps?.searchFacetData ?? []}
                        isOpen={isFilterOpen}
                        onFilterClose={() => {
                            setIsFilterOpen(false);
                            setShouldFocusFilterButton(true);
                        }}
                        onFiltersUpdate={(filters) => {
                            setShouldFocusFilterButton(true);
                            props.filterContainerProps.onFilterUpdated(filters);
                        }}
                        shouldResetFilters={props.filterContainerProps.setPristineSearchFilters}
                        selectedFilters={convertFacetsToSelectedFilters(props.searchFilters)}
                    ></UsageDashboardFilterContainer>
                </>
            )}
        </div>
    );
}
