import {Button, Link, Title1} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import useClasses from './OrganizationData.styles';
import {OpenIcon} from '../ui/icons';
import AuditLoggingOptingSwitch from './AuditLoggingOptingSwitch';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {PurviewStatusWarningMessageBar} from '../sections/tours/admin-ga/stops/AuditLoggingOpting/PurviewStatusWarningMessageBar';
import useGetAuditStatus from '@/api/user/useGetAuditStatus';
import React from 'react';

interface AuditLoggingOptingSettingsProps {
    includeInfoLabel: boolean;
}

export default function AuditLoggingOptingSettings(props: AuditLoggingOptingSettingsProps) {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    const {t: tTours} = useTranslation('tours');
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const isDSPMTestingEnabled = useFeatureFlag(MedeinaFeatures.DSPMAuditLoggingOptingEnabled);
    const {data: auditStatus} = useGetAuditStatus({enabled: Boolean(isDSPMTestingEnabled)});
    // make sure auditStatus is not undefined
    const isDisabled = auditStatus && isDSPMTestingEnabled && auditStatus?.status !== 'Enabled';
    const getDescription = () => {
        return isDSPMTestingEnabled
            ? t('ownerSettings.auditLoggingOpting.DSPMInfoContent')
            : isWorkspacesTestingEnabled
            ? t('ownerSettings.auditLoggingOpting.WorkspacesInfoContent')
            : t('ownerSettings.auditLoggingOpting.InfoContent');
    };

    const description = React.useMemo(getDescription, [
        isDSPMTestingEnabled,
        isWorkspacesTestingEnabled,
        t,
    ]);
    return (
        <div className={classes.wrapper}>
            <Title1 role="heading" className={classes.subtitle}>
                {t('ownerSettings.auditLoggingOpting.SectionHeading')}
            </Title1>
            <div className={classes.paragraphContentAudit} data-testid="audit-logging-description">
                {description}{' '}
                {isDSPMTestingEnabled ? (
                    <Link
                        data-test-id="logging-security-copilot-data-link"
                        href={MedeinaVariables.CfSAuditLogging}
                        target="_blank"
                        className={classes.linkContent}
                    >
                        {tTours('adminV2Fre.FREAuditLoggingOpting.LearnMoreLogging')}{' '}
                    </Link>
                ) : (
                    <Link
                        data-test-id="data-access-article-link"
                        href={MedeinaVariables.DataResidency}
                        target="_blank"
                        className={classes.linkContent}
                    >
                        {tTours('adminV2Fre.FREAuditLoggingOpting.LearnMoreDataResidency')}{' '}
                    </Link>
                )}
            </div>
            {isDisabled && (
                <PurviewStatusWarningMessageBar
                    title={t('ownerSettings.auditLoggingOpting.ErrorStatusTitle')}
                    description={t('ownerSettings.auditLoggingOpting.ErrorStatusDescription')}
                />
            )}
            <div className={classes.dataSwitcher}>
                <AuditLoggingOptingSwitch
                    includeInfoLabel={props.includeInfoLabel}
                    isDisabled={isDisabled || false}
                />
            </div>
            <div className={classes.purviewLink}>
                <a
                    href={MedeinaVariables.AuditMicrosoftPurview}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={t(
                        'ownerSettings.auditLoggingOpting.AuditMicrosoftPurviewLinkAriaLabel',
                    )}
                    data-test-id="audit-microsoft-purview-link"
                >
                    <Button icon={<OpenIcon />} iconPosition="after">
                        {t('ownerSettings.auditLoggingOpting.AuditMicrosoftPurviewLink')}
                    </Button>
                </a>
            </div>
        </div>
    );
}
