import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    section: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalS,
        ...shorthands.padding(tokens.spacingVerticalL, 0),
        borderTop: `1px solid ${tokens.colorNeutralStroke1}`,

        '&:first-of-type': {
            borderTop: 'none',
        },
    },
    sectionTitle: {
        marginBlock: 0,
    },
    sectionTitleWithButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    sectionList: {
        marginBlock: 0,
        paddingInlineStart: 0,
    },
    sectionListItem: {
        marginInlineStart: tokens.spacingHorizontalL,
    },
});
