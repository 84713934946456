import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto',
    },
    results: {
        ...shorthands.padding(tokens.spacingHorizontalL, tokens.spacingHorizontalXXL),
        overflow: 'auto',
    },
    resultsHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '64px',
        ...shorthands.padding(0, tokens.spacingHorizontalXXL),
        ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralBackground6),
    },
    resultsHeaderTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalS,
    },
    resultsHeaderActions: {
        display: 'flex',
        gap: tokens.spacingHorizontalS,
        alignItems: 'center',
    },
    resultsHeaderActionsDivider: {
        height: '10px',
    },
    error: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    loading: {
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXXL),
    },
});
