export const MSW_NAMESPACE = 'medeina_msw';

// These map to the scenario handlers in the scenarioHandlers directory
// these are case sensitive and must match the path to the scenario handler
// these can be activated using ?scenario=scenarioName in the url
export const MedeinaScenarios = [
    'helloWorld',
    'multiWorkspaces',
    'default',
    'FRESetupForm',
    'usageDashboardV2',
    'owner',
    'contributor',
    'FREGANewTenant',
    'FREPodNewTenant',
    'freeTrialBannerMessageForSevenDaysAdmin',
    'freeTrialBannerMessageForSevenDaysAnalyst',
    'freeTrialBannerMessageForTomorrowAdmin',
    'freeTrialBannerMessageForTomorrowAnalyst',
    'freeTrialExpiredBannerMessageForAdmin',
    'freeTrialExpiredBannerMessageForAnalyst',
    'FREMultiNewTenant',
    'exceedCapacity',
    'nearingCapacityLimit',
    'auditStatus',
    'ownerSettings',
];

// These can be activated using ?feature=featureName in the url
export const ScenarioFeatureFlagMap: Record<string, string> = {
    // hello world is an api demo
    HelloWorld: MedeinaScenarios[0],
    // workspaces is the only feature that has a scenario associated with it
    MultiWorkspacesEnabled: MedeinaScenarios[1],
    FRESetupForm: MedeinaScenarios[3],
    ViewUsageDashboardV2: MedeinaScenarios[4],
    Owner: MedeinaScenarios[5],
    /** Testing of the full FRE flow, GA version prior to geo/pod changes*/
    FREGANewTenant: MedeinaScenarios[7],

    FREPodNewTenant: MedeinaScenarios[8],

    // These scenarios are for the free trial banner message
    FreeTrialEndingMessageIn7DaysAdmin: MedeinaScenarios[9],
    FreeTrialEndingMessageIn7DaysAnalyst: MedeinaScenarios[10],
    FreeTrialEndingMessageIn1DayAdmin: MedeinaScenarios[11],
    FreeTrialEndingMessageIn1DayAnalyst: MedeinaScenarios[12],
    ExpiredTrialEndMessageForAdmin: MedeinaScenarios[13],
    ExpiredTrialEndMessageForAnalyst: MedeinaScenarios[14],
    FREMultiNewTenant: MedeinaScenarios[15],
    /** Test exceed capacity message */
    ExceedCapacity: MedeinaScenarios[16],
    /** Test exceed threshold message */
    NearingCapacityLimit: MedeinaScenarios[17],
    AuditStatus: MedeinaScenarios[18],
    OwnerSettings: MedeinaScenarios[19],
};
