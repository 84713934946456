import {useMemo, useState} from 'react';
import {AssistanceMenuItems, Feedback} from '.';
import SupportCentralWidget from '../../../sections/supportCentral/SupportCentralWidget';
import {useLocation} from 'react-router-dom';
import {useViewportSize} from '../../Grid';
import {useFeatureFlag} from '@/api/user';
import features from '@/util/features';

export default function AssistanceManager() {
    const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
    const [isSupportCentralOpen, setIsSupportCentralOpen] = useState(false);
    const screenSize = useViewportSize();
    const isSupportCentralEnabled = useFeatureFlag(features.SupportCentral);

    // Disable the assistance menu on tour pages.
    const location = useLocation();
    const showMenu = useMemo(() => {
        return !location.pathname.startsWith('/tour/') && !screenSize.sm && !screenSize.md;
    }, [location.pathname, screenSize]);

    return (
        <>
            {showMenu && (
                <AssistanceMenuItems
                    onFeedbackClick={() => setIsFeedbackDialogOpen(true)}
                    onSupportCentralClick={() => setIsSupportCentralOpen(true)}
                />
            )}

            <Feedback
                openModal={isFeedbackDialogOpen}
                onCloseModal={() => setIsFeedbackDialogOpen(false)}
            />
            {isSupportCentralEnabled && (
                <SupportCentralWidget
                    openModal={isSupportCentralOpen}
                    onCloseModal={() => setIsSupportCentralOpen(false)}
                />
            )}
        </>
    );
}
