import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    inputHeader: {
        ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralBackground6),
    },
    accordionHeader: {
        height: '64px',
        display: 'flex',
        ...shorthands.padding(0, tokens.spacingHorizontalXXL),
        '> button': {
            ...shorthands.padding(0),
        },
    },
    accordionPanel: {
        paddingLeft: tokens.spacingHorizontalXXL,
        ...shorthands.margin(0),
    },
    inputs: {
        overflow: 'auto',
        maxHeight: '156px',
    },
    inputItem: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding(tokens.spacingVerticalS, 0),
    },
});
