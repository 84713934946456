import {useEffect, useState, useCallback} from 'react';
import type {Agent, AgentDefinition, AgentDefinitionSettings} from '@/api/agents';

export enum AgentSettingsConfigurationSectionPrefixes {
    AGENT_SETTINGS = 'settings-',
    TRIGGER_SETTINGS = 'triggerSettings-',
}

export default function useAgentSettingsConfigurationForm(
    agentDefinition: AgentDefinition,
    agent?: Agent,
) {
    const [formValues, setFormValues] = useState<Map<string, string>>(new Map());
    const [failedValidationSettings, setFailedValidationSettings] = useState<string[]>([]);

    const initializeFormValues = useCallback(() => {
        if (agentDefinition) {
            const initialFormValues = new Map<string, string>();

            if (agentDefinition.settings) {
                agentDefinition.settings.forEach((setting) => {
                    initialFormValues.set(
                        `${AgentSettingsConfigurationSectionPrefixes.AGENT_SETTINGS}${setting.name}`,
                        agent?.settings?.[setting.name] ?? setting.defaultValue ?? '',
                    );
                });
            }

            if (agentDefinition.triggers && agentDefinition.triggers.length > 0) {
                agentDefinition.triggers[0].settings.forEach((setting) => {
                    initialFormValues.set(
                        `${AgentSettingsConfigurationSectionPrefixes.TRIGGER_SETTINGS}${setting.name}`,
                        agent?.triggers?.[0]?.settings?.[setting.name] ??
                            setting.defaultValue ??
                            '',
                    );
                });
            }

            setFormValues(initialFormValues);
        }
        setFailedValidationSettings([]);
    }, [agent, agentDefinition]);

    useEffect(() => {
        initializeFormValues();
    }, [initializeFormValues]);

    const validateSettings = useCallback(() => {
        const failedValidations: string[] = [];

        const validate = (settings: Array<AgentDefinitionSettings>, prefix: string) => {
            settings.forEach((setting) => {
                const settingName = `${prefix}${setting.name}`;
                const value = formValues.get(settingName);
                if (setting.required && (!value || value === '')) {
                    failedValidations.push(settingName);
                } else {
                    // Remove from failed validations if it is now valid
                    const index = failedValidations.indexOf(settingName);
                    if (index !== -1) {
                        failedValidations.splice(index, 1);
                    }
                }
            });
        };

        if (agentDefinition.settings) {
            validate(
                agentDefinition.settings,
                AgentSettingsConfigurationSectionPrefixes.AGENT_SETTINGS,
            );
        }

        if (agentDefinition.triggers && agentDefinition.triggers.length > 0) {
            validate(
                agentDefinition.triggers[0].settings,
                AgentSettingsConfigurationSectionPrefixes.TRIGGER_SETTINGS,
            );
        }

        setFailedValidationSettings(failedValidations);
        return failedValidations.length === 0;
    }, [agentDefinition.settings, agentDefinition.triggers, formValues]);

    const processFormValues = useCallback(() => {
        const settings = new Map<string, string | null>();
        const triggerSettings = new Map<string, string | null>();

        formValues.forEach((value, key) => {
            const processedValue = value === '' ? null : value;
            if (key.startsWith(AgentSettingsConfigurationSectionPrefixes.AGENT_SETTINGS)) {
                settings.set(
                    key.replace(AgentSettingsConfigurationSectionPrefixes.AGENT_SETTINGS, ''),
                    processedValue,
                );
            } else if (key.startsWith(AgentSettingsConfigurationSectionPrefixes.TRIGGER_SETTINGS)) {
                triggerSettings.set(
                    key.replace(AgentSettingsConfigurationSectionPrefixes.TRIGGER_SETTINGS, ''),
                    processedValue,
                );
            }
        });

        return {settings, triggerSettings};
    }, [formValues]);

    return {
        formValues,
        setFormValues,
        failedValidationSettings,
        validateSettings,
        processFormValues,
    };
}
