import {tokens} from '@fluentui/react-components';
import {makeStyles} from '@griffel/react';

export default makeStyles({
    topRow: {
        display: 'flex',
        flexDirection: 'row',
        '@media (min-width:320px) and (max-width: 1023px)': {
            flexDirection: 'column',
        },
    },
    leftContainerRoot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: tokens.spacingVerticalM,
    },
    leftContainer: {
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            width: '50%',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            width: '59%',
        },
        '@media (min-width: 479px) and (max-width: 1023px)': {
            width: '80%',
        },
    },
    rightContainer: {
        display: 'flex',
        flexGrow: '1',
        justifyContent: 'flex-end',
        height: 'fit-content',
        '@media (min-width:320px) and (max-width: 1023px)': {
            justifyContent: 'flex-start',
            marginBottom: '20px',
        },
    },
    singleColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    unitDisplay: {
        paddingBottom: '20px',
        marginRight: '20px',
        minWidth: '220px',
    },
    rightColumnRoot: {
        display: 'flex',
        flexDirection: 'row',
    },
    rightColumnSmallScreen: {
        flexDirection: 'column',
        '& div:first-child': {
            marginBottom: tokens.spacingVerticalSNudge,
        },
    },
});
