import {DismissRegular} from '@fluentui/react-icons';
import {useState} from 'react';
import {
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarTitle,
    Button,
    Link,
} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import useClasses from './PurviewStatusWarningMessageBar.styles';

interface PurviewStatusWarningMessageBarProps {
    title: string;
    description: string;
}

export const PurviewStatusWarningMessageBar: React.FC<PurviewStatusWarningMessageBarProps> = ({
    title,
    description,
}) => {
    const classes = useClasses();
    const {t} = useTranslation('tours');
    const [isMessageBarVisible, setIsMessageBarVisible] = useState(true);
    const handleDismiss = () => {
        setIsMessageBarVisible(false);
    };
    return (
        <>
            {isMessageBarVisible && (
                <div className={classes.messageBarContainer}>
                    <MessageBar
                        key="warning"
                        layout="multiline"
                        intent="warning"
                        data-testid="purview-status-warning"
                    >
                        <MessageBarBody className={classes.messageBarBody}>
                            <MessageBarTitle>{title}</MessageBarTitle>
                            <div className={classes.messageContent}>
                                {description}{' '}
                                <Link
                                    href={MedeinaVariables.ProvisioningAuditLogging}
                                    target="_blank"
                                    data-testid="learn-more-purview-audit-link"
                                >
                                    {t('adminV2Fre.FREAuditLoggingOpting.LearnMorePurviewAudit')}
                                </Link>
                            </div>
                        </MessageBarBody>
                        <MessageBarActions
                            containerAction={
                                <Button
                                    aria-label={t('ManageWorkspaces.DismissButtonAriaLabel')}
                                    appearance="transparent"
                                    onClick={handleDismiss}
                                    icon={<DismissRegular />}
                                />
                            }
                        ></MessageBarActions>
                    </MessageBar>
                </div>
            )}
        </>
    );
};
