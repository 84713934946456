import React from 'react';
import {useTranslation} from 'react-i18next';
import {Text} from '@fluentui/react-components';
import {segmentKeys} from '../Breadcrumbs.constants';
import useClasses from '../Breadcrumbs.styles';

interface BreadcrumbItemProps {
    // the segment id from common.json > Breadcrumbs.[key]
    segment: string;
}

// Default breadcrumb item to render a segment of the path
// will first lookup the translation key in common.json > Breadcrumbs.[key] - default matches id in MedeinaRouteConfiguration
// if that key is not found it will display the segment string
function BreadcrumbItem(props: BreadcrumbItemProps) {
    const {t} = useTranslation();
    const {segment} = props;
    const classes = useClasses();
    const key = segmentKeys[segment] || segment;
    const label = t(`Breadcrumbs.${key}`, {defaultValue: segment});

    return (
        <li className={classes.crumb}>
            <Text className={classes.link}>{label}</Text>
        </li>
    );
}

export default BreadcrumbItem;
