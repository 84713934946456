import {ChangeEvent, Dispatch, SetStateAction, useCallback} from 'react';
import {InputOnChangeData} from '@fluentui/react-components';
import type {AgentDefinition, AgentDefinitionSettings} from '@/api/agents';
import useClasses from './AgentSettingsConfiguration.styles';
import {useTranslation} from 'react-i18next';
import AgentSettingsField from './AgentSettingsField/AgentSettingsField';
import {AgentSettingsConfigurationSectionPrefixes} from './useAgentSettingsConfigurationForm';

export type ExtendedInputOnChangeData = InputOnChangeData & {
    id: string;
};

export type AgentConfigurationSettings = AgentDefinitionSettings & {
    id: string;
};

export interface AgentSettingsConfigurationProps {
    agentDefinition: AgentDefinition;
    formValues: Map<string, string>;
    setFormValues: Dispatch<SetStateAction<Map<string, string>>>;
    failedValidationSettings: string[];
}

export default function AgentSettingsConfiguration({
    agentDefinition,
    formValues,
    setFormValues,
    failedValidationSettings,
}: AgentSettingsConfigurationProps) {
    const {t: tAgents} = useTranslation(['agents']);
    const classes = useClasses();

    const handleInputChange = useCallback(
        (_event: ChangeEvent<HTMLInputElement>, data: ExtendedInputOnChangeData) => {
            setFormValues((prevValues) => {
                const newValues = new Map(prevValues);
                newValues.set(data.id, data.value);
                return newValues;
            });
        },
        [setFormValues],
    );

    const hasFailedValidation = useCallback(
        (id: string) => failedValidationSettings.includes(id),
        [failedValidationSettings],
    );

    const getValidationMessage = useCallback(
        (id: string, name: string) => {
            if (hasFailedValidation(id)) {
                return tAgents('AgentConfiguration.SettingsInputRequired', {
                    parameterName:
                        agentDefinition?.settings.find((setting) => setting.name === name)?.label ??
                        name,
                });
            }
            return '';
        },
        [agentDefinition?.settings, hasFailedValidation, tAgents],
    );

    return (
        <form className={classes.form}>
            {agentDefinition.settings.map((config, index) => (
                <AgentSettingsField
                    key={index}
                    config={{
                        ...config,
                        id: `${AgentSettingsConfigurationSectionPrefixes.AGENT_SETTINGS}${config.name}`,
                    }}
                    formValues={formValues}
                    handleInputChange={handleInputChange}
                    hasFailedValidation={hasFailedValidation}
                    getValidationMessage={getValidationMessage}
                />
            ))}
            {agentDefinition.triggers?.[0]?.settings.map((config, index) => (
                <AgentSettingsField
                    key={index}
                    config={{
                        ...config,
                        id: `${AgentSettingsConfigurationSectionPrefixes.TRIGGER_SETTINGS}${config.name}`,
                    }}
                    formValues={formValues}
                    handleInputChange={handleInputChange}
                    hasFailedValidation={hasFailedValidation}
                    getValidationMessage={getValidationMessage}
                />
            ))}
        </form>
    );
}
