import {useMutation, useQueryClient} from '@tanstack/react-query';
import {CreateSessionWithPromptRequest, SourceType, Session} from './sessions.types';
import useCreateSession from './useCreateSession';
import useCreatePromptAndEvaluation from '../combined/useCreatePromptAndEvaluation';
import {ClientApiError} from '../api';

export default function useCreateSessionWithPrompt() {
    const queryClient = useQueryClient();
    const {mutateAsync: createSession} = useCreateSession();
    const {mutateAsync: createPromptAndEvaluation} = useCreatePromptAndEvaluation();

    return useMutation<Session, ClientApiError, CreateSessionWithPromptRequest>({
        mutationFn: async (body: CreateSessionWithPromptRequest) => {
            const {prompt: promptBody, ...sessionBody} = body;
            const session = await createSession({
                ...sessionBody,
                name: sessionBody.name ?? promptBody?.content ?? promptBody?.skillName ?? undefined,
                source: SourceType.Immersive,
            });
            // If a prompt was passed, also create a prompt and its evaluation too.
            if (session && promptBody) {
                const evaluation = await createPromptAndEvaluation({
                    ...promptBody,
                    sessionId: session.sessionId,
                    source: SourceType.Immersive,
                });
            }

            return session;
        },

        onSuccess: () => {
            queryClient.invalidateQueries(['sessions']);
        },
    });
}
