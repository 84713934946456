import {useTranslation} from 'react-i18next';
import UnitsDisplay, {UnitsDisplayStyle} from '../../ChangeCapacityDialog/UnitsDisplay';
import {ScuAllocationLabels} from '../UsageDashboard.constants';
import {isSingularUnit} from '../UsageDashboard.utils';
import {useMemo} from 'react';

/**
 * Props for the OverageUnits component.
 *
 * @property {number} overageUnitsUsed - The number of overage units that have been used.
 * @property {number} overageUnitsLimit - The limit of overage units allowed.
 */
interface OverageUnitsProps {
    overageUnitsUsed: number;
    overageUnitsLimit: number;
}

export default function OverageUnitsUsed(props: OverageUnitsProps) {
    const {t: tAdmin} = useTranslation('admin');

    const overageUnitDetailsHeader = useMemo(
        () => tAdmin(ScuAllocationLabels.OverageHeader),
        [tAdmin],
    );

    const overageUnitsFooter = useMemo(() => tAdmin(ScuAllocationLabels.OverageFooter), [tAdmin]);

    const overageUnits = useMemo(
        () => ({used: props.overageUnitsUsed, available: props.overageUnitsLimit}),
        [props.overageUnitsUsed, props.overageUnitsLimit],
    );

    const overageUnitsNumericDisplay = useMemo(
        () =>
            isSingularUnit(props.overageUnitsLimit)
                ? tAdmin(ScuAllocationLabels.TotalUnitsSingular, overageUnits)
                : tAdmin(ScuAllocationLabels.UnitsAvailablePlural, overageUnits),
        [props.overageUnitsLimit, overageUnits, tAdmin],
    );

    return (
        <UnitsDisplay
            data-testid="overage-units-section"
            heading={overageUnitDetailsHeader}
            numericDisplay={overageUnitsNumericDisplay}
            footer={overageUnitsFooter}
            displayStyle={UnitsDisplayStyle.Visible}
        ></UnitsDisplay>
    );
}
