import React from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentRunContent.styles';
import {StatusLabel} from '@sfe/react-status-label';
import {Spinner} from '@fluentui/react-components';
import AgentEvaluationResults from './AgentEvaluationResults';
import {AgentExecution, AgentTriggerRun} from '@/api/agents';
import AgentRunInputHeader from './AgentRunInputHeader';

interface AgentRunContentProps {
    triggerRun: AgentTriggerRun | undefined;
    triggerExecution: AgentExecution | undefined;
    isLoading: boolean;
    isError: boolean;
    isFailedOrPendingRun: boolean;
    hasInputs: boolean;
}

export default function AgentRunContent(props: AgentRunContentProps) {
    const {triggerRun, triggerExecution, isFailedOrPendingRun, isError, isLoading, hasInputs} =
        props;
    const classes = useClasses();

    const {t} = useTranslation(['common', 'agents']);

    return (
        <>
            {!isLoading && !isError && triggerRun ? (
                <>
                    {hasInputs && (
                        <AgentRunInputHeader
                            fetchSkillInputs={triggerExecution?.fetchSkillInputs}
                        />
                    )}
                    {!isFailedOrPendingRun ? (
                        <AgentEvaluationResults
                            sessionId={triggerRun.sessionId}
                            evaluationId={triggerRun.evaluationId}
                            promptId={triggerRun.promptId}
                        />
                    ) : (
                        <div className={classes.error}>
                            <StatusLabel
                                status={triggerRun?.status === 'Failed' ? 'failed' : 'pending'}
                                text={
                                    triggerRun?.status === 'Failed'
                                        ? t('agents:AgentRun.Failed')
                                        : t('agents:AgentRun.Pending')
                                }
                            />
                        </div>
                    )}
                </>
            ) : !isLoading && isError ? (
                <div className={classes.error} data-testid="agent-run-error">
                    <StatusLabel status="failed" text={t('agents:AgentRun.RunError')} />
                </div>
            ) : (
                <Spinner
                    labelPosition="below"
                    label={t('agents:AgentRun.RunLoading')}
                    data-testid="agent-run-loading-spinner"
                    className={classes.loading}
                />
            )}
        </>
    );
}
