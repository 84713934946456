import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import type {Agent, AgentsRequestQueryParameters, CreateAgentRequest} from './agents.types';

export default function useCreateAgent(requestParams: AgentsRequestQueryParameters) {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (request: CreateAgentRequest) => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<Agent>(`/agents?${queryParams.toString()}`, {
                method: 'POST',
                body: JSON.stringify(request.body),
            });
        },

        onSuccess: () => {
            queryClient.refetchQueries({
                queryKey: ['agents', requestParams],
                exact: true,
            });
        },
    });
}
