import {NavigationBarHeight} from '@/components/App.styles';
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'block',
        maxHeight: `${NavigationBarHeight}px`,
    },
    crumbList: {
        display: 'flex',
        flexDirection: 'row',
        paddingInlineStart: '0px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalXS),
        marginTop: tokens.spacingHorizontalM,
        ...shorthands.marginBlock(tokens.spacingHorizontalSNudge, tokens.spacingHorizontalSNudge),
    },
    crumb: {
        listStyleType: 'none',
        color: tokens.colorNeutralForeground1,
        ...shorthands.flex(1, 1, 'none'),
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
    },
    link: {
        color: tokens.colorNeutralForeground1,
        fontWeight: tokens.fontWeightRegular,
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalXS),
        columnGap: tokens.spacingHorizontalS,
        display: 'block' /* Makes the link fill the <li> */,
        textOverflow: 'ellipsis' /* Adds an ellipsis to overflowed text */,
        overflowX: 'hidden' /* Ensures overflow is hidden */,
    },
    crumbHome: {
        listStyleType: 'none',
        color: tokens.colorNeutralForeground2Link,
    },
    linkHome: {
        color: tokens.colorNeutralForeground1,
    },
    linkAgents: {
        minWidth: 'unset',
    },
    spacer: {
        listStyleType: 'none',
        color: tokens.colorNeutralForeground4,
    },
});
