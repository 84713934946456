import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentDetailDialog.styles';
import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogContent,
    Button,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    Caption1Strong,
    Caption1,
    Body1,
    Body1Strong,
} from '@fluentui/react-components';
import {AgentDefinition} from '@/api/agents';
import AgentPlugins from '../Plugins/AgentPlugins';
import AgentConfigurationDialog from '../AgentConfigurationDialog/AgentConfigurationDialog';
import AgentPermissions from '../Permissions/AgentPermissions';
import {AgentDetails, AgentDetailsSection} from '@sfe/react-agent-details';
import {Dismiss20Regular} from '@fluentui/react-icons';
import formatTime from '@/util/timeFormatterUtil';

export interface AgentDetailDialogProps {
    agentDefinition: AgentDefinition | null;
    open: boolean;
    onClose: () => void;
}

const imagePath = '/images/icons/';
const defaultImage = `${imagePath + 'MicrosoftAgentPersona.svg'}`;

export default function AgentDetailDialog({
    agentDefinition,
    open,
    onClose,
}: AgentDetailDialogProps) {
    const {t} = useTranslation('common');
    const {t: tAgents} = useTranslation('agents');
    const classes = useClasses();
    const [allPluginsEnabled, setAllPluginsEnabled] = useState<'loading' | 'enabled' | 'disabled'>(
        'loading',
    );
    const [isSetupDialogOpen, setIsSetupDialogOpen] = useState(false);

    const isAutomaticTrigger = useMemo(() => {
        return (
            agentDefinition &&
            agentDefinition.triggers?.[0]?.defaultPollPeriodSeconds &&
            agentDefinition.triggers?.[0]?.defaultPollPeriodSeconds > 0
        );
    }, [agentDefinition]);

    if (!agentDefinition) {
        return null;
    }

    const handleViewSetup = () => {
        onClose();
        setIsSetupDialogOpen(true);
    };

    const handleCloseSetup = () => {
        setIsSetupDialogOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onOpenChange={(event, data) => {
                    if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                        onClose();
                    }
                }}
            >
                <DialogSurface className={classes.dialog} data-testid="agent-detail-dialog">
                    <DialogBody>
                        <DialogContent>
                            <AgentDetails
                                data-testid="agent-details-content"
                                header={{
                                    persona: {
                                        name: agentDefinition.displayName || agentDefinition.name,
                                        avatar: {
                                            image: {
                                                className: classes.imageBackground,
                                                src: defaultImage || '',
                                                alt: tAgents('AgentImage'),
                                            },
                                        },
                                        badge: {
                                            className: classes.badgeBackground,
                                            appearance: 'outline',
                                            children: t('Preview'),
                                        },
                                        secondaryText: agentDefinition.publisher,
                                    },
                                    actions: {
                                        children: (
                                            <Button
                                                data-testid="agent-detail-close-button"
                                                appearance="subtle"
                                                icon={<Dismiss20Regular />}
                                                aria-label={t('ButtonLabels.Close')}
                                                onClick={onClose}
                                            />
                                        ),
                                    },
                                }}
                                body={{
                                    children: [
                                        <AgentDetailsSection
                                            key={1}
                                            data-testid="agent-detail-description"
                                        >
                                            <>
                                                {allPluginsEnabled == 'disabled' && (
                                                    <MessageBar intent="error">
                                                        <MessageBarBody>
                                                            <MessageBarTitle>
                                                                <Caption1Strong>
                                                                    {tAgents(
                                                                        'AgentDetailDialog.DisabledPluginsMessageBar.Title',
                                                                    )}
                                                                </Caption1Strong>
                                                            </MessageBarTitle>
                                                            <Caption1>
                                                                {tAgents(
                                                                    'AgentDetailDialog.DisabledPluginsMessageBar.Body',
                                                                )}
                                                            </Caption1>
                                                        </MessageBarBody>
                                                    </MessageBar>
                                                )}
                                                <Body1>{agentDefinition.description}</Body1>
                                            </>
                                        </AgentDetailsSection>,
                                        <AgentDetailsSection
                                            key={2}
                                            header={
                                                <Body1Strong>
                                                    {tAgents('Agent.Details.TriggerHeader')}
                                                </Body1Strong>
                                            }
                                            data-testid="agent-detail-trigger-section"
                                        >
                                            <Body1>
                                                {(!agentDefinition.triggers ||
                                                    agentDefinition.triggers.length === 0 ||
                                                    !agentDefinition.triggers[0].description) &&
                                                    (!isAutomaticTrigger
                                                        ? tAgents(
                                                              'Agent.Details.TriggerDescriptionManual',
                                                          )
                                                        : tAgents(
                                                              'Agent.Details.TriggerDescription',
                                                              {
                                                                  triggerInterval: formatTime(
                                                                      agentDefinition?.triggers?.[0]
                                                                          ?.defaultPollPeriodSeconds ??
                                                                          0,
                                                                  ),
                                                              },
                                                          ))}
                                                {agentDefinition.triggers &&
                                                    agentDefinition.triggers.length > 0 &&
                                                    agentDefinition.triggers[0].description}
                                            </Body1>
                                        </AgentDetailsSection>,
                                        <AgentDetailsSection
                                            key={3}
                                            header={
                                                <Body1Strong>
                                                    {tAgents('Agent.Details.PermissionsHeader')}
                                                </Body1Strong>
                                            }
                                            data-testid="agent-detail-permissions-section"
                                        >
                                            <AgentPermissions
                                                requiredSkillsets={
                                                    agentDefinition.requiredSkillsets
                                                }
                                            />
                                        </AgentDetailsSection>,
                                        <AgentDetailsSection
                                            key={4}
                                            header={
                                                <Body1Strong>
                                                    {tAgents('Agent.Details.IdentityHeader')}
                                                </Body1Strong>
                                            }
                                            data-testid="agent-detail-identity-section"
                                        >
                                            <Body1>
                                                {tAgents('AgentDetailDialog.IdentityDescription')}
                                            </Body1>
                                        </AgentDetailsSection>,
                                        <AgentDetailsSection
                                            key={5}
                                            header={
                                                <Body1Strong>
                                                    {tAgents('Agent.Details.PluginsHeader')}
                                                </Body1Strong>
                                            }
                                            data-testid="agent-detail-plugins-section"
                                        >
                                            <AgentPlugins
                                                requiredSkillsets={
                                                    agentDefinition.requiredSkillsets
                                                }
                                                setAllPluginsEnabled={setAllPluginsEnabled}
                                            />
                                        </AgentDetailsSection>,
                                        <AgentDetailsSection
                                            key={6}
                                            header={
                                                <Body1Strong>
                                                    {tAgents('Agent.Details.RbacHeader')}
                                                </Body1Strong>
                                            }
                                            data-testid="agent-detail-rbac-section"
                                        >
                                            <Body1>
                                                {tAgents('AgentDetailDialog.RbacDescription')}
                                            </Body1>
                                        </AgentDetailsSection>,
                                    ],
                                }}
                                footer={{
                                    primary: {
                                        appearance: 'primary',
                                        onClick: handleViewSetup,
                                        children: tAgents('Setup'),
                                    },
                                }}
                            />
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <AgentConfigurationDialog
                agentDefinition={agentDefinition}
                mode="create"
                open={isSetupDialogOpen}
                onClose={handleCloseSetup}
            />
        </>
    );
}
