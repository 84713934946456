import React, {useRef, useEffect, useState} from 'react';
import {mergeClasses, useFocusFinders, Link, RadioGroup, Radio} from '@fluentui/react-components';
import usePageClasses from './Access1pRoleInfo.styles';
import {CommunityIcon} from '@/components/ui/icons';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {motion} from 'framer-motion';
import {useTourContext, TourStageContainer, TourControls} from '@/components/ui/Tour';
import useManageTenantInfo from '@/api/tenant/useManageTenantInfo';
import {useTranslation} from 'react-i18next';
import {FreRbacTypeSelection, TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from '../AdminTour.types';
import useManageTransitionDetails from '../useManageTourTransition';
import {useNavigate} from 'react-router-dom';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useQueryClient} from '@tanstack/react-query';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {FreStage} from '@/api/tenant';
import {ParagraphContent, TextH1, TextSubtitle1, TextSubtitle2} from '@/components/ui/Text';
import RoleInfoContent from '../AccessRoleInfo/RoleInfoContent';
import OwnersRoleInfoContent from './OwnersRoleInfoContent';
import AddRoles from '@/components/admin/rbac/AddRoles/AddRoles';
import {PolicyWorkspace} from '@/api/rbac/rbac.types';
import {usePolicyManager} from '@/components/admin/rbac';
import {useGetRoleMembers, useUpdateWorkspacePolicyById} from '@/api/rbac';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

export function Access1pRoleInfo() {
    const tour = useTourContext();
    const {setTourErrorV2} = useTourContext();
    const tourClasses = useTourClasses();
    const pageClasses = usePageClasses();
    const queryClient = useQueryClient();
    const {t} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');
    const {validatePostWorkspaceSetup} = useManageTransitionDetails();
    const navigate = useNavigate();
    const {update: providerUpdate, workspaceName} = useWorkspaceState();
    const {patchFreMetadata} = useManageTenantInfo({
        onFetchTenantError: (error: any) => {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.RoleInfoNew,
            });
        },
    });

    const Access1pRoleInfoWrapper = useRef<HTMLDivElement>(null);
    const {findFirstFocusable} = useFocusFinders();
    const [isContinueLoading, setIsContinueLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedRbacOption, setselectedRbacOption] = useState<string>(
        FreRbacTypeSelection.RecommendedRoles,
    );

    const EnableRecommendedRolesAsDefault = useFeatureFlag(
        MedeinaFeatures.EnableOnboardingWithRoles,
    );

    const {
        data: dataShareResponse,
        isLoading: dataShareLoading,
        isFetched: dataShareFetched,
        isSuccess: dataShareIsSuccessful,
        isError: dataShareError,
        isError: dataShareIsError,
        isRefetching: dataShareRefetching,
    } = useGetRoleMembers(workspaceName || '', undefined, {
        enabled: !!workspaceName,
        refetchOnMount: true,
    });

    const policyManager = usePolicyManager({
        workspacePolicy: dataShareResponse as PolicyWorkspace,
        workspaceName: workspaceName as string,
    });

    const {mutateAsync: updateWorkspacePolicy, isLoading: updatePolicyLoading} =
        useUpdateWorkspacePolicyById();

    const handleViewRolesToggle = () => {
        setModalOpen(!isModalOpen);
    };

    useEffect(() => {
        if (Access1pRoleInfoWrapper?.current) {
            const firstFocusable = findFirstFocusable(Access1pRoleInfoWrapper.current);
            firstFocusable?.focus();
        }
    }, [Access1pRoleInfoWrapper]);

    async function handleContinueClick() {
        try {
            setIsContinueLoading(true);
            await handlePolicyUpdate();
            const nextStop = await validatePostWorkspaceSetup();
            if (nextStop === TOUR_STEPS.None) {
                await patchFreMetadata(FreStage.WorkspaceSetup);
                tour.nextStop();
            } else {
                if (nextStop === TOUR_STEPS.HOME) {
                    await providerUpdate();
                    await queryClient.invalidateQueries(['auth', 'getUserInfo']);
                    navigate('/');
                    tour.quitTour();
                } else {
                    tour.setTourStep(nextStop);
                }
            }
        } catch (error) {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.RoleInfoNew,
            });
        } finally {
            setIsContinueLoading(false);
        }
    }

    async function handlePolicyUpdate() {
        if (selectedRbacOption === FreRbacTypeSelection.everyone) {
            return;
        }

        const policyAfterEveryoneRemoved = policyManager.removeEveryoneFromContributors();
        const addOnly = selectedRbacOption === FreRbacTypeSelection.RecommendedRoles;
        const removeOnly = selectedRbacOption === FreRbacTypeSelection.None;
        const finalPolicy = policyManager.togglePartnerRolesCondition(
            policyAfterEveryoneRemoved,
            removeOnly,
            addOnly,
        );

        if (finalPolicy) {
            await updateWorkspacePolicy({
                workspaceName: workspaceName as string,
                policy: finalPolicy,
            });
        }
    }

    function handleBackClick(): void {
        tour.prevStop();
    }

    return (
        <>
            <motion.div
                key="terms"
                variants={overlayAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <TourStageContainer ref={Access1pRoleInfoWrapper}>
                    <TourStageContainer.StageContent>
                        <div>
                            <CommunityIcon
                                className={mergeClasses(
                                    pageClasses.icon,
                                    tourClasses.tourSectionSpacing,
                                )}
                            />
                        </div>
                        <div className={mergeClasses(tourClasses.tourSectionSpacing)}>
                            <TextH1>{t('adminV2Fre.roleInfo.NewTitle')}</TextH1>
                        </div>
                        <div className={mergeClasses(pageClasses.supplementaryContentWidth)}>
                            <div className={tourClasses.tourSectionSpacing}>
                                <TextSubtitle2>
                                    {t('adminV2Fre.roleInfo.ContributorsTitle')}
                                </TextSubtitle2>
                            </div>
                            <div className={tourClasses.tourSectionSpacing}>
                                <ParagraphContent>
                                    {t('adminV2Fre.roleInfo.ContributorsContent')}
                                </ParagraphContent>
                            </div>
                        </div>
                        <div
                            className={mergeClasses(
                                pageClasses.radioGroupContainer,
                                tourClasses.tourSectionSpacing,
                            )}
                        >
                            <div className={pageClasses.whoToAddContainer}>
                                <span>{t('adminV2Fre.roleInfo.WhoToAdd')}</span>
                            </div>
                            <div className={pageClasses.whoToAddInfoContainer}>
                                <span>{t('adminV2Fre.roleInfo.AfterSetupMessage')}</span>
                            </div>
                            <RadioGroup
                                defaultValue={FreRbacTypeSelection.RecommendedRoles}
                                onChange={(_, data) => {
                                    setselectedRbacOption(data.value);
                                }}
                            >
                                <Radio
                                    value={FreRbacTypeSelection.None}
                                    label={t('adminV2Fre.roleInfo.AddNoOne')}
                                />
                                <Radio
                                    value={FreRbacTypeSelection.RecommendedRoles}
                                    label={
                                        <>
                                            {t('adminV2Fre.roleInfo.AddRecommendedRoles')}.{' '}
                                            <Link onClick={handleViewRolesToggle}>View roles</Link>
                                        </>
                                    }
                                />
                                {/* {!EnableRecommendedRolesAsDefault && (
                                    <Radio
                                        value={FreRbacTypeSelection.everyone}
                                        label={t('adminV2Fre.roleInfo.AddEveryone')}
                                    />
                                )} */}
                            </RadioGroup>
                        </div>

                        <OwnersRoleInfoContent />
                    </TourStageContainer.StageContent>

                    <TourStageContainer.FooterContent>
                        <TourControls
                            spanFullScreen={true}
                            previousButton={commonLabel('Back')}
                            nextButton={commonLabel('Continue')}
                            handlePrevious={handleBackClick}
                            handleNext={handleContinueClick}
                            isNextButtonDisabled={isContinueLoading || dataShareLoading}
                            showPrevious={!tour.currentStop?.isFirstStop}
                            showProgressSpinner={isContinueLoading}
                        ></TourControls>
                    </TourStageContainer.FooterContent>
                </TourStageContainer>
            </motion.div>
            <AddRoles
                isAddRolesOpen={isModalOpen}
                onAddRolesClose={handleViewRolesToggle}
                viewOnly={true}
            />
        </>
    );
}

export default Access1pRoleInfo;
