import {useQuery} from '@tanstack/react-query';
import {AgentIdentity, AgentRequest} from './agents.types';
import useFetch from '@/api/useFetch';

export default function useGetAgentIdentity({agentId, requestParams}: AgentRequest) {
    const {customFetch} = useFetch();

    return useQuery({
        queryKey: ['agents', agentId, 'identity', requestParams],
        refetchOnWindowFocus: false,
        enabled: !!agentId,
        queryFn: async () => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<AgentIdentity>(
                `/agents/${agentId}/identity?${queryParams.toString()}`,
                {
                    method: 'GET',
                },
            );
        },
    });
}
