/**
 * Props for the TopRow component.
 *
 * @interface TopRowProps
 * @property {KeyValueFilterDetails[]} copilotExperienceMap - Array of key-value filter details for copilot experience.
 * @property {SelectedSearchFacet[]} searchFilters - Array of selected search facets.
 * @property {boolean} isLimitedOverageState - Indicates if the overage state is limited.
 * @property {OverageCapacityDisplayData} usageDisplayDetails - Data for displaying overage capacity.
 * @property {WorkspaceDisplayDetails} workspaceDetails - Details of the workspace.
 * @property {UsageParametersDisplayConfiguration} displayConfiguration - Configuration for displaying usage parameters.
 * @property {UsageDashboardDateFilterDropdownProps} dateFilterOptions - Options for the date filter dropdown.
 * @property {DashboardFilterContainerProps} filterContainerProps - Props for the filter container.
 * @property {SelectedFilterPillConfiguration} filterPillsOptions - Configuration for selected filter pills.
 * @property {boolean} isEvaluationAggregateDataLoading - Indicates if evaluation aggregate data is loading.
 * @property {boolean} isOverageEnabled - Indicates if overage is enabled.
 */

/**
 * TopRow component for displaying the top row of the usage parameters display.
 *
 * @param {TopRowProps} props - Props for the TopRow component.
 * @returns {JSX.Element} The rendered TopRow component.
 */
import {KeyValueFilterDetails, SelectedSearchFacet} from '@/api/capacities';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import useClasses from './UsageParameterDisplay.styles';
import {
    OverageCapacityDisplayData,
    WorkspaceDisplayDetails,
    UsageParametersDisplayConfiguration,
    UsageDashboardDateFilterDropdownProps,
    DashboardFilterContainerProps,
    SelectedFilterPillConfiguration,
} from '../UsageDashboard.types';

interface TopRowProps {
    copilotExperienceMap: KeyValueFilterDetails[];
    searchFilters: SelectedSearchFacet[];
    isLimitedOverageState: boolean;
    usageDisplayDetails: OverageCapacityDisplayData;
    workspaceDetails: WorkspaceDisplayDetails;
    displayConfiguration: UsageParametersDisplayConfiguration;
    dateFilterOptions: UsageDashboardDateFilterDropdownProps;
    filterContainerProps: DashboardFilterContainerProps;
    filterPillsOptions: SelectedFilterPillConfiguration;
    isEvaluationAggregateDataLoading: boolean;
    isOverageEnabled: boolean;
}

export default function TopRow(props: TopRowProps) {
    const classes = useClasses();

    return (
        <div className={classes.topRow}>
            <div className={classes.leftContainer}>
                <LeftColumn {...props} />
            </div>
            <div className={classes.rightContainer}>
                <RightColumn
                    dateRangeOption={props.dateFilterOptions?.dateRangeOption}
                    onOptionChanged={props.dateFilterOptions?.onOptionChanged}
                    showDateFilterDropDown={props.dateFilterOptions?.showDateFilterDropDown}
                    filterContainerProps={props.filterContainerProps}
                    searchFilters={props.searchFilters}
                    copilotExperienceMap={props.copilotExperienceMap}
                    filterPillsOptions={props.filterPillsOptions}
                    isEvaluationAggregateDataLoading={props.isEvaluationAggregateDataLoading}
                    isOverageEnabled={props.isOverageEnabled}
                ></RightColumn>
            </div>
        </div>
    );
}
