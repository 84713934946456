import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    field: {
        marginBlockEnd: tokens.spacingVerticalL,
    },
    configText: {
        marginBottom: tokens.spacingVerticalXS,
        fontWeight: tokens.fontWeightSemibold,
    },
    textareaWrapper: {
        fontSize: tokens.fontSizeBase300,
    },
});
