import {useMutation, useQueryClient} from '@tanstack/react-query';
import {AgentIdentityLogin, AgentsRequestQueryParameters, GetAgentRequest} from './agents.types';
import useFetch from '@/api/useFetch';

export default function useGetAgentIdentityLogin(requestParams: AgentsRequestQueryParameters) {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async ({agentId}: GetAgentRequest) => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<AgentIdentityLogin>(
                `/agents/${agentId}/identity:login?${queryParams.toString()}`,
                {
                    method: 'POST',
                    body: {},
                },
            );
        },
        onSettled: (_data, agentId) => {
            queryClient.refetchQueries({
                queryKey: ['agents', agentId, 'identity', requestParams],
            });
        },
    });
}
