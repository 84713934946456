import {makeStyles, shorthands, tokens} from '@fluentui/react-components';
import {NavigationBarHeight} from '@/components/App.styles';

export default makeStyles({
    title: {
        ...shorthands.margin(
            `calc(${NavigationBarHeight}px + ${tokens.spacingHorizontalL})`,
            0,
            tokens.spacingHorizontalXS,
        ),
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalXS, tokens.spacingHorizontalXS),
    },
    badge: {
        ...shorthands.margin(tokens.spacingVerticalS, tokens.spacingHorizontalS),
        display: 'inline-flex',
        alignItems: 'center',
    },
    badgeBackground: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    subtitle: {
        fontSize: tokens.fontSizeBase400,
        paddingBottom: tokens.spacingVerticalXL,
    },
});
