import {ContentTitle2} from '@/components/ui/Text';
import {tokens} from '@fluentui/react-components';
import React from 'react';
import useClasses from './ChangeCapacity.styles';
import {NONE} from '@/components/sections/admin/UsageDashboard/UsageDashboard.constants';
import {WarningIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';

/**
 * Props for the WorkspaceDisplayProps component.
 *
 * @interface UsageDashboardWorkspaceDisplayProps
 * @property {string} numericDisplay - The numeric value to be displayed.
 */

interface UsageDashboardWorkspaceDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
    numericDisplay: string;
}

export default function UsageDashboardWorkspaceDisplay(props: UsageDashboardWorkspaceDisplayProps) {
    const classes = useClasses();
    const {t} = useTranslation('admin');

    return (
        <>
            <div>
                {props.numericDisplay.length > 0 ? (
                    <div>
                        {props.numericDisplay && (
                            <ContentTitle2>{props.numericDisplay}</ContentTitle2>
                        )}
                    </div>
                ) : (
                    <div>
                        <WarningIcon
                            filled
                            className={classes.icon}
                            data-testid="warning-icon-no-workspace"
                        />
                        {t(NONE) && (
                            <ContentTitle2 data-testid="no-workspace-attached">
                                {t(NONE)}
                            </ContentTitle2>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
