export const STATIC_CONTENT: Record<string, string> = {
    READ_MORE_COMPUTE_UNITS: 'ChangeCapacityDialog.LinkValues.ReadMoreComputeUnits',
    MANAGE_BILLING_AZURE: 'ChangeCapacityDialog.LinkValues.ManageBillingAzure',
    TENANT_READ_ONLY_ACCESS_MESSAGE: 'ChangeCapacityDialog.TenantReadOnlyAccessMessage',
    ESTIMATED_MONTHLY_COST: 'ChangeCapacityDialog.EstimatedMonthlyCost',
    MONTH: 'ChangeCapacityDialog.Month',
    USD: 'ChangeCapacityDialog.USD',
    SECURITY_COMPUTE_UNITS: 'ChangeCapacityDialog.SecurityComputeUnits',
    UPDATE_CAPACITY_ERROR: 'ChangeCapacityDialog.UpdateCapacityError',
    DELETE_SECURITY_FABRIC_CAPACITY_HEADING:
        'ChangeCapacityDialog.DeleteSecurityFabricCapacityHeading',
    DELETE_CAPACITY_SUCCESS: 'ChangeCapacityDialog.DeleteCapacitySuccess',
    DELETE_CAPACITY_ERROR: 'ChangeCapacityDialog.DeleteCapacityError',
    DELETE_CAPACITY_CONFIRMATION_MESSAGE: 'ChangeCapacityDialog.DeleteCapacityConfirmationMessage',
    MODAL_INFO_TEXT: 'ChangeCapacityDialog.ModalInfoText',
    CAPACITY_UPDATED_SUCCESSFULLY: 'ChangeCapacityDialog.CapacityUpdatedSuccessfully',
    CHANGE_CAPACITY_INFORMATION: 'ChangeCapacityDialog.ChangeCapacityInformation',
    UPDATE_CAPACITY_SUCCESS_HEADER_OVERAGE:
        'ChangeCapacityDialog.UpdateCapacitySuccessHeaderOverage',
    UPDATE_CAPACITY_SUCCESS_HEADER_UNITS_MULTIPLE:
        'ChangeCapacityDialog.UpdateCapacitySuccessHeaderUnitsMultiple',
    UPDATE_CAPACITY_SUCCESS_HEADER_UNIT_SINGLE:
        'ChangeCapacityDialog.UpdateCapacitySuccessHeaderUnitsSingle',
    UPDATE_CAPACITY_SUCCESS_HEADER_OVERAGE_NO_LIMIT:
        'ChangeCapacityDialog.UpdateCapacitySuccessHeaderOverageNoLimit',
    UPDATE_CAPACITY_SUCCESS_HEADER_OVERAGE_NONE:
        'ChangeCapacityDialog.UpdateCapacitySuccessHeaderOverageNone',
    UPDATE_CAPACITY_SUCCESS_HEADER: 'ChangeCapacityDialog.UpdateCapacitySuccessHeader',
    PROVISIONED_CAPACITY_HEADER: 'ChangeCapacityDialog.ProvisionedSecurityComputeUnits',
    USD_UNITS: 'ChangeCapacityDialog.USDUnits',
    PER_UNIT: 'ChangeCapacityDialog.PerUnit',
    USE_OVERAGE_UNITS: 'ChangeCapacityDialog.UseOverageUnits',
    HOW_OVERAGE_WORKS: 'ChangeCapacityDialog.HowOverageWorks',
    HOW_OVERAGE_WORKS_DESCRIPTION: 'ChangeCapacityDialog.HowOverageWorksDescription',
    NO_OF_ON_DEMAND_UNITS: 'ChangeCapacityDialog.NoOfOnDemandUnits',
    NO_LIMIT: 'ChangeCapacityDialog.NoLimit',
    SET_MAX_LIMIT: 'ChangeCapacityDialog.SetMaxLimit',
    UNITS_PER_HOUR: 'ChangeCapacityDialog.UnitsPerHour',
    USD_PER_UNIT: 'ChangeCapacityDialog.USDPerUnit',
};

export const CONTROL_LABELS: Record<string, string> = {
    DELETE_CAPACITY: 'ChangeCapacityDialog.ControlLabels.DeleteCapacity',
    CANCEL: 'ButtonLabels.Cancel',
    APPLY: 'ButtonLabels.Apply',
    MANAGE_BILLING_AZURE: 'ChangeCapacityDialog.ControlLabels.ManageBillingAzure',
    DONE: 'ChangeCapacityDialog.ControlLabels.Done',
    YES: 'ChangeCapacityDialog.ControlLabels.Yes',
};

export const ARIA_LABELS: Record<string, string> = {
    CLOSE_CHANGE_CAPACITY_DIALOG: 'ChangeCapacityDialog.AriaLabels.CloseChangeCapacityDialog',
    CLOSE_UPDATE_CAPACITY_DIALOG: 'ChangeCapacityDialog.AriaLabels.CloseDeleteCapacityDialog',
    CLOSE_DELETE_CAPACITY_DIALOG: 'ChangeCapacityDialog.AriaLabels.CloseUpdateCapacityDialog',
    CHANGE_CAPACITY_UNITS: 'ChangeCapacityDialog.AriaLabels.ChangeCapacityUnits',
    CONFIRM_DELETE_CAPACITY: 'ChangeCapacityDialog.AriaLabels.ConfirmDeleteCapacity',
};
