import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    title: {
        marginTop: tokens.spacingVerticalMNudge,
        marginBottom: tokens.spacingVerticalXS,
    },
    description: {
        marginTop: tokens.spacingVerticalMNudge,
        marginBottom: tokens.spacingVerticalXS,
    },
    buttonContainer: {
        display: 'flex',
        gap: tokens.spacingHorizontalMNudge,
        ...shorthands.margin(
            tokens.spacingVerticalMNudge,
            tokens.spacingHorizontalMNudge,
            tokens.spacingVerticalMNudge,
            tokens.spacingHorizontalNone,
        ),
    },
});
