import {
    EvaluationOutputComponentType,
    EvaluationState,
    EvaluationType,
    useGetEvaluation,
} from '@/api/evaluations';
import {Markdown} from '@/components/ui';
import {EvaluationProps} from './Evaluation.types';
import useClasses from './SuccessEvaluationResult.styles';
import EvaluationMetadata from './EvaluationMetadata';
import EvaluationSources from './EvaluationSources';
import EvaluationImages from './EvaluationImages';
import EvaluationUserInputPanel from './EvaluationUserInputPanel';
import EvaluationFiles from './EvaluationFiles';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

export default function SuccessEvaluationResult({
    sessionId,
    promptId,
    evaluationId,
}: EvaluationProps) {
    const classes = useClasses();
    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});
    const isEvaluationUserInputsEnabled = useFeatureFlag(
        MedeinaFeatures.EnableEvaluationUserInputs,
    ) as boolean;
    const isWriteActionsEnabled = useFeatureFlag(
        MedeinaFeatures.EnableWriteActionsPromptCard,
    ) as boolean;
    const isEvaluationFilesEnabled = useFeatureFlag(
        MedeinaFeatures.EnableEvaluationFiles,
    ) as boolean;

    const hasImages = evaluation?.result?.outputComponents?.some(
        (component) => component?.type === EvaluationOutputComponentType.Image,
    );
    const hasFiles = evaluation?.result?.outputComponents?.some(
        (component) => component?.type === EvaluationOutputComponentType.File,
    );

    // Determine elapsed time when in debug mode.
    return (
        <div className={classes.root}>
            {evaluation && (
                <>
                    <div className={classes.content} data-testid="evaluation-result">
                        <EvaluationMetadata evaluation={evaluation} />

                        {isWriteActionsEnabled &&
                        isEvaluationUserInputsEnabled &&
                        evaluation.evaluationType == EvaluationType.WaitForInput &&
                        evaluation.state == EvaluationState.WaitingForInput &&
                        evaluation.evaluationUserInputFields ? (
                            <EvaluationUserInputPanel
                                evaluationUserInputFields={evaluation.evaluationUserInputFields}
                                evaluationId={evaluationId}
                                promtId={promptId}
                                sessionId={sessionId}
                            />
                        ) : (
                            <Markdown>{evaluation?.result?.content ?? ''}</Markdown>
                        )}
                        {hasImages && <EvaluationImages evaluation={evaluation} />}
                        {hasFiles && isEvaluationFilesEnabled && (
                            <EvaluationFiles evaluation={evaluation} />
                        )}
                    </div>
                    <div className={classes.sources}>
                        <EvaluationSources evaluation={evaluation} />
                    </div>
                </>
            )}
        </div>
    );
}
