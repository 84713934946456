import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingHorizontalS,
    },
    headerStatus: {
        display: 'flex',
        gap: tokens.spacingHorizontalL,
    },
    statusLabel: {
        paddingLeft: '0 !important', // Overriding the default padding from SFE StatusLabel
    },
});
