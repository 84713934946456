import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    surface: {
        height: 'auto',
        maxHeight: '800px',
        width: 'auto',
        maxWidth: '700px',

        '@media (max-width: 479px)': {
            height: '100%',
            maxHeight: 'none',
            width: '100%',
            maxWidth: 'none',
        },
    },
    body: {
        height: '100%',
    },
    title: {
        marginBottom: tokens.spacingVerticalL,
    },
});
