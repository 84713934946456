import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    error: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        ...shorthands.padding(tokens.spacingVerticalXXL, 0),
    },
    loading: {
        width: '100%',
        height: '100%',
    },
});
