import React from 'react';
import {Badge, BadgeProps} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

function Preview({color = 'informative', ...badgeProps}: BadgeProps) {
    const {t} = useTranslation();

    return (
        <Badge color={color} {...badgeProps}>
            {t('ApplicationTitleBadge')}
        </Badge>
    );
}

export default Preview;
