import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        wordBreak: 'break-word',
        ...shorthands.margin('48px', '64px'),
        marginLeft: '25%',
        width: '40%',
        height: '100%',
        minWidth: '280px',
    },
    titleSection: {
        marginLeft: '14px',
    },
    title: {
        marginTop: tokens.spacingVerticalNone,
        marginBottom: '20px',
        fontSize: tokens.spacingHorizontalXXXL,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.fontSizeHero800,
    },
    componentContainer: {
        '& > *:not(:last-child)': {
            paddingBottom: '48px',
        },
    },
    paragraphContent: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightRegular,
        textAlign: 'justify',
        paddingBottom: tokens.spacingVerticalXL,
    },
    addMemberButton: {
        flexDirection: 'row-reverse',
        alignContent: 'flex-start',
        width: '100%',
        marginRight: tokens.spacingHorizontalXXL,

        '& svg': {
            marginRight: tokens.spacingHorizontalXS,
        },
        '& > *:not(:last-child)': {
            marginRight: tokens.spacingHorizontalM,
        },
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    hidden: {
        display: 'none',
    },
    overflowAvatars: {
        overflow: 'visible',
    },
    subheading: {
        flexGrow: 1,
    },
});
