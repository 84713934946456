import {useQuery} from '@tanstack/react-query';
import {AgentDefinitionRequest, AgentDefinition} from './agents.types';
import useFetch from '@/api/useFetch';

export default function useGetAgentDefinition({agentDefinitionName}: AgentDefinitionRequest) {
    const {customFetch} = useFetch();

    return useQuery({
        queryKey: ['agentdefinitions', agentDefinitionName],
        refetchOnWindowFocus: false,
        enabled: !!agentDefinitionName,
        queryFn: async () => {
            return await customFetch<AgentDefinition>(`/agentdefinitions/${agentDefinitionName}`, {
                method: 'GET',
            });
        },
    });
}
