import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, 'auto'),
        maxWidth: '85%',
        minHeight: '100%',
        ...shorthands.gap(tokens.spacingVerticalM, tokens.spacingHorizontalM),
    },
});
