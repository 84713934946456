import {RoleType, MemberType} from '@/components/admin/rbac/rbac.types';

export const CONDITION_ATTRIBUTES = {
    User: 'principal.microsoft.id',
    Group: 'principal.microsoft.groups',
    Role: 'principal.microsoft.aad.approles',
    ServicePrincipal: 'principal.microsoft.id',
};

export const ROLE_POLICY_NAME_PREFIX_MAP: Record<RoleType, string> = {
    [RoleType.Contributor]: 'purviewworkspacerole_builtin_workspace-contributor',
    [RoleType.Owner]: 'purviewworkspacerole_builtin_workspace-owner',
};

export const GROUP_POLICY_KEYS: Record<MemberType, string> = {
    [MemberType.User]: 'principal.microsoft.id',
    [MemberType.Group]: 'principal.microsoft.groups',
    [MemberType.Role]: 'principal.microsoft.aad.approles',
    [MemberType.ServicePrincipal]: 'principal.microsoft.id',
};

// Workspace policy's contributor condition for 1P partner roles
export const PARTNER_ROLES_CONDITION = [
    {
        attributeName: 'microsoft.accessprovider.partner',
        attributeValueIncludedIn: ['ALL'],
    },
    {
        fromRule: 'purviewworkspacerole_builtin_workspace-contributor',
        attributeName: 'derived.purview.role',
        attributeValueIncludes: 'purviewworkspacerole_builtin_workspace-contributor',
    },
];

export const PARTNER_ROLES_ID = '1pRecommendedRoles';

export const SERVICE_PRINCIPAL_MAIL = 'Application';
