import {useViewportSize} from '@/components/ui/Grid';
import {
    FILTER_PILLS_NUMBER,
    MULTI_SELECTED_FILTER_PILL_LABELS,
    SelectedFilterPillLabelsPrefix,
    SINGLE_SELECTED_FILTER_PILL_LABELS,
} from '../UsageDashboard.constants';
import {useTranslation} from 'react-i18next';
import {SearchCategory} from '../UsageDashboard.types';
import {useMemo} from 'react';
import {getDisplayValue} from './Filter.utils';
import {KeyValueFilterDetails, SelectedSearchFacet} from '@/api/capacities/capacities.types';

interface FilterPillsLabelsProps {
    isOverageMSWUIEnabled?: boolean;
    isOverageEnabled: boolean;
    filters: SelectedSearchFacet[];
    copilotExperienceMap: KeyValueFilterDetails[];
}

export default function useGetSelectedFilterPillsLabels(props: FilterPillsLabelsProps) {
    const {
        isSmallScreen,
        isMediumScreen,
        isLargeScreen,
        isXLargeScreen,
        isXXLargeScreen,
        isXXXLargeScreen,
    } = useViewportSize();

    let filterPillsNumber = FILTER_PILLS_NUMBER.ABOVE_X_LARGE_SCREEN;

    if (props.isOverageMSWUIEnabled) {
        filterPillsNumber = FILTER_PILLS_NUMBER.SMALL_MEDIUM_SCREEN;
    } else if (props.isOverageEnabled) {
        if (isXXXLargeScreen) {
            filterPillsNumber = FILTER_PILLS_NUMBER.ABOVE_X_LARGE_SCREEN;
        } else if (isXXLargeScreen) {
            filterPillsNumber = FILTER_PILLS_NUMBER.LARGE_SCREEN;
        } else {
            filterPillsNumber = FILTER_PILLS_NUMBER.SMALL_MEDIUM_SCREEN;
        }
    } else {
        filterPillsNumber =
            isSmallScreen || isMediumScreen
                ? FILTER_PILLS_NUMBER.SMALL_MEDIUM_SCREEN
                : isLargeScreen
                ? FILTER_PILLS_NUMBER.LARGE_SCREEN
                : isXLargeScreen
                ? FILTER_PILLS_NUMBER.X_LARGE_SCREEN
                : FILTER_PILLS_NUMBER.ABOVE_X_LARGE_SCREEN;
    }
    const {t} = useTranslation('admin');

    const getMultiSelectedLabel = (category: string, value: number): string => {
        switch (category) {
            case SearchCategory.CopilotExperience:
                return t(MULTI_SELECTED_FILTER_PILL_LABELS.CopilotExperiences, {count: value});
            case SearchCategory.PluginUsed:
                return t(MULTI_SELECTED_FILTER_PILL_LABELS.PluginsUsed, {count: value});
            case SearchCategory.Users:
                return t(MULTI_SELECTED_FILTER_PILL_LABELS.Users, {count: value});
            case SearchCategory.InvocationType:
                return t(MULTI_SELECTED_FILTER_PILL_LABELS.InvocationTypes, {count: value});
            case SearchCategory.InvocationCategory:
                return t(MULTI_SELECTED_FILTER_PILL_LABELS.InvocationCategories, {count: value});
            default:
                return '';
        }
    };

    const getSingleSelectedLabel = (category: string, selection: string): string => {
        if (!selection) {
            return '';
        }

        switch (category) {
            case SearchCategory.CopilotExperience:
                return t(SINGLE_SELECTED_FILTER_PILL_LABELS.CopilotExperience, {value: selection});
            case SearchCategory.PluginUsed:
                return t(SINGLE_SELECTED_FILTER_PILL_LABELS.PluginUsed, {value: selection});
            case SearchCategory.Users:
                return t(SINGLE_SELECTED_FILTER_PILL_LABELS.User, {value: selection});
            case SearchCategory.InvocationType:
                return t(SINGLE_SELECTED_FILTER_PILL_LABELS.InvocationType, {value: selection});
            case SearchCategory.InvocationCategory:
                return t(SINGLE_SELECTED_FILTER_PILL_LABELS.InvocationCategory, {value: selection});
            default:
                return '';
        }
    };

    const multiSelectedLabels = useMemo(() => {
        const labels: Record<string, string> = {};
        props.filters.forEach((filter) => {
            if (filter.values.length > 1) {
                labels[filter.category] = getMultiSelectedLabel(
                    filter.category,
                    filter.values.length,
                );
            }
        });
        return labels;
    }, [props.filters]);

    const singleSelectedLabels = useMemo(() => {
        const labels: Record<string, string> = {};
        props.filters.forEach((filter) => {
            if (filter.values.length === 1) {
                labels[filter.category] = getSingleSelectedLabel(
                    filter.category,
                    getDisplayValue(filter, props.copilotExperienceMap),
                );
            }
        });
        return labels;
    }, [props.filters]);

    const getMoreFiltersLabel = (count: number): string => {
        return t(`${SelectedFilterPillLabelsPrefix}.MoreFilterLabel`, {count: count});
    };

    const getAppliedFiltersLabel = (count: number): string => {
        const moreFiltersLabel = `${SelectedFilterPillLabelsPrefix}.AppliedFilters.${
            count !== 1 ? 'Plural' : 'Singular'
        }`;
        return t(moreFiltersLabel, {count: count});
    };

    return {
        filterPillsNumber,
        multiSelectedLabels,
        singleSelectedLabels,
        getMoreFiltersLabel,
        getAppliedFiltersLabel,
    };
}
