import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalM,
        ...shorthands.margin(tokens.spacingVerticalM, 0),
    },
});
