import {IUsageParameterDisplayConfigurator} from '../UsageParametersDisplay.types';
import parameterDisplayConfiguratorsUtils from './parameterDisplayConfigurators.utils';

export class SingleColumnConfigurator implements IUsageParameterDisplayConfigurator {
    private parameterDisplayConfiguratorsUtils;

    constructor(classes: Record<string, string>) {
        this.parameterDisplayConfiguratorsUtils = parameterDisplayConfiguratorsUtils(classes);
    }

    generateStructuredUsageParameterElements(elementsToRender: JSX.Element[]): JSX.Element[] {
        let elementRender: JSX.Element[] = [];

        elementRender.push(
            this.parameterDisplayConfiguratorsUtils.generateRowDisplayElement(elementsToRender),
        );

        return elementRender;
    }
}
