import {Body1, Body1Strong, Button} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import AgentIdentityDisplay from '../../../AgentItentityDisplay/AgentIdentityDisplay';
import type {AgentIdentity, AgentDefinition} from '@/api/agents';
import useClasses from './AgentIdentityConfiguration.styles';
import AgentPermissions from '../../../Permissions/AgentPermissions';

export interface AgentIdentityConfigurationProps {
    mode: 'create' | 'edit';
    identity: AgentIdentity | undefined;
    agentDefinition: AgentDefinition;
    onAddIndentityClick: () => void;
}

export default function AgentIdentityConfiguration({
    mode,
    identity,
    agentDefinition,
    onAddIndentityClick,
}: AgentIdentityConfigurationProps) {
    const {t: tAgents} = useTranslation('agents');
    const classes = useClasses();

    return (
        <>
            <Body1>{tAgents('AgentConfiguration.PermissionsDescription')}</Body1>
            <section className={classes.section}>
                <Body1Strong as="h2" className={classes.sectionTitle}>
                    {tAgents('Agent.Details.PermissionsHeader')}
                </Body1Strong>
                <AgentPermissions requiredSkillsets={agentDefinition.requiredSkillsets} />
            </section>
            <section className={classes.section}>
                <div className={classes.sectionTitleWithButton}>
                    <Body1Strong as="h2" className={classes.sectionTitle}>
                        {tAgents('Agent.Details.IdentityHeader')}
                    </Body1Strong>
                    {mode === 'edit' && (
                        <Button appearance="secondary" onClick={onAddIndentityClick}>
                            {tAgents('AgentConfiguration.IdentityChooseAnother')}
                        </Button>
                    )}
                </div>
                <Body1>
                    {mode === 'create' && tAgents('AgentConfiguration.IdentityDescription')}
                    {mode === 'edit' && tAgents('AgentConfiguration.IdentityDescriptionAlternate')}
                </Body1>
                <Body1>
                    {mode === 'create' && tAgents('AgentConfiguration.IdentityGrantAccess')}
                    {mode === 'edit' && identity && <AgentIdentityDisplay identity={identity} />}
                </Body1>
            </section>
        </>
    );
}
