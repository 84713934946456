import {useQuery} from '@tanstack/react-query';
import {Agent, AgentRequest} from './agents.types';
import useFetch from '@/api/useFetch';

export default function useGetAgent({agentId, requestParams}: AgentRequest) {
    const {customFetch} = useFetch();

    return useQuery({
        queryKey: ['agents', agentId, requestParams],
        refetchOnWindowFocus: false,
        enabled: !!agentId,
        queryFn: async () => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<Agent>(`/agents/${agentId}?${queryParams.toString()}`, {
                method: 'GET',
            });
        },
    });
}
