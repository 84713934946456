import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    container: {
        ...shorthands.padding(tokens.spacingHorizontalXXXL),
    },
    emptyStateSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: tokens.spacingHorizontalS,
    },
    emptyStateButton: {
        paddingRight: '5px',
    },
});
