import {useEffect, useState} from 'react';
// Safely disable hydration errors, since we're not using SSR.
export default function SafeHydrate({children}: {children: React.ReactNode}) {
    // With React 18, we also need to flag that the client is ready.
    const [ready, setReady] = useState(false);
    useEffect(() => {
        setReady(true);
    }, []);

    return (
        <div suppressHydrationWarning>
            {typeof document === 'undefined' || !ready ? null : children}
        </div>
    );
}
