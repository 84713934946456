import {ReactElement} from 'react';
import {
    EvaluationLog,
    EvaluationLogLevel,
    EvaluationResultType,
    EvaluationState,
    EvaluationStepStatus,
} from '@/api/evaluations';
import {PageResponse, PreviewState} from '@/api/api.types';

export interface EvaluationProps {
    sessionId: string;
    promptId: string;
    evaluationId: string;
    isReadOnly?: boolean;
    onEvaluationStateChange?: (evaluationState: EvaluationState) => void;
    isAgentEvaluation?: boolean;
}

export interface ExportEvaluationProps extends EvaluationProps {
    isDisabled: boolean;
    isSummaryExport: boolean;
    enableCopy?: boolean;
    label?: string;
    className?: string | undefined;
}

export type EvaluationResultComponents = {
    [T in EvaluationResultType]: (props: EvaluationProps) => ReactElement;
};

export interface EvaluationContextData {
    sessionId: string;
    promptId: string;
    evaluationId: string;
    children?: React.ReactNode;
}

export interface DetailedEvaluationProps {
    logs: PageResponse<EvaluationLog> | undefined;
    evaluationState: string | undefined;
    isEvaluationComplete: boolean;
    previewState: PreviewState | undefined;
}

export interface EvaluationLogsSummaryProps {
    logs: PageResponse<EvaluationLog> | undefined;
}

export interface GetElapsedTimeForStepRequest {
    stepStatus: EvaluationStepStatus;
    areAllStepsComplete: boolean;
    createdAt: string;
    updatedAt: string;
}

export const EvaluationLogLevels = new Set([
    EvaluationLogLevel.Error,
    EvaluationLogLevel.Warning,
    EvaluationLogLevel.Information,
]);
