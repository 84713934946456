import {useTranslation} from 'react-i18next';
import UnitsDisplay from '../../ChangeCapacityDialog/UnitsDisplay';
import {WORKSPACE_USAGE} from '../UsageDashboard.constants';
import {Link} from '@fluentui/react-components';
import useClasses from './UsageParametersDisplay.styles';
import {PAGE_LINKS} from '@/components/App.constants';

/**
 * Component to display the details of a workspace.
 *
 * @param {WorkspaceDetailsProps} props - The properties for the WorkspaceDetails component.
 * @param {string} props.name - The name of the workspace to be displayed.
 *
 * @returns {JSX.Element} The rendered WorkspaceDetails component.
 */
interface WorkspaceDetailsProps {
    name: string;
}

export default function WorkspaceDetails(props: WorkspaceDetailsProps) {
    const {t} = useTranslation('admin');
    const classes = useClasses();
    const MANAGE_WORKSPACES_MENU_BUTTON_IDENTIFIER =
        'ManageWorkspaces.MenuButtons.ManageWorkspaces';

    return (
        <>
            <div className={classes.workspaceContainer}>
                <UnitsDisplay
                    heading={t(WORKSPACE_USAGE)}
                    numericDisplay={props.name}
                    footer={''}
                ></UnitsDisplay>
                <Link href={PAGE_LINKS.MANAGE_WORKSPACES} data-testid="manage-workspaces-link">
                    {t(MANAGE_WORKSPACES_MENU_BUTTON_IDENTIFIER)}
                </Link>
            </div>
        </>
    );
}
