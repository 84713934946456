import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import type {AgentsRequestQueryParameters, GetAgentRequest} from './agents.types';
import handleError from '../handleError';

export default function useDeleteAgent(requestParams: AgentsRequestQueryParameters) {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (request: GetAgentRequest) => {
            const queryParams = new URLSearchParams(requestParams);
            const response = await customFetch<Response>(
                `/agents/${request.agentId}?${queryParams.toString()}`,
                {
                    method: 'DELETE',
                },
                true,
            );

            if (response.ok) {
                return true;
            } else {
                handleError(response, 'Failed to delete agent');
            }
        },
        onSuccess: (_, request) => {
            queryClient.removeQueries({
                queryKey: ['agents', request.agentId, requestParams],
            });
            queryClient.refetchQueries({
                queryKey: ['agents', requestParams],
                exact: true,
            });
        },
    });
}
