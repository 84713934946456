import {useTranslation} from 'react-i18next';
import UnitsDisplay, {UnitsDisplayStyle} from '../../ChangeCapacityDialog/UnitsDisplay';
import {ScuAllocationLabels} from '../UsageDashboard.constants';
import {isSingularUnit} from '../UsageDashboard.utils';
import {useMemo} from 'react';

/**
 * Props for the ProvisionedUnits component.
 *
 * @interface ProvisionedUnitsProps
 * @property {number} provisionedUnitsAvailable - The number of provisioned units available.
 * @property {number} provisionedUnitsUsed - The number of provisioned units used.
 */

interface ProvisionedUnitsProps {
    showUnitValues: boolean;
    provisionedUnitsAvailable: number;
    provisionedUnitsUsed: number;
}

export default function ProvisionedUnits(props: ProvisionedUnitsProps) {
    const {t: tAdmin} = useTranslation('admin');

    const provisionedUnitDetailsHeader = useMemo(
        () => tAdmin(ScuAllocationLabels.ProvisionedHeader),
        [tAdmin],
    );

    const provisionedUnitsFooter = useMemo(
        () => tAdmin(ScuAllocationLabels.ProvisionedFooter),
        [tAdmin],
    );

    const provisionedUnits = useMemo(
        () => ({used: props.provisionedUnitsUsed, available: props.provisionedUnitsAvailable}),
        [props.provisionedUnitsUsed, props.provisionedUnitsAvailable],
    );

    const provisionedUnitsNumericDisplay = useMemo(
        () =>
            isSingularUnit(props.provisionedUnitsAvailable)
                ? tAdmin(ScuAllocationLabels.UnitsAvailableSingular, provisionedUnits)
                : tAdmin(ScuAllocationLabels.UnitsAvailablePlural, provisionedUnits),
        [props.provisionedUnitsAvailable, tAdmin, provisionedUnits],
    );

    return (
        <UnitsDisplay
            data-testid="provisioned-units-section"
            heading={provisionedUnitDetailsHeader}
            numericDisplay={provisionedUnitsNumericDisplay}
            footer={provisionedUnitsFooter}
            displayStyle={UnitsDisplayStyle.Visible}
        ></UnitsDisplay>
    );
}
