import React from 'react';
import ReactMarkdown from 'react-markdown';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import useClasses from './SessionAgentView.styles';
import {useLayout} from '@/components/ui/Layout';
import {mergeClasses} from '@griffel/react';

const SessionAgentNodeDetailsModal = ({nodeData, onClose}: any) => {
    const classes = useClasses();
    const {sidePanel} = useLayout();

    if (!nodeData) return null;

    const formatValue = (value: any) => {
        if (typeof value === 'object' && value !== null) {
            return (
                <SyntaxHighlighter language="json" wrapLongLines>
                    {JSON.stringify(value, null, 2)}
                </SyntaxHighlighter>
            );
        }
        if (typeof value === 'string') {
            try {
                const parsedValue = JSON.parse(value);
                if (typeof parsedValue === 'object' && parsedValue !== null) {
                    return (
                        <SyntaxHighlighter language="json" wrapLongLines>
                            {JSON.stringify(parsedValue, null, 2)}
                        </SyntaxHighlighter>
                    );
                }
            } catch (e) {
                // If parsing fails, treat it as a regular string - this is to show JSON objects in a readable format
            }
        }
        return value as string;
    };

    return (
        <div
            className={classes.modalOverlay}
            style={{width: sidePanel?.open ? `${100 - sidePanel.width}%` : '100%'}}
            onClick={onClose}
            data-testid="agent-node-details-dialog"
        >
            <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
                <h2>Node Details</h2>
                {Object.entries(nodeData).map(([key, value]) => (
                    <div key={key} className={classes.modalItem}>
                        <strong>{key}:</strong>{' '}
                        {key === 'Skill Output' ? (
                            <ReactMarkdown>{value as string}</ReactMarkdown>
                        ) : (
                            formatValue(value)
                        )}
                    </div>
                ))}
                <button onClick={onClose} data-testid="agent-node-details-close-button">
                    Close
                </button>
            </div>
        </div>
    );
};

export default SessionAgentNodeDetailsModal;
