import React, {useCallback, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentHeader.styles';
import {Persona} from '@sfe/react-persona';
import {StatusLabel} from '@sfe/react-status-label';
import {
    Menu,
    MenuTrigger,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuButton,
    Divider,
    Button,
} from '@fluentui/react-components';
import {
    MoreHorizontal24Regular,
    Power24Regular,
    Send24Regular,
    Pause24Regular,
    Edit24Regular,
} from '@fluentui/react-icons';
import {Agent, AgentDefinition} from '@/api/agents';
import AgentConfigurationDialog from '../AgentConfigurationDialog/AgentConfigurationDialog';
import useAgentStatus from '../useAgentStatus';

interface AgentHeaderProps {
    agent: Agent;
    definition: AgentDefinition;
    setOpenPauseDialog: (open: boolean) => void;
    onEnableTrigger: (enabled: boolean) => void;
    onManualRun: () => void;
}

export default function AgentHeader(props: AgentHeaderProps) {
    const {agent, definition, setOpenPauseDialog, onEnableTrigger, onManualRun} = props;
    const {t: tAgents} = useTranslation('agents');
    const classes = useClasses();

    const [openConfigurationDialog, setOpenConfigurationDialog] = useState<boolean>(false);

    const personaImagePlaceholder = '/images/icons/MicrosoftAgentPersona.svg';

    const handleOpenPauseDialog = useCallback(() => {
        setOpenPauseDialog(true);
    }, [setOpenPauseDialog]);

    const handleRunTrigger = useCallback(() => {
        onEnableTrigger(true);
    }, [onEnableTrigger]);

    const handleOpenConfigurationDialog = () => {
        setOpenConfigurationDialog(true);
    };

    const handleCloseConfigurationDialog = () => {
        setOpenConfigurationDialog(false);
    };

    const {getStatus, isAutomaticTrigger, isTriggerActive} = useAgentStatus(agent, definition);

    const {status, text: statusText} = getStatus();

    return (
        <>
            <div className={classes.header}>
                <Persona
                    data-testid="agent-persona"
                    avatar={{
                        image: {
                            className: classes.headerImage,
                            src: personaImagePlaceholder,
                        },
                        shape: 'square',
                    }}
                    textAlignment="center"
                    badge={{
                        children: tAgents('Agent.Header.PreviewBadge'),
                        size: 'medium',
                    }}
                    name={agent.displayName ?? definition.displayName}
                    size="huge"
                    tertiaryText={
                        <div className={classes.headerStatus} data-testid={'agent-header-status'}>
                            <StatusLabel
                                className={classes.statusLabel}
                                status={status}
                                text={statusText}
                            />
                            <Divider vertical /> {definition.publisher}{' '}
                        </div>
                    }
                />
                <div className={classes.headerButtons}>
                    {isTriggerActive && (
                        <Button
                            icon={<Pause24Regular />}
                            data-testid={'agent-header-pause-button'}
                            onClick={handleOpenPauseDialog}
                        >
                            {tAgents('Agent.Header.PauseAgentButton')}
                        </Button>
                    )}
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton
                                appearance="primary"
                                className={classes.runButton}
                                data-testid={'agent-header-run-button'}
                            >
                                {tAgents('Agent.Header.RunButton')}
                            </MenuButton>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem
                                    icon={<Power24Regular />}
                                    data-testid={'agent-header-run-trigger-button'}
                                    disabled={
                                        !agent.triggers?.[0]?.enabled ||
                                        isTriggerActive ||
                                        !isAutomaticTrigger
                                    }
                                    onClick={handleRunTrigger}
                                >
                                    {tAgents('Agent.Header.RunOnTriggerButton')}
                                </MenuItem>
                                <MenuItem
                                    icon={<Send24Regular />}
                                    data-testid={'agent-header-run-once-button'}
                                    disabled={!agent.triggers?.[0]?.enabled}
                                    onClick={onManualRun}
                                >
                                    {tAgents('Agent.Header.RunOneTimeButton')}
                                </MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton
                                appearance="transparent"
                                icon={<MoreHorizontal24Regular />}
                                data-testid={'agent-header-options-button'}
                                aria-label={tAgents('Agent.Header.OptionsButton')}
                            />
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem
                                    icon={<Edit24Regular />}
                                    data-testid={'agent-header-edit-button'}
                                    onClick={handleOpenConfigurationDialog}
                                >
                                    {tAgents('Agent.Header.EditAgentButton')}
                                </MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </div>
            </div>

            <AgentConfigurationDialog
                agentDefinition={definition}
                agent={agent}
                mode="edit"
                open={openConfigurationDialog}
                onClose={handleCloseConfigurationDialog}
            />
        </>
    );
}
