import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    choices: {
        display: 'flex',
        gap: tokens.spacingHorizontalMNudge,
    },
    combobox: {
        width: '40%',
    },
    textInput: {
        width: '40%',
    },
    inputComponent: {
        marginTop: '5px',
        marginBottom: '5px',
    },
});
