import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentRun.styles';
import {Spinner, Subtitle1} from '@fluentui/react-components';
import {AgentScopeTypes, useGetAgentDetails, useGetAgentTriggerRunDetails} from '@/api/agents';
import AgentRunContent from './AgentRunContent';
import AgentRunHeader from './AgentRunHeader';

interface AgentRunProps {
    agentId: string;
    runId: string;
}

export default function AgentRun(props: AgentRunProps) {
    const {agentId, runId} = props;
    const classes = useClasses();
    const {t: tAgents} = useTranslation('agents');

    const {agent, definition, isLoading, isError} = useGetAgentDetails({
        agentId,
        requestParams: {scope: AgentScopeTypes.WORKSPACE},
    });

    const {
        triggerRun,
        triggerExecution,
        isFailedOrPendingRun,
        isError: isRunError,
        isLoading: isRunLoading,
    } = useGetAgentTriggerRunDetails({
        agentId: agentId,
        triggerName: agent?.triggers?.[0]?.name,
        runId: runId,
        requestParams: {scope: AgentScopeTypes.WORKSPACE},
    });

    const hasInputs = useMemo(() => {
        // Check if the agent has any required settings
        const hasAgentSettings = definition?.settings?.some((setting) => {
            return setting.required;
        });
        if (hasAgentSettings) return true;

        // Check if the trigger has any required settings
        return definition?.triggers?.[0]?.settings?.some((setting) => {
            return setting.required;
        });
    }, [definition]);

    return (
        <div className={classes.root} data-testid="agent-run-page">
            {isLoading || isRunLoading ? (
                <div className={classes.loading}>
                    <Spinner
                        labelPosition="below"
                        label={tAgents('Agent.Loading')}
                        data-testid="agent-loading-spinner"
                    />
                </div>
            ) : isError || isRunError ? (
                <>{tAgents('Agent.Error')}</>
            ) : (
                <>
                    <div className={classes.header}>
                        <div className={classes.headerTitle}>
                            <AgentRunHeader isLoading={isRunLoading} triggerRun={triggerRun} />
                        </div>
                    </div>
                    <div className={classes.content}>
                        <div className={classes.output}>
                            <AgentRunContent
                                triggerExecution={triggerExecution}
                                triggerRun={triggerRun}
                                isError={isRunError}
                                isLoading={isRunLoading}
                                isFailedOrPendingRun={isFailedOrPendingRun}
                                hasInputs={!!hasInputs}
                            />
                        </div>
                        <div className={classes.activity}>
                            <div className={classes.activityTitle}>
                                <Subtitle1>{tAgents('AgentRun.ActivityTitle')}</Subtitle1>
                            </div>
                            <div className={classes.activityMap}>{/** NodeMap */}</div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
