import {
    Button,
    Persona,
    TableCellLayout,
    TableColumnDefinition,
    createTableColumn,
} from '@fluentui/react-components';
import {DimensionalHourlyAggregate, HourlyCapacityUsageStatus} from './UsageDashboard.types';
import {
    DATETIME_FORMAT,
    TableHeaders,
    USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
} from './UsageDashboard.constants';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {roundToDecimalPlaces} from './UsageDashboard.utils';
import {ChevronDownIcon, ChevronRightIcon} from '@/components/ui/icons';

export const columns: TableColumnDefinition<HourlyCapacityUsageStatus>[] = [
    createTableColumn<HourlyCapacityUsageStatus>({
        columnId: 'date',
        compare: (usageData1, usageData2) => {
            return usageData1.startTime < usageData2.startTime ? -1 : 1;
        },
        renderHeaderCell: () => {
            return `${TableHeaders.DATE}`;
        },
        renderCell: (item) => {
            const date = new Date(item.startTime);
            return (
                <TableCellLayout>{format(date, DATETIME_FORMAT.TABLE_COLUMN_DATE)}</TableCellLayout>
            );
        },
    }),
    createTableColumn<HourlyCapacityUsageStatus>({
        columnId: 'unitUsageValue',
        renderHeaderCell: () => {
            return `${TableHeaders.UNIT_USAGE}`;
        },
        renderCell: (item) => {
            return item.usageValue;
        },
    }),
    createTableColumn<HourlyCapacityUsageStatus>({
        columnId: 'unitUsageStatus',
        renderHeaderCell: () => {
            return `${TableHeaders.UNIT_STATUS}`;
        },
        renderCell: (item) => <UnitUsageStatusCell item={item} />,
    }),
];

const UnitUsageStatusCell = ({item}: {item: HourlyCapacityUsageStatus}) => {
    const {t} = useTranslation('admin');
    return (
        <TableCellLayout media={item.usageStatus.icon}>
            {t(item.usageStatus.status)}
        </TableCellLayout>
    );
};

export const roundToOneDecimal = (value: number): number =>
    Math.round((value + Number.EPSILON) * 10) / 10;

export const dimensionalAggregateTableColumns: TableColumnDefinition<DimensionalHourlyAggregate>[] =
    [
        createTableColumn<DimensionalHourlyAggregate>({
            columnId: 'sessionId',
            renderHeaderCell: () => {
                return `${TableHeaders.SESSION_ID}`;
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout
                        media={
                            item.isSessionHeader ? (
                                <Button
                                    appearance="subtle"
                                    size="small"
                                    icon={
                                        item.isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />
                                    }
                                    onClick={() => item.toggleSession?.(item.sessionId)}
                                    aria-expanded={item.isExpanded}
                                    aria-label={
                                        item.isExpanded
                                            ? 'Collapse this session'
                                            : 'Expand this session'
                                    }
                                />
                            ) : null
                        }
                    >
                        {item.sessionId}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<DimensionalHourlyAggregate>({
            columnId: 'date',
            compare: (usageData1, usageData2) => {
                return usageData1.aggregateStartTime < usageData2.aggregateStartTime ? -1 : 1;
            },
            renderHeaderCell: () => {
                return `${TableHeaders.DATE}`;
            },
            renderCell: (item) => {
                const date = new Date(item.aggregateStartTime);
                return (
                    <TableCellLayout>
                        {format(date, DATETIME_FORMAT.TABLE_COLUMN_DATE)}
                    </TableCellLayout>
                );
            },
        }),
        createTableColumn<DimensionalHourlyAggregate>({
            columnId: 'unitUsageValue',
            renderHeaderCell: () => {
                return `${TableHeaders.UNIT_USAGE}`;
            },
            renderCell: (item) => {
                if (item.isSessionHeader) {
                    return roundToDecimalPlaces(
                        item.totalUsedCapacityBySessionId ?? 0,
                        USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
                    ); // Show total capacity at session level
                }
                return roundToDecimalPlaces(item.usedCapacity, USAGE_DATA_TRUNCATION_DECIMAL_COUNT);
            },
        }),
        createTableColumn<DimensionalHourlyAggregate>({
            columnId: 'initiatedBy',
            renderHeaderCell: () => {
                return `${TableHeaders.INITIATED_BY}`;
            },
            renderCell: (item) => {
                return (
                    <Persona
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        textPosition="after"
                        name={item.userName}
                        avatar={{color: 'colorful'}}
                        size="large"
                    />
                );
            },
        }),
        createTableColumn<DimensionalHourlyAggregate>({
            columnId: 'category',
            renderHeaderCell: () => {
                return `${TableHeaders.CATEGORY}`;
            },
            renderCell: (item) => {
                return item.invocationCategory;
            },
        }),
        createTableColumn<DimensionalHourlyAggregate>({
            columnId: 'type',
            renderHeaderCell: () => {
                return `${TableHeaders.TYPE}`;
            },
            renderCell: (item) => {
                return item.invocationType;
            },
        }),
        createTableColumn<DimensionalHourlyAggregate>({
            columnId: 'copilotExperience',
            renderHeaderCell: () => {
                return `${TableHeaders.COPILOT_EXPERIENCE}`;
            },
            renderCell: (item) => {
                return item.copilotExperience;
            },
        }),
        createTableColumn<DimensionalHourlyAggregate>({
            columnId: 'plugins',
            renderHeaderCell: () => {
                return `${TableHeaders.PLUGINS}`;
            },
            renderCell: (item) => {
                return item.skillSetNames?.join(', ') ?? '';
            },
        }),
    ];
