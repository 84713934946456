import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        background: tokens.colorNeutralBackground2,
        '@media (max-width: 1024px)': {
            overflow: 'auto',
        },
    },
    loading: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    header: {
        minHeight: '112px',
        display: 'flex',
        alignItems: 'center',
        ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralBackground6),
    },
    headerTitle: {
        ...shorthands.padding(0, tokens.spacingHorizontalXXL),
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        height: 'calc(-156px + 100vh)',
        '@media (max-width: 1024px)': {
            flexDirection: 'column',
        },
    },
    output: {
        width: '700px',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.borderRight(tokens.strokeWidthThin, 'solid', tokens.colorNeutralBackground6),
        '@media (max-width: 1024px)': {
            width: '100%',
            minHeight: '100%',
            borderRight: 'none',
        },
    },
    activity: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    activityTitle: {
        minHeight: '64px',
        display: 'flex',
        alignItems: 'center',
        ...shorthands.padding(0, tokens.spacingHorizontalXXL),
        ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralBackground6),
    },
    activityMap: {
        minHeight: 'calc(-220px + 100vh)',
    },
});
