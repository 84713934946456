import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    dialog: {
        minWidth: '700px',
    },
    badgeBackground: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    imageBackground: {
        backgroundColor: tokens.colorNeutralBackground1,
        objectFit: 'contain',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingVerticalM,
    },
    titleInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: tokens.spacingVerticalXS,
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingVerticalS,
    },
    detailContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalL,
    },
    description: {
        marginTop: tokens.spacingVerticalXS,
    },
    sectionItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalXS,
    },
    permissionsList: {
        margin: 0,
        ...shorthands.padding(0, tokens.spacingHorizontalXL),
    },
    dismissColor: {
        color: tokens.colorStatusDangerForeground3,
    },
});
