import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 2.5fr 1.5fr',
        columnGap: tokens.spacingHorizontalM,
    },
    editorContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: tokens.colorNeutralBackground1,
        borderRadius: '12px',
        boxShadow: tokens.shadow4,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
        minHeight: '60px',
        position: 'relative',
    },
    label: {
        fontSize: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase500,
        ...shorthands.padding(0, tokens.spacingHorizontalXXL),
    },
    saveButton: {
        marginLeft: 'auto',
        marginRight: tokens.spacingHorizontalXXL,
    },
    toggleSwitch1: {
        position: 'absolute',
        left: '37%',
    },
    toggleSwitch2: {
        position: 'absolute',
        left: '45%',
    },
});
