import {useMemo, useState} from 'react';
import {
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridProps,
    DataGridRow,
} from '@fluentui/react-components';
import {DataTableProps} from './DataTable.types';
import useClasses from './DataTable.styles';
import {useTranslation} from 'react-i18next';
import {DimensionalHourlyAggregate} from '../UsageDashboard.types';
import {SortingDirection} from '@/api/api';

export default function DataTable<T extends DimensionalHourlyAggregate>(props: DataTableProps<T>) {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    const [expandedSessions, setExpandedSessions] = useState<{[sessionId: string]: boolean}>({});

    const groupedData = useMemo(() => {
        const dataMap: Record<string, DimensionalHourlyAggregate[]> = {};
        const sessionTotals: Record<string, {totalUsedCapacity: number}> = {}; // ✅ Store session totals separately

        props.data.forEach((item) => {
            if (!dataMap[item.sessionId]) {
                dataMap[item.sessionId] = [];
            }

            // Initialize session totals storage
            if (!sessionTotals[item.sessionId]) {
                sessionTotals[item.sessionId] = {totalUsedCapacity: 0};
            }

            sessionTotals[item.sessionId].totalUsedCapacity += item.usedCapacity || 0;

            dataMap[item.sessionId].push({...item, isSessionHeader: false});
        });

        Object.keys(dataMap).forEach((sessionId) => {
            const sessionItems = dataMap[sessionId];

            if (sessionItems.length > 1) {
                // Create a separate session header row
                const sessionHeader = {
                    ...sessionItems[0],
                    isSessionHeader: true,
                    isExpanded: expandedSessions[sessionId] ?? false,
                    totalUsedCapacityBySessionId: sessionTotals[sessionId].totalUsedCapacity,
                };

                // Insert session header at index 0 (before data rows)
                dataMap[sessionId] = [sessionHeader, ...sessionItems];
            }
        });

        return dataMap;
    }, [props.data, expandedSessions]);

    // Toggle expand/collapse for session groups
    const toggleSession = (sessionId: string) => {
        setExpandedSessions((prev) => ({
            ...prev,
            [sessionId]: !prev[sessionId],
        }));
    };

    //If v2 is not implemented, shouldSortData will always be true, else if the feature is enabled, will render depending on
    // the sorting parameters
    const shouldSortData = props.sortingParameters?.isSortable ?? false;

    const sortColumn =
        props?.sortingParameters?.sortColumn?.[0]?.column ?? props.columns?.[0]?.columnId;

    const sortDirection = Boolean(props.sortingParameters?.sortDirection)
        ? props.sortingParameters?.sortDirection
        : 'descending';

    const [sortState, setSortState] = useState<
        Parameters<NonNullable<DataGridProps['onSortChange']>>[1]
    >(
        props.sortingParameters?.isSortable
            ? {
                  sortColumn: sortColumn,
                  sortDirection: sortDirection ?? 'descending',
              }
            : {
                  sortColumn: sortColumn,
                  sortDirection: 'descending',
              },
    );
    const onGridSortChange: DataGridProps['onSortChange'] = (e, nextSortState) => {
        if (Boolean(props.onSortChange) && props.sortingParameters?.isSortable) {
            e.preventDefault();
            e.stopPropagation();

            props.onSortChange?.(e, setSortState, {
                sortColumn: nextSortState.sortColumn as string,
                sortDirection: nextSortState.sortDirection as SortingDirection,
            });
        } else {
            setSortState(nextSortState);
        }
    };

    return (
        <div>
            <div className={classes.root}>
                <DataGrid
                    className={classes.grid}
                    items={Object.values(groupedData).flat()}
                    columns={props.columns}
                    sortable={shouldSortData}
                    focusMode="composite"
                    sortState={sortState}
                    onSortChange={onGridSortChange}
                    data-testid={props.dataTestId}
                >
                    <DataGridHeader>
                        <DataGridRow>
                            {({renderHeaderCell}) => (
                                <DataGridHeaderCell className={classes.gridHeaderCell}>
                                    {t(renderHeaderCell() as string)}
                                </DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>

                    <DataGridBody<T>>
                        {({item}) => {
                            const isSessionHeader = item.isSessionHeader;
                            const sessionId = item.sessionId;
                            const sessionItems = groupedData[sessionId];
                            const hasMultipleItems = sessionItems.length > 1;
                            const isExpanded = item.isExpanded;

                            return (
                                <>
                                    {/* Case 1: Session Header - Shows Total Capacity */}
                                    {isSessionHeader && (
                                        <DataGridRow
                                            className={classes.gridRow}
                                            onClick={() =>
                                                hasMultipleItems && toggleSession(sessionId)
                                            }
                                        >
                                            {({renderCell}) => (
                                                <DataGridCell
                                                    colSpan={props.columns.length}
                                                    className={classes.gridHeaderCell}
                                                >
                                                    {renderCell(item)}
                                                </DataGridCell>
                                            )}
                                        </DataGridRow>
                                    )}
                                    {/* Case 2: Expanded Session Rows */}
                                    {isSessionHeader &&
                                        isExpanded &&
                                        sessionItems
                                            .filter((rowItem) => !rowItem.isSessionHeader)
                                            .map((rowItem, index) => (
                                                <DataGridRow
                                                    key={`${rowItem.sessionId}-${index}`}
                                                    className={classes.expandedRow}
                                                >
                                                    {({renderCell}) => (
                                                        <DataGridCell
                                                            className={classes.expandedCell}
                                                        >
                                                            {renderCell(rowItem)}
                                                        </DataGridCell>
                                                    )}
                                                </DataGridRow>
                                            ))}

                                    {/* Case 3: Single-Item Session*/}
                                    {!isSessionHeader && !hasMultipleItems && (
                                        <DataGridRow
                                            key={item.sessionId}
                                            className={classes.gridRow}
                                        >
                                            {({renderCell}) => (
                                                <DataGridCell
                                                    key={`${item.sessionId}`}
                                                    className={classes.gridRowCell}
                                                >
                                                    {renderCell(item)}
                                                </DataGridCell>
                                            )}
                                        </DataGridRow>
                                    )}
                                </>
                            );
                        }}
                    </DataGridBody>
                </DataGrid>
            </div>
        </div>
    );
}
