import {useQuery, UseQueryOptions} from '@tanstack/react-query';
/** IMPORTANT: Use the raw customFetch function from api.ts NOT useFetch
 * useFetch depends on the FeatureFlagProvider and would cause a circular dependency */
import {customFetch} from '@/api/api';
import type {UserFeatures} from '@/api/user/user.types';

export default function useGetUserFeatureFlags(
    props: Pick<UseQueryOptions<UserFeatures>, 'enabled' | 'onSettled'>,
) {
    return useQuery({
        queryKey: ['userFeatureFlags'],
        enabled: props.enabled,
        staleTime: Infinity,
        queryFn: async () => {
            return await customFetch<UserFeatures>(`/users/features`, {
                method: 'GET',
            });
        },
    });
}
