import React, {useState, useEffect, useRef} from 'react';
import {Combobox, Label, Option, OptionGroup, useId} from '@fluentui/react-components';
import useGetUserSubscriptions from '@/api/user/useGetUserSubscriptions';
import useClasses from './SubscriptionSelector.styles';
import {useTranslation} from 'react-i18next';
import {ErrorIcon, SuccessIcon, InfoIcon} from '@/components/ui/icons';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {useViewportSize} from '@/components/ui/Grid';
import useCheckPermission from '@/api/utils/useCheckArmPermissions';
import {useMsalUserId} from '@/util/msal';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';

interface SubscriptionSelectorProps {
    onSubscriptionSelect: (subscriptionId: string) => void;
    onSubscriptionValidation?: (response: SubscriptionSelectionResponse) => void;
    needsResponsiveStyles?: boolean;
}

export interface SubscriptionSelectionResponse {
    subscriptionId: string;
    validationState: 'valid' | 'invalid' | 'loading' | 'empty';
}

const SubscriptionSelector: React.FC<SubscriptionSelectorProps> = ({
    onSubscriptionSelect,
    onSubscriptionValidation,
    needsResponsiveStyles,
}) => {
    const classes = useClasses();
    const {t} = useTranslation('tours');
    const defaultLabelId = useId('azure-subscription');
    const permissionLabelId = useId('permission-label');
    const [subscriptionFilterInput, setSubscriptionFilterInput] = useState<string>('');
    const {
        data: listSubscriptions,
        isLoading: isLoadingSubscriptions,
        isSuccess,
    } = useGetUserSubscriptions();
    const subscriptionComboboxRef = useRef<HTMLInputElement>(null);
    const filteredSubscriptions = (listSubscriptions || []).filter((sub) =>
        sub.displayName.toLowerCase().includes(subscriptionFilterInput.toLowerCase()),
    );

    const {mutate: trackEvent} = useTrackEvent();
    // get the principal id
    const principalId = useMsalUserId();
    const [subscriptionId, setSubscriptionId] = useState<string>('');
    const [scopeResource, setScopeResource] = useState<string>('');
    const {hasPermission, error, loading} = useCheckPermission({
        subscriptionId: subscriptionId,
        scope: scopeResource,
        principalId: principalId || '',
        requiredRoles: ['Contributor', 'Owner'],
    });

    const handleSubscriptionChange = (data: string) => {
        setSubscriptionFilterInput('');
        onSubscriptionSelect(data);
        // set data to fire our permissions check for the given subscription
        setScopeResource(`subscriptions/${data}`);
        setSubscriptionId(data);
    };
    const handleSubscriptionInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubscriptionFilterInput(event.target.value);
    };
    useEffect(() => {
        subscriptionComboboxRef.current?.focus();
    }, []);

    const {sm: isSmallScreen} = useViewportSize();

    const labelSize = Boolean(needsResponsiveStyles)
        ? isSmallScreen
            ? 'small'
            : 'medium'
        : 'medium';

    // handle Subscription Validation
    useEffect(() => {
        if (subscriptionId && hasPermission !== null) {
            onSubscriptionValidation?.({
                subscriptionId,
                // we are not blocking the user from proceeding if they don't have permissions
                // this is to allow for current issues with certain subscriptions while we investigate
                validationState: 'valid',
            });
        } else if (!subscriptionId) {
            onSubscriptionValidation?.({
                subscriptionId,
                validationState: 'empty',
            });
        }
    }, [hasPermission, onSubscriptionValidation, subscriptionId]);

    useEffect(() => {
        if (isSuccess) {
            trackEvent({
                name: MedeinaTelemetryEvent.UserInteractionData.Subscriptions,
                customProperties: {
                    subscriptionCount: listSubscriptions?.length,
                },
                eventType: MedeinaEvent.UserInteractionData,
            });
        }
    }, [isSuccess]);

    return (
        <div className={classes.field}>
            <div className={classes.label}>
                <MedeinaInfoLabel
                    labelContent={
                        <Label
                            size={labelSize}
                            id={defaultLabelId}
                            data-test-id="azure-subscription-label"
                            required
                        >
                            {t('adminV2Fre.setup.AzureSubscription')}
                        </Label>
                    }
                    infoContent={t('adminV2Fre.setup.SubscriptionTooltip')}
                    size="small"
                />
            </div>
            <div className={classes.leftAlignedContainer}>
                <Combobox
                    ref={subscriptionComboboxRef}
                    placeholder={t('adminV2Fre.setup.AzureSubscriptionPlaceholder')}
                    className={classes.dropdownSelector}
                    input={{className: classes.comboInput}}
                    required
                    aria-label={t('adminV2Fre.setup.AzureSubscriptionDropdown')}
                    data-testid="azure-subscription-dropdown"
                    disabled={filteredSubscriptions.length === 0}
                    onChange={handleSubscriptionInputChange}
                    onOptionSelect={(event: any, data: any) => {
                        handleSubscriptionChange(data.optionValue);
                    }}
                    aria-describedby={permissionLabelId}
                >
                    <OptionGroup className={classes.comboOptionGroup}>
                        {filteredSubscriptions.map((sub) => (
                            <Option key={sub.subscriptionId} value={sub.subscriptionId}>
                                {sub.displayName || sub.subscriptionId}
                            </Option>
                        ))}
                    </OptionGroup>
                </Combobox>
            </div>
            {!isLoadingSubscriptions && filteredSubscriptions.length === 0 && (
                <Label id={permissionLabelId} className={classes.warningLabel}>
                    <ErrorIcon className={classes.errorIcon} filled />
                    {t('adminV2Fre.setup.EmptySubscriptions')}
                </Label>
            )}
            {hasPermission === false && (
                <Label id={permissionLabelId} className={classes.infoLabel}>
                    <InfoIcon filled />
                    {t('adminV2Fre.setup.SubscriptionPermissionError')}
                </Label>
            )}
        </div>
    );
};

export default SubscriptionSelector;
