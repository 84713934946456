import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        ...shorthands.margin(tokens.spacingVerticalXXL, 0),
    },
    formContainer: {
        width: '100%',
        maxWidth: '650px',
        height: '100%',
        ...shorthands.padding(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXL),
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.margin(0, tokens.spacingHorizontalXXL),
        borderRadius: tokens.borderRadiusXLarge,
        boxShadow: tokens.shadow16,
    },
    landingContainer: {
        width: '100%',
        height: '75vh',
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.padding(tokens.spacingVerticalXXL, tokens.spacingHorizontalXXL),
        ...shorthands.margin(0, tokens.spacingHorizontalXXL),
        borderRadius: tokens.borderRadiusXLarge,
        boxShadow: tokens.shadow16,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
