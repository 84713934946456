import React from 'react';
import {MessageBar, MessageBarTitle, MessageBarBody} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {Trial, useGetAvailableTrials} from '@/api/trials';
import {useGetTenantInfo} from '@/api/tenant';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';

interface TrialMessageBarProps {
    shown: boolean;
}

function parseDuration(duration: string): [number, number, number, number, number] {
    // Check for a negative sign
    const isNegative = duration.startsWith('-');
    if (isNegative) {
        duration = duration.slice(1); // Remove the negative sign for parsing
    }

    // Regular expression to match the duration format
    const regex = /^(?:(\d+)\.)?(\d{1,2}):(\d{2}):(\d{2})(?:\.(\d+))?$/;
    const match = duration.match(regex);

    if (!match) {
        throw new Error('Invalid duration format');
    }

    // Extract components from the match
    const [, days = '0', hours, minutes, seconds, fraction = '0'] = match;

    // Convert only days to number
    const totalDays = parseInt(days, 10);
    const totalHours = parseInt(hours, 10);
    const totalMinutes = parseInt(minutes, 10);
    const totalSeconds = parseInt(seconds, 10);
    const totalFraction = parseInt(fraction, 10);

    return [totalDays, totalHours, totalMinutes, totalSeconds, totalFraction];
}

function isTrialValid(trial: Trial, now: Date): boolean {
    if (trial.trialActivatedDateTime) {
        // this is an active trial, check if the trial is still valid.
        const trialStart = new Date(trial.trialActivatedDateTime);
        const [days, hours, minutes, seconds] = parseDuration(trial.trialDuration);
        const trialEnd = new Date(
            trialStart.getUTCFullYear(),
            trialStart.getUTCMonth(),
            trialStart.getUTCDate() + days,
            trialStart.getUTCHours() + hours,
            trialStart.getUTCMinutes() + minutes,
            trialStart.getUTCSeconds() + seconds,
        );
        return now >= trialStart && now <= trialEnd;
    } else {
        // this is a future trial, so check if it can be activated.
        return now <= new Date(trial.trialActivationDeadline);
    }
}

const TrialMessageBar: React.FC<TrialMessageBarProps> = (props: TrialMessageBarProps) => {
    const {t} = useTranslation('admin');
    const {
        data: tenantInfo,
        isLoading: isLoadingTenantInfo,
        isSuccess: isTenantInfoLoadingSuccess,
    } = useGetTenantInfo();
    const {
        data: availableTrialsResponse,
        isLoading: isLoadingAvailableTrials,
        isSuccess: isAvailableTrialsLoadingSuccess,
    } = useGetAvailableTrials();
    const {workspaceName} = useWorkspaceState();

    // If the banner should not be shown, return null.
    if (!props.shown) {
        return null;
    }

    let freeSCUs = 0;
    let trialDurationDays = 0;
    let trialFound = false;

    if (isLoadingTenantInfo) {
        // If tenant info is still loading, don't show a banner.
        return null;
    }

    // TenantInfo loaded, check for valid trials.
    let tenantHasTrials = false;
    if (isTenantInfoLoadingSuccess && tenantInfo) {
        const trials = tenantInfo?.trials ?? [];
        if (trials.length > 0) {
            // Prevent showing the banner if the tenant has both an AvailableTrial and any Trial record.
            // This is because a tenant can only ever have one trial.
            tenantHasTrials = true;
            // Check Trial validity. Use the first valid trial.
            const now = new Date();
            for (const trial of trials) {
                if (trial.workspaceId == workspaceName && isTrialValid(trial, now)) {
                    freeSCUs = trial.freeSCUs ?? 0;
                    [trialDurationDays] = parseDuration(trial.trialDuration ?? '0:00:00:00');
                    trialFound = true;
                    break;
                }
            }
        }
    }

    // If tenant info is loaded but has no valid trials, try to check the available trials.
    if (!trialFound && !tenantHasTrials) {
        if (isLoadingAvailableTrials) {
            // If available trials are still loading, don't show a banner.
            return null;
        } else if (isAvailableTrialsLoadingSuccess && availableTrialsResponse.value.length > 0) {
            const availableTrials = availableTrialsResponse.value;
            // If available trials are loaded, use the first valid trial.
            for (const trial of availableTrials) {
                if (isTrialValid(trial.trial, new Date())) {
                    freeSCUs = trial.trial.freeSCUs ?? 0;
                    [trialDurationDays] = parseDuration(trial.trial.trialDuration ?? '0:00:00:00');
                    trialFound = true;
                    break;
                }
            }
        }
    }

    if (!trialFound) {
        // If we still don't have a valid trial, don't show a banner.
        return null;
    }

    return (
        <MessageBar data-test-id="trial-banner" intent="success" style={{marginBottom: '12px'}}>
            <MessageBarBody>
                <div style={{textAlign: 'left'}}>
                    <MessageBarTitle>
                        {t('Trials.TrialInformationTitle', {
                            freeSCUs: freeSCUs,
                            trialDuration: trialDurationDays,
                        })}
                    </MessageBarTitle>
                    <br />
                    {t('Trials.TrialInformationBody', {
                        trialDuration: trialDurationDays,
                    })}
                </div>
            </MessageBarBody>
        </MessageBar>
    );
};

export default TrialMessageBar;
