import {getStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {GetWorkspacesResponse} from '.';
import {
    DEFAULT_API_RETRY_COUNT,
    RESOURCE_SCOPES,
    WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
} from '../api.constants';
import useFetch from '../useFetch';
import securityGraphApi from '@/api/securityGraph.api';

export default function useGetWorkspaces(
    useQueryOptions?: Pick<UseQueryOptions<GetWorkspacesResponse>, 'enabled' | 'refetchOnMount'> & {
        queryKey?: string[];
    },
) {
    const url = securityGraphApi({
        path: '/account/workspaces',
        version: '2023-12-01-preview',
        isGlobal: true,
    });
    const {customFetch} = useFetch();
    const response = useQuery<GetWorkspacesResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['workspaces', ...(useQueryOptions?.queryKey || [])],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async () => {
            const response = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                },
                true,
            );

            if (!response.ok) {
                const error = {
                    code: response.status,
                    message: response.statusText,
                };

                throw error;
            }

            const data = await response.json();
            return data as GetWorkspacesResponse;
        },
        ...useQueryOptions,
    });
    return response;
}
