import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {DataOptInSwitch} from '@/components/admin/DataOptIn';

export default function DataSharingSwitches() {
    const tourClasses = useTourClasses();

    return (
        <>
            <div className={tourClasses.switchLayout}>
                <DataOptInSwitch type="product" />
            </div>
            <div className={tourClasses.switchLayout}>
                <DataOptInSwitch type="model" />
            </div>
        </>
    );
}
