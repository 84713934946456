import {useCallback, useEffect, useState} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {motion} from 'framer-motion';
import {DismissIcon} from '@/components/ui/icons';
import {
    useCheckAgentIdentityChange,
    useGetAgentIdentity,
    useGetAgentIdentityLogin,
    usePatchAgent,
    AgentScopeTypes,
    type Agent,
    type AgentDefinition,
} from '@/api/agents';
import AgentConfigurationDialogOverlay from '../AgentConfigurationDialogOverlay/AgentConfigurationDialogOverlay';
import AgentIdentityConfiguration from './AgentIdentityConfiguration';
import useAgentConfigurationDialogClasses from '../../AgentConfigurationDialog.styles';

export interface AgentIdentityConfigurationDialogContentProps {
    agent: Agent | undefined;
    agentDefinition: AgentDefinition;
    mode: 'create' | 'edit';
    onClose?: () => void;
    onNext?: () => void;
    isFinalDialogStep?: boolean;
}

export default function AgentIdentityConfigurationDialogContent({
    agent,
    agentDefinition,
    mode,
    onClose,
    onNext,
    isFinalDialogStep = false,
}: AgentIdentityConfigurationDialogContentProps) {
    const {t} = useTranslation(['common', 'agents']);
    const dialogClasses = useAgentConfigurationDialogClasses();

    const [checkForNewIdentity, setCheckForNewIdentity] = useState<boolean>(false);
    const [authWindow, setAuthWindow] = useState<Window | null>(null);

    const {
        data: agentIdentity,
        isLoading: isGetIdentityLoading,
        isError: isGetIdentityError,
        refetch: refetchAgentIdentity,
    } = useGetAgentIdentity({
        agentId: agent?.agentId,
        requestParams: {scope: AgentScopeTypes.WORKSPACE},
    });

    useCheckAgentIdentityChange(
        {
            agentId: agent?.agentId,
            requestParams: {scope: AgentScopeTypes.WORKSPACE},
        },
        {enabled: !!agentIdentity && !!checkForNewIdentity},
    );

    const {
        mutateAsync: getLoginUrl,
        isLoading: isGetAgentIdentityLoginLoading,
        isError: isGetAgentIdentityLoginError,
    } = useGetAgentIdentityLogin({
        scope: AgentScopeTypes.WORKSPACE,
    });

    const {
        mutateAsync: updateAgent,
        isLoading: isUpdateAgentLoading,
        isError: isUpdateAgentError,
        isSuccess: isUpdateAgentSuccess,
    } = usePatchAgent({
        scope: AgentScopeTypes.WORKSPACE,
    });

    const updateAgentAsync = useCallback(async () => {
        if (agent) {
            await updateAgent({
                agentId: agent.agentId,
                body: {
                    triggers: [
                        {
                            name: agent.triggers?.[0]?.name,
                            enabled: true,
                            pollPeriodSeconds: 0,
                        },
                    ],
                    enabled: true,
                },
            });
        }
    }, [agent, updateAgent]);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const openAuthPopup = useCallback((url: string) => {
        const width = 500;
        const height = 600;
        const left = (screen.width - width) / 2;
        const top = (screen.height - height) / 2;
        const windowFeatures = `width=${width},height=${height},left=${left},top=${top}`;
        const authWindow = window.open(`${url}&prompt=select_account`, '_blank', windowFeatures);

        setCheckForNewIdentity(true);
        setAuthWindow(authWindow);
    }, []);

    const handleAddIdentity = useCallback(async () => {
        if (!agent) return;

        try {
            setCheckForNewIdentity(false);
            const {loginUrl} = await getLoginUrl({agentId: agent.agentId});
            await refetchAgentIdentity();
            openAuthPopup(loginUrl);
        } catch (error) {}
    }, [agent, getLoginUrl, openAuthPopup, refetchAgentIdentity]);

    const handleNext = useCallback(async () => {
        if (mode === 'create' && !agentIdentity?.capturedUserId) {
            handleAddIdentity();
        } else {
            if (mode === 'create' && isFinalDialogStep) await updateAgentAsync();
            onNext?.();
        }
    }, [
        agentIdentity?.capturedUserId,
        handleAddIdentity,
        isFinalDialogStep,
        mode,
        onNext,
        updateAgentAsync,
    ]);

    // Close the auth window if the new identity is captured
    useEffect(() => {
        if (agentIdentity?.capturedUserId && authWindow) {
            authWindow?.close();
            setAuthWindow(null);
            if (mode === 'create') {
                handleNext();
            }
        }
    }, [agentIdentity?.capturedUserId, authWindow, handleNext, mode]);

    return (
        <>
            {isGetIdentityLoading && (
                <AgentConfigurationDialogOverlay showSetupAgentLoading onClose={handleClose} />
            )}
            {isGetAgentIdentityLoginLoading && (
                <AgentConfigurationDialogOverlay showAuthenticationLoading onClose={handleClose} />
            )}
            {isUpdateAgentLoading && (
                <AgentConfigurationDialogOverlay showUpdateAgentLoading onClose={handleClose} />
            )}
            {isGetIdentityError && (
                <AgentConfigurationDialogOverlay
                    showSetupAgentError
                    showCancelButton
                    showTryAgainButton
                    onClose={handleClose}
                    onTryAgain={refetchAgentIdentity}
                />
            )}
            {isGetAgentIdentityLoginError && (
                <AgentConfigurationDialogOverlay
                    showAuthenticationError
                    showCancelButton
                    showTryAgainButton
                    onClose={handleClose}
                    onTryAgain={handleAddIdentity}
                />
            )}
            {isUpdateAgentError && (
                <AgentConfigurationDialogOverlay
                    showUpdatingError
                    showCancelButton
                    showTryAgainButton
                    onClose={handleClose}
                    onTryAgain={updateAgentAsync}
                />
            )}
            {!isGetIdentityLoading &&
                !isGetAgentIdentityLoginLoading &&
                !isUpdateAgentLoading &&
                !isGetIdentityError &&
                !isGetAgentIdentityLoginError &&
                !isUpdateAgentSuccess && (
                    <>
                        <DialogTitle
                            as="h1"
                            className={dialogClasses.title}
                            action={
                                <Button
                                    autoFocus
                                    appearance="subtle"
                                    icon={<DismissIcon />}
                                    onClick={onClose}
                                    aria-label={t('common:ButtonLabels.Close')}
                                />
                            }
                        >
                            {mode === 'create'
                                ? t('agents:AgentConfiguration.CreateTitle')
                                : t('agents:AgentConfiguration.EditTitle')}
                        </DialogTitle>
                        <DialogContent>
                            <motion.div
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{delay: 0, duration: 0.3}}
                                exit={{opacity: 0, transition: {duration: 0.3}}}
                            >
                                <AgentIdentityConfiguration
                                    mode={mode}
                                    agentDefinition={agentDefinition}
                                    identity={agentIdentity}
                                    onAddIndentityClick={handleAddIdentity}
                                />
                            </motion.div>
                        </DialogContent>
                        <DialogActions>
                            <Button appearance="secondary" onClick={onClose}>
                                {t('common:ButtonLabels.Cancel')}
                            </Button>
                            <Button appearance="primary" onClick={handleNext}>
                                {isFinalDialogStep
                                    ? t('common:ButtonLabels.Finish')
                                    : t(`common:ButtonLabels.Next`)}
                            </Button>
                        </DialogActions>
                    </>
                )}
        </>
    );
}
