import React from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentDrawer.styles';
import {Button, DrawerHeader, DrawerBody, DrawerHeaderTitle} from '@fluentui/react-components';
import {Dismiss24Regular, Flow24Regular} from '@fluentui/react-icons';
import {AgentTriggerRun} from '@/api/agents';
import {useNavigate} from 'react-router-dom';
import {useGetAgentTriggerRunDetails, Agent, AgentScopeTypes} from '@/api/agents';
import AgentRunHeader from '../run/AgentRunHeader';
import AgentRunContent from '../run/AgentRunContent';

interface AgentRunDrawerContentProps {
    agent: Agent;
    selectedRun: AgentTriggerRun | undefined;
    onClose: () => void;
    hasInputs: boolean;
}

export default function AgentRunDrawerContent(props: AgentRunDrawerContentProps) {
    const {agent, selectedRun, onClose, hasInputs} = props;
    const classes = useClasses();

    const {t} = useTranslation(['common', 'agents']);
    const navigate = useNavigate();

    const {triggerRun, triggerExecution, isFailedOrPendingRun, isError, isLoading} =
        useGetAgentTriggerRunDetails({
            agentId: agent.agentId,
            triggerName: agent.triggers?.[0]?.name,
            runId: selectedRun?.runId,
            requestParams: {scope: AgentScopeTypes.WORKSPACE},
        });

    if (!selectedRun) return null;

    return (
        <>
            <DrawerHeader className={classes.drawerHeader} data-testid={'agent-drawer-header'}>
                <DrawerHeaderTitle
                    action={
                        <div className={classes.drawerActions}>
                            {!isFailedOrPendingRun && !isLoading && triggerRun && (
                                <Button
                                    icon={<Flow24Regular />}
                                    onClick={() => {
                                        navigate(`/agents/${agent.agentId}/${selectedRun.runId}`);
                                    }}
                                    appearance="primary"
                                >
                                    {t('agents:AgentRun.ViewActivity')}
                                </Button>
                            )}
                            <Button
                                appearance="subtle"
                                aria-label={t('common:ButtonLabels.Close')}
                                icon={<Dismiss24Regular />}
                                data-testid={'agent-drawer-close-button'}
                                onClick={onClose}
                            />
                        </div>
                    }
                >
                    <AgentRunHeader triggerRun={triggerRun} isLoading={isLoading} />
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody className={classes.drawerBody} data-testid={'agent-drawer-body'}>
                <AgentRunContent
                    triggerExecution={triggerExecution}
                    triggerRun={triggerRun}
                    isError={isError}
                    isLoading={isLoading}
                    isFailedOrPendingRun={isFailedOrPendingRun}
                    hasInputs={!!hasInputs}
                />
            </DrawerBody>
        </>
    );
}
