import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {AuditStatus} from './auditStatus.types';
export default function useGetAuditStatus(
    useQueryOptions?: Pick<UseQueryOptions<AuditStatus>, 'enabled' | 'refetchOnMount'>,
) {
    const {customFetch} = useFetch();
    const getAuditStatus = async () => {
        try {
            return await customFetch<AuditStatus>(`/settings/datashare/auditStatus`, {
                method: 'GET',
            });
        } catch (error) {
            throw error;
        }
    };
    return useQuery({
        queryFn: getAuditStatus,
        queryKey: ['auditStatus'],
        enabled: useQueryOptions?.enabled ?? true,
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
}
