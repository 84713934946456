import {InputComponent, InputType} from '@/api/evaluations';
import {
    Combobox,
    Field,
    Input,
    Option,
    ToggleButton,
    SelectionEvents,
    OptionOnSelectData,
} from '@fluentui/react-components';
import {useState} from 'react';
import useClasses from './UserInputComponent.styles';
interface UserInputComponentPorps {
    inputComponent: InputComponent;
    onInputChange: (id: string, value: string) => void;
}

export default function UserInputComponent(props: UserInputComponentPorps) {
    const classes = useClasses();
    const input = props.inputComponent;
    const [currentValue, setCurrentValue] = useState<string>(input.defaultValue ?? '');

    const handleChoiceSelect = function (ev: React.MouseEvent) {
        setCurrentValue(ev.currentTarget.getAttribute('value') ?? '');
        props.onInputChange(
            ev.currentTarget.getAttribute('id') ?? '',
            ev.currentTarget.getAttribute('value') ?? '',
        );
    };

    const onDropDownSelect = (event: SelectionEvents, data: OptionOnSelectData) => {
        props.onInputChange(input.id, data.optionValue ?? input.defaultValue ?? '');
        setCurrentValue(data.optionValue ?? input.defaultValue ?? '');
    };

    return (
        <Field
            key={input.id}
            label={input.displayName}
            required={input.required}
            className={classes.inputComponent}
        >
            {input.inputType === InputType.OpenText ? (
                <Input
                    data-testid={input.id}
                    key={input.id}
                    defaultValue={input.defaultValue}
                    onChange={(e) => props.onInputChange(input.id, e.target.value)}
                    className={classes.textInput}
                />
            ) : input.inputType === InputType.DropDown ? (
                <Combobox
                    defaultValue={input.defaultValue}
                    onOptionSelect={onDropDownSelect}
                    className={classes.combobox}
                    data-testid={input.id}
                    key={input.id}
                    selectedOptions={currentValue ? [currentValue] : []}
                    value={currentValue}
                >
                    {input.choices?.map((choice) => (
                        <Option key={choice} value={choice}>
                            {choice}
                        </Option>
                    ))}
                </Combobox>
            ) : input.inputType === InputType.Choices ? (
                <div className={classes.choices} data-testid={input.id}>
                    {input.choices?.map((choice) => (
                        <ToggleButton
                            key={choice}
                            id={input.id}
                            data-testid="user-choice-button"
                            aria-label={choice}
                            checked={choice == currentValue}
                            value={choice}
                            onClick={handleChoiceSelect}
                            appearance={currentValue == choice ? 'primary' : 'outline'}
                        >
                            {choice}
                        </ToggleButton>
                    ))}
                </div>
            ) : (
                <></>
            )}
        </Field>
    );
}
