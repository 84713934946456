import {Body1, mergeClasses, Subtitle2, tokens} from '@fluentui/react-components';
import React from 'react';
import useClasses from './ChangeCapacity.styles';
import UsageDashboardWorkspaceDisplay from './UsageDashboardWorkspaceDisplay';

/**
 * Props for the UnitsDisplay component.
 *
 * @interface UnitsDisplayProps
 * @property {string} heading - The heading text to be displayed.
 * @property {string} numericDisplay - The numeric value to be displayed.
 * @property {string} footer - The footer text to be displayed.
 * @property {UnitsDisplayStyle} [displayStyle] - The display style of the component. Default is visible.
 */

interface UnitsDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
    heading: string;
    numericDisplay: string;
    footer: string;
    displayStyle?: UnitsDisplayStyle;
}

export enum UnitsDisplayStyle {
    Hidden = 'hidden',
    Visible = 'visible',
    NoDisplay = 'no-display',
}

export default function UnitsDisplay(props: UnitsDisplayProps) {
    const classes = useClasses();
    let additionalClasses = '';

    // Default to visible if no display style is provided
    const displayStyle = props.displayStyle ?? UnitsDisplayStyle.Visible;

    // Set the additional classes based on the display style
    if (displayStyle === UnitsDisplayStyle.Hidden) {
        additionalClasses = classes.hiddenUnitsDisplayWrapper;
    } else if (displayStyle === UnitsDisplayStyle.NoDisplay) {
        additionalClasses = classes.noDisplayWrapper;
    }

    let containerClasses = mergeClasses(classes.unitsDisplayWrapper, additionalClasses);

    let elementId = `units-display-${props.displayStyle}`;

    return (
        <>
            <div className={containerClasses} {...props}>
                <div data-testid={elementId}></div>
                {props.heading && <Subtitle2>{props.heading}</Subtitle2>}
                <UsageDashboardWorkspaceDisplay numericDisplay={props.numericDisplay} />

                {props.footer && (
                    <Body1 color={tokens.colorNeutralForeground2}>{props.footer}</Body1>
                )}
            </div>
        </>
    );
}
