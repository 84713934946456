import {useQuery} from '@tanstack/react-query';
import {AgentExecution, AgentTriggerRequest} from './agents.types';
import useFetch from '@/api/useFetch';

export default function useGetAgentTriggerExecution({
    agentId,
    triggerName,
    executionId,
    requestParams,
}: AgentTriggerRequest) {
    const {customFetch} = useFetch();

    return useQuery({
        queryKey: [
            'agents',
            agentId,
            'triggers',
            triggerName,
            'executions',
            executionId,
            requestParams,
        ],
        refetchOnWindowFocus: false,
        enabled: !!agentId && !!triggerName && !!executionId,
        queryFn: async () => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<AgentExecution>(
                `/agents/${agentId}/triggers/${triggerName}/executions/${executionId}?${queryParams.toString()}`,
                {method: 'GET'},
            );
        },
    });
}
