import React from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentPermissions.styles';
import {Body1, Skeleton, SkeletonItem} from '@fluentui/react-components';
import {useGetSkillsets} from '@/api/skills';
import {StatusLabel} from '@sfe/react';
import {AgentDetailsList, AgentDetailsListItem} from '@sfe/react-agent-details';

interface AgentPermissionsProps {
    requiredSkillsets: Array<string>;
}

export default function AgentPermissions(props: AgentPermissionsProps) {
    const {requiredSkillsets} = props;
    const {t: tAgents} = useTranslation('agents');
    const classes = useClasses();

    const {
        data: skillsets,
        isLoading: isSkillsetsLoading,
        isError: isSkillsetsError,
    } = useGetSkillsets();

    if (requiredSkillsets.length === 0)
        return <AgentDetailsListItem>{tAgents('PlaceholderData')}</AgentDetailsListItem>;

    return (
        <>
            {isSkillsetsLoading && (
                <Skeleton appearance="translucent" animation="pulse" className={classes.skeleton}>
                    <SkeletonItem />
                </Skeleton>
            )}
            {!isSkillsetsLoading && isSkillsetsError && (
                <StatusLabel status="failed" text={tAgents('Permissions.Error')} />
            )}
            {!isSkillsetsLoading && !isSkillsetsError && skillsets && (
                <AgentDetailsList>
                    {requiredSkillsets.map((plugin: string, index: number) => {
                        const matchedSkillset = skillsets.value.find(
                            (skillset) => skillset.name === plugin,
                        );
                        return (
                            matchedSkillset && (
                                <AgentDetailsListItem
                                    key={index}
                                    data-testid={`agent-permissions-${index}`}
                                >
                                    <Body1>
                                        {tAgents('Permissions.ReadData', {
                                            pluginName:
                                                matchedSkillset.displayName ?? matchedSkillset.name,
                                        })}
                                    </Body1>
                                </AgentDetailsListItem>
                            )
                        );
                    })}
                </AgentDetailsList>
            )}
        </>
    );
}
