import React from 'react';
import {Button} from '@fluentui/react-button';
import useClasses from './EvaluationSources.styles';
import {Caption1, Link} from '@fluentui/react-components';
import {
    Evaluation,
    EvaluationOutputComponent,
    EvaluationOutputComponentType,
} from '@/api/evaluations';

function EvaluationFiles({evaluation}: {evaluation: Evaluation}) {
    const classes = useClasses();
    const evaluationFiles = evaluation?.result?.outputComponents?.filter(
        (component) => component?.type === EvaluationOutputComponentType.File,
    );

    if (!evaluationFiles || evaluationFiles.length === 0) {
        return null;
    }

    const handleDownload = (fileName: string, base64Content: string) => {
        const element = document.createElement('a');
        const fileContent = window.atob(base64Content);
        const blob = new Blob([fileContent], {type: 'text/plain'});
        const url = URL.createObjectURL(blob);
        element.href = url;
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        URL.revokeObjectURL(url);
    };

    return (
        <>
            <div>
                <Caption1 className={classes.caption}>Files</Caption1>
            </div>
            <div>
                {evaluationFiles.map((item: EvaluationOutputComponent, index: number) => {
                    return (
                        <Link
                            key={`${item.name}-${index}`}
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleDownload(item.name, item.content);
                            }}
                        >
                            <Button className={classes.button}>{item.name}</Button>
                        </Link>
                    );
                })}
            </div>
        </>
    );
}

export default EvaluationFiles;
