import {Trial} from '../trials/trial.types';

export type TenantFreRequirements = {
    allowModelImprovement?: boolean;
    allowProductImprovement?: boolean;
    freRequirementsMet?: boolean;
    freStage?: FreStage | null;
    optOutDate?: string | null;
};

export type TenantInfo = {
    enabled?: boolean;
    tenantId: string;
    name: string;
    description?: string | null;
    featureFlags: string[];
    freRequirements: Partial<TenantFreRequirements>;
    allowTenantPluginUpload?: boolean;
    allowUserPluginUpload?: boolean;
    allowUserPluginManagement?: boolean;
    allowUserFileUpload?: boolean;
    allowSubmitFeedback?: boolean;
    complianceRequirements?: {
        allowO365DataCollection: {
            enabled: boolean;
            updatedAt?: string;
        };
    } | null;
    mandatoryFeatureFlags?: string[];
    allowedRestrictedFeatureFlags?: string[];
    allowAuditLogging?: boolean;
    trials?: Trial[];
};

export enum FreStage {
    TenantCreation = 'TenantCreation',
    CapacitySetup = 'CapacitySetup',
    WorkspaceSetup = 'WorkspaceSetup',
}
