import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: tokens.spacingHorizontalL,
    },
    failedIcon: {
        color: tokens.colorPaletteRedBackground3,
        height: tokens.lineHeightHero800,
        width: tokens.lineHeightHero800,
    },
});
