import {Badge, Body1} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import type {AgentIdentity} from '@/api/agents';
import useClasses from './AgentIdentityDisplay.styles';

export interface AgentIdentityDisplayProps {
    identity: AgentIdentity | undefined;
}

export default function AgentIdentityDisplay({identity}: AgentIdentityDisplayProps) {
    const {t: tAgents} = useTranslation('agents');
    const classes = useClasses();

    return (
        <div className={classes.root} data-testid={'agent-identity'}>
            <Body1>{tAgents('Agent.Details.IdentityDescription')}</Body1>
            <Badge size="medium" color="informative" appearance="outline">
                {identity?.capturedObjectName
                    ? identity.capturedObjectName
                    : identity?.capturedUserId
                    ? tAgents('Agent.Details.UnknownIdentity')
                    : tAgents('Agent.Details.PermissionsPlaceholder')}
            </Badge>
        </div>
    );
}
