import {Params} from 'react-router-dom';

export enum Tabs {
    Overview = 'overview',
    Build = 'build',
}

export interface PluginBuilderParams extends Params {
    pluginName?: string;
}
