import {useMutation, useQueryClient} from '@tanstack/react-query';
import {
    OverageState,
    PutCapacityRequest,
    PutCapacityRequestForApi,
    PatchCapacityResponse,
} from '../capacities/capacities.types';
import useFetch from '../useFetch';
import {RESOURCE_SCOPES} from '../api.constants';
import MedeinaVariables from '@/util/variables';
import MedeinaFeatures from '@/util/features';
import {useFeatureFlag} from '../user';

export default function usePutCapacity() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    let enableUsageDashboardOverage = useFeatureFlag(MedeinaFeatures.EnableUsageDashboardOverage);

    return useMutation({
        mutationFn: async (request: PutCapacityRequest) => {
            let reqBody: PutCapacityRequestForApi = {
                id: request.id,
                name: request.capacityName,
                type: request.type,
                location: request.location,
                properties: {
                    geo: request.properties.geo,
                    numberOfUnits: request.properties.numberOfUnits,
                    crossGeoCompute: request.properties.crossGeoCompute,
                    overageState: request.properties.overageState,
                    overageAmount: request.properties.overageAmount,
                },
            };
            //Leaving as a comment for now, will re-add once all customers are migrated into an overage state
            /*
            if (!Boolean(request.properties?.overageState)) {
                reqBody.properties.overageState = OverageState.None;
            }*/

            let apiVersion = enableUsageDashboardOverage
                ? '2024-11-01-preview'
                : '2023-12-01-preview';
            // This check is in place to ensure that the overage state and amount are not sent to the backend if the feature is disabled
            if (!enableUsageDashboardOverage && Boolean(reqBody.properties)) {
                delete reqBody.properties.overageState;
                delete reqBody.properties.overageAmount;
            }

            return await customFetch<PatchCapacityResponse>(
                `${MedeinaVariables.ArmUri}/subscriptions/${request.subscriptionId}/resourceGroups/${request.resourceGroupName}/providers/Microsoft.SecurityCopilot/capacities/${request.capacityName}?api-version=${apiVersion}`,
                {
                    method: 'PUT',
                    scopes: RESOURCE_SCOPES.ARM,
                    body: reqBody,
                },
            );
        },
        onSuccess: (_, variables) => {
            // Invalidate the cache
            queryClient.invalidateQueries({queryKey: ['capacities', variables.capacityName]});
        },
    });
}
