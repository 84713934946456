import {RESOURCE_SCOPES} from '../api.constants';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {RbacErrorResponse, useUpdateWorkspacePolicyRequest} from './rbac.types';
import useFetch from '../useFetch';
import securityGraphApi from '@/api/securityGraph.api';
import {isGeoPodEnabled} from '@/components/sections/workspaces/WorkspacesProvider';

export default function useUpdateWorkspacePolicyById() {
    const {customFetch} = useFetch();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (request: useUpdateWorkspacePolicyRequest) => {
            const url = securityGraphApi({
                path: isGeoPodEnabled()
                    ? `/policystore/workspacePolicies/${request.policy.id}`
                    : `/policyStore/workspacePolicies/${request.policy.id}`,
                version: '2024-01-01-preview',
                podPath: true,
            });
            const response = await customFetch<Response>(
                url,
                {
                    method: 'PUT',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                    body: request.policy,
                },
                true,
            );

            if (!response.ok) {
                const error = (await response.json()) as RbacErrorResponse;
                throw error;
            }

            return response;
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['rbac', variables.workspaceName]);
            queryClient.invalidateQueries(['auth', 'getUserInfo']);
        },
        onError: (data, variables) => {
            queryClient.invalidateQueries(['rbac', variables.workspaceName]);
            queryClient.invalidateQueries(['auth', 'getUserInfo']);
        },
    });
}
