// Shared styles for stage layouts and fonts/type
import {
    makeStyles,
    shorthands,
    GriffelStyle,
    tokens,
    typographyStyles,
} from '@fluentui/react-components';

export const normalForegroundText: GriffelStyle = {
    // #FFF
    color: tokens.colorNeutralForeground1,
};

export const lightestForegroundText: GriffelStyle = {
    // #DBDFE1
    color: tokens.colorNeutralForeground3,
};

export const heroTitle: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '40px',
    lineHeight: '60px',
    ...normalForegroundText,
};

export const heroSubTitle: GriffelStyle = {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: '600',
};

// used for the Terms view
export const blockTitle: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '40px',
    lineHeight: '52px',
    ...normalForegroundText,
};

export const blockTitleMedium: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '40px',
    ...normalForegroundText,
};

// the paragraph for the Terms view
export const blockText: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    ...lightestForegroundText,
};

export const blockTextMedium: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    ...lightestForegroundText,
};

export const paddedButton: GriffelStyle = {
    ...shorthands.padding('8px', '49px', '10px'),
    ...shorthands.border('1px', 'solid', '#fff'),
};

export const centeredText: GriffelStyle = {
    textAlign: 'center',
};

export const brandButtonNoBorder: GriffelStyle = {
    ...shorthands.borderBottom('none'),
    ...shorthands.borderTop('none'),
    ...shorthands.borderLeft('none'),
    ...shorthands.borderRight('none'),
};

export const tourCenterWrapper: GriffelStyle = {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    flexWrap: 'wrap',
};

export const tourStageContentWrapper: GriffelStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    flexGrow: 1,
    justifyContent: 'center',
    marginTop: tokens.spacingVerticalXL,
    marginBottom: tokens.spacingVerticalXL,
};

export const tourContainerRoot: GriffelStyle = {
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    flexWrap: 'nowrap',

    '@media (min-width: 480px) and (max-width: 639px)': {
        justifyContent: 'center',
    },
    '@media (max-width: 479px)': {
        justifyContent: 'center',
    },
};

export const tourControlButton: GriffelStyle = {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    ...shorthands.padding('8px', '16px', '8px', '16px'),
};

export const tourContainerContent: GriffelStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...shorthands.flex(1, 0, 'auto'),
    maxWidth: '600px',
};

// style for the subtitle 2 type within a tour page
export const tourSubtitle2: GriffelStyle = {
    paddingBottom: tokens.spacingVerticalS,
    fontWeight: tokens.fontWeightSemibold,

    '@media (max-width: 479px)': {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase300,
    },
};

export const tourSectionSpacing: GriffelStyle = {
    marginBottom: tokens.spacingVerticalM,
};

export const tourMediumSectionSpacing: GriffelStyle = {
    marginBottom: '28px',

    '@media (max-width: 479px)': {
        marginBottom: '18px',
    },
};

export const tourLargeSectionSpacing: GriffelStyle = {
    marginBottom: '36px',
};

export const tourXLargeSectionSpacing: GriffelStyle = {
    marginBottom: '48px',

    '@media (max-width: 479px)': {
        marginBottom: tokens.spacingVerticalXL,
    },
};

export const containerFullWidth: GriffelStyle = {
    width: '100%',
};

export const dropDown: GriffelStyle = {
    backgroundColor: tokens.colorNeutralBackground5,
    display: 'flex',
    height: tokens.lineHeightHero800,
    '& input': {
        width: '90%',
    },

    '@media (max-width: 479px)': {
        height: tokens.lineHeightBase500,
    },
};

export const textBox: GriffelStyle = {
    height: '40px',
};

export const switchLayout: GriffelStyle = {
    backgroundColor: tokens.colorNeutralBackground1Hover,
    ...shorthands.borderRadius(tokens.borderRadiusXLarge),
    ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingVerticalM),
    marginBottom: tokens.spacingVerticalL,
    textAlign: 'left',
};

export const switchStyles: GriffelStyle = {
    width: '100%',
    minHeight: '52px',
    textAlign: 'left',

    '& > label': {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        ...shorthands.flex(1, 1, '80%'),

        '@media (max-width: 479px)': {
            lineHeight: tokens.lineHeightBase200,
            fontSize: tokens.fontSizeBase100,
        },
    },
};

export const switchError: GriffelStyle = {
    display: 'flex',
    color: tokens.colorPaletteCranberryBorderActive,
    marginBottom: tokens.spacingVerticalL,
    fontSize: tokens.fontSizeBase200,
    lineHeight: tokens.lineHeightBase200,
    '& > span:first-child': {
        position: 'relative',
        top: '3px',
        paddingRight: '5px',
        paddingLeft: '8px',
        scale: 1.2,
    },
};

//Icon styles
// style for a large icon
export const largeIcon: GriffelStyle = {
    width: '48px',
    height: '48px',
    '@media (forced-colors: active)': {
        backgroundColor: 'Canvas',
    },
};

export const tourStageWrapper: GriffelStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 60px)',
};

export const tourButtonWrapper: GriffelStyle = {
    display: 'flex',
    ...shorthands.margin(
        tokens.spacingVerticalNone,
        'calc(((100vw - 100%) / 2) * -1 + 20px)',
        tokens.spacingVerticalMNudge,
    ),
};

export const tourTwoButtonWrapper: GriffelStyle = {
    ...tourButtonWrapper,
    justifyContent: 'space-between',
};

export const tourMonoButtonWrapper: GriffelStyle = {
    ...tourButtonWrapper,
    justifyContent: 'flex-end',
};

//Think about moving this to a control??
export const buttonWrapper: GriffelStyle = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: tokens.spacingVerticalXXL,
    marginTop: '5%',
    position: 'absolute',
    right: '0',

    '& button:first-of-type': {
        marginLeft: tokens.spacingHorizontalXXL,
    },

    '& button:last-of-type': {
        marginRight: tokens.spacingHorizontalXXL,
    },
};

//This should probably be present at a global level
// This needs to be applied to the toast control
export const tourCenteredToast: GriffelStyle = {
    '& div.fui-Toast': {
        display: 'flex',
        justifyContent: 'center',
    },
};

export const coverFullWidth: GriffelStyle = {
    width: '100%',
};

export const tourContainerControls: GriffelStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'auto',
    height: 'min-content',
    marginTop: 'auto',
    maxWidth: '600px',
    ...shorthands.padding(tokens.spacingHorizontalXXL),

    '@media (min-width: 1366px) and (max-width: 1919px)': {
        ...shorthands.padding(tokens.spacingHorizontalXXL),
    },
    '@media (min-width: 1024px) and (max-width: 1365px)': {
        ...shorthands.padding(tokens.spacingHorizontalXXL),
    },
    '@media (min-width: 640px) and (max-width: 1023px)': {
        ...shorthands.padding(tokens.spacingHorizontalL),
    },
    '@media (min-width: 480px) and (max-width: 639px)': {
        ...shorthands.padding(tokens.spacingHorizontalM),
    },
    '@media (max-width: 479px)': {
        ...shorthands.padding(tokens.spacingHorizontalM),
    },
};

export default makeStyles({
    centeredText,
    blockTitle,
    blockTitleMedium,
    blockText,
    blockTextMedium,
    heroTitle,
    heroSubTitle,
    paddedButton,
    brandButtonNoBorder,
    normalForegroundText,
    lightestForegroundText,
    tourCenterWrapper,
    tourStageWrapper,
    tourStageContentWrapper,
    tourContainerRoot,
    tourContainerContent,
    tourContainerControls,
    tourControlButton,
    tourSectionSpacing,
    tourMediumSectionSpacing,
    tourLargeSectionSpacing,
    tourXLargeSectionSpacing,
    containerFullWidth,
    dropDown,
    textBox,
    tourSubtitle2,
    tourTwoButtonWrapper,
    tourMonoButtonWrapper,
    buttonWrapper,
    switchLayout,
    switchStyles,
    switchError,
    largeIcon,
    tourCenteredToast,
    coverFullWidth,
});
