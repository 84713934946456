import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    container: {
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: tokens.shadow4,
        borderRadius: tokens.borderRadiusXLarge,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
        minHeight: '60px',
        position: 'relative',
    },
    headerButton: {
        marginLeft: 'auto',
        marginRight: tokens.spacingHorizontalS,
    },
    label: {
        fontSize: tokens.lineHeightBase200,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase400,
        ...shorthands.padding(0, tokens.spacingHorizontalXXL),
    },
});
