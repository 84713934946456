import React, {useState, useMemo, useContext, useEffect} from 'react';
import {
    useGetUserTenants,
    useGetUserPreferences,
    UserState,
    UserStateObject,
    Tenant,
    UserPreferences,
    TenantAccountDetails,
    useFeatureFlag,
} from '@/api/user';
import {
    msalInstance,
    msalLoginRequest,
    setCurrentTenantId,
    msalConfig,
    signInErrorHandler,
    isUserSignedIn,
} from '@/util/msal/authConfig';
import {useIsAuthenticated} from '@azure/msal-react';
import {tenantIdUrlParam} from '@/api/api';
import {useGetUserInfo} from '@/api/app';
import {DEFAULT_USER_STATE, LOCAL_STORAGE_NAMESPACE} from './user.constants';
import {
    isGeoPodEnabled,
    setIsGeoPodEnabled,
} from '@/components/sections/workspaces/WorkspacesProvider';
import {MedeinaUserFeatures} from '@/util/features';
import MedeinaFeatures from '@/util/features';
import {useECSFlags} from '@/components/FeatureFlags';
import {useTrackEvent, MedeinaTelemetryEvent, MedeinaEvent} from '@/api/telemetry';
import {isAuth2Enabled} from '@/components/MedeinaBase/Authentication';

export const UserStateContext = React.createContext<UserStateObject>(undefined!);

function clearMsalCache() {
    for (let key in localStorage) {
        if (key.startsWith('msal.')) {
            localStorage.removeItem(key);
        }
    }
}

export async function updateMsalConfig(tenantId: string) {
    const accounts = msalInstance.getAllAccounts();
    const tenantAccounts = accounts.filter((account) => account.tenantId === tenantId);
    if (tenantAccounts.length === 0) {
        // User is not signed in to the specified tenant, so redirect them to the login page
        const loginRequest = {
            scopes: msalLoginRequest.scopes,
            authority: msalConfig(tenantId).auth.authority,
        };
        await msalInstance.loginRedirect(loginRequest).catch((error) => {
            // when redeeming a token, we may get an interaction_in_progress error, we don't want to forward a second request until this finishes

            if (error.errorCode === 'interaction_in_progress') {
                return;
            } else if (error.errorCode) {
                signInErrorHandler(error, msalLoginRequest);
            }
            // If there is any error, such as a bad tenant ID, redirect to the common tenant
            window.location.href = `/?tenantId=common`;
        });
    }
    // Used to set local cache for the tenantId for msalconfig
    setCurrentTenantId(tenantId);
    msalInstance.setActiveAccount(tenantAccounts[0]);
}

export function readFromLocalStorage(): UserState {
    const currentUserState =
        typeof window !== 'undefined' && window.localStorage.getItem(LOCAL_STORAGE_NAMESPACE);
    return currentUserState
        ? Object.assign({}, DEFAULT_USER_STATE, JSON.parse(currentUserState))
        : Object.assign({}, DEFAULT_USER_STATE);
}

function writeToLocalStorage(currentUserState: UserState): void {
    window.localStorage.setItem(LOCAL_STORAGE_NAMESPACE, JSON.stringify(currentUserState));
}

const defaultUserStateValue = readFromLocalStorage();

export function useUserState() {
    return useContext(UserStateContext);
}

export function UserStateProvider({children}: {children: React.ReactNode}) {
    // Check if the user is authenticated before starting any data calls
    // If auth2 is enabled we can assume this check will no longer be necessary
    const isAuthenticated = isUserSignedIn(msalInstance) || isAuth2Enabled();
    const {mutate: trackEvent} = useTrackEvent();
    const [userStateValue, setUserStateValue] = useState<UserState>(defaultUserStateValue);
    const {tenant} = userStateValue;
    const {tenantId} = tenant;
    const tenantParam = tenantIdUrlParam();
    const {data: userInfo, isSuccess: getUserInfoSuccess} = useGetUserInfo({
        enabled: isAuthenticated,
        onSettled: async (data, error) => {
            if (data) {
                if (data?.featureFlags.includes(MedeinaUserFeatures.GeoPodUrlEnabled)) {
                    await setIsGeoPodEnabled(true);
                }
            }
        },
    });
    const {featureFlags: ecsFeatureFlags} = useECSFlags();

    /**
     * Log any issues in feature flags
     */
    useEffect(() => {
        if (isAuthenticated) {
            if (ecsFeatureFlags.length === 0) {
                trackEvent({
                    name: MedeinaTelemetryEvent.App.FeatureFlagsNotSynced,
                    eventType: MedeinaEvent.DataEvent,
                    data: {
                        ecsfeatureFlags: ecsFeatureFlags,
                        message: 'User is missing ECS feature flags in UserStateProvider',
                    },
                });
            } else if (userInfo && ecsFeatureFlags.length !== userInfo?.featureFlags.length) {
                trackEvent({
                    name: MedeinaTelemetryEvent.App.FeatureFlagsNotSynced,
                    eventType: MedeinaEvent.DataEvent,
                    data: {
                        ecsfeatureFlags: ecsFeatureFlags,
                        userInfoFeatureFlags: userInfo?.featureFlags,
                        message: 'Flags from /user/info and /users/features do not match',
                    },
                });
            }
        }
        // do not retrigger on trackEvent change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ecsFeatureFlags, isAuthenticated, userInfo]);

    // If a user manually updates their tenant in the URL, we need to update the MSAL config AND our local storage information
    // check the url tenantId to see if it matches against the tenantId in local storage
    // our TenantSwitcher component will update the tenantId in local storage before it redirects
    const isMatchingTenant = useMemo(() => tenantId === tenantParam, [tenantId, tenantParam]);

    // make a data call for the users tenants so we can populate user state with the right display name
    // only do this if there's a parameter in the URL and the tenantId doesn't match the one in local storage
    const {data: listTenants, refetch: refetchUserTenants} = useGetUserTenants({
        enabled:
            (Boolean(tenantParam && !isMatchingTenant) || !tenant.displayName) && isAuthenticated,
        onSuccess: (data: Tenant[]) => {
            let matchingTenant: Tenant | undefined;
            if (tenantParam) {
                // find the matching tenant for the parameter tenantID
                matchingTenant = data.find((tenant) => tenant.tenantId === tenantParam);
            }
            if (!tenant.displayName) {
                // if we don't have a display name, find the matching tenant for the tenantId in local storage
                matchingTenant = data.find((dataTenant) => dataTenant.tenantId === tenant.tenantId);
            }
            // update the local storage with the new tenant
            setUserStateValue({
                ...userStateValue,
                tenant: matchingTenant || {
                    tenantId: tenantParam || tenant.tenantId,
                    displayName: tenant.displayName || tenantParam || '',
                },
            });
        },
    });

    // Whenever UserState changes, write it to localStorage.
    useEffect(() => {
        writeToLocalStorage(userStateValue);
    }, [userStateValue]);

    // Return the UserState value context.
    const value = useMemo<UserStateObject>(
        () => ({
            ...userStateValue,
            toggleDailyTip: (show?: boolean) => {
                // if no value is passed in toggle off of the current state
                let showDailyTipValue = show === undefined ? !userStateValue.showDailyTip : show;
                return setUserStateValue({...userStateValue, showDailyTip: showDailyTipValue});
            },
            setTenant: (tenant: Tenant) => {
                return setUserStateValue({...userStateValue, tenant});
            },
            setPreferences: (newPreferences: Partial<UserPreferences>) => {
                return setUserStateValue({
                    ...userStateValue,
                    preferences: {...userStateValue.preferences, ...newPreferences},
                });
            },
            setUseBrowserLanguage: (useBrowserLanguage: boolean) => {
                return setUserStateValue({
                    ...userStateValue,
                    useBrowserLanguage,
                });
            },
            setUseBrowserTime: (useBrowserTime: boolean) => {
                return setUserStateValue({
                    ...userStateValue,
                    useBrowserTime,
                });
            },
            setTenantAccountDetails: (accountDetails: TenantAccountDetails) => {
                // We can't use `useFeatureFlag` here, because that hook is dependent on this component.
                return (
                    MedeinaFeatures.LaunchExperienceFidelisGA &&
                    setUserStateValue({...userStateValue, accountDetails})
                );
            },
        }),
        [userStateValue, setUserStateValue],
    );

    const {data: userPreferences, isSuccess: getUserPreferencesSuccess} = useGetUserPreferences({
        onSuccess: (data: UserPreferences) => {
            if (data) {
                setUserStateValue({
                    ...userStateValue,
                    preferences: data,
                });
            }
        },
    });

    // Refetch the user tenants each time the tenant changes if we don't already have a display name
    useEffect(() => {
        if (!tenant.displayName) {
            refetchUserTenants();
        }
    }, [tenantId, tenant.displayName]);

    useEffect(() => {
        // check if we are loading into an app instance with a tenantId in the URL
        const tenantId = tenantIdUrlParam();
        if (tenantId) {
            // update our MSAL config to use the new tenantID
            updateMsalConfig(tenantId);
        } else {
            // retrieve the last known tenant from local storage
            const userState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAMESPACE) || '{}');
            // If this is the default value, then we need to clear the MSAL cache to populate the tenant id in local storage
            if (userState?.tenant?.tenantId === 'common') {
                clearMsalCache();
            }
            setCurrentTenantId(value.tenant.tenantId);
        }
    }, []);

    useEffect(() => {
        /**
         * Preparing for Auth2, if feature flag is turned on we skip this
         */
        if (isAuth2Enabled()) {
            return;
        }
        msalInstance
            .handleRedirectPromise()
            .then((response: any) => {
                if (response) {
                    // Update local storage on initial login
                    setUserStateValue({
                        ...userStateValue,
                        tenant: {tenantId: response.tenantId, displayName: ''},
                    });
                    updateMsalConfig(response.tenantId);
                    window.location.href = '/?tenantId=' + response.tenantId;
                }
            })
            .catch((error) => {
                signInErrorHandler(error, msalLoginRequest);
            });
    }, []);

    // write the user info to the user state
    useEffect(() => {
        if (getUserInfoSuccess) {
            setUserStateValue({
                ...userStateValue,
                isAdmin: userInfo?.isAdmin,
                isOperator: userInfo?.isOperator,
                featureFlags: userInfo?.featureFlags || [],
                isEmailTriggerAllowed: userInfo?.isEmailTriggerAllowed,
            });
        }
    }, [getUserInfoSuccess, userInfo, ecsFeatureFlags]);

    const isGeoPodSynced = useMemo(() => {
        /** we can only make a determination after we have userInfo */
        if (getUserInfoSuccess) {
            /** We check if the user has the geoPod flag enabled */
            if (userInfo?.featureFlags.includes(MedeinaUserFeatures.GeoPodUrlEnabled)) {
                // if the flag is present we need to wait for sessionStorage to populate
                if (!isGeoPodEnabled()) {
                    return false;
                } else {
                    return true;
                }
            }
            /** If it is not found we return undefined to let the app know it's ok to continue rendering */
            return undefined;
        }
    }, [getUserInfoSuccess, userInfo?.featureFlags]);

    /** If the user is not logged we need to allow rendering without waiting for userInfo to allow AuthorizedTemplate to render
     * If the user is logged in and we have userInfo we check for the geoPod flag sync
     * If the flag is not synced we don't render the children until it is OR if the flag is undefined it means the user doesn't have the flag
     */
    if (
        !isAuthenticated ||
        (isAuthenticated &&
            getUserInfoSuccess &&
            (isGeoPodSynced === true || isGeoPodSynced === undefined))
    ) {
        return <UserStateContext.Provider value={value}>{children}</UserStateContext.Provider>;
    } else {
        return null;
    }
}
