import {
    Button,
    Link,
    Persona,
    useFocusFinders,
    useFocusableGroup,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Spinner,
} from '@fluentui/react-components';
import useClasses from './RoleSection.styles';
import {AccordionHeader, AccordionItem, AccordionPanel} from '@fluentui/react-components';
import {PolicyWorkspace, RoleMember, RoleMembers} from '@/api/rbac/rbac.types';
import {useEffect, useMemo, useRef, useState} from 'react';
import {MemberType, RoleType} from '@/components/admin/rbac/rbac.types';
import {usePolicyManager} from '.';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useUpdateWorkspacePolicyById} from '@/api/rbac';
import RemoveMemberButton from './RemoveMemberButton';
import {DismissIcon, FailedIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import {EVERYONE_IDENTIFIER, MICROSOFT_ENTRA_ID} from '@/api/rbac/rbac.constants';
import {PARTNER_ROLES_ID} from './rbac.constants';
import AddRoles from './AddRoles/AddRoles';
import ViewRolesButton from './ViewRolesButton';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';
import {cloneDeep} from 'lodash-es';

interface RoleSectionProps {
    header: string;
    infoContent: string;
    sectionId: string;
    sectionValue: string;
    roleMembers?: RoleMembers;
    workspacePolicy: PolicyWorkspace;
    role: RoleType;
    policiesSuccessfullyFetched: boolean;
    policyProcessed: boolean;
    setCopiedOwnerRoleMembers?: (copiedRoleMembers: RoleMembers) => void;
    setCopiedContributorRoleMembers?: (copiedRoleMembers: RoleMembers) => void;
    policyManager: ReturnType<typeof usePolicyManager>;
}

export default function RoleSection(props: RoleSectionProps) {
    const classes = useClasses();

    const roleSectionWrapper = useRef<HTMLDivElement>(null);
    const {findFirstFocusable} = useFocusFinders();
    const [noContributor, setNoContributor] = useState<boolean>(false);
    const [errorTag, setErrorTag] = useState<string>('');
    const [isAddRolesOpen, setIsAddRolesOpen] = useState<boolean>(false);
    const is1pRbacEnabled = useFeatureFlag(MedeinaFeatures.Enable1pRbac);
    const everyoneNotSupported = useFeatureFlag(MedeinaFeatures.EnableOnboardingWithRoles);
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {t} = useTranslation('admin');
    const {t: commonLabel} = useTranslation('common');
    const {mutate: trackEvent} = useTrackEvent();
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const [confirmButtonLoading, setConfirmButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        if (roleSectionWrapper?.current) {
            const firstFocusable = findFirstFocusable(roleSectionWrapper.current);
            firstFocusable?.focus();
        }
    }, [roleSectionWrapper]);

    const viewRolesClick = () => {
        setIsAddRolesOpen(!isAddRolesOpen);
    };

    const performActionsOnAddRolesClose = () => {
        setIsAddRolesOpen(false);
    };

    const countOfRoleMembers = useMemo(() => {
        let count = 0;
        if (props?.roleMembers?.roles) {
            count += props.roleMembers.roles.length;
        }
        if (props?.roleMembers?.groups) {
            count += props.roleMembers.groups.length;
        }
        if (props?.roleMembers?.users) {
            count += props.roleMembers.users.length;
        }
        if (props?.roleMembers?.servicePrincipals) {
            count += props.roleMembers.servicePrincipals.length;
        }
        return count;
    }, [props.roleMembers]);

    /** Logic for duplicate role members (Start) */
    const copied = useMemo(
        () =>
            cloneDeep(props.roleMembers) || {
                roles: [],
                groups: [],
                users: [],
                servicePrincipals: [],
            },
        [props.roleMembers],
    );
    const [copiedMembers, setCopiedMembers] = useState<RoleMembers>(copied);
    useEffect(() => {
        setCopiedMembers(copied);
        // once this gets set, stop calling role assignment and role section
        // to update the copied members
    }, [copied]);
    useEffect(() => {
        if (props.role === RoleType.Owner) {
            props.setCopiedOwnerRoleMembers && props.setCopiedOwnerRoleMembers(copiedMembers);
        } else {
            props.setCopiedContributorRoleMembers &&
                props.setCopiedContributorRoleMembers(copiedMembers);
        }
    }, [copiedMembers]);
    const countOfCopiedRoleMembers = useMemo(() => {
        let count =
            copiedMembers.groups.length + copiedMembers.roles.length + copiedMembers.users.length;
        return count;
    }, [copiedMembers]);
    const handleRemoveCopiedMember = useMemo(
        () => (memberType: string, memberId: string) => {
            // edit copied role members
            const updatedCopied = {...copiedMembers};
            if (memberType === 'roles') {
                updatedCopied.roles = updatedCopied.roles.filter((role) => role.id !== memberId);
            } else if (memberType === 'groups') {
                updatedCopied.groups = updatedCopied.groups.filter(
                    (group) => group.id !== memberId,
                );
            } else if (memberType === 'users') {
                if (memberId === '*') {
                    updatedCopied.users = [];
                } else {
                    updatedCopied.users = updatedCopied.users.filter(
                        (user) => user.id !== memberId,
                    );
                }
            } else if (memberType === 'servicePrincipals') {
                updatedCopied.servicePrincipals = updatedCopied.servicePrincipals.filter(
                    (servicePrincipal) => servicePrincipal.id !== memberId,
                );
            }
            setCopiedMembers(updatedCopied);
        },
        [copiedMembers],
    );
    /** Logic for duplicate role members (End) */

    // To generate secondary text for persona of user
    const generateSecondaryTextUser = (roleMember: RoleMember) => {
        return <>{roleMember.mail}</>;
    };

    // To generate secondary text for persona of group
    const generateSecondaryTextGroup = (roleMember: RoleMember) => {
        return (
            <>
                {t('RoleAssignment.Group')} • {roleMember.memberCount}{' '}
                {roleMember.memberCount!! > 1
                    ? t('RoleAssignment.Users')
                    : t('RoleAssignment.User')}
            </>
        );
    };

    // To generate secondary text for persona of role
    const generateSecondaryTextRole = (roleMember: RoleMember) => {
        return (
            <>
                {t('RoleAssignment.Role')} • {t('RoleAssignment.RoleItemText')}{' '}
                <Link href={MedeinaVariables.MicrosoftEntraUri} target="_blank">
                    {MICROSOFT_ENTRA_ID}
                </Link>
            </>
        );
    };

    const {workspaceName} = useWorkspaceState();

    const {mutateAsync: updateWorkspacePolicy, isLoading: updatePolicyLoading} =
        useUpdateWorkspacePolicyById();

    // To handle remove member from the list
    const deleteMember = async (objectId: string, memberType: MemberType) => {
        try {
            setConfirmButtonLoading(true);
            const updatedList = props.policyManager.removeMemberFromPolicy(
                objectId,
                memberType,
                props.role,
            );

            await updateWorkspacePolicy(
                {
                    workspaceName: workspaceName as string,
                    policy: updatedList,
                },
                {
                    onSuccess: () => {
                        setErrorTag('');
                    },
                    onError: () => {
                        setErrorTag(objectId);
                    },
                },
            );
            setConfirmDialogOpen(false);
            setConfirmButtonLoading(false);
        } catch (e) {
            //TODO: discussions on handling error in progress
            //throw e;
            setErrorTag(objectId);
            setConfirmButtonLoading(false);
            setConfirmDialogOpen(false);
        }
    };

    // Add or remove the condition for 1P partner roles from the policy
    // This condition is a constant so we just need to add/remove it (toggle)
    const toggleRecommendedRoles = async () => {
        const updatedPolicy = props.policyManager.togglePartnerRolesCondition(undefined, true);

        if (!!updatedPolicy) {
            await updateWorkspacePolicy({
                workspaceName: workspaceName as string,
                policy: updatedPolicy,
            });
        }
    };
    const attributes = useFocusableGroup({tabBehavior: 'limited'});

    // To set the state of noContributor state to handle scenario when there are no contributors present.
    useEffect(() => {
        if (props.policiesSuccessfullyFetched && props.policyProcessed) {
            if (
                props.role === RoleType.Contributor &&
                props.roleMembers?.groups.length === 0 &&
                props.roleMembers?.roles.length === 0 &&
                props.roleMembers?.users.length === 0 &&
                props.roleMembers?.servicePrincipals.length === 0
            ) {
                setNoContributor(true);
            } else {
                setNoContributor(false);
            }
        }
    }, [props.policiesSuccessfullyFetched, props.roleMembers, props.policyProcessed]);

    if (
        (props.setCopiedOwnerRoleMembers || props.setCopiedContributorRoleMembers) &&
        isWorkspacesTestingEnabled
    ) {
        return (
            <>
                <AccordionItem
                    value={props.sectionId}
                    className={classes.roleSection}
                    id={props.sectionId}
                    key={props.sectionId}
                >
                    <AccordionHeader id={`role-section-${props.role}`}>
                        <div>
                            {props.header}
                            {' ('}
                            {countOfCopiedRoleMembers}
                            {')'}
                        </div>
                    </AccordionHeader>

                    {noContributor && props.policiesSuccessfullyFetched && (
                        <div className={classes.noContributor}>
                            {t('RoleAssignment.RoleGroup.Contributor.NoContributors')}
                        </div>
                    )}

                    {copiedMembers?.roles?.map((roleMember: RoleMember) => {
                        return (
                            <>
                                <AccordionPanel
                                    className={classes.roleMemberPanel}
                                    key={roleMember.id}
                                    {...attributes}
                                    tabIndex={0}
                                >
                                    <span>
                                        <Persona
                                            className={classes.roleMemberPersona}
                                            textPosition="after"
                                            name={roleMember.displayName}
                                            secondaryText={generateSecondaryTextRole(roleMember)}
                                            avatar={{color: 'colorful'}}
                                            size="large"
                                        />
                                    </span>
                                    <RemoveMemberButton
                                        canBeDeleted={roleMember?.canBeDeleted ?? true}
                                        onRemoveButtonClick={() => {
                                            handleRemoveCopiedMember(
                                                'roles',
                                                roleMember.id as string,
                                            ),
                                                setErrorTag('');
                                        }}
                                        updatePolicyLoading={updatePolicyLoading}
                                        policyProcessed={props.policyProcessed}
                                    ></RemoveMemberButton>
                                </AccordionPanel>

                                {errorTag === roleMember.id && (
                                    <div className={classes.errorTag}>
                                        <span>
                                            <FailedIcon filled />
                                        </span>
                                        <span>
                                            {t('RoleAssignment.RoleGroup.RemoveAction.RemoveError')}
                                        </span>
                                    </div>
                                )}
                            </>
                        );
                    })}

                    {copiedMembers?.groups?.map((roleMember: RoleMember) => {
                        return (
                            <>
                                <AccordionPanel
                                    className={classes.roleMemberPanel}
                                    key={roleMember.id}
                                    {...attributes}
                                    tabIndex={0}
                                >
                                    <span>
                                        <Persona
                                            className={classes.roleMemberPersona}
                                            textPosition="after"
                                            name={roleMember.displayName}
                                            secondaryText={generateSecondaryTextGroup(roleMember)}
                                            avatar={{color: 'colorful'}}
                                            size="large"
                                        />
                                    </span>
                                    <RemoveMemberButton
                                        canBeDeleted={roleMember?.canBeDeleted || true}
                                        onRemoveButtonClick={() => {
                                            handleRemoveCopiedMember(
                                                'groups',
                                                roleMember.id as string,
                                            ),
                                                setErrorTag('');
                                        }}
                                        updatePolicyLoading={updatePolicyLoading}
                                        policyProcessed={props.policyProcessed}
                                    ></RemoveMemberButton>
                                </AccordionPanel>

                                {errorTag === roleMember.id && (
                                    <div className={classes.errorTag}>
                                        <span>
                                            <FailedIcon filled />
                                        </span>
                                        <span>
                                            {t('RoleAssignment.RoleGroup.RemoveAction.RemoveError')}
                                        </span>
                                    </div>
                                )}
                            </>
                        );
                    })}

                    {copiedMembers?.users?.map((roleMember: RoleMember) => {
                        const isRecommendedRole = roleMember.id === PARTNER_ROLES_ID;
                        return (
                            <>
                                <AccordionPanel
                                    className={classes.roleMemberPanel}
                                    key={roleMember.id}
                                    {...attributes}
                                    tabIndex={0}
                                >
                                    <span>
                                        <Persona
                                            className={classes.roleMemberPersona}
                                            textPosition="after"
                                            name={roleMember.displayName}
                                            secondaryText={generateSecondaryTextUser(roleMember)}
                                            avatar={
                                                isRecommendedRole
                                                    ? {color: 'brand', initials: '1P'}
                                                    : {color: 'colorful'}
                                            }
                                            size="large"
                                        />
                                    </span>
                                    <div className={classes.actionButtonsContainer}>
                                        {isRecommendedRole && is1pRbacEnabled && (
                                            <ViewRolesButton
                                                onViewRolesButtonClick={viewRolesClick}
                                                updatePolicyLoading={updatePolicyLoading}
                                                policyProcessed={props.policyProcessed}
                                            />
                                        )}
                                        <RemoveMemberButton
                                            canBeDeleted={roleMember?.canBeDeleted || true}
                                            onRemoveButtonClick={() => {
                                                if (
                                                    props.setCopiedOwnerRoleMembers ||
                                                    props.setCopiedContributorRoleMembers
                                                ) {
                                                    handleRemoveCopiedMember(
                                                        'users',
                                                        roleMember.id as string,
                                                    );
                                                } else if (
                                                    roleMember.id === EVERYONE_IDENTIFIER &&
                                                    everyoneNotSupported
                                                ) {
                                                    setConfirmDialogOpen(true);
                                                } else if (isRecommendedRole) {
                                                    toggleRecommendedRoles();
                                                    trackEvent({
                                                        name: MedeinaTelemetryEvent.Rbac
                                                            .RemoveRecommendedRoles,
                                                        eventType: MedeinaEvent.ActionEvent,
                                                    });
                                                } else {
                                                    handleRemoveCopiedMember(
                                                        'users',
                                                        roleMember.id as string,
                                                    );
                                                }
                                                setErrorTag('');
                                            }}
                                            updatePolicyLoading={updatePolicyLoading}
                                            policyProcessed={props.policyProcessed}
                                        />
                                    </div>
                                </AccordionPanel>
                                {errorTag === roleMember.id && (
                                    <div className={classes.errorTag}>
                                        <span>
                                            <FailedIcon filled />
                                        </span>
                                        <span>
                                            {t('RoleAssignment.RoleGroup.RemoveAction.RemoveError')}
                                        </span>
                                    </div>
                                )}
                            </>
                        );
                    })}
                </AccordionItem>
                {is1pRbacEnabled && (
                    <>
                        <AddRoles
                            viewOnly={true}
                            isAddRolesOpen={isAddRolesOpen}
                            onAddRolesClose={performActionsOnAddRolesClose}
                        ></AddRoles>

                        <Dialog open={isConfirmDialogOpen}>
                            <DialogSurface>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button
                                        data-test-id="add-member-confirmation-close-button"
                                        className={classes.closeButton}
                                        appearance="transparent"
                                        aria-label={t(
                                            'RoleAssignment.AddRoles.AriaLabels.CloseConfirmationDialog',
                                        )}
                                        onClick={() => setConfirmDialogOpen(false)}
                                    >
                                        <DismissIcon className={classes.icon} />
                                    </Button>
                                </DialogTrigger>
                                <DialogBody>
                                    <DialogTitle>
                                        {t('RoleAssignment.AddRoles.EveryoneNotSupported.Title')}
                                    </DialogTitle>
                                    <DialogContent className={classes.dialogContentSection}>
                                        {t(
                                            'RoleAssignment.AddRoles.EveryoneNotSupported.DescriptionV2',
                                        )}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            data-test-id="add-member-confirmation-done-button"
                                            appearance="primary"
                                            disabled={confirmButtonLoading}
                                            onClick={() => {
                                                if (
                                                    props.setCopiedOwnerRoleMembers ||
                                                    props.setCopiedContributorRoleMembers
                                                ) {
                                                    handleRemoveCopiedMember('users', '*');
                                                } else {
                                                    deleteMember('*', MemberType.User);
                                                }
                                            }}
                                        >
                                            {confirmButtonLoading ? (
                                                <Spinner size="tiny"></Spinner>
                                            ) : (
                                                commonLabel('ButtonLabels.Confirm')
                                            )}
                                        </Button>
                                        <Button
                                            data-test-id="add-member-confirmation-done-button"
                                            onClick={() => setConfirmDialogOpen(false)}
                                        >
                                            {commonLabel('ButtonLabels.Cancel')}
                                        </Button>
                                    </DialogActions>
                                </DialogBody>
                            </DialogSurface>
                        </Dialog>
                    </>
                )}
            </>
        );
    }
    return (
        <>
            <AccordionItem
                value={props.sectionId}
                className={classes.roleSection}
                id={props.sectionId}
                key={props.sectionId}
            >
                <AccordionHeader id={`role-section-${props.role}`}>
                    <div className={classes.subtitle}>
                        {props.header}
                        {' ('}
                        {countOfRoleMembers}
                        {')'}
                    </div>
                </AccordionHeader>
                <div className={classes.paragraphContent} ref={roleSectionWrapper}>
                    {props.infoContent}
                </div>

                {noContributor && props.policiesSuccessfullyFetched && (
                    <div className={classes.noContributor}>
                        {t('RoleAssignment.RoleGroup.Contributor.NoContributors')}
                    </div>
                )}

                {props?.roleMembers?.roles?.map((roleMember: RoleMember) => {
                    return (
                        <>
                            <AccordionPanel
                                className={classes.roleMemberPanel}
                                key={roleMember.id}
                                {...attributes}
                                tabIndex={0}
                            >
                                <span>
                                    <Persona
                                        className={classes.roleMemberPersona}
                                        textPosition="after"
                                        name={roleMember.displayName}
                                        secondaryText={generateSecondaryTextRole(roleMember)}
                                        avatar={{color: 'colorful'}}
                                        size="large"
                                    />
                                </span>
                                <RemoveMemberButton
                                    canBeDeleted={roleMember?.canBeDeleted ?? true}
                                    onRemoveButtonClick={() => {
                                        deleteMember(roleMember.id as string, MemberType.Role),
                                            setErrorTag('');
                                    }}
                                    updatePolicyLoading={updatePolicyLoading}
                                    policyProcessed={props.policyProcessed}
                                ></RemoveMemberButton>
                            </AccordionPanel>

                            {errorTag === roleMember.id && (
                                <div className={classes.errorTag}>
                                    <span>
                                        <FailedIcon filled />
                                    </span>
                                    <span>
                                        {t('RoleAssignment.RoleGroup.RemoveAction.RemoveError')}
                                    </span>
                                </div>
                            )}
                        </>
                    );
                })}

                {props?.roleMembers?.groups?.map((roleMember: RoleMember) => {
                    return (
                        <>
                            <AccordionPanel
                                className={classes.roleMemberPanel}
                                key={roleMember.id}
                                {...attributes}
                                tabIndex={0}
                            >
                                <span>
                                    <Persona
                                        className={classes.roleMemberPersona}
                                        textPosition="after"
                                        name={roleMember.displayName}
                                        secondaryText={generateSecondaryTextGroup(roleMember)}
                                        avatar={{color: 'colorful'}}
                                        size="large"
                                    />
                                </span>
                                <RemoveMemberButton
                                    canBeDeleted={roleMember?.canBeDeleted || true}
                                    onRemoveButtonClick={() => {
                                        deleteMember(roleMember.id as string, MemberType.Group),
                                            setErrorTag('');
                                    }}
                                    updatePolicyLoading={updatePolicyLoading}
                                    policyProcessed={props.policyProcessed}
                                ></RemoveMemberButton>
                            </AccordionPanel>

                            {errorTag === roleMember.id && (
                                <div className={classes.errorTag}>
                                    <span>
                                        <FailedIcon filled />
                                    </span>
                                    <span>
                                        {t('RoleAssignment.RoleGroup.RemoveAction.RemoveError')}
                                    </span>
                                </div>
                            )}
                        </>
                    );
                })}

                {props?.roleMembers?.users?.map((roleMember: RoleMember) => {
                    const isRecommendedRole = roleMember.id === PARTNER_ROLES_ID;
                    return (
                        <>
                            <AccordionPanel
                                className={classes.roleMemberPanel}
                                key={roleMember.id}
                                {...attributes}
                                tabIndex={0}
                            >
                                <span>
                                    <Persona
                                        className={classes.roleMemberPersona}
                                        textPosition="after"
                                        name={roleMember.displayName}
                                        secondaryText={generateSecondaryTextUser(roleMember)}
                                        avatar={
                                            isRecommendedRole
                                                ? {color: 'brand', initials: '1P'}
                                                : {color: 'colorful'}
                                        }
                                        size="large"
                                    />
                                </span>
                                <div className={classes.actionButtonsContainer}>
                                    {isRecommendedRole && is1pRbacEnabled && (
                                        <ViewRolesButton
                                            onViewRolesButtonClick={viewRolesClick}
                                            updatePolicyLoading={updatePolicyLoading}
                                            policyProcessed={props.policyProcessed}
                                        />
                                    )}
                                    <RemoveMemberButton
                                        canBeDeleted={roleMember?.canBeDeleted || true}
                                        onRemoveButtonClick={() => {
                                            if (
                                                roleMember.id === EVERYONE_IDENTIFIER &&
                                                everyoneNotSupported
                                            ) {
                                                setConfirmDialogOpen(true);
                                            } else if (isRecommendedRole) {
                                                toggleRecommendedRoles();
                                                trackEvent({
                                                    name: MedeinaTelemetryEvent.Rbac
                                                        .RemoveRecommendedRoles,
                                                    eventType: MedeinaEvent.ActionEvent,
                                                });
                                            } else {
                                                deleteMember(
                                                    roleMember.id as string,
                                                    MemberType.User,
                                                );
                                            }
                                            setErrorTag('');
                                        }}
                                        updatePolicyLoading={updatePolicyLoading}
                                        policyProcessed={props.policyProcessed}
                                    />
                                </div>
                            </AccordionPanel>
                            {errorTag === roleMember.id && (
                                <div className={classes.errorTag}>
                                    <span>
                                        <FailedIcon filled />
                                    </span>
                                    <span>
                                        {t('RoleAssignment.RoleGroup.RemoveAction.RemoveError')}
                                    </span>
                                </div>
                            )}
                        </>
                    );
                })}

                {props?.roleMembers?.servicePrincipals?.map((roleMember: RoleMember) => {
                    return (
                        <>
                            <AccordionPanel
                                className={classes.roleMemberPanel}
                                key={roleMember.id}
                                {...attributes}
                                tabIndex={0}
                            >
                                <span>
                                    <Persona
                                        className={classes.roleMemberPersona}
                                        textPosition="after"
                                        name={roleMember.displayName}
                                        secondaryText={generateSecondaryTextUser(roleMember)}
                                        avatar={{color: 'colorful'}}
                                        size="large"
                                    />
                                </span>
                                <div className={classes.actionButtonsContainer}>
                                    <RemoveMemberButton
                                        canBeDeleted={roleMember?.canBeDeleted || true}
                                        onRemoveButtonClick={() => {
                                            deleteMember(
                                                roleMember.id as string,
                                                MemberType.ServicePrincipal,
                                            );

                                            setErrorTag('');
                                        }}
                                        updatePolicyLoading={updatePolicyLoading}
                                        policyProcessed={props.policyProcessed}
                                    />
                                </div>
                            </AccordionPanel>
                            {errorTag === roleMember.id && (
                                <div className={classes.errorTag}>
                                    <span>
                                        <FailedIcon filled />
                                    </span>
                                    <span>
                                        {t('RoleAssignment.RoleGroup.RemoveAction.RemoveError')}
                                    </span>
                                </div>
                            )}
                        </>
                    );
                })}
            </AccordionItem>
            {is1pRbacEnabled && (
                <>
                    <AddRoles
                        viewOnly={true}
                        isAddRolesOpen={isAddRolesOpen}
                        onAddRolesClose={performActionsOnAddRolesClose}
                    ></AddRoles>

                    <Dialog open={isConfirmDialogOpen}>
                        <DialogSurface>
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    data-test-id="add-member-confirmation-close-button"
                                    className={classes.closeButton}
                                    appearance="transparent"
                                    aria-label={t(
                                        'RoleAssignment.AddRoles.AriaLabels.CloseConfirmationDialog',
                                    )}
                                    onClick={() => setConfirmDialogOpen(false)}
                                >
                                    <DismissIcon className={classes.icon} />
                                </Button>
                            </DialogTrigger>
                            <DialogBody>
                                <DialogTitle>
                                    {t('RoleAssignment.AddRoles.EveryoneNotSupported.Title')}
                                </DialogTitle>
                                <DialogContent className={classes.dialogContentSection}>
                                    {t(
                                        'RoleAssignment.AddRoles.EveryoneNotSupported.DescriptionV2',
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        data-test-id="add-member-confirmation-done-button"
                                        appearance="primary"
                                        disabled={confirmButtonLoading}
                                        onClick={() => deleteMember('*', MemberType.User)}
                                    >
                                        {confirmButtonLoading ? (
                                            <Spinner size="tiny"></Spinner>
                                        ) : (
                                            commonLabel('ButtonLabels.Confirm')
                                        )}
                                    </Button>
                                    <Button
                                        data-test-id="add-member-confirmation-done-button"
                                        onClick={() => setConfirmDialogOpen(false)}
                                    >
                                        {commonLabel('ButtonLabels.Cancel')}
                                    </Button>
                                </DialogActions>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                </>
            )}
        </>
    );
}
