import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        ...shorthands.padding(tokens.spacingHorizontalS),
    },
    headerButtons: {
        display: 'flex',
        gap: tokens.spacingHorizontalS,
    },
    headerStatus: {
        display: 'flex',
        gap: tokens.spacingHorizontalL,
        alignItems: 'center',
    },
    headerImage: {
        backgroundColor: tokens.colorNeutralBackground2,
        objectFit: 'contain',
    },
    runButton: {
        minWidth: 'unset',
    },
    statusLabel: {
        paddingLeft: '0 !important', // Overriding the default padding from SFE StatusLabel
        paddingRight: '0 !important',
    },
});
