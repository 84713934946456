import {Button} from '@fluentui/react-components';
import useClasses from './Capacity.styles';
import {useState, useRef, useEffect} from 'react';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useGetCapacityByName} from '@/api/capacities';
import {ErrorIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import ChangeCapacity from '../sections/admin/ChangeCapacityDialog/ChangeCapacity';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

import {useNavigate} from 'react-router-dom';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';

export default function Capacity() {
    const classes = useClasses();
    const changeCapacityButtonRef = useRef<HTMLButtonElement>(null);
    const navigate = useNavigate();

    const [isChangeCapacityDialogOpen, setChangeCapacityDialogOpen] = useState<boolean>(false);

    const {capacityName, workspaceName, update, isProviderLoaded} = useWorkspaceState();

    const areBothInputsAvailable = !!workspaceName && !!capacityName;

    let isOverageEnabled = useFeatureFlag(MedeinaFeatures.EnableUsageDashboardOverage) as boolean;
    let areTrialsEnabled = useFeatureFlag(MedeinaFeatures.EnableTrials) as boolean;
    const {
        data: capacities,
        isError: isCapacitiesError,
        isSuccess: isCapacitiesSuccess,
        isFetched: isCapacitiesFetched,
        isRefetching: isCapacitiesRefetching,
        isRefetchError: isCapacitiesRefetchError,
        isLoading: isCapacitiesLoading,
    } = useGetCapacityByName(capacityName || '', {
        enabled: areBothInputsAvailable,
    });

    const isDataLoaded = isProviderLoaded;
    const canShowErrorFromProviderValues = isDataLoaded && (!workspaceName || !capacityName);

    const [capacityUnits, setCapacityUnits] = useState<number>();
    const {mutate: trackEvent} = useTrackEvent();

    const {t} = useTranslation('admin');

    useEffect(() => {
        if (isCapacitiesSuccess) {
            setCapacityUnits(capacities?.properties?.numberOfUnits);
        }
    }, [isCapacitiesFetched, isCapacitiesRefetching, isCapacitiesLoading]);

    const getCapacityUnitsText = () => {
        if (!capacityUnits) {
            return '';
        }
        const unitText =
            capacityUnits === 1
                ? t('ownerSettings.capacity.Unit')
                : t('ownerSettings.capacity.Units');
        return `${capacityUnits} ${unitText}`;
    };

    const performActionsOnCapacityDialogClosure = () => {
        setChangeCapacityDialogOpen(false);
        changeCapacityButtonRef.current?.focus();
    };

    return (
        <div>
            <h2 className={classes.subtitle}>{t('ownerSettings.capacity.SectionHeading')}</h2>
            {!isCapacitiesError && !isCapacitiesRefetchError && !canShowErrorFromProviderValues && (
                <>
                    <div className={classes.capacityUnitLabelContainer}>
                        {!isCapacitiesError && <p>{getCapacityUnitsText()}</p>}
                    </div>

                    <div className={classes.capacityButtonContainer}>
                        <Button
                            disabled={
                                (!isDataLoaded && !isCapacitiesFetched) || !isCapacitiesSuccess
                            }
                            data-test-id="change-capacity-button"
                            onClick={() => {
                                setChangeCapacityDialogOpen(true);
                                trackEvent({
                                    name: MedeinaTelemetryEvent.Capacity.ChangeCapacity,
                                    eventType: MedeinaEvent.ActionEvent,
                                });
                            }}
                            ref={changeCapacityButtonRef}
                        >
                            {t('ownerSettings.capacity.ChangeCapacity')}
                        </Button>
                        <Button
                            as="a"
                            disabled={
                                (!isDataLoaded && !isCapacitiesFetched) || !isCapacitiesSuccess
                            }
                            data-test-id="view-usage-button"
                            onClick={() => navigate('/usage-monitoring')}
                        >
                            {t('ownerSettings.capacity.SeeUsage')}
                        </Button>
                    </div>
                </>
            )}
            {(canShowErrorFromProviderValues || isCapacitiesError) && (
                <div>
                    <div className={classes.errorTag}>
                        <span>
                            <ErrorIcon filled />
                        </span>
                        <span>{t('ownerSettings.capacity.ErrorMessage')}</span>
                    </div>
                    <div>
                        <Button
                            data-test-id="try-again-button"
                            onClick={() => {
                                update();
                            }}
                            appearance="primary"
                        >
                            {t('ownerSettings.capacity.TryAgain')}
                        </Button>
                    </div>
                </div>
            )}
            <ChangeCapacity
                isChangeCapacityDialogOpen={isChangeCapacityDialogOpen}
                onChangeCapacityDialogClose={performActionsOnCapacityDialogClosure}
                isOverageEnabled={isOverageEnabled}
                areTrialsEnabled={areTrialsEnabled}
            />
        </div>
    );
}
