import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: tokens.spacingHorizontalS,
        flex: 1,
        alignItems: 'center',
    },
});
