import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {AgentIdentity, AgentRequest} from './agents.types';
import useFetch from '@/api/useFetch';

export default function useCheckAgentIdentityChange(
    {agentId, requestParams}: AgentRequest,
    useQueryOptions?: Pick<UseQueryOptions<AgentIdentity>, 'enabled'>,
) {
    const {customFetch} = useFetch();

    return useQuery({
        ...useQueryOptions,
        queryKey: ['agents', agentId, 'identity', requestParams],
        refetchOnWindowFocus: false,
        enabled: !!agentId,
        queryFn: async () => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<AgentIdentity>(
                `/agents/${agentId}/identity?${queryParams.toString()}`,
                {
                    method: 'GET',
                },
            );
        },
        retry: false,
        refetchInterval: (response) => {
            if (response?.capturedUserId) {
                return false;
            }
            return 1000;
        },
    });
}
