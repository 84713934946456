import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {AgentDefinition, AgentDefinitionsRequestQueryParameters} from './agents.types';

//Cache for 10 minutes.
const CACHE_TIME = 10 * 60 * 1000;

export default function useGetAgentDefinitions(
    requestParams: AgentDefinitionsRequestQueryParameters,
) {
    const {customFetch} = useFetch();
    return useQuery({
        cacheTime: CACHE_TIME,
        staleTime: CACHE_TIME,
        refetchOnWindowFocus: false,

        queryKey: ['agentdefinitions', requestParams],
        queryFn: async () => {
            const queryParams = new URLSearchParams(requestParams);
            const agentDefinitions = await customFetch<AgentDefinition[]>(
                `/agentdefinitions?${queryParams.toString()}`,
                {
                    method: 'GET',
                },
            );
            // If displayName exists, sort based on displayName, otherwise sort based on name
            agentDefinitions.sort((a, b) =>
                a.displayName == null
                    ? a.name.localeCompare(b.displayName == null ? b.name : b.displayName)
                    : a.displayName.localeCompare(b.displayName == null ? b.name : b.displayName),
            );
            return agentDefinitions;
        },
    });
}
