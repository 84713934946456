import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {EvaluationInputRequest} from './evaluations.types';

export default function useSubmitEvaluationInput() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (body: EvaluationInputRequest) => {
            const response = await customFetch<Response>(
                `/sessions/${body.sessionId}/prompts/${body.promptId}/evaluations/${body.evaluationId}/input`,
                {
                    method: 'POST',
                    body: body.input,
                },
            );
            return response.status === 200;
        },

        onSuccess: (response, body) => {
            queryClient.refetchQueries({
                queryKey: ['sessions', body?.sessionId, 'prompts'],
            });
        },
    });
}
