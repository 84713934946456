import {useCallback} from 'react';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Spinner,
    Text,
} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {DismissIcon, FailedIcon} from '@/components/ui/icons';
import useClasses from './AgentConfigurationDialogOverlay.styles';

export interface AgentIdentityConfigurationDialogContentProps {
    showSetupAgentLoading?: boolean;
    showAuthenticationLoading?: boolean;
    showUpdateAgentLoading?: boolean;
    showSetupAgentError?: boolean;
    showAuthenticationError?: boolean;
    showUpdatingError?: boolean;
    showCancelButton?: boolean;
    showTryAgainButton?: boolean;
    onClose: () => void;
    onTryAgain?: () => void;
}

export default function AgentConfigurationDialogOverlay({
    showSetupAgentLoading = false,
    showAuthenticationLoading = false,
    showUpdateAgentLoading = false,
    showSetupAgentError = false,
    showAuthenticationError = false,
    showUpdatingError = false,
    showCancelButton = false,
    showTryAgainButton = false,
    onClose,
    onTryAgain,
}: AgentIdentityConfigurationDialogContentProps) {
    const {t} = useTranslation(['common', 'agents']);
    const classes = useClasses();

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleTryAgain = useCallback(() => {
        onTryAgain?.();
    }, [onTryAgain]);

    return (
        <>
            <DialogTitle
                as="h1"
                aria-label={t('agents:AgentConfiguration.EditTitle')}
                action={
                    <Button
                        autoFocus
                        appearance="subtle"
                        icon={<DismissIcon />}
                        onClick={onClose}
                        aria-label={t('common:ButtonLabels.Close')}
                    />
                }
            />
            <DialogContent className={classes.root}>
                {showSetupAgentLoading && (
                    <Spinner
                        size="small"
                        labelPosition="below"
                        label={t('agents:AgentConfiguration.Loading.SettingUp')}
                    />
                )}
                {showAuthenticationLoading && (
                    <Spinner
                        size="small"
                        labelPosition="below"
                        label={t('agents:AgentConfiguration.Loading.Authentication')}
                    />
                )}
                {showUpdateAgentLoading && (
                    <Spinner
                        size="small"
                        labelPosition="below"
                        label={t('agents:AgentConfiguration.Loading.Updating')}
                    />
                )}
                {showSetupAgentError && (
                    <>
                        <FailedIcon className={classes.failedIcon} filled />
                        <Text aria-live="assertive" role="alert">
                            {t('agents:AgentConfiguration.Error.SettingUp')}
                        </Text>
                    </>
                )}
                {showAuthenticationError && (
                    <>
                        <FailedIcon className={classes.failedIcon} filled />
                        <Text aria-live="assertive" role="alert">
                            {t('agents:AgentConfiguration.Error.Authentication')}
                        </Text>
                    </>
                )}
                {showUpdatingError && (
                    <>
                        <FailedIcon className={classes.failedIcon} filled />
                        <Text aria-live="assertive" role="alert">
                            {t('agents:AgentConfiguration.Error.Updating')}
                        </Text>
                    </>
                )}
                {(showCancelButton || showTryAgainButton) && (
                    <DialogActions>
                        {showCancelButton && (
                            <Button appearance="secondary" onClick={handleClose}>
                                {t('common:ButtonLabels.Cancel')}
                            </Button>
                        )}
                        {showTryAgainButton && (
                            <Button appearance="primary" onClick={handleTryAgain}>
                                {t('common:ButtonLabels.TryAgain')}
                            </Button>
                        )}
                    </DialogActions>
                )}
            </DialogContent>
        </>
    );
}
