import {Button, Label, mergeClasses} from '@fluentui/react-components';
import useClasses from './TestSideMenu.styles';
import {useTranslation} from 'react-i18next';
import {DismissIcon} from '@/components/ui/icons';
import {TestSideMenuProps} from './TestSideMenu.types';

export default function TestSideMenu({onTestMenuExit}: TestSideMenuProps) {
    const classes = useClasses();
    const {t} = useTranslation('pluginBuilder');

    const handleTestMenuExit = () => {
        onTestMenuExit?.();
    };

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Label className={classes.label}>{t('TestButton')}</Label>
                <Button
                    className={classes.headerButton}
                    appearance={'transparent'}
                    icon={<DismissIcon />}
                    aria-label="Exit Test Menu"
                    onClick={() => handleTestMenuExit()}
                />
            </div>
        </div>
    );
}
