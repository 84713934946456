import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    header: {
        ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralBackground6),
        ...shorthands.padding(0, tokens.spacingHorizontalXL),
        width: `calc(100%-${tokens.spacingHorizontalS})`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: '58px',
    },
    tabList: {
        ...shorthands.margin(0, tokens.spacingHorizontalS),
    },
    editButton: {
        ...shorthands.padding(0, tokens.spacingHorizontalM),
        fontSize: tokens.fontSizeBase400,
        minWidth: '0px',
    },
    title: {
        fontSize: tokens.fontSizeBase600,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase600,
        fontStyle: 'normal',
        textOverflow: 'ellipsis',
    },
    controlButtonsDiv: {
        marginLeft: 'auto',
        minWidth: '0px',
    },
    controlButtons: {
        minWidth: '0px',
        marginInline: tokens.spacingHorizontalXS,
    },
});
