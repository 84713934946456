import {useMemo, useCallback} from 'react';
import {AgentDefinition, Agent} from '@/api/agents';
import {StatusLabelStatus} from '@sfe/react';
import {useTranslation} from 'react-i18next';

type AgentStatus = {
    getStatus: () => {
        status: StatusLabelStatus;
        text: string;
    };
    isAutomaticTrigger: boolean;
    isTriggerActive: boolean;
};

export default function useAgentStatus(
    agent: Agent | undefined,
    definition: AgentDefinition,
): AgentStatus {
    const {t: tAgents} = useTranslation('agents');

    const isAutomaticTrigger = useMemo(() => {
        return Boolean(
            definition.triggers?.[0]?.defaultPollPeriodSeconds &&
                definition.triggers?.[0]?.defaultPollPeriodSeconds > 0,
        );
    }, [definition]);

    const isTriggerActive = useMemo(() => {
        return Boolean(
            agent?.triggers?.[0]?.enabled &&
                agent?.triggers?.[0]?.pollPeriodSeconds &&
                agent?.triggers?.[0]?.pollPeriodSeconds > 0,
        );
    }, [agent]);

    const getStatus = useCallback(() => {
        if (!agent) {
            return {
                status: 'new' as StatusLabelStatus,
                text: tAgents('Agent.Header.AvailableStatus'),
            };
        } else if (
            agent.enabled &&
            agent.triggers?.[0]?.enabled &&
            (!isAutomaticTrigger || isTriggerActive)
        ) {
            // Active status: agent is enabled, triggers are enabled and active or manual run.
            return {
                status: 'success' as StatusLabelStatus,
                text: tAgents('Agent.Header.ActiveStatus'),
            };
        } else {
            // Paused status: agent is enabled but triggers are disabled or not active or manual run.
            return {
                status: 'pause' as StatusLabelStatus,
                text: tAgents('Agent.Header.PausedStatus'),
            };
        }
    }, [agent, isAutomaticTrigger, isTriggerActive, tAgents]);

    return {
        getStatus,
        isAutomaticTrigger,
        isTriggerActive,
    };
}
