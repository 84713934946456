import {GetWorkspaceByNameResponse, Workspace} from '.';
import {RESOURCE_SCOPES} from '../api.constants';
import {PutWorkspaceRequest} from './workspaces.types';
import securityGraphApi from '@/api/securityGraph.api';
import useFetch from '../useFetch';

interface PutWorkspaceParams {
    request: PutWorkspaceRequest;
    targetWorkspace?: Workspace;
    whatIfHeader?: boolean;
}

export default function useCustomPutWorkspace() {
    const {customFetch} = useFetch();

    return {
        customPutWorkspace: async <T = GetWorkspaceByNameResponse>(
            putWorkspaceParams: PutWorkspaceParams,
        ) => {
            const useWhatIfHeader = putWorkspaceParams.whatIfHeader ?? false;
            const optionalheaders = useWhatIfHeader ? {'x-ms-operation-whatif': 'true'} : null;
            const returnResponse = useWhatIfHeader;
            const getUrl = () =>
                securityGraphApi({
                    path: ``,
                    version: '2023-12-01-preview',
                    targetWorkspace: putWorkspaceParams.targetWorkspace,
                });

            return await customFetch<T>(
                getUrl(),
                {
                    method: 'PUT',
                    body: putWorkspaceParams.request,
                    scopes: RESOURCE_SCOPES.FIDELIS,
                    headersFromOptions: optionalheaders,
                },
                returnResponse,
            );
        },
    };
}
