import MedeinaFeatures, {MedeinaFeatureFlagValue} from '@/util/features';
import {TOUR_STEPS} from './stops/AdminTour.types';

export const getAdminTourStops = (
    isMultiWorkspacesEnabled: MedeinaFeatureFlagValue,
): Partial<Record<TOUR_STEPS, number>> => {
    let adminTourSteps: Partial<Record<TOUR_STEPS, number>> = {
        [TOUR_STEPS.Welcome]: 1,
        [TOUR_STEPS.CreateCapacity]: 2,
        [TOUR_STEPS.SelectCapacity]: 2,
        [TOUR_STEPS.Locations]: 3,
        [TOUR_STEPS.DataAndPrivacy]: 4,
        [TOUR_STEPS.O365Consent]: 5,
        [TOUR_STEPS.AuditLoggingConsent]: 6,
        [TOUR_STEPS.RoleInfoNew]: 7,
        [TOUR_STEPS.TourCompleted]: 8,
    };

    if (isMultiWorkspacesEnabled) {
        adminTourSteps = {
            [TOUR_STEPS.Welcome]: 1,
            [TOUR_STEPS.SelectLocation]: 2,
            [TOUR_STEPS.CreateCapacity]: 3,
            [TOUR_STEPS.SelectCapacity]: 3,
            [TOUR_STEPS.DataAndPrivacy]: 4,
            [TOUR_STEPS.O365Consent]: 5,
            [TOUR_STEPS.AuditLoggingConsent]: 6,
            [TOUR_STEPS.RoleInfoNew]: 7,
            [TOUR_STEPS.TourCompleted]: 8,
        };
    }
    return adminTourSteps;
};
