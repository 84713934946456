import {useTranslation} from 'react-i18next';
import UnitsDisplay, {UnitsDisplayStyle} from '../../ChangeCapacityDialog/UnitsDisplay';
import {ScuAllocationLabels} from '../UsageDashboard.constants';
import {isSingularUnit} from '../UsageDashboard.utils';
import {useMemo} from 'react';

/**
 * Props for the TotalOverageUnitsUsed component.
 */
interface TotalOverageUnitsUsedProps {
    /**
     * The number of overage units used in the current billing period.
     */
    overageInCurrentBilingPeriod: number;
}

export default function TotalOverageUnitsUsed(props: TotalOverageUnitsUsedProps) {
    const {t: tAdmin} = useTranslation('admin');

    const totalUnitDetailsHeader = useMemo(
        () => tAdmin(ScuAllocationLabels.TotalOverageHeader),
        [tAdmin],
    );

    const totalUnitsNumericDisplay = useMemo(
        () =>
            isSingularUnit(props.overageInCurrentBilingPeriod)
                ? tAdmin(ScuAllocationLabels.TotalUnitsSingular, {
                      0: props.overageInCurrentBilingPeriod,
                  })
                : tAdmin(ScuAllocationLabels.TotalUnitsPlural, {
                      0: props.overageInCurrentBilingPeriod,
                  }),
        [props.overageInCurrentBilingPeriod, tAdmin],
    );

    const totalUnitsFooter = useMemo(
        () => tAdmin(ScuAllocationLabels.TotalOverageFooter),
        [tAdmin],
    );

    return (
        <UnitsDisplay
            data-testid="total-allocated-units-section"
            heading={totalUnitDetailsHeader}
            numericDisplay={totalUnitsNumericDisplay}
            footer={totalUnitsFooter}
            displayStyle={UnitsDisplayStyle.Visible}
        ></UnitsDisplay>
    );
}
