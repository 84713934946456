import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    root: {
        width: '100%',
    },
    footer: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalXXL),
        borderBottom: `0.6px solid ${tokens.colorNeutralStroke2}`,
    },
    boldedText: {
        fontWeight: tokens.fontWeightBold,
        margin: '0px',
    },
    secondaryText: {
        margin: '0px',
    },
    editor: {
        paddingTop: tokens.spacingVerticalM,
    },
});
