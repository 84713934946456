import AgentRun from '@/components/sections/agents/run/AgentRun';
import {useParams, Params} from 'react-router-dom';

interface AgentRunParams extends Params {
    agentId: string;
    runId: string;
}

export default function AgentRunPage() {
    const {agentId, runId} = useParams() as AgentRunParams;
    return <AgentRun agentId={agentId} runId={runId} />;
}
