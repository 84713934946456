import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    icon: {
        '& > svg.fui-Icon-regular': {
            height: '40px',
            width: '40px',
            '@media (forced-colors: active)': {
                backgroundColor: 'Canvas',
            },
        },

        marginBottom: '38px',
    },

    subtitle: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase500,
        marginBottom: tokens.spacingVerticalXL,
    },
    dataLocationFieldSection: {
        marginBottom: tokens.spacingVerticalL,
        '& > div:first-of-type': {
            marginBottom: tokens.spacingVerticalSNudge,
        },
    },
    listBoxHeight: {
        maxHeight: '200px',
        height: 'auto',
    },
    dropDownContainer: {
        width: 'min(600px, 70vw)',
    },
    leftAlignedContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    label: {
        display: 'block',
        marginBottom: tokens.spacingVerticalMNudge,
        textAlign: 'left',
    },
    field: {
        marginBottom: tokens.spacingVerticalXXL,
    },
    columnLayout: {
        display: 'flex',
        flexDirection: 'column',
    },
});
