'use client';

import React, {useEffect, useRef, useState, forwardRef, useImperativeHandle} from 'react';
import {Editor, type Monaco} from '@monaco-editor/react';
import useClasses from './PluginCodeEditor.styles';
import {useId} from '@fluentui/react-components';
import {
    SkillsetDescriptor,
    SkillsetFormat,
    SkillsetContentType,
    useGetAllSkills,
    useGetSkillsets,
    useGetSkillsetCode,
    useUpdateSkillset,
    useCreateSkillset,
} from '@/api/skills';
import {CancellationToken, Position, languages, type editor} from 'monaco-editor';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import {Intent, ToastPositionUI} from '@/components/ui/Toasts/ToastNotification.types';
import {ColorScheme, useAppState} from '@/api/app';
import * as yaml from 'js-yaml';
import {SkillsetEditorProps} from '@/components/ui/SkillsetCreator/SkillsetCreator.types';
import {useTranslation} from 'react-i18next';

const PluginCodeEditor = forwardRef(
    (
        {
            onValueChange,
            currentSkillsetYaml,
            currentSkillsetName,
            onChangeCurrentSkillset,
            onSkillsetSave,
        }: SkillsetEditorProps,
        ref,
    ) => {
        PluginCodeEditor.displayName = 'PluginCodeEditor';
        const classes = useClasses();
        const {t} = useTranslation('pluginBuilder');
        const createSkillset = useCreateSkillset();
        const updateSkillset = useUpdateSkillset();
        const {
            data: skillsets,
            status: skillsetsLoadStatus,
            refetch: refetchSkillsets,
        } = useGetSkillsets();
        const saveButtonId = useId('saveButton');
        const [saveButtonContent, setSaveButtonContent] = useState<string>('Save');
        const [activeSkillset, setActiveSkillset] = useState<SkillsetDescriptor | null>(null);
        const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
        const {data: skillsetText, refetch: refetchSkillset} = useGetSkillsetCode({
            skillset: activeSkillset?.name ?? '',
        });
        const [activeError, setActiveError] = useState<string | null>(null);
        const {data: allSkills, refetch: refetchAllSkills} = useGetAllSkills();
        const {colorScheme} = useAppState();

        const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
        const monacoRef = useRef<Monaco | null>(null);
        const handleEditorDidMount = (editor: editor.IStandaloneCodeEditor, monaco: Monaco) => {
            editorRef.current = editor;
            monacoRef.current = monaco;
            editor.onDidChangeModelContent((e: editor.IModelContentChangedEvent) => {
                setUnsavedChanges(true);
                if (editorRef.current) {
                    onValueChange?.(editorRef.current.getModel()?.getValue());
                }
            });
            editor.setModel(
                monaco.editor.createModel('', 'yaml', monaco.Uri.parse('content://skillset')),
            );
            editor.updateOptions({
                wordWrap: 'on',
                suggest: {
                    filterGraceful: false,
                    selectionMode: 'always',
                    preview: true,
                },
                suggestOnTriggerCharacters: true,
                tabFocusMode: false,
                tabSize: 2,
            });
            monaco.editor.addKeybindingRule({
                keybinding: monaco.KeyCode.Enter,
                command: 'jumpToNextSnippetPlaceholder',
                when: 'inSnippetMode',
            });
            monaco.editor.addCommand({
                id: 'saveSkillset',
                run: () => document.getElementById(saveButtonId)?.click(),
            });
            monaco.editor.addKeybindingRule({
                keybinding: monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
                command: 'saveSkillset',
            });
        };

        useImperativeHandle(ref, () => ({
            handleUpdateSkillset,
        }));

        const handleUpdateSkillset = async () => {
            if (!editorRef?.current) {
                onSkillsetSave?.(false);
                return;
            }
            if (!allSkills) {
                await refetchAllSkills();
            }
            setUnsavedChanges(false);
            setSaveButtonContent('Saving...');
            const currentCode = editorRef.current.getModel()?.getValue();
            if (!currentCode) {
                onSkillsetSave?.(false);
                return;
            }
            const skillsetName = tryGetSkillsetName(currentCode, activeSkillset);
            if (!skillsetName) {
                onSkillsetSave?.(false);
                setActiveError('Could not determine skillset name');
                return;
            }
            const skillsetAlreadyExists =
                skillsets?.value &&
                skillsets.value.findIndex((s) => s.name === skillsetName) !== -1;
            const upsertPromise = skillsetAlreadyExists
                ? updateSkillset.mutateAsync({
                      body: currentCode,
                      name: skillsetName,
                      scope: 'User',
                      format: SkillsetFormat.SkillsetYaml,
                      contentType: SkillsetContentType.Yaml,
                      contentUrl: '',
                  })
                : createSkillset.mutateAsync({
                      body: currentCode,
                      scope: 'User',
                      format: SkillsetFormat.SkillsetYaml,
                      contentType: SkillsetContentType.Yaml,
                      contentUrl: '',
                  });
            await upsertPromise
                .then(
                    (success) => {
                        setUnsavedChanges(false);
                        if (monacoRef.current && editorRef.current) {
                            monacoRef.current.editor.setModelMarkers(
                                editorRef.current.getModel()!,
                                'skillsetValidator',
                                [],
                            );
                            editorRef.current.trigger(null, 'closeMarkersNavigation', null);
                        }
                        let basePromise = Promise.resolve();
                        setActiveSkillset(success.descriptor);
                        onSkillsetSave?.(true);
                        return basePromise;
                    },
                    (failure) => {
                        setUnsavedChanges(true);
                        if (failure?.name === 'ClientApiError') {
                            failure.response.text().then((failureBody: string) => {
                                tryApplyErrorMarkers(failureBody);
                                setActiveError(failureBody);
                            });
                        } else {
                            setActiveError(failure.toString());
                        }
                        onSkillsetSave?.(false);
                    },
                )
                .finally(() => {
                    setSaveButtonContent('Save');
                });
        };

        const tryApplyErrorMarkers = (failureBody: string) => {
            try {
                const errorMessage = (JSON.parse(failureBody) as any).message as string | null;
                if (!errorMessage) {
                    return;
                }
                const errorRegex =
                    /\(Line: (\d+), Col: (\d+), .*Line: (\d+), Col: (\d+), .*\): (.*)/;
                const groups = errorMessage.match(errorRegex);
                if (groups) {
                    const startLine = parseInt(groups[1]);
                    const startColumn = parseInt(groups[2]);
                    const endLine = parseInt(groups[3]);
                    const endColumn = parseInt(groups[4]);
                    const message = groups[5];
                    if (!monacoRef.current || !editorRef.current) {
                        return;
                    }
                    editorRef.current.focus();
                    monacoRef.current.editor.setModelMarkers(
                        editorRef.current.getModel()!,
                        'skillsetValidator',
                        [
                            {
                                startLineNumber: startLine,
                                endLineNumber: endLine + 1,
                                startColumn: startColumn,
                                endColumn: endColumn + 1,
                                message: message,
                                severity: monacoRef.current.MarkerSeverity.Error,
                            },
                        ],
                    );
                    editorRef.current.trigger(null, 'editor.action.marker.next', null);
                }
            } catch (e) {
                console.error(e);
            }
        };

        const tryGetSkillsetName = (
            skillsetBody: string,
            activeSkillset: SkillsetDescriptor | null,
        ) => {
            try {
                const parsedSkillset = yaml.load(skillsetBody) as any;
                return (parsedSkillset.Descriptor.Name as string | null) ?? activeSkillset?.name;
            } catch (e) {
                console.error(e);
                return activeSkillset?.name;
            }
        };

        useEffect(() => {
            const initializeEditor = async () => {
                if (currentSkillsetYaml) {
                    while (!editorRef.current) {
                        await new Promise((resolve) => setTimeout(resolve, 100));
                    }
                    const editor = editorRef.current;
                    const contribution = editor.getContribution('snippetController2');
                    editor.setValue('');
                    (contribution as any).insert(currentSkillsetYaml);
                }
            };
            initializeEditor();
        }, []);

        useEffect(() => {
            if (currentSkillsetName) {
                setActiveSkillset(
                    skillsets?.value?.find((n) => n.name == currentSkillsetName) || null,
                );
            }
        }, [currentSkillsetName]);

        useEffect(() => {
            if (
                !activeSkillset ||
                !editorRef ||
                !editorRef.current ||
                !skillsetText ||
                typeof skillsetText !== 'string'
            ) {
                return;
            }
            editorRef.current.setValue(skillsetText ?? '');
            setUnsavedChanges(false);
        }, [editorRef, skillsetText]);

        return (
            <div className={classes.root}>
                <div className={classes.footer}>
                    <p className={classes.boldedText}>{t('EditManifest')}</p>
                    <p className={classes.secondaryText}>{t('LearnMore')}</p>
                </div>
                <Editor
                    className={classes.editor}
                    height="calc(100vh - 100px)"
                    defaultLanguage="yaml"
                    theme={
                        colorScheme === ColorScheme.Dark
                            ? 'vs-dark'
                            : colorScheme === ColorScheme.Light
                            ? 'light'
                            : colorScheme === ColorScheme.HighContrast
                            ? 'hc-black'
                            : 'vs-dark'
                    }
                    onMount={handleEditorDidMount}
                    onChange={(value) => onValueChange?.(value)}
                />
                {activeError && (
                    <ToastNotification
                        intent={Intent.Error}
                        message={activeError}
                        position={ToastPositionUI.Bottom_End}
                    ></ToastNotification>
                )}
            </div>
        );
    },
);

export default PluginCodeEditor;
