import React, {useState} from 'react';
import {Label, Input, useId, mergeClasses, Field} from '@fluentui/react-components';
import useClasses from '../Setup.styles';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {useTranslation} from 'react-i18next';

interface WorkspaceNameProps {
    setWorkspaceName: (value: string) => void;
    workspaceName: string;
    labelSize?: 'small' | 'medium';
}

export const WorkspaceNameField = ({
    setWorkspaceName,
    workspaceName,
    labelSize = 'medium',
}: WorkspaceNameProps) => {
    const classes = useClasses();
    const tourClasses = useTourClasses();
    const {t} = useTranslation('tours');
    const labelId = useId('workspace-name');
    const [error, setError] = useState<string | null>(null);

    const validateInput = (value: string) => {
        const regex = /^[a-zA-Z0-9_-]*$/;
        if (!regex.test(value)) {
            setError(t('adminV2Fre.workspaceInfo.InvalidCharacters'));
        } else {
            setError(null);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        validateInput(value);
        setWorkspaceName(value);
    };

    return (
        <Field
            label={
                <Label className={mergeClasses(classes.label)}>
                    {t('adminV2Fre.workspaceInfo.WorkspaceName')}
                </Label>
            }
            data-testid="data-storage-location-label"
            size={labelSize}
            validationState={error ? 'error' : 'none'}
            validationMessage={error}
        >
            <Input
                className={mergeClasses(tourClasses.coverFullWidth, tourClasses.textBox)}
                required
                type="text"
                // placeholder={t('adminV2Fre.workspaceInfo.PlaceholderText')}
                aria-describedby={labelId}
                data-testid="workspace-name-input"
                value={workspaceName}
                onChange={handleChange}
            />
        </Field>
    );
};

export default WorkspaceNameField;
