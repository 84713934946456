import React, {useRef, useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {Button, useFocusFinders} from '@fluentui/react-components';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import useClasses from './Welcome.styles';
import {useNavigate} from 'react-router-dom';
import {useManageTenantInfo} from '@/api/tenant';
import {useTranslation} from 'react-i18next';
import {TourStageContainer, useTourContext} from '@/components/ui/Tour';
import {TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from './AdminTour.types';
import useSetupAndProvisionFidelisConstructs from '@/api/capacities/useSetupAndProvisionFidelisConstructs';
import {useGetCapacitiesForAdminFre} from '@/api/capacities';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import SetupInProgress from './SetupInprogress';
import {useQueryClient} from '@tanstack/react-query';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {useViewportSize} from '@/components/ui/Grid';
import {Body, TextH1} from '@/components/ui/Text';
import MedeinaFeatures from '@/util/features';
import useManageTourTransition from './useManageTourTransition';
import {useFeatureFlag} from '@/api/user';
import useGetWorkspaces from '@/api/workspaces/useGetWorkspaces';
export interface WelcomeProps {
    freStage?: boolean;
    isFreComplete?: boolean;
    workspaceName?: string;
}

/**
 * Welcome screen for the Admin Tour with multiworkspace support
 *
 * The welcome screen checks:
 * - is the FRE complete
 * - what was the last step of the FRE that has been completed
 * - if the FRE is complete, the user is taken to the home screen
 * - if the FRE is not complete and there's no workspace, the user is taken to the next step in the FRE
 * - if the FRE is not complete and there's a workspace but no capacity the user is taken to the capacity selection screen
 * - if the FRE is complete but there's no workspace, the user is taken to the manage workspaces screen
 *
 * @param props
 * @returns
 */
function Welcome(props: WelcomeProps) {
    const tourClasses = useTourClasses();
    const classes = useClasses();
    const queryClient = useQueryClient();

    const {sm: isSmallScreen} = useViewportSize();

    const {setTourErrorV2, setTourStep, currentStop, tourError} = useTourContext();
    const navigate = useNavigate();

    const {validateMultiWorkspaceWelcomeScreenTransition} = useManageTourTransition();

    const {t} = useTranslation('tours');
    const {update: providerUpdate} = useWorkspaceState();
    const [isProvisioningInProgress, setIsProvisioningInProgress] = useState(false);

    const welcomeWrapper = useRef(null);
    const getStartedButtonRef = useRef(null);

    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    const {refetch: getCapacities} = useGetCapacitiesForAdminFre({
        enabled: false,
    });

    const {initializeTenantFreInfo} = useManageTenantInfo();

    const {findFirstFocusable} = useFocusFinders();

    useEffect(() => {
        if (welcomeWrapper?.current && getStartedButtonRef?.current) {
            const firstFocusable = findFirstFocusable(welcomeWrapper.current);
            firstFocusable?.focus();
        }
    }, [welcomeWrapper, getStartedButtonRef]);

    const performActionsOnContinue = async () => {
        try {
            const step = await validateMultiWorkspaceWelcomeScreenTransition();
            console.log('step evaluated at welcome onContinue', step);
            if (step == TOUR_STEPS.SelectLocation) {
                setIsProvisioningInProgress(true);
                // If we have no tenant info record we want to create one
                await initializeTenantFreInfo()
                    .then(() => {
                        setIsProvisioningInProgress(false);
                    })
                    .catch(() => {
                        setIsProvisioningInProgress(false);
                        setTourErrorV2({
                            ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                            actionButtonStep: TOUR_STEPS.Welcome,
                        });
                    });
            }
            // we want to initialize our tenant info record on the welcome screen
            // we will use this to track the progress of the FRE
            setTourStep(step);
        } catch (error) {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.Welcome,
            });
        } finally {
            setIsProvisioningInProgress(false);
        }
    };

    const handleClick = async () => {
        // if the FRE is complete we don't want to take the user through any of the setup steps
        if (props.isFreComplete) {
            // if we are in multiworkspaces we will

            setTourStep(TOUR_STEPS.HOME);
            await providerUpdate();
            await queryClient.invalidateQueries(['auth', 'getUserInfo']);
            navigate('/');
            return;
        } else {
            // if multiworkspaces is enabled we will take the user to the Workspace name/Location screen
            // move to the next tour step to
            // we want to create the MSG account
            setIsProvisioningInProgress(true);
            performActionsOnContinue();
        }
    };

    return (
        <motion.div
            key="welcome"
            variants={overlayAnimation}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <TourStageContainer ref={welcomeWrapper}>
                <TourStageContainer.StageContent>
                    <>
                        {isProvisioningInProgress && (
                            <SetupInProgress
                                heading={t('adminV2Fre.locationSelection.SetupInProgress')}
                                subHeading={t('adminV2Fre.locationSelection.Polite')}
                            ></SetupInProgress>
                        )}
                        {!isProvisioningInProgress && (
                            <>
                                <div className={tourClasses.tourMediumSectionSpacing}>
                                    <TextH1 role="heading">
                                        {t('adminV2Fre.welcome.Heading')}-v2
                                    </TextH1>
                                </div>
                                <div className={classes.paragraphOverride}>
                                    <Body>
                                        {!!props.freStage || !!props.isFreComplete
                                            ? t('adminV2Fre.welcome.FreNotStartedInfoMessage')
                                            : isWorkspacesTestingEnabled
                                            ? t(
                                                  'adminV2Fre.WorkspacesWelcome.FreStartedInfoMessage',
                                              )
                                            : t('adminV2Fre.welcome.FreStartedInfoMessage')}
                                    </Body>
                                </div>
                                <span className={classes.buttonRow}>
                                    <Button
                                        ref={getStartedButtonRef}
                                        onClick={handleClick}
                                        appearance="primary"
                                        size={isSmallScreen ? 'small' : 'medium'}
                                        tabIndex={0}
                                        disabled={
                                            !Boolean(props?.isFreComplete) &&
                                            (Boolean(tourError) || currentStop?.isFirstStop)
                                        }
                                        className={classes.buttonContent}
                                    >
                                        {t('adminV2Fre.welcome.GetStarted')}
                                    </Button>
                                </span>
                            </>
                        )}
                    </>
                </TourStageContainer.StageContent>
            </TourStageContainer>
        </motion.div>
    );
}

export default Welcome;
