import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

const lineHeight = tokens.lineHeightBase300;

export default makeStyles({
    agentCard: {
        display: 'flex',
        minWidth: '304px',
        maxWidth: '348px',
        flexDirection: 'column',
        gap: tokens.spacingVerticalM,
        width: '100%',
    },
    agentCardDescription: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        lineHeight: `${lineHeight}`,
        minHeight: `calc(${lineHeight} * 3)`,
        height: '100%',
    },
    agentCardImage: {
        backgroundColor: tokens.colorNeutralBackground1,
        objectFit: 'contain',
    },
    agentStatusAvailableIcon: {
        color: tokens.colorPaletteBlueBorderActive,
    },
});
