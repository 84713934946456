import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {CreatePromptRequest} from '../prompts/prompts.types';
import {useBumpSessionUpdatedAtUntilInvestigationsExist, useClearSessionSummary} from '../sessions';
import {EvaluationCreationResponse} from '../evaluations';

export default function useCreatePromptAndEvaluation() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    const {mutate: clearSessionSummary} = useClearSessionSummary();
    const {mutate: changeSessionUpdatedAt} = useBumpSessionUpdatedAtUntilInvestigationsExist();
    return useMutation({
        mutationFn: async (body: CreatePromptRequest) => {
            const {evaluation} = await customFetch<EvaluationCreationResponse>(
                `/sessions/${body?.sessionId}/promptEvaluation`,
                {
                    method: 'POST',
                    body,
                },
            );
            return evaluation;
        },
        onSuccess: (evaluation, body) => {
            if (body) changeSessionUpdatedAt(body.sessionId);
            // Refetch the parent prompt, in case this is either editing or re-running the prompt.
            clearSessionSummary({sessionId: evaluation.sessionId});
            queryClient.refetchQueries({
                queryKey: ['sessions', body?.sessionId, 'prompts', evaluation?.promptId],
            });
        },
    });
}
