import {Field, Label, mergeClasses} from '@fluentui/react-components';
import {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react';
import {DownIcon, SkillIcon, UpIcon} from '@/components/ui/icons';
import Textarea from '@/components/ui/Textarea';
import useClasses from './PromptbookPromptForm.styles';
import {PromptbookPromptFormProps} from './PromptbookPromptForm.types';
import {SkillInputDescriptor} from '@/api/skills';
import {useTranslation} from 'react-i18next';
import {PromptInputs} from '@/api/prompts';

export * from './PromptbookPromptForm.types';

export default function PromptbookPromptForm({
    skillInputs,
    promptInputs,
    promptContent,
    skillInputDescriptors,
    isModal,
    displayView,
    skillName,
    checkValidity,
    onInputsChange,
}: PromptbookPromptFormProps) {
    const classes = useClasses();
    const inputRefs = useRef<{[key: string]: HTMLTextAreaElement | null}>({});
    const [hasOptional, setHasOptional] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [tempPromptInputs, setTempPromptInputs] = useState(promptInputs as PromptInputs);
    const [tempSkillInputs, setTempSkillInputs] = useState(skillInputs as PromptInputs);
    const {t} = useTranslation('promptbooks');
    const skillInputDescriptorsArray = skillInputDescriptors ?? [];
    const isCombinedPrompt =
        skillInputDescriptors &&
        skillInputDescriptors.length > 0 &&
        promptContent &&
        promptContent !== ''
            ? true
            : false;

    // Reset parent state initially, otherwise it could include stale or empty data.
    useEffect(() => {
        onInputsChange?.(promptInputs ?? {}, 'promptInputs');
        onInputsChange?.(skillInputs ?? {}, 'skillInputs');
    }, []);

    // Set `hasOptional` to true when optional fields are detected
    useEffect(() => {
        const hasOptionalField = skillInputDescriptorsArray.some((input) => !input.required);
        setHasOptional(hasOptionalField);
    }, [skillInputDescriptors]); // Only rerun when inputs change

    // Used to highlight parameters in the prompt when in modal view
    const setUpPromptModalText = (content: String | undefined) => {
        if (content === undefined) {
            return '';
        }
        const promptText = content;
        const regex = /(<[^>]+>)/g;
        const parts = promptText.split(regex);
        if (!isModal) {
            return content;
        }
        return (
            <>
                {parts.map((part, index) =>
                    regex.test(part) ? (
                        <span key={index} className={classes.promptbookLibraryLabel}>
                            {part.slice(1, -1)}
                        </span>
                    ) : (
                        part
                    ),
                )}
            </>
        );
    };

    // Update state based on form changes.
    const handleSkillInputChange = useCallback(
        (ev: ChangeEvent<HTMLTextAreaElement>, input: SkillInputDescriptor) => {
            const nextValues = {...tempSkillInputs, [input.name]: ev.target.value};
            setTempSkillInputs(nextValues);
            onInputsChange?.(nextValues, 'skillInputs');
        },
        [onInputsChange],
    );

    // Update state based on form changes.
    const handlePromptInputChange = useCallback(
        (ev: ChangeEvent<HTMLTextAreaElement>, key: string) => {
            const nextValues = {...tempPromptInputs, [key]: ev.target.value};
            setTempPromptInputs(nextValues);
            onInputsChange?.(nextValues, 'promptInputs');
        },
        [onInputsChange],
    );

    const handleClick = () => {
        setIsVisible(!isVisible);
    };

    function isEmpty(value: string): boolean {
        return (value === '' || value === undefined) && checkValidity;
    }

    return (
        <div
            className={mergeClasses(
                classes.root,
                (skillInputDescriptorsArray.length > 0 || tempPromptInputs !== undefined) &&
                    classes.rowGap,
            )}
        >
            {promptContent && (
                <>
                    <div
                        className={classes.promptContent}
                        style={!displayView ? (isModal ? {width: '465px'} : undefined) : undefined}
                    >
                        {setUpPromptModalText(promptContent)}
                    </div>
                    {Object.entries(tempPromptInputs ?? {}).map(([key, value]) => (
                        <div
                            key={`${key} div`}
                            className={mergeClasses(classes.root, classes.inputs)}
                        >
                            <Label
                                required={true}
                                className={classes.label}
                                htmlFor={value as string}
                            >
                                {key}
                            </Label>
                            <Field
                                id={`prompt-input-${key}`}
                                validationState={isEmpty(value) ? 'error' : 'none'}
                                validationMessage={
                                    isEmpty(value) ? t('MissingInputMessage') : undefined
                                }
                            >
                                <Textarea
                                    id={key}
                                    key={key}
                                    value={value}
                                    appearance="outline"
                                    autosize
                                    size="large"
                                    onChange={(ev) => handlePromptInputChange(ev, key)}
                                    required={true}
                                    placeholder={t('EnterInput')}
                                />
                            </Field>
                        </div>
                    ))}
                </>
            )}
            {skillName && (
                <>
                    <div className={classes.title}>
                        <SkillIcon className={classes.skillIcon} />
                        {skillName}
                    </div>
                    {!isCombinedPrompt && (
                        <div data-testid={`skill-form`}>
                            {skillInputDescriptorsArray.map(
                                (input) =>
                                    input.required && (
                                        <div
                                            key={`${input.name} div`}
                                            className={mergeClasses(
                                                classes.inputs,
                                                isModal ? classes.promptbookLibraryGap : undefined,
                                            )}
                                        >
                                            <Label
                                                required={isModal ? false : true}
                                                key={`${input.name} label`}
                                                htmlFor={input.name}
                                                className={
                                                    isModal
                                                        ? classes.promptbookLibraryLabel
                                                        : classes.label
                                                }
                                            >
                                                {`${
                                                    input.placeholderValue ??
                                                    input.description ??
                                                    input.name
                                                }`}
                                            </Label>
                                            {!isModal && (
                                                <Field
                                                    id={`prompt-input-${input.name}`}
                                                    validationState={
                                                        isEmpty(tempSkillInputs[input.name])
                                                            ? 'error'
                                                            : 'none'
                                                    }
                                                    validationMessage={
                                                        isEmpty(tempSkillInputs[input.name])
                                                            ? t('MissingInputMessage')
                                                            : undefined
                                                    }
                                                >
                                                    <Textarea
                                                        id={input.name}
                                                        key={input.name}
                                                        data-testid={`direct-skill-form-input-${input.name}`}
                                                        ref={(el) =>
                                                            (inputRefs.current[input.name] = el)
                                                        }
                                                        value={tempSkillInputs[input.name] ?? ''}
                                                        size="large"
                                                        appearance="outline"
                                                        autosize
                                                        onChange={(ev) => {
                                                            handleSkillInputChange(ev, input);
                                                        }}
                                                        required={input.required}
                                                        placeholder={t('EnterInput')}
                                                    />
                                                </Field>
                                            )}
                                        </div>
                                    ),
                            )}
                        </div>
                    )}
                </>
            )}
            {(hasOptional || isCombinedPrompt) && (
                <button
                    onClick={handleClick}
                    className={classes.optionalFieldsButton}
                    data-testid={`optional-fields`}
                    aria-label={t('OpenOptionalInputFields')}
                    type="button"
                >
                    {t('OptionalFields')}
                    {isVisible ? (
                        <UpIcon className={classes.largeIcon} />
                    ) : (
                        <DownIcon className={classes.largeIcon} />
                    )}
                </button>
            )}
            {isVisible &&
                skillInputDescriptorsArray.map(
                    (input) =>
                        (!input.required || isCombinedPrompt) && (
                            <div
                                key={`optional-${input.name} div`}
                                className={mergeClasses(
                                    classes.inputs,
                                    isModal ? classes.promptbookLibraryGap : undefined,
                                )}
                            >
                                <label
                                    className={isModal ? classes.promptbookLibraryLabel : undefined}
                                    key={`optional-${input.name} label`}
                                    htmlFor={input.name}
                                >{`${
                                    input.placeholderValue ?? input.description ?? input.name
                                }`}</label>
                                {!isModal && (
                                    <Textarea
                                        id={input.name}
                                        data-testid={`optional-${input.name}`}
                                        key={`optional-${input.name}`}
                                        ref={(el) => (inputRefs.current[input.name] = el)}
                                        value={tempSkillInputs[input.name] ?? ''}
                                        size="large"
                                        appearance="outline"
                                        autosize
                                        onChange={(ev) => handleSkillInputChange(ev, input)}
                                        required={input.required}
                                        placeholder={t('EnterInput')}
                                    />
                                )}
                            </div>
                        ),
                )}
        </div>
    );
}
