import {useCallback, useEffect, useState, useContext} from 'react';
import {useAppState, useGetUserInfo} from '@/api/app';
import {useGetTenantDataLocation} from '@/api/data';
import useGetTenantInfoForAdminFre from '@/api/tenant/useGetTenantInfoForAdminFre';
import {useNavigate} from 'react-router-dom';
import {WorkspaceStateContext} from '@/components/workspaces';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

// A hook that checks if the workspace has been setup
// if the user has been shown the corresponding FRE
function useCheckForOnboarding(activeTour: boolean) {
    const {userTours} = useAppState();
    const workspace = useContext(WorkspaceStateContext);
    const debugEnabled = useFeatureFlag(MedeinaFeatures.Debug);
    const isMultiWorkspacesEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    if (debugEnabled) console.log({workspaceState: workspace});
    const {analyst, admin} = userTours;
    // a flag we use to determine if onboarding tour conditions have been checked
    const [isChecked, setIsChecked] = useState(false);

    const navigate = useNavigate();

    const {
        data: userInfo,
        isLoading: isGetAdminLoading,
        isSuccess,
        isFetched: isGetIsAdminFetched,
    } = useGetUserInfo({enabled: isChecked});

    // we care that this information has finished loading before we allow any tours to be shown
    // get location data for the tenant, if no location is set this will return as configuredGeo: null
    // Multiworkspaces: if enabled, we don't need to check for location this is now controlled by a new geo endpoint
    const {data: dataLocationResponse, isFetched: isGetTenantDataLocationFetched} =
        useGetTenantDataLocation({enabled: isChecked && !isMultiWorkspacesEnabled});

    // get tenantInfo, if tenantInfo is not set this will return an error which is acceptable in case of 404s
    // use isFetched to check that we are done requesting the data
    const {
        data: tenantInfo,
        isFetched: isTenantInfoFetched,
        isSuccess: isTenantInfoSuccess,
    } = useGetTenantInfoForAdminFre({
        enabled: isChecked,
    });

    // if we have all the data we need, we are ready to show the tour
    // individual tours and the useEffect below will check for the correct tour to show
    const isReady =
        isGetIsAdminFetched &&
        (isGetTenantDataLocationFetched || isMultiWorkspacesEnabled) &&
        isTenantInfoFetched;

    // if we have all the data we need successfully, we are ready to show the tour
    const areInvocationsSuccessful = isSuccess && isTenantInfoSuccess;

    const checkForOnboarding = useCallback(() => {
        setIsChecked(true);
    }, []);

    const isValidFreRedirectSetupForTenant = useCallback(() => {
        // If no tenant record exists then we should invoke the FRE
        if (!tenantInfo) {
            return true;
        }
        // If the tenant record exists and the FRE has not met, we should invoke the FRE
        return !tenantInfo?.freRequirements?.freRequirementsMet;
    }, [tenantInfo]);

    const selectTour = () => {
        if (!activeTour && userInfo?.isAdmin && isValidFreRedirectSetupForTenant()) {
            navigate('/tour/admin');
        } else if (!activeTour && !analyst && userInfo && !userInfo.isAdmin) {
            navigate('/tour/welcome');
        }
    };

    useEffect(() => {
        // we can only reliably launch the tours if we know the user is an admin or not
        // and if we have the sessions data
        if (userInfo && areInvocationsSuccessful) {
            selectTour();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo, areInvocationsSuccessful, tenantInfo]);

    return {checkForOnboarding, isReady};
}

export default useCheckForOnboarding;
