import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {GetRoleMembersResponse} from '.';
import useFetch from '../useFetch';
import {RESOURCE_SCOPES} from '../api.constants';
import securityGraphApi from '@/api/securityGraph.api';
import {isGeoPodEnabled} from '@/components/sections/workspaces/WorkspacesProvider';
import {Workspace} from '../workspaces';

/** Get Workspace Policy */
export default function useGetRoleMembers(
    workspaceName: string,
    targetWorkspace?: Workspace,
    useQueryOptions?: Pick<UseQueryOptions<GetRoleMembersResponse>, 'enabled' | 'refetchOnMount'>,
) {
    const {customFetch} = useFetch();
    const url = securityGraphApi({
        path: isGeoPodEnabled()
            ? `/policystore/workspaces/${workspaceName}/workspacePolicy`
            : `/policyStore/workspacePolicies?workspaceName=${workspaceName}`,
        version: '2024-01-01-preview',
        podPath: true,
        targetWorkspace: targetWorkspace,
    });

    return useQuery<GetRoleMembersResponse>({
        refetchOnMount: useQueryOptions?.refetchOnMount ?? false,
        refetchOnWindowFocus: false,
        enabled: useQueryOptions?.enabled ?? true,
        retry: false,
        queryKey: ['rbac', workspaceName],
        queryFn: async () =>
            await customFetch<GetRoleMembersResponse>(url, {
                method: 'GET',
                scopes: RESOURCE_SCOPES.FIDELIS,
            }),
        ...useQueryOptions,
    });
}
