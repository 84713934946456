import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useGetSession, useIsUserSessionOwner} from '@/api/sessions';
import {useLayout} from '@/components/ui/Layout';
import {useViewportSize} from '@/components/ui/Grid';
import {AgentProps} from './SessionAgentView.types';
import {useGetPrompts} from '@/api/prompts';
import {useGetEvaluationSkillInvocations} from '@/api/evaluations';
import {Spinner} from '@fluentui/react-components';
import AgentNodeDetailsModal from './SessionAgentNodeDetailsModal';
import {convertToNodesAndEdges} from './ConvertToNodesAndEdges';
import useClasses from './SessionAgentView.styles';
import {
    NodeMap,
    ReactFlowProvider,
    useReactFlow,
    type AgentTaskNodeFooterProps,
    type Node,
} from '@sfe/react';
import type {ButtonProps} from '@fluentui/react-components';

export * from './SessionAgentView.types';

const AgentNodeMap = ({sessionId}: AgentProps) => {
    const classes = useClasses();
    const {data: session, isLoading, isError, refetch: getSession} = useGetSession({sessionId});
    const {
        data: prompts,
        refetch: getPrompts,
        isLoading: isGetPromptsLoading,
    } = useGetPrompts({sessionId});

    const {isUserSessionOwner: isUserSessionOwnerResponse} = useIsUserSessionOwner(sessionId);
    const [selectedNodeData, setSelectedNodeData] = useState<any>(null);

    useEffect(() => {
        if (isError && !isUserSessionOwnerResponse) {
            getSession();
            getPrompts();
        }
    }, [isError, isUserSessionOwnerResponse, getSession, getPrompts]);

    const {sidePanel} = useLayout();
    const screen = useViewportSize();

    useEffect(() => {
        if (screen.sm || screen.md) {
            sidePanel?.toggleClosed?.();
        }
    }, [screen, sidePanel]);

    // Fetch evaluation skill invocations
    const evaluationIds = prompts?.value?.flatMap((prompt: any) => prompt.latestEvaluationId) || [];
    const promptIds = prompts?.value?.map((prompt: any) => prompt.promptId) || [];
    const evaluationsSkillInvocations = useGetEvaluationSkillInvocations({
        sessionId,
        promptIds,
        evaluationIds,
        enabled: !!session,
    });

    const evaluationsSkillInvocationsLoading = useMemo(() => {
        return evaluationsSkillInvocations?.some((result) => result.isLoading);
    }, [evaluationsSkillInvocations]);

    const evaluationData = evaluationsSkillInvocations.map((invocation) => ({
        data: {value: invocation.data?.value || []},
    }));

    const onNodeSelected = useCallback((node: Node) => {
        const {header, body, footer, expanded, ...rest} = node.data;
        setSelectedNodeData(rest);
    }, []);

    const {updateNode} = useReactFlow();

    const expandNode = useCallback(
        (id: string) => {
            updateNode(id, (node: Node) => {
                const {footer, expanded} = node.data;
                // Update the footer action button's text on expanded
                const updatedFooter = {
                    ...(footer as AgentTaskNodeFooterProps),
                    actionButton: {
                        ...((footer as AgentTaskNodeFooterProps).actionButton as ButtonProps),
                        action: expanded ? 'expand' : 'collapse',
                        children: expanded ? 'Expand' : 'Collapse',
                    },
                };
                // Toggle the expanded state
                const updatedData = {
                    ...node.data,
                    footer: updatedFooter,
                    expanded: !expanded,
                };

                return {
                    ...node,
                    data: updatedData,
                };
            });
        },
        [updateNode],
    );

    const {nodes, edges} =
        session && prompts
            ? convertToNodesAndEdges(session, prompts, evaluationData, expandNode)
            : {nodes: [], edges: []};

    return (
        <div className={classes.container} data-testid="agent-view">
            {(isLoading || isGetPromptsLoading || evaluationsSkillInvocationsLoading) &&
            !isError ? (
                <Spinner />
            ) : (
                <NodeMap
                    minZoom={0.2}
                    nodes={nodes}
                    edges={edges}
                    fitView
                    onNodeSelected={onNodeSelected}
                    navigation={{
                        className: classes.navigation,
                        controls: {
                            zoomToFitButton: {
                                'aria-label': 'Zoom to fit',
                            },
                            toggleMapButton: {
                                'aria-label': 'Toggle mini-map',
                            },
                            zoom: {
                                slider: {min: 0.2, step: 0.2, 'aria-label': 'Zoom slider'},
                                zoomInButton: {'aria-label': 'Zoom in'},
                                zoomOutButton: {'aria-label': 'Zoom out'},
                            },
                        },
                    }}
                />
            )}
            <AgentNodeDetailsModal
                nodeData={selectedNodeData}
                onClose={() => setSelectedNodeData(null)}
            />
        </div>
    );
};

export default function SessionAgentView({sessionId}: AgentProps) {
    return (
        <ReactFlowProvider>
            <AgentNodeMap sessionId={sessionId} />
        </ReactFlowProvider>
    );
}
