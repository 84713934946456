import {useEffect, useState} from 'react';
import {Button, Link, mergeClasses, Title1} from '@fluentui/react-components';
import useClasses from './DataOptIn.styles';
import {
    useGetWorkspaceByName,
    useGetWorkspaceSettings,
    useUpdateWorkspaceSettings,
} from '@/api/workspaces';
import {useUpdateWorkspace} from '@/api/workspaces';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import {useGetTenantInfo} from '@/api/tenant';
import {CustomSwitchTransition} from '../../ui/Switch/switch.types';
import MSManagedBanner from '../MSManagedBanner';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {WorkspaceSettings} from '@/api/workspaces/workspaces.types';
import {getCurrentWorkspaceCache} from '@/components/sections/workspaces/WorkspacesProvider';
import OptInSwitch from './OptInSwitch';
import {DataAndPrivacyDetails} from './DataOptIn.types';

// These are MSFT managed tenants that we don't want settings changed in
const managedTenants = MedeinaVariables.MSManagedTenants;

export default function DataOptIn() {
    const classes = useClasses();
    // Get the workspace name from the cache

    const {data: tenantInfo, isSuccess: isTenantInfoSuccess} = useGetTenantInfo();
    const overrideSettings = useFeatureFlag(MedeinaFeatures.OverrideSettings);
    const disableSettingsUpdate = tenantInfo && managedTenants.includes(tenantInfo?.tenantId ?? '');

    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    // multi-workspaces
    const {
        data: workspace,
        isFetched: workspaceSettingsFetched,
        isSuccess: workspaceSettingsSuccess,
        refetch: refetchWorkspace,
        isError: workspaceSettingsError,
    } = useGetWorkspaceSettings(undefined, {
        enabled: Boolean(isWorkspacesTestingEnabled),
    });

    const {t} = useTranslation('admin');

    return (
        <>
            <h2 className={classes.subtitle}>
                {t('ownerSettings.organizationData.SectionHeading')}
            </h2>
            <div className={mergeClasses(classes.paragraphContent)}>
                {isWorkspacesTestingEnabled && workspace
                    ? t('ownerSettings.WorkspacesOrganizationData.InfoContent')
                    : t('ownerSettings.organizationData.InfoContent')}{' '}
                <Link
                    data-test-id="privacy-and-data-security-link"
                    href={MedeinaVariables.PrivacyAndDataSecurity}
                    target="_blank"
                >
                    {t('ownerSettings.organizationData.PrivacyAndSecurity')}
                </Link>
            </div>
            {disableSettingsUpdate && <MSManagedBanner />}

            <div className={classes.dataSwitcher}>
                <div>
                    <OptInSwitch
                        type="product"
                        disabled={disableSettingsUpdate && !overrideSettings}
                    />
                </div>
                <div>
                    <OptInSwitch
                        type="model"
                        disabled={disableSettingsUpdate && !overrideSettings}
                    />
                </div>
            </div>
        </>
    );
}
