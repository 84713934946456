import React from 'react';
import useClasses from './AgentDrawer.styles';
import {InlineDrawer, useRestoreFocusSource} from '@fluentui/react-components';
import {Agent, AgentDefinition, AgentTriggerRun, RunAgentTriggerRequest} from '@/api/agents';
import AgentInputDrawerContent from './AgentInputDrawerContent';
import AgentRunDrawerContent from './AgentRunDrawerContent';

interface AgentDrawerProps {
    agent: Agent;
    agentDefinition: AgentDefinition;
    selectedRun: AgentTriggerRun | undefined;
    openInputDrawer: boolean;
    isManualRunLoading: boolean;
    onClose: () => void;
    onRunTrigger: (body: RunAgentTriggerRequest['body']) => void;
    hasInputs: boolean;
}

export default function AgentDrawer(props: AgentDrawerProps) {
    const {
        agent,
        agentDefinition,
        selectedRun,
        openInputDrawer,
        isManualRunLoading,
        onClose,
        onRunTrigger,
        hasInputs,
    } = props;
    const classes = useClasses();

    const restoreFocusSourceAttributes = useRestoreFocusSource();

    return (
        <InlineDrawer
            {...restoreFocusSourceAttributes}
            className={classes.drawer}
            separator
            position="end"
            open={!!selectedRun || openInputDrawer}
            surfaceMotion={null}
            data-testid="agent-drawer"
        >
            {openInputDrawer ? (
                <AgentInputDrawerContent
                    agent={agent}
                    agentDefinition={agentDefinition}
                    onClose={onClose}
                    onRunTrigger={onRunTrigger}
                    isManualRunLoading={isManualRunLoading}
                />
            ) : (
                <AgentRunDrawerContent
                    selectedRun={selectedRun}
                    onClose={onClose}
                    agent={agent}
                    hasInputs={hasInputs}
                />
            )}
        </InlineDrawer>
    );
}
