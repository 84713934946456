import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    activity: {
        flex: 3,
    },
    activityCard: {
        ...shorthands.padding(tokens.spacingHorizontalL),
        maxHeight: '100%',
    },
    activityHeader: {
        display: 'flex',
        gap: tokens.spacingHorizontalS,
    },
    loading: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        ...shorthands.padding(tokens.spacingHorizontalXXXL),
    },
});
