export default function parameterDisplayConfiguratorsUtils(
    classDefinitions: Record<string, string>,
) {
    let generateUnitDisplayElement = (element: JSX.Element) => {
        return <div className={classDefinitions.unitDisplay}>{element}</div>;
    };

    let generateRowDisplayElement = (elements: JSX.Element[]) => {
        return generateUnitDisplayElement(
            <div className={classDefinitions.singleColumn}>
                {elements.map((element) => (
                    <>{generateUnitDisplayElement(element as JSX.Element)}</>
                ))}
            </div>,
        );
    };

    return {
        generateUnitDisplayElement,
        generateRowDisplayElement,
    };
}
