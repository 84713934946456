import WorkspaceSwitchingPage from '@/components/Navigation/WorkspaceSwitchingPage';
import {useParams, Params} from 'react-router-dom';

interface WorkspaceSwitchingPageParams extends Params {
    workspaceId: string;
}

export default function WorkspaceSwitching() {
    const {workspaceId} = useParams() as WorkspaceSwitchingPageParams;
    return <WorkspaceSwitchingPage workspaceName={workspaceId} />;
}
