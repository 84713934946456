import {useTranslation} from 'react-i18next';
import useClasses from './AgentPauseDialog.styles';
import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Body1,
} from '@fluentui/react-components';
import {DismissIcon} from '@/components/ui/icons';
import {Agent} from '@/api/agents';

export interface AgentPauseDialogProps {
    agent: Agent | undefined;
    open: boolean;
    onClose: () => void;
    onPause: () => void;
}

export default function AgentPauseDialog(props: AgentPauseDialogProps) {
    const {agent, open, onClose, onPause} = props;
    const classes = useClasses();
    const {t: tAgents} = useTranslation('agents');

    if (!agent) return null;

    return (
        <Dialog
            open={open}
            onOpenChange={(event, data) => {
                if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                    onClose();
                }
            }}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle
                        action={
                            <Button
                                appearance="subtle"
                                icon={<DismissIcon />}
                                onClick={onClose}
                                data-testid={'agent-pause-close-button'}
                            />
                        }
                    >
                        {`${tAgents('Agent.PauseDialog.Pause')} ${agent.displayName}?`}
                    </DialogTitle>
                    <DialogContent>
                        <Body1>{tAgents('Agent.PauseDialog.Description')}</Body1>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onClose}
                            className={classes.actionButton}
                            data-testid="agent-pause-cancel-button"
                        >
                            {tAgents('Agent.PauseDialog.Cancel')}
                        </Button>
                        <Button
                            appearance="primary"
                            className={classes.actionButton}
                            data-testid="agent-pause-dialog-button"
                            onClick={onPause}
                        >
                            {tAgents('Agent.PauseDialog.Pause')}
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
