import {Session} from '@/api/sessions';
import {EvaluationSkillInvocation, EvaluationSkillInvocationState} from '@/api/evaluations';
import {Prompt} from '@/api/prompts';
import {formatLocaleDate} from '@/util/locale';
import type {Node, Edge, StatusLabelStatus, AgentTaskNodeFooterProps} from '@sfe/react';

const statusMap: Record<EvaluationSkillInvocationState, StatusLabelStatus> = {
    Completed: 'success',
    Created: 'success',
    Failed: 'failed',
    InvocationCompleted: 'success',
    InvocationStarted: 'inProgress',
    Unknown: 'unknown',
};

export const convertToNodesAndEdges = (
    session: Session,
    prompts: {value: Prompt[]},
    evaluationsSkillInvocations: {data: {value: EvaluationSkillInvocation[]}}[],
    expandNode: (id: string) => void,
): {nodes: Node[]; edges: Edge[]} => {
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    if (session) {
        const sessionNode: Node = {
            id: session.sessionId,
            position: {x: 0, y: 0},
            style: {opacity: 0},
            data: {
                header: {
                    persona: {
                        primaryText: 'Session',
                        secondaryText: session.sessionId,
                    },
                },
                body: {
                    primaryText: session.name,
                    secondaryText:
                        session.createdAt &&
                        formatLocaleDate(session.createdAt, 'MMM dd yyyy, hh:mm a'),
                },
                footer: {
                    status: {icon: null},
                } as AgentTaskNodeFooterProps,
                Session: session.name,
                'Session ID': session.sessionId,
            },
            type: 'agentTaskNode',
        };

        nodes.push(sessionNode);

        prompts?.value?.forEach((prompt: Prompt, index: number) => {
            // Shorten the content to 110 characters and add ellipsis if it exceeds that length
            const truncatedContent =
                prompt.content && prompt.content.length > 110
                    ? `${prompt.content.substring(0, 110)}...`
                    : prompt.content ?? '';

            const promptNode: Node = {
                id: `prompt-${prompt.promptId}`,
                position: {x: 0, y: 0},
                style: {opacity: 0},
                data: {
                    header: {
                        persona: {primaryText: prompt.promptType, secondaryText: prompt.promptId},
                    },
                    body: {
                        primaryText:
                            prompt.promptType === 'Prompt' ? truncatedContent : prompt.skillName,
                        secondaryText:
                            prompt.createdAt &&
                            formatLocaleDate(prompt.createdAt, 'MMM dd yyyy, hh:mm a'),
                    },
                    footer: {
                        status: {icon: null},
                    },
                    [`Prompt ${index + 1} ID`]: prompt.promptId,
                    [`Prompt ${index + 1} Input`]: prompt?.inputs?.Input,
                },
                type: 'agentTaskNode',
            };

            nodes.push(promptNode);

            if (prompt.latestEvaluationId) {
                promptNode.data[`Evaluation ${index + 1} ID`] = prompt.latestEvaluationId;

                const allInvocations = evaluationsSkillInvocations.flatMap(
                    (invocationGroup) => invocationGroup.data?.value || [],
                );

                const filteredInvocations = allInvocations?.filter(
                    (invocation: EvaluationSkillInvocation) =>
                        invocation.promptId === prompt.promptId,
                );

                const defaultAgentOrchestrator = 'DefaultAgentOrchestrator';

                filteredInvocations.forEach(
                    (invocation: EvaluationSkillInvocation, idx: number) => {
                        const invocationId = `invocation-${invocation.skillInvocationId}`;

                        // Check if the skill name is 'DefaultAgentOrchestrator' and merge with parent if true
                        if (
                            invocation.skillName === defaultAgentOrchestrator &&
                            invocation.parentSkillInvocationId
                        ) {
                            const parentNode = nodes.find(
                                (node) =>
                                    node.id === `invocation-${invocation.parentSkillInvocationId}`,
                            );
                            if (parentNode) {
                                parentNode.data['Skill Inputs'] = JSON.stringify(
                                    invocation.skillInputs,
                                    null,
                                    2,
                                );
                                parentNode.data['Skill Invocation ID'] =
                                    invocation.skillInvocationId;
                                parentNode.data['Skill Output'] = invocation.skillOutput;
                                parentNode.data['Exception'] = invocation.exception;

                                // Redirect edges from children of DefaultAgentOrchestrator to its parent
                                filteredInvocations.forEach((childInvocation) => {
                                    if (
                                        childInvocation.parentSkillInvocationId ===
                                        invocation.skillInvocationId
                                    ) {
                                        edges.push({
                                            id: `edge-${parentNode.id}-invocation-${childInvocation.skillInvocationId}`,
                                            style: {opacity: 0},
                                            source: parentNode.id,
                                            target: `invocation-${childInvocation.skillInvocationId}`,
                                            type: 'edge',
                                        });

                                        // Update the source node to include the expand/collapse button
                                        const sourceNode = nodes.find(
                                            (node) => node.id === parentNode.id,
                                        );
                                        const sourceNodeFooter = sourceNode?.data
                                            .footer as AgentTaskNodeFooterProps;

                                        if (sourceNode && !sourceNodeFooter?.actionButton) {
                                            sourceNode.data.footer = {
                                                ...(sourceNodeFooter || {}),
                                                actionButton: {
                                                    action: 'collapse',
                                                    children: 'Collapse',
                                                    onClick: () => {
                                                        expandNode(parentNode.id);
                                                    },
                                                },
                                            };
                                            sourceNode.data.expanded = true;
                                        }
                                    }
                                });
                            }
                        } else if (invocation.skillName !== 'GetSummaryTitleAndTags') {
                            nodes.push({
                                id: invocationId,
                                position: {x: 0, y: 0},
                                style: {opacity: 0},
                                data: {
                                    header: {
                                        persona: {
                                            primaryText: invocation.skillsetName,
                                            secondaryText: invocation.skillInvocationId,
                                        },
                                    },
                                    body: {
                                        primaryText: invocation.skillName,
                                        secondaryText:
                                            invocation.createdAt &&
                                            formatLocaleDate(
                                                invocation.createdAt,
                                                'MMM dd yyyy, hh:mm a',
                                            ),
                                    },
                                    footer: {
                                        status: {
                                            status: statusMap[invocation.state],
                                            text: invocation.state,
                                        },
                                    },
                                    'Skillset Name': invocation.skillsetName,
                                    'Skill Name': invocation.skillName,
                                    'Skill Invocation ID': invocation.skillInvocationId,
                                    'Parent Skill Invocation ID':
                                        invocation.parentSkillInvocationId,
                                    'Skill Inputs': JSON.stringify(invocation.skillInputs, null, 2),
                                    'Skill Output': invocation.skillOutput,
                                    Exception: invocation.exception,
                                },
                                type: 'agentTaskNode',
                            });

                            // Create edge from parentSkillInvocationId if it exists, otherwise from promptNode
                            const sourceId = invocation.parentSkillInvocationId
                                ? `invocation-${invocation.parentSkillInvocationId}`
                                : promptNode.id;

                            const parentInvocation = filteredInvocations.find(
                                (inv) =>
                                    inv.skillInvocationId === invocation.parentSkillInvocationId,
                            );

                            if (
                                !parentInvocation ||
                                parentInvocation.skillName !== defaultAgentOrchestrator
                            ) {
                                edges.push({
                                    id: `edge-${sourceId}-${invocationId}`,
                                    style: {opacity: 0},
                                    source: sourceId,
                                    target: invocationId,
                                    type: 'edge',
                                });

                                // Update the source node to include the expand/collapse button
                                const sourceNode = nodes.find((node) => node.id === sourceId);
                                const sourceNodeFooter = sourceNode?.data
                                    .footer as AgentTaskNodeFooterProps;

                                if (sourceNode && !sourceNodeFooter?.actionButton) {
                                    sourceNode.data.footer = {
                                        ...(sourceNodeFooter || {}),
                                        actionButton: {
                                            action: 'collapse',
                                            children: 'Collapse',
                                            onClick: () => {
                                                expandNode(sourceId);
                                            },
                                        },
                                    };
                                    sourceNode.data.expanded = true;
                                }
                            }
                        }
                    },
                );
            }

            // Create edge from sessionNode to promptNode
            edges.push({
                id: `edge-${sessionNode.id}-${promptNode.id}`,
                style: {opacity: 0},
                source: sessionNode.id,
                target: promptNode.id,
                type: 'edge',
            });

            // Update the source node to include the expand/collapse button
            const sourceNode = nodes.find((node) => node.id === sessionNode.id);
            const sourceNodeFooter = sourceNode?.data.footer as AgentTaskNodeFooterProps;

            if (sourceNode && !sourceNodeFooter?.actionButton) {
                sourceNode.data.footer = {
                    ...(sourceNodeFooter || {}),
                    actionButton: {
                        action: 'collapse',
                        children: 'Collapse',
                        onClick: () => {
                            expandNode(sessionNode.id);
                        },
                    },
                };
                sourceNode.data.expanded = true;
            }
        });
    }

    return {nodes, edges};
};
