import useClasses from './Page.styles';
import {mergeClasses} from '@fluentui/react-components';

function Page({className, children, ...rest}: {className?: string; children: React.ReactNode}) {
    const classes = useClasses();
    return (
        <div className={mergeClasses(classes.root, Boolean(className) && className)} {...rest}>
            {children}
        </div>
    );
}

export default Page;
