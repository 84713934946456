import Preview from '../Badges/Preview';
import {PageHeaderProps} from './Page.types';
import useClasses from './PageHeader.styles';
import {Body1, Title1} from '@fluentui/react-components';

function PageHeader(props: PageHeaderProps) {
    const classes = useClasses();
    const {title, subtitle = '', isPreview = false} = props;

    return (
        <>
            <div className={classes.title}>
                <Title1>{title}</Title1>
                {isPreview && (
                    <div className={classes.badge}>
                        <Preview className={classes.badgeBackground} appearance="outline" />
                    </div>
                )}
            </div>
            <Body1 className={classes.subtitle}>{subtitle}</Body1>
        </>
    );
}

export default PageHeader;
