import {useFeatureFlag} from '@/api/user';
import {useTranslation} from 'react-i18next';
import useClasses from './UsageDashboard.styles';
import MedeinaFeatures from '@/util/features';
import {NumericDisplayEmphasis, SmallTitle} from '@/components/ui/Text';
import {WORKSPACE_USAGE} from './UsageDashboard.constants';
import {Link} from '@fluentui/react-components';
import {PAGE_LINKS} from '@/components/App.constants';
import {WarningIcon} from '@/components/ui/icons';
import {NONE} from '@/components/sections/admin/UsageDashboard/UsageDashboard.constants';

interface WorkspaceAllocationSectionProps {
    workspaceDisplayName: string;
}

export default function WorkspaceAllocationSection(props: WorkspaceAllocationSectionProps) {
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {t} = useTranslation('admin');

    const classes = useClasses();

    const MANAGE_WORKSPACES_MENU_BUTTON_IDENTIFIER =
        'ManageWorkspaces.MenuButtons.ManageWorkspaces';

    return (
        <div className={classes.metaData}>
            {isWorkspacesTestingEnabled && (
                <>
                    <div>
                        <SmallTitle>{t(WORKSPACE_USAGE)}</SmallTitle>
                    </div>
                    <div className={classes.capacityValueContainer}>
                        {props.workspaceDisplayName ? (
                            <NumericDisplayEmphasis>
                                {props.workspaceDisplayName}
                            </NumericDisplayEmphasis>
                        ) : (
                            <div className={classes.noWorkspace}>
                                <WarningIcon
                                    filled
                                    className={classes.icon}
                                    data-testid="warning-icon-no-workspace"
                                />
                                <NumericDisplayEmphasis>{t(NONE)}</NumericDisplayEmphasis>
                            </div>
                        )}
                    </div>
                    <Link href={PAGE_LINKS.MANAGE_WORKSPACES} data-testid="manage-workspaces-link">
                        {t(MANAGE_WORKSPACES_MENU_BUTTON_IDENTIFIER)}
                    </Link>
                </>
            )}
        </div>
    );
}
