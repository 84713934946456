import {useTranslation} from 'react-i18next';
import useClasses from './UsageDashboard.styles';
import {
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tab,
    TabList,
} from '@fluentui/react-components';
import {Capacity} from '@/api/capacities';
import {AddIcon, CheckmarkIcon, MoreIcon, WarningIcon} from '@/components/ui/icons';
import CreateCapacity from '@/components/CreateCapacity';
import React, {useEffect} from 'react';
import useIsCapacityExpired from '@/api/capacities/useIsCapacityExpired';

interface CapacityTabListProps {
    capacities: Capacity[];
    selectedCapacity: string;
    setSelectedCapacity: (capacity: string) => void;
    disconnectedCapacities: Capacity[];
    isCreateCapacityDialogOpen: boolean;
    setIsCreateCapacityDialogOpen: (isOpen: boolean) => void;
    newCapacityButtonRef: React.RefObject<HTMLButtonElement>;
}

export default function CapacityTabList(props: CapacityTabListProps) {
    const {t} = useTranslation('admin');
    const classes = useClasses();
    const isCapacityExpired = useIsCapacityExpired();
    const allTabs = props.capacities
        ?.filter((capacity) => !isCapacityExpired(capacity))
        .map((capacity: Capacity) => {
            if (!capacity) return null;
            return capacity.name;
        })
        .filter((name) => name !== null) as string[];
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [visibleTabs, setVisibleTabs] = React.useState<string[]>(allTabs);
    const [hiddenTabs, setHiddenTabs] = React.useState<string[]>([]);
    useEffect(() => {
        const handleResize = () => {
            if (!containerRef.current) return;

            const containerWidth = containerRef.current.offsetWidth;
            let totalWidth = 0;
            let newVisibleTabs: string[] = [];
            let newHiddenTabs: string[] = [];

            for (let tab of allTabs) {
                const tabWidth = 120;
                if (totalWidth + tabWidth < containerWidth - 240) {
                    newVisibleTabs.push(tab);
                    totalWidth += tabWidth;
                } else {
                    newHiddenTabs.push(tab);
                }
            }

            setVisibleTabs((prev) =>
                JSON.stringify(prev) === JSON.stringify(newVisibleTabs) ? prev : newVisibleTabs,
            );
            setHiddenTabs((prev) =>
                JSON.stringify(prev) === JSON.stringify(newHiddenTabs) ? prev : newHiddenTabs,
            );
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [allTabs, containerRef]);
    return (
        <div className={classes.TabListContainer} ref={containerRef}>
            <div className={classes.TabListRow}>
                <TabList
                    defaultSelectedValue={`tab-${props.selectedCapacity}`}
                    data-testid="capacity-tablist"
                    className={classes.tabList}
                    selectedValue={
                        hiddenTabs.includes(props.selectedCapacity!)
                            ? null
                            : `tab-${props.selectedCapacity}`
                    }
                >
                    {visibleTabs.map((tab, index) => {
                        const isDisconnected = props.disconnectedCapacities.some(
                            (c) => JSON.stringify(c.name) === JSON.stringify(tab),
                        );

                        return (
                            <Tab
                                key={index}
                                name={tab}
                                onClick={() => {
                                    props.setSelectedCapacity(tab);
                                }}
                                data-testid={`capacity-${tab}`}
                                value={`tab-${tab}`}
                            >
                                {isDisconnected && (
                                    <WarningIcon
                                        filled
                                        className={classes.icon}
                                        data-testid={`warning-icon-${tab}`}
                                    />
                                )}
                                {tab}
                            </Tab>
                        );
                    })}
                </TabList>
            </div>
            {hiddenTabs.length > 0 && (
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <Button
                            icon={<MoreIcon />}
                            appearance="subtle"
                            aria-label="more-capacities-button"
                        />
                    </MenuTrigger>
                    <MenuPopover className={classes.menuPopover}>
                        <MenuList>
                            {hiddenTabs.map((tab, index) => {
                                const isDisconnected = props.disconnectedCapacities.some(
                                    (c) => JSON.stringify(c.name) === JSON.stringify(tab),
                                );
                                return (
                                    <MenuItem
                                        key={index}
                                        onClick={() => props.setSelectedCapacity(tab)}
                                        className={isDisconnected ? classes.errorMenuButton : ''}
                                        icon={
                                            props.selectedCapacity === tab ? (
                                                <CheckmarkIcon />
                                            ) : (
                                                <div className={classes.fillerIcon} />
                                            )
                                        }
                                    >
                                        {isDisconnected
                                            ? tab +
                                              ' ' +
                                              t('ManageWorkspaces.MenuButtons.NotConnectedError')
                                            : tab}
                                    </MenuItem>
                                );
                            })}
                        </MenuList>
                    </MenuPopover>
                </Menu>
            )}
            <Button
                onClick={() => {
                    props.setIsCreateCapacityDialogOpen(true);
                }}
                icon={<AddIcon />}
                data-testid="new-capacity-button"
                appearance="transparent"
                ref={props.newCapacityButtonRef}
            >
                {t('ManageWorkspaces.MenuButtons.NewCapacity')}
            </Button>
            <CreateCapacity
                isCreateCapacityDialogOpen={props.isCreateCapacityDialogOpen}
                onCreateCapacityDialogClose={() => {
                    props.setIsCreateCapacityDialogOpen(false);
                    props.newCapacityButtonRef.current?.focus();
                }}
                hideWorkspaceSelectField={false}
            />
        </div>
    );
}
