import {makeStyles, tokens, shorthands} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        height: '100%',
        background: tokens.colorNeutralBackground2,
    },
    loading: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    content: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingHorizontalXXL,
        ...shorthands.margin(tokens.spacingHorizontalXXL),
    },
    container: {
        overflow: 'auto',
        ...shorthands.padding(tokens.spacingHorizontalS),
        display: 'flex',
        gap: tokens.spacingHorizontalL,
        '@media (max-width: 768px)': {
            flexDirection: 'column',
        },
    },
    wrapContainer: {
        flexDirection: 'column',
    },
});
