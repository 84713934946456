import {SpinButton} from '@fluentui/react-components';
import useClasses from './ProvisionCapacityPanel.styles';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Capacity, OverageState, useGetCapacityByName, usePatchCapacity} from '@/api/capacities';
import {GEO_DATA} from '@/components/App.constants';
import {GeoName} from '@/components/App.types';
import {useTranslation} from 'react-i18next';
import {ARIA_LABELS, CONTROL_LABELS, STATIC_CONTENT} from './ChangeCapacity.constants';
import formatCurrency from '@/util/currencyFormatterUtil';
import {AnnounceText} from '@/components/ui/AnnounceText';
import OveragePanel from './OveragePanel';
import {OVERAGE_SCU_COST} from '../UsageDashboard/UsageDashboard.constants';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import TrialMessageBar from './TrialMessageBar';
interface OverageUnitDetails {
    useOverage: boolean;
    unlimitedOverage: boolean;
    limitValue: number;
}
interface ProvisionCapacityPanelProps {
    isOverageEnabled?: boolean;
    overageUnitDetails: OverageUnitDetails;
    capacityToOverageDetails: OverageUnitDetails;
    onCapacityValueChanged: (value: number) => void;
    setOverageUnitDetails: (value: OverageUnitDetails) => void;
    setCapacityToOverageDetails: (value: OverageUnitDetails) => void;
    // note that geo is optional, but not including a value for geo will prevent the updating of estimated cost value
    geo?: string;
    capacity?: Capacity;
    capacityValue: number;
    minRequiredCapacity?: number;
    trialsEnabled?: boolean;
}

export default function ProvisionCapacityPanel(props: ProvisionCapacityPanelProps) {
    const classes = useClasses();
    const multiWorkspaceEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled) as boolean;
    const {t: tAdmin} = useTranslation('admin');
    let geo = props.geo ? GEO_DATA[props.geo.toUpperCase() as GeoName] : undefined;
    let perUnitCost = geo?.pricing ?? 4;
    const [minCapacityValue, setMinCapacityValue] = useState(props.minRequiredCapacity || 1);
    const [maxCapacityValue, setMaxCapacityValue] = useState(1000);
    if (!multiWorkspaceEnabled && props.capacityValue == 0) {
        props.onCapacityValueChanged(minCapacityValue);
    }
    useEffect(() => {
        if (props.geo) {
            geo = GEO_DATA[props.geo.toUpperCase() as GeoName];
            setMaxCapacityValue(geo.maxCapacity);
            if (multiWorkspaceEnabled) {
                if (props.minRequiredCapacity && props.minRequiredCapacity > 0) {
                    setMinCapacityValue(props.minRequiredCapacity);
                } else {
                    setMinCapacityValue(geo.minCapacity);
                }
            } else {
                setMinCapacityValue(1);
            }
        }
    }, [props.capacity, props.capacityValue, props.minRequiredCapacity, props.geo]);

    useEffect(() => {
        if (props.isOverageEnabled && props.capacity && Boolean(props.capacity.properties)) {
            props.setOverageUnitDetails({
                useOverage:
                    Boolean(props.capacity.properties.overageState) &&
                    props.capacity.properties.overageState !== OverageState.None,
                unlimitedOverage: props.capacity.properties.overageState === OverageState.Unlimited,
                limitValue: props.capacity.properties.overageAmount || 0,
            });
            props.setCapacityToOverageDetails({
                useOverage:
                    Boolean(props.capacity.properties.overageState) &&
                    props.capacity.properties.overageState !== OverageState.None,
                unlimitedOverage: props.capacity.properties.overageState === OverageState.Unlimited,
                limitValue: props.capacity.properties.overageAmount || 0,
            });
        }
    }, [props.capacity]);

    // Actions to take on close/dismiss of change capacity dialog
    const setSpinValue = useCallback(
        (event: any, data: any) => {
            if (data.value !== undefined) {
                props.onCapacityValueChanged(data.value);
            } else if (data.displayValue !== undefined) {
                const newValue = parseFloat(data.displayValue);
                if (
                    !Number.isNaN(newValue) &&
                    newValue >= minCapacityValue &&
                    newValue <= maxCapacityValue
                ) {
                    props.onCapacityValueChanged(newValue);
                }
            }
        },
        [props.onCapacityValueChanged],
    );
    const isGeoSelected = props.geo == '';
    return (
        <div>
            <TrialMessageBar shown={props.trialsEnabled ?? false} />
            <div className={classes.numericSection}>
                <div className={classes.spinButtonSection}>
                    <SpinButton
                        data-testid="capacity-units-spin-button"
                        min={minCapacityValue}
                        max={maxCapacityValue}
                        value={props.capacityValue}
                        onChange={setSpinValue}
                        className={classes.spinButton}
                        aria-label={tAdmin(ARIA_LABELS.CHANGE_CAPACITY_UNITS)}
                        disabled={isGeoSelected}
                    />
                </div>
                <div className={classes.estimatedMonthlyCostSection}>
                    <AnnounceText textProps={{as: 'span'}} ariaLive="polite">
                        <div className={classes.provisionedUnits}>
                            {tAdmin(STATIC_CONTENT.PROVISIONED_CAPACITY_HEADER)}
                        </div>
                        <div>
                            <span className={classes.costPerMonth}>
                                {tAdmin(STATIC_CONTENT.USD_UNITS, {cost: `$ ${perUnitCost}`})}
                                <span className={classes.perUnit}>
                                    {tAdmin(STATIC_CONTENT.PER_UNIT)}
                                </span>
                            </span>
                        </div>
                    </AnnounceText>
                </div>
            </div>

            {props.isOverageEnabled && (
                <div className={classes.changeCapacityOverageSection}>
                    <OveragePanel
                        needsOverageUnits={props.overageUnitDetails.useOverage}
                        unlimitedOverage={props.overageUnitDetails.unlimitedOverage}
                        onOverageChange={(useOverage) => {
                            props.setOverageUnitDetails({
                                ...props.overageUnitDetails,
                                ...useOverage,
                            });
                        }}
                        limitValue={props.overageUnitDetails.limitValue}
                        scuCost={OVERAGE_SCU_COST}
                    />
                </div>
            )}
        </div>
    );
}
