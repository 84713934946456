import {useTranslation} from 'react-i18next';
import useClasses from './AgentsLibrary.styles';
import AgentsPreviewMessage from './AgentsPreviewMessage';
import {Spinner} from '@fluentui/react-components';
import {AgentScopeTypes, useGetAgentDefinitions, useGetAgents, Agent} from '@/api/agents';
import {Page, PageHeader} from '@/components/ui/Page';
import AgentLibraryCard from './AgentLibraryCard';
import {useEffect, useState} from 'react';
import {EmptyState, EmptyStateIllustration} from '@sfe/react';

export default function AgentsLibrary() {
    const classes = useClasses();
    const {t: tAgents} = useTranslation('agents');
    const [agentsMap, setAgentsMap] = useState<Map<string, Agent | undefined>>(new Map());

    const {
        data: agentDefinitions,
        isLoading: agentsLoading,
        isError: getAllAgentsFailed,
    } = useGetAgentDefinitions({scope: AgentScopeTypes.WORKSPACE});

    // Get all agents in one query instead of doing separate queries for each definition
    const {data: agentsData, isLoading: agentsStatusLoading} = useGetAgents({
        scope: AgentScopeTypes.WORKSPACE,
    });

    // Update the map whenever agentsData changes
    useEffect(() => {
        if (agentsData?.values) {
            const map = new Map<string, Agent | undefined>();

            agentsData.values.forEach((agent: Agent) => {
                // For PrP, assume there will be only one agent instance per definition
                if (map.get(agent.agentDefinitionName) === undefined) {
                    map.set(agent.agentDefinitionName, agent);
                }
            });

            setAgentsMap(map);
        }
    }, [agentsData]);

    return (
        <>
            <AgentsPreviewMessage />
            <Page data-testid="agents-library-page">
                <PageHeader
                    title={tAgents('AgentLibrary.Title')}
                    subtitle={tAgents('AgentLibrary.Subtitle')}
                    isPreview={true}
                />

                {(agentsLoading || agentsStatusLoading) && (
                    <div className={classes.loadingbox}>
                        <Spinner
                            labelPosition="below"
                            label={tAgents('AgentLibrary.LoadingAgentDefinitions')}
                            data-testid="agents-loading-spinner"
                        />
                    </div>
                )}
                {!agentsLoading && !agentsStatusLoading && agentDefinitions && (
                    <div className={classes.gridContainer}>
                        {agentDefinitions.length === 0 && (
                            <EmptyState
                                className={classes.emptyStateContainer}
                                data-testid="agents-library-empty-state"
                                heading={tAgents('AgentLibrary.Empty')}
                                image={<EmptyStateIllustration illustrationType="noResults" />}
                                actions={null}
                            />
                        )}
                        {agentDefinitions.length > 0 &&
                            agentDefinitions.map((agentDefinition, index) => (
                                <AgentLibraryCard
                                    agentDefinition={agentDefinition}
                                    agent={agentsMap.get(agentDefinition.name)}
                                    key={index}
                                />
                            ))}
                    </div>
                )}
            </Page>
        </>
    );
}
