import {CreatePromptbookRequest, PromptbookPrompts} from '@/api/promptbooks';
import {useState} from 'react';
import {EMPTY_PROMPTBOOK_PROMPT_CONTENT, FormErrors} from './Promptbook.types';
import {uniq} from 'lodash-es';
import {useTranslation} from 'react-i18next';
import {PromptType} from '@/api/prompts';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {SkillInputDescriptor} from '@/api/skills';

export default function useCreatePromptbookFormValidations(
    promptbookCreateRequest: CreatePromptbookRequest | undefined,
) {
    const {t} = useTranslation('promptbooks');
    const [errors, setErrors] = useState<FormErrors>({});
    const isDirectSkillInvocationEnabled = useFeatureFlag(
        MedeinaFeatures.EnableDirectSkillsInPromptbook,
    );

    function validateForm() {
        let newErrors: FormErrors = {};

        // Perform validation
        if (!promptbookCreateRequest) {
            newErrors['promptbookCreateRequest'] = t(
                'CreatePromptbookFormValidations.CreateRequestRequired',
            );
        } else {
            if (!promptbookCreateRequest.name) {
                newErrors['name'] = t('CreatePromptbookFormValidations.NameRequired');
            }

            if (!promptbookCreateRequest.description) {
                newErrors['description'] = t('CreatePromptbookFormValidations.DescriptionRequired');
            }

            if (!promptbookCreateRequest.prompts || promptbookCreateRequest.prompts.length === 0) {
                newErrors['prompts'] = t('CreatePromptbookFormValidations.PromptsRequired');
            }

            // Check if the prompts have text in it or are blank prompts (or default prompts)
            const blankOrDefaultPrompts = promptbookCreateRequest.prompts.filter((p) => {
                // For prompts of type 'skill', check if the skill and skill inputs are missing
                if (p.promptType === PromptType.Skill) {
                    return !p.plugins || !p.skillName;
                }
                if (!p) return true;
                // For prompts of type 'prompt', check if content is missing or empty
                if (p.promptType === PromptType.Prompt) {
                    return !p.content || p.content === t(EMPTY_PROMPTBOOK_PROMPT_CONTENT);
                }
                return false;
            });

            if (blankOrDefaultPrompts.length > 0) {
                newErrors['prompts'] = t(`CreatePromptbookFormValidations.EmptyPrompts`);
            }

            // Check for input parameter usage in prompts.
            const params = getParamsFromPrompts(promptbookCreateRequest.prompts);

            if (!isDirectSkillInvocationEnabled) {
                // Check if all inputs are used in prompts.
                promptbookCreateRequest.promptbookinputs.forEach((input) => {
                    if (!params || params.length === 0 || !params.includes(input.name)) {
                        newErrors['promptbookinputs'] = t(
                            'CreatePromptbookFormValidations.InputNotUsed',
                            {inputName: input.name},
                        );
                    }
                });

                // Check if all parameters are declared in inputs.
                if (params && params.length > 0) {
                    params.forEach((param) => {
                        if (
                            !promptbookCreateRequest.promptbookinputs
                                .map((i) => i.name)
                                .includes(param)
                        ) {
                            newErrors['promptbookinputs'] = t(
                                'CreatePromptbookFormValidations.InputNotDeclared',
                                {param},
                            );
                        }
                    });
                }
            }
        }

        setErrors(newErrors);

        return newErrors && Object.keys(newErrors).length === 0;
    }

    function getParamsFromPrompts(prompts: PromptbookPrompts[]) {
        const inputsRegEx: RegExp = new RegExp(/<([a-zA-Z0-9_]+)>/, 'g');

        const tempPromptsParamsList: string[][] = prompts
            .filter((p) => p! && p.content)
            .map((prompt) => {
                let match;
                const allMatches = [];

                while ((match = inputsRegEx.exec(prompt.content)) !== null) {
                    allMatches.push(match[1]);
                }

                return allMatches;
            });
        let promptParameters =
            tempPromptsParamsList && tempPromptsParamsList.length > 0
                ? tempPromptsParamsList.flat()
                : [];
        promptParameters = uniq(promptParameters);

        //Extract skill inputs from skill prompts to display as parameters
        const skillParameterPrompts: PromptbookPrompts[] = prompts.filter(
            (p) =>
                p.promptType === PromptType.Skill &&
                p.skillInputDescriptors?.length !== 0 &&
                (!p.content || p.content.trim() === ''),
        );
        const skillPromptInputs: SkillInputDescriptor[] = skillParameterPrompts.flatMap(
            (prompt) => prompt.skillInputDescriptors ?? [],
        );
        const skillPromptInputsRequired = skillPromptInputs.filter(
            (skillInput) => skillInput.required,
        );
        const skillParameters: string[] = skillPromptInputsRequired.map(
            (skillInput) => skillInput.description ?? '',
        );
        if (!isDirectSkillInvocationEnabled) {
            return promptParameters;
        }
        // const params = parameters &&
        //     parameters.filter((p) => p !== null && p!.length > 0).map((p) => p![1]);
        return [...promptParameters, ...skillParameters];
    }

    return {
        validateForm,
        errors,
        isError: errors && Object.keys(errors).length > 0,
        getParamsFromPrompts,
    };
}
