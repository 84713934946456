import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import MedeinaVariables from '@/util/variables';
import {GetWorkspacesResponse} from '.';
import {
    DEFAULT_API_RETRY_COUNT,
    RESOURCE_SCOPES,
    WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
} from '../api.constants';
import useFetch from '../useFetch';
import securityGraphApi from '@/api/securityGraph.api';

//The idea to have this is that workspace invocations for a tenant
//will return 404s if the account isn't set up. Thus during the FRE there
//will be a need to suppress the 404
export default function useGetWorkspacesForAdminFre(
    useQueryOptions?: Pick<UseQueryOptions<GetWorkspacesResponse>, 'enabled' | 'refetchOnMount'>,
) {
    const {customFetch} = useFetch();
    const url = securityGraphApi({
        path: '/account/workspaces',
        version: '2023-12-01-preview',
        isGlobal: true,
    });

    const defaults: GetWorkspacesResponse = {
        value: [],
        count: 0,
    };

    return useQuery<GetWorkspacesResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['workspaces-presetup'],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async () => {
            const result = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                    headersFromOptions: null,
                    scopes: RESOURCE_SCOPES.FIDELIS,
                },
                true,
            );

            if (!result.ok && result.status === 404) {
                return defaults;
            }

            const data = await result.json();
            return data as GetWorkspacesResponse;
        },
        ...useQueryOptions,
    });
}
