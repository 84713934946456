import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingHorizontalL,
        flex: 1,
    },
    card: {
        minHeight: 'fit-content',
        ...shorthands.padding(tokens.spacingHorizontalL),
    },
    cardSection: {
        display: 'flex',
        flexDirection: 'row',
        gap: tokens.spacingHorizontalS,
        flex: 1,
    },
    cardSectionTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    permissionsList: {
        margin: 0,
        ...shorthands.padding(0, tokens.spacingHorizontalXL),
    },
});
