import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '316px',
        minWidth: '220px',
        ...shorthands.padding(0, tokens.spacingHorizontalM),
    },
    componentsTitle: {
        width: '100%',
        height: '100%',
        ...shorthands.padding(tokens.spacingVerticalS, 0),
        alignSelf: 'stretch',
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase400,
        borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
        maxWidth: '296px',
        maxHeight: '25px',
    },
    componentsLabel: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase300,
        color: tokens.colorNeutralForeground3,
        ...shorthands.padding(0, 0, '3px', '7px'),
    },
    createButton: {
        float: 'right',
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        minWidth: '59px',
        fontStyle: 'normal',
        backgroundColor: tokens.colorBrandBackground,
        color: tokens.colorNeutralStrokeOnBrand,
        ...shorthands.padding(
            tokens.spacingVerticalXXS,
            tokens.spacingHorizontalS,
            tokens.spacingVerticalXXS,
            tokens.spacingHorizontalXS,
        ),
    },
    addIcon: {
        fontSize: tokens.lineHeightBase200,
    },
    branches: {
        ...shorthands.padding(tokens.spacingVerticalSNudge, 0),
        flexDirection: 'row',
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase400,
        textOverflow: 'ellipsis',
        fontSize: tokens.lineHeightBase200,
    },
    leaves: {
        paddingLeft: tokens.spacingHorizontalXL,
        borderRadius: tokens.borderRadiusMedium,
    },
    currentComponent: {
        backgroundColor: tokens.colorNeutralBackground4Selected,
        borderLeft: `4px solid ${tokens.colorBrandStroke1}`,
        paddingLeft: tokens.spacingHorizontalL,
    },
    menuPopover: {
        width: '90%',
    },
    actionIcon: {
        fontSize: tokens.fontSizeBase100,
        fontWeight: tokens.fontWeightSemibold,
        ...shorthands.padding('1px', tokens.spacingVerticalXXS),
        backgroundColor: tokens.colorNeutralBackground5,
    },
});
