import React, {useMemo} from 'react';
import {useGetAgent, AgentScopeTypes} from '@/api/agents';

interface AgentBreadcrumbProps {
    agentId: string;
}

// Custom breadcrumb component for the agentId segment of the breadcrumb
function AgentBreadcrumb(props: AgentBreadcrumbProps) {
    const {agentId} = props;
    const {data: agent} = useGetAgent({agentId, requestParams: {scope: AgentScopeTypes.WORKSPACE}});

    const breadcrumb = useMemo(() => {
        return agent?.displayName ?? agentId;
    }, [agentId, agent]);

    return <>{breadcrumb}</>;
}

export default AgentBreadcrumb;
