/**
 * Component for rendering the left column of the data display section of the usage dashboard.
 *
 * @component
 * @param {LeftColumnProps} props - The properties for the LeftColumn component.
 * @param {boolean} props.isLimitedOverageState - Indicates if the overage state is limited.
 * @param {OverageCapacityDisplayData} props.usageDisplayDetails - The details of the usage display.
 * @param {WorkspaceDisplayDetails} props.workspaceDetails - The details of the workspace.
 * @param {UsageParametersDisplayConfiguration} props.displayConfiguration - The configuration for displaying usage parameters.
 *
 * @returns {JSX.Element} The rendered LeftColumn component.
 */
import useClasses from './UsageParameterDisplay.styles';
import {useViewportSize} from '@/components/ui/Grid';
import {useMemo} from 'react';
import {
    OverageCapacityDisplayData,
    WorkspaceDisplayDetails,
    UsageParametersDisplayConfiguration,
} from '../UsageDashboard.types';
import OverageUnitsUsed from './OverageUnitsUsed';
import {MultiColumnConfigurator} from './parameterDisplayConfigurators/multiColumnConfigurator';
import {SingleColumnConfigurator} from './parameterDisplayConfigurators/singleColumnConfigurator';
import {SingleRowConfigurator} from './parameterDisplayConfigurators/singleRowConfigurator';
import ProvisionedUnits from './ProvisionedUnitsUsed';
import TotalOverageUnitsUsed from './TotalOverageUnitsUsed';
import WorkspaceDetails from './WorkspaceDetails';

interface LeftColumnProps {
    isLimitedOverageState: boolean;
    usageDisplayDetails: OverageCapacityDisplayData;
    workspaceDetails: WorkspaceDisplayDetails;
    displayConfiguration: UsageParametersDisplayConfiguration;
}

export default function LeftColumn(props: LeftColumnProps) {
    const {isLimitedOverageState, usageDisplayDetails, displayConfiguration, workspaceDetails} =
        props;
    const {isXXLargeScreen, isXXXLargeScreen, isLargeScreen, isXLargeScreen} = useViewportSize();

    const classes = useClasses();
    const singleColumnConfigurator = useMemo(
        () => new SingleColumnConfigurator(classes),
        [classes],
    );
    const multiColumnConfigurator = useMemo(() => new MultiColumnConfigurator(classes), [classes]);
    const singleRowConfigurator = useMemo(() => new SingleRowConfigurator(classes), [classes]);

    const elements = useMemo(() => {
        let elements: JSX.Element[] = [];
        elements.push(
            <ProvisionedUnits
                showUnitValues={!props.displayConfiguration.hideAll}
                provisionedUnitsAvailable={usageDisplayDetails.provisionedUnitsAvailable}
                provisionedUnitsUsed={usageDisplayDetails.provisionedUnitsUsed}
            />,
        );

        if (isLimitedOverageState) {
            elements.push(
                <OverageUnitsUsed
                    overageUnitsLimit={usageDisplayDetails.overageUnitsLimit}
                    overageUnitsUsed={usageDisplayDetails.overageUnitsUsed}
                />,
            );
        }

        if (displayConfiguration.enableBillingInCurrentHour) {
            elements.push(
                <TotalOverageUnitsUsed
                    overageInCurrentBilingPeriod={usageDisplayDetails.overageInCurrentBilingPeriod}
                />,
            );
        }

        if (!workspaceDetails.hideWorkspaceDetails) {
            elements.push(<WorkspaceDetails name={props.workspaceDetails.name} />);
        }

        return elements;
    }, [
        props.displayConfiguration.hideAll,
        usageDisplayDetails.provisionedUnitsAvailable,
        usageDisplayDetails.provisionedUnitsUsed,
        isLimitedOverageState,
        usageDisplayDetails.overageUnitsLimit,
        usageDisplayDetails.overageUnitsUsed,
        displayConfiguration.enableBillingInCurrentHour,
        usageDisplayDetails.overageInCurrentBilingPeriod,
        workspaceDetails.hideWorkspaceDetails,
        props.workspaceDetails.name,
    ]);

    let elementsToRender: JSX.Element[] = useMemo(() => {
        let elementsToRender: JSX.Element[] = [];
        if (elements.length === 2) {
            if (isXXLargeScreen || isXLargeScreen) {
                elementsToRender =
                    singleRowConfigurator.generateStructuredUsageParameterElements(elements);
            } else {
                elementsToRender =
                    multiColumnConfigurator.generateStructuredUsageParameterElements(elements);
            }
        } else {
            if (isXXXLargeScreen) {
                elementsToRender =
                    singleRowConfigurator.generateStructuredUsageParameterElements(elements);
            } else if (isXXLargeScreen || isLargeScreen || isXLargeScreen) {
                elementsToRender =
                    multiColumnConfigurator.generateStructuredUsageParameterElements(elements);
            } else {
                elementsToRender =
                    singleColumnConfigurator.generateStructuredUsageParameterElements(elements);
            }
        }

        return elementsToRender;
    }, [
        elements,
        isXXLargeScreen,
        isXLargeScreen,
        isXXXLargeScreen,
        isLargeScreen,
        singleRowConfigurator,
        multiColumnConfigurator,
        singleColumnConfigurator,
    ]);

    return (
        <>
            {!props.displayConfiguration.hideAll && (
                <div className={classes.leftContainerRoot}>
                    {elementsToRender.map((element) => (
                        <>{element}</>
                    ))}
                </div>
            )}
        </>
    );
}
