import {useState} from 'react';
import {ErrorIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import {Link, Tooltip} from '@fluentui/react-components';
import {useGetTenantInfo} from '@/api/tenant';
import {CustomSwitchTransition} from '../ui/Switch/switch.types';
import ProgressiveSwitch from '../ui/Switch';
import useClasses from './AuditLoggingOptingSwitch.styles';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {usePatchTenantInfo} from '@/api/tenant';
import {useGetUserInfo} from '@/api/app';

interface OrganizationDataProps {
    includeInfoLabel: boolean;
    isDisabled?: boolean;
}

export default function AuditLoggingOptingSwitch(props: OrganizationDataProps) {
    const classes = useClasses();
    const {data: tenantInfo} = useGetTenantInfo();
    const [errorTagForAuditLoggingConsent, setErrorTagForAuditLoggingConsent] =
        useState<boolean>(false);
    const [updateAuditLoggingConsent, setAuditLoggingConsent] = useState<CustomSwitchTransition>({
        isChanging: false,
        isChangeSuccessful: false,
        hasChangeFailed: false,
    });
    const {data: userInfo} = useGetUserInfo();
    const isAdmin = userInfo?.isAdmin ?? false;
    const isGlobalAdmin = userInfo?.isGlobalAdmin ?? false;
    const isAuditLoggingEnabled = useFeatureFlag(MedeinaFeatures.AuditLoggingOptingEnabled);
    const needToDisableAuditLoggingOptingToggle = isAuditLoggingEnabled ? !isAdmin : !isGlobalAdmin;
    const isDSPMTestingEnabled = useFeatureFlag(MedeinaFeatures.DSPMAuditLoggingOptingEnabled);

    const {t} = useTranslation('admin');

    const {mutate: patchTenantInfo} = usePatchTenantInfo();
    const handleAuditLoggingOptinChange = async (allowAuditLoggingEvent: boolean) => {
        setAuditLoggingConsent({
            isChanging: true,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
        patchTenantInfo(
            {
                ...tenantInfo,
                allowAuditLogging: allowAuditLoggingEvent,
            },
            {
                onSuccess: () => {
                    setAuditLoggingConsent({
                        isChanging: false,
                        isChangeSuccessful: true,
                        hasChangeFailed: false,
                    });
                    setErrorTagForAuditLoggingConsent(false);
                },
                onError: () => {
                    setAuditLoggingConsent({
                        isChanging: false,
                        isChangeSuccessful: false,
                        hasChangeFailed: true,
                    });
                    setErrorTagForAuditLoggingConsent(true);
                },
            },
        );
    };

    const renderSwitch = () => (
        <div>
            <ProgressiveSwitch
                className={classes.switch}
                data-test-id="audit-logging-enable-switch"
                label={
                    props.includeInfoLabel ? (
                        <div>
                            <MedeinaInfoLabel
                                labelContent={t(
                                    'ownerSettings.auditLoggingOpting.AllowMicrosoftToggleText',
                                )}
                                infoContent={
                                    isDSPMTestingEnabled ? (
                                        <>
                                            {t(
                                                'ownerSettings.auditLoggingOpting.DSPMAllowMicrosoftTooltip',
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {t(
                                                'ownerSettings.auditLoggingOpting.AllowMicrosoftTooltip',
                                            ) + ' '}
                                            <Link
                                                href={MedeinaVariables.ProvisioningAuditLogging}
                                                target="_blank"
                                            >
                                                {t(
                                                    'ownerSettings.auditLoggingOpting.LearnMoreProvisioningLinkText',
                                                )}
                                            </Link>
                                        </>
                                    )
                                }
                                defaultButtonWrap={true}
                            ></MedeinaInfoLabel>
                        </div>
                    ) : (
                        t('ownerSettings.auditLoggingOpting.AllowMicrosoftToggleText')
                    )
                }
                labelPosition="before"
                aria-label={t('ownerSettings.auditLoggingOpting.ToggleAriaLabel')}
                checked={tenantInfo?.allowAuditLogging ?? false}
                onClick={(ev) => handleAuditLoggingOptinChange(ev)}
                state={updateAuditLoggingConsent}
                disabled={needToDisableAuditLoggingOptingToggle || props.isDisabled}
            ></ProgressiveSwitch>
        </div>
    );

    return (
        <>
            {needToDisableAuditLoggingOptingToggle ? (
                <Tooltip
                    content={t('ownerSettings.auditLoggingOpting.DisabledStateTooltip')}
                    relationship="label"
                >
                    {renderSwitch()}
                </Tooltip>
            ) : (
                renderSwitch()
            )}
            {errorTagForAuditLoggingConsent && (
                <div>
                    <div className={classes.errorTag}>
                        <span>
                            <ErrorIcon filled />
                        </span>
                        <span>{t('ownerSettings.organizationData.ErrorMessage')}</span>
                    </div>
                </div>
            )}
        </>
    );
}
