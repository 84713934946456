import {TourControls, TourStageContainer, useTourContext} from '@/components/ui/Tour';
import {Body1Strong, Link, mergeClasses} from '@fluentui/react-components';
import {InvestigationsIcon} from '@/components/ui/icons';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import useClasses from './AuditLoggingConsent.styles';
import {useRef} from 'react';
import {motion} from 'framer-motion';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {useTranslation} from 'react-i18next';
import {TextH1} from '@/components/ui/Text';
import MedeinaVariables from '@/util/variables';
import AuditLoggingOptingSwitch from '@/components/admin/AuditLoggingOptingSwitch';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {PurviewStatusWarningMessageBar} from './PurviewStatusWarningMessageBar';
import useGetAuditStatus from '@/api/user/useGetAuditStatus';

export default function AuditLoggingConsent() {
    const tour = useTourContext();
    const tourClasses = useTourClasses();
    const classes = useClasses();
    const {t} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const isDSPMTestingEnabled = useFeatureFlag(MedeinaFeatures.DSPMAuditLoggingOptingEnabled);
    const {data: auditStatus} = useGetAuditStatus({enabled: Boolean(isDSPMTestingEnabled)});
    const isDisabled = isDSPMTestingEnabled && auditStatus?.status !== 'Enabled';

    const auditLoggingInfoRootRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <motion.div
                key="audit-logging-info"
                variants={overlayAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <TourStageContainer ref={auditLoggingInfoRootRef}>
                    <TourStageContainer.StageContent>
                        <div className={mergeClasses(classes.icon)}>
                            <InvestigationsIcon />
                        </div>
                        <div className={mergeClasses(tourClasses.tourSectionSpacing)}>
                            <TextH1 data-testid="audit-logging-consent-title">
                                {t('adminV2Fre.FREAuditLoggingOpting.Title')}
                            </TextH1>
                        </div>
                        {isDisabled && (
                            <div className={classes.warningMessageBar}>
                                <PurviewStatusWarningMessageBar
                                    title={t('adminV2Fre.FREAuditLoggingOpting.ErrorStatusTitle')}
                                    description={t(
                                        'adminV2Fre.FREAuditLoggingOpting.ErrorStatusDescription',
                                    )}
                                />
                            </div>
                        )}
                        <div className={mergeClasses(tourClasses.tourXLargeSectionSpacing)}>
                            <div
                                className={classes.description}
                                data-testid="audit-logging-description"
                            >
                                {isDSPMTestingEnabled
                                    ? t('adminV2Fre.FREAuditLoggingOpting.DSPMDescription')
                                    : t('adminV2Fre.FREAuditLoggingOpting.Description')}{' '}
                                {isDSPMTestingEnabled && (
                                    <Link
                                        data-test-id="logging-security-copilot-data-link"
                                        href={MedeinaVariables.CfSAuditLogging}
                                        target="_blank"
                                    >
                                        {t('adminV2Fre.FREAuditLoggingOpting.LearnMoreLogging')}{' '}
                                    </Link>
                                )}
                            </div>
                            <Body1Strong role="heading">
                                {t('adminV2Fre.FREAuditLoggingOpting.StorageSubheading')}
                            </Body1Strong>
                            <div className={classes.description}>
                                {isDSPMTestingEnabled
                                    ? t('adminV2Fre.FREAuditLoggingOpting.DSPMStorageDescription')
                                    : t('adminV2Fre.FREAuditLoggingOpting.StorageDescription')}{' '}
                                <Link href={MedeinaVariables.DataResidency} target="_blank">
                                    {t('adminV2Fre.FREAuditLoggingOpting.LearnMoreDataResidency')}
                                </Link>
                            </div>
                            <Body1Strong role="heading">
                                {t('adminV2Fre.FREAuditLoggingOpting.SetupSubheading')}
                            </Body1Strong>
                            <div className={classes.description}>
                                {isDSPMTestingEnabled
                                    ? t('adminV2Fre.FREAuditLoggingOpting.DSPMSetupDescription')
                                    : t('adminV2Fre.FREAuditLoggingOpting.SetupDescription')}{' '}
                                {isDSPMTestingEnabled ? (
                                    <Link
                                        href={MedeinaVariables.ProvisioningAuditLogging}
                                        target="_blank"
                                    >
                                        {t(
                                            'adminV2Fre.FREAuditLoggingOpting.LearnMorePurviewAuditShorter',
                                        )}
                                    </Link>
                                ) : (
                                    <Link
                                        href={MedeinaVariables.ProvisioningAuditLogging}
                                        target="_blank"
                                    >
                                        {t(
                                            'adminV2Fre.FREAuditLoggingOpting.LearnMoreProvisioning',
                                        )}
                                    </Link>
                                )}
                            </div>
                            {isDSPMTestingEnabled && (
                                <>
                                    <Body1Strong role="heading">
                                        {t('adminV2Fre.FREAuditLoggingOpting.DSPMSubheading')}
                                    </Body1Strong>
                                    <div className={classes.description}>
                                        {t('adminV2Fre.FREAuditLoggingOpting.DescriptionDSPM')}{' '}
                                        <Link
                                            href={MedeinaVariables.DSPMAuditLogging}
                                            target="_blank"
                                        >
                                            {t('adminV2Fre.FREAuditLoggingOpting.LearnMoreDSPM')}
                                        </Link>
                                    </div>
                                </>
                            )}
                            <div className={tourClasses.switchLayout}>
                                <AuditLoggingOptingSwitch
                                    includeInfoLabel={false}
                                    isDisabled={isDisabled || false}
                                />
                            </div>
                            <div className={classes.description}>
                                {isWorkspacesTestingEnabled
                                    ? t('adminV2Fre.FREAuditLoggingOpting.WorkspacesYouCanChange')
                                    : t('adminV2Fre.FREAuditLoggingOpting.YouCanChange')}
                            </div>
                        </div>
                    </TourStageContainer.StageContent>
                    <TourStageContainer.FooterContent>
                        <TourControls
                            spanFullScreen={true}
                            handlePrevious={tour.prevStop}
                            handleNext={tour.nextStop}
                            previousButton={commonLabel('Back')}
                            nextButton={isDisabled ? commonLabel('Skip') : commonLabel('Continue')}
                            showPrevious={!tour.currentStop?.isFirstStop}
                        ></TourControls>
                    </TourStageContainer.FooterContent>
                </TourStageContainer>
            </motion.div>
        </>
    );
}
