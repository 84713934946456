import {useState} from 'react';
import useClasses from '../ManageWorkspaces.styles';
import {TableCellLayout, TableCellActions} from '@fluentui/react-components';
import Highlighter from 'react-highlight-words';
import WorkspaceContextMenu from '../WorkspaceContextMenu';
import {Workspace} from '@/api/workspaces';

interface WorkspaceColumnProps {
    workspace: Workspace;
    onDelete?: () => void;
    onDuplicate?: () => void;
}
export const WorkspaceColumn: React.FC<WorkspaceColumnProps> = ({
    workspace,
    onDelete,
    onDuplicate,
}) => {
    const classes = useClasses();
    const [searchText, setSearchText] = useState<string>('');
    return (
        <>
            <TableCellLayout className={classes.overflowNameContent} data-testid="workspace-name">
                <Highlighter
                    highlightClassName={classes.highlight}
                    searchWords={[searchText]}
                    textToHighlight={workspace.name}
                />
            </TableCellLayout>
            <TableCellActions
                onClick={(e: {preventDefault: () => void; stopPropagation: () => void}) => {
                    // We do not want row selection when the table-cell-action button is pressed.
                    e.preventDefault();
                    e.stopPropagation();
                }}
                aria-haspopup="true"
            >
                <WorkspaceContextMenu
                    workspace={workspace}
                    onDelete={onDelete}
                    onDuplicate={onDuplicate}
                />
            </TableCellActions>
        </>
    );
};
