import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {
    DEFAULT_API_RETRY_COUNT,
    RESOURCE_SCOPES,
    WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
} from '../api.constants';
import securityGraphApi from '../securityGraph.api';
import useFetch from '../useFetch';
import {Workspace} from './workspaces.types';

/** Iterate over all workspaces user has access to and check if user is
 * * owner of any workspace
 * * owner of current workspace
 * and returns the list of workspaces user owns
 */
interface WorkspacesOwnerCheckResponse {
    ownerOfAWorkspace?: boolean;
    ownerOfSelectedWorkspace?: boolean;
    ownedWorkspaces: string[];
}

interface WorkspacesResponse {
    'Purview:Workspace'?: {
        'Microsoft.SecurityPlatform/workspaces/write'?: {
            collections: string[];
            type: string;
        };
    };
}

function useWorkspacesOwnerCheck(
    workspaceName?: string,
    workspaces?: Workspace[],
    useQueryOptions?: Pick<
        UseQueryOptions<WorkspacesOwnerCheckResponse>,
        'enabled' | 'refetchOnMount'
    >,
) {
    const {customFetch} = useFetch();

    return useQuery<WorkspacesOwnerCheckResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['workspaces', 'workspacesOwnerCheck', workspaceName],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async (): Promise<WorkspacesOwnerCheckResponse> => {
            let ownerOfSelectedWorkspace = false;
            let ownerOfAWorkspace = false;
            let ownedWorkspaces: string[] = [];

            const getUrl = () => {
                return securityGraphApi({
                    path: '/api/gateway/actions/containers/me',
                    isGlobal: true,
                });
            };

            let response = await customFetch<WorkspacesResponse>(getUrl(), {
                method: 'POST',
                body: ['Microsoft.SecurityPlatform/workspaces/write'],
                scopes: RESOURCE_SCOPES.FIDELIS,
            });
            let collections =
                response?.['Purview:Workspace']?.[
                    'Microsoft.SecurityPlatform/workspaces/write'
                ]?.collections?.map((w) => w.toLowerCase()) || [];
            let type =
                response?.['Purview:Workspace']?.['Microsoft.SecurityPlatform/workspaces/write']
                    ?.type;

            if (type === 'Permit') {
                ownedWorkspaces =
                    workspaces
                        ?.filter((workspace) => collections.includes(workspace.name.toLowerCase()))
                        .map((workspace) => workspace.name) || [];
            } else if (type === 'NotApplicable') {
                ownedWorkspaces =
                    workspaces
                        ?.filter((workspace) => !collections.includes(workspace.name.toLowerCase()))
                        .map((workspace) => workspace.name) || [];
            }

            ownerOfAWorkspace = ownedWorkspaces.length > 0;
            if (workspaceName) {
                ownerOfSelectedWorkspace = ownedWorkspaces.includes(workspaceName);
            }
            return {
                ownerOfSelectedWorkspace,
                ownerOfAWorkspace,
                ownedWorkspaces,
            } as WorkspacesOwnerCheckResponse;
        },
        ...useQueryOptions,
    });
}

export default useWorkspacesOwnerCheck;
