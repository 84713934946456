import useClasses from './WorkspaceSwitchingPage.styles';
import {Spinner} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import React from 'react';

interface WorkspaceSwitchingPageProps {
    workspaceName: string;
}

export default function WorkspaceSwitchingPage({workspaceName}: WorkspaceSwitchingPageProps) {
    const classes = useClasses();
    const {t} = useTranslation('common');

    return (
        <div className={classes.root}>
            <Spinner
                labelPosition="below"
                size="extra-large"
                label={t('WorkspaceSwitchingLabel', {0: workspaceName})}
                tabIndex={0}
            />
        </div>
    );
}
