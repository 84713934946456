import {useState, useCallback, useEffect, useMemo} from 'react';
import {Dialog, DialogBody, DialogSurface, mergeClasses} from '@fluentui/react-components';
import {useNavigate} from 'react-router-dom';
import {useDialogClasses} from '@/components/ui/Dialog';
import AgentIdentityConfigurationDialogContent from './components/AgentIdentityConfiguration/AgentIdentityConfigurationDialogContent';
import AgentSettingsConfigurationDialogContent from './components/AgentSettingsConfiguration/AgentSettingsConfigurationDialogContent';
import AgentConfigurationDialogOverlay from './components/AgentConfigurationDialogOverlay/AgentConfigurationDialogOverlay';
import {
    useCreateAgent,
    useDeleteAgent,
    AgentScopeTypes,
    type AgentDefinition,
    type Agent,
} from '@/api/agents';
import useClasses from './AgentConfigurationDialog.styles';

export interface AgentConfigurationDialogProps {
    agentDefinition: AgentDefinition;
    agent?: Agent;
    mode: 'create' | 'edit';
    open: boolean;
    onClose?: () => void;
}

export default function AgentConfigurationDialog({
    agentDefinition,
    mode,
    open,
    onClose,
    agent: initialAgent,
}: AgentConfigurationDialogProps) {
    const classes = useClasses();
    const dialogClasses = useDialogClasses();
    const navigate = useNavigate();

    const [agent, setAgent] = useState<Agent | undefined>(initialAgent);
    const [currentStep, setCurrentStep] = useState(0);

    const {
        mutateAsync: createAgent,
        isLoading: isCreateAgentLoading,
        isError: isCreateAgentError,
    } = useCreateAgent({
        scope: AgentScopeTypes.WORKSPACE,
    });

    const {mutateAsync: deleteAgent} = useDeleteAgent({
        scope: AgentScopeTypes.WORKSPACE,
    });

    const createAgentAsync = useCallback(async () => {
        const agent = await createAgent({
            body: {
                agentDefinitionName: agentDefinition.name,
                displayName: agentDefinition.displayName || agentDefinition.name,
                identity: {
                    type: 'user',
                },
            },
        });
        setAgent(agent);
    }, [createAgent, agentDefinition]);

    useEffect(() => {
        if (open && mode === 'create' && agentDefinition.name && !initialAgent) {
            createAgentAsync();
        } else if (open && mode === 'edit' && initialAgent) {
            setAgent(initialAgent);
        }
    }, [open, mode, agentDefinition.name, initialAgent, createAgentAsync]);

    const handleOpenChange = useCallback(() => {
        onClose?.();
        if (agent && mode === 'create' && !initialAgent) {
            // If the agent was created but not saved, delete it
            deleteAgent({agentId: agent.agentId});
        }

        // Delay dialog reset to reset after closing transition
        setTimeout(() => {
            setCurrentStep(0);
        }, 1000);
    }, [agent, deleteAgent, onClose, mode, initialAgent]);

    const onNext = useCallback(() => {
        setCurrentStep((prevStep) => prevStep + 1);
    }, []);

    const handleFinalStep = useCallback(() => {
        onClose?.();
        mode === 'create' && navigate(`/agents/${agent?.agentId}`);

        setTimeout(() => {
            setCurrentStep(0);
        }, 1000);
    }, [agent?.agentId, mode, navigate, onClose]);

    const steps = useMemo(() => {
        const isIdentityFinalStep = !(
            agentDefinition.settings?.length || agentDefinition.triggers?.[0]?.settings?.length
        );

        return [
            <AgentIdentityConfigurationDialogContent
                key={'agentIdentityConfiguration'}
                agent={agent}
                agentDefinition={agentDefinition}
                mode={mode}
                onClose={handleOpenChange}
                onNext={isIdentityFinalStep ? handleFinalStep : onNext}
                isFinalDialogStep={isIdentityFinalStep}
            />,
            <AgentSettingsConfigurationDialogContent
                key={'agentSettingsConfiguration'}
                agentDefinition={agentDefinition}
                agent={agent}
                mode={mode}
                onClose={handleOpenChange}
                onNext={handleFinalStep}
                isFinalDialogStep
            />,
        ];
    }, [agent, agentDefinition, handleOpenChange, mode, onNext, handleFinalStep]);

    return (
        <Dialog modalType="alert" open={open} onOpenChange={handleOpenChange}>
            <DialogSurface className={mergeClasses(classes.surface, dialogClasses.dialogSurface)}>
                <DialogBody className={classes.body}>
                    {isCreateAgentLoading && (
                        <AgentConfigurationDialogOverlay
                            showSetupAgentLoading
                            onClose={handleOpenChange}
                        />
                    )}
                    {isCreateAgentError && (
                        <AgentConfigurationDialogOverlay
                            showSetupAgentError
                            showCancelButton
                            showTryAgainButton
                            onClose={handleOpenChange}
                            onTryAgain={createAgentAsync}
                        />
                    )}
                    {!isCreateAgentLoading && !isCreateAgentError && steps[currentStep]}
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
