import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        rowGap: tokens.spacingHorizontalM,
        backgroundColor: tokens.colorNeutralBackground2,
        padding: tokens.spacingHorizontalM,
        borderRadius: tokens.borderRadiusLarge,
        marginBottom: tokens.spacingHorizontalM,
        display: 'flex',
        flexDirection: 'row',
        boxShadow: tokens.shadow4,
        '@media (min-width: 1920px)': {
            width: '692px',
        },
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            width: '692px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            width: '692px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            width: '446px',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            width: '431px',
        },
        '@media (max-width: 479px)': {
            position: 'fixed',
            bottom: 0,
            width: '90%',
            display: 'flex',
            height: 'auto',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    skillIcon: {
        color: tokens.colorBrandForeground1,
        ...shorthands.padding(tokens.spacingHorizontalM, tokens.spacingVerticalL),
    },
    dismissIcon: {
        paddingLeft: tokens.spacingHorizontalM,
    },
    icons: {
        display: 'flex',
        flexDirection: 'row',
    },
    promptBar: {
        display: 'flex',
        flexDirection: 'row',
        rowGap: tokens.spacingHorizontalM,
        width: '100%',
        justifyContent: 'space-between',
    },
    submit: {
        border: `none`,
        '&:hover': {
            color: tokens.colorBrandBackground2,
        },
    },
    textContainer: {
        gap: tokens.spacingHorizontalXXL,
        padding: tokens.spacingVerticalS, // Optional padding for spacing within
    },
    editable: {
        backgroundColor: tokens.colorNeutralBackgroundAlpha2,
        cursor: 'pointer',
        height: '25px',
        maxheight: '25px',
        width: 'auto',
        borderRadius: tokens.borderRadiusMedium,
        '&:hover': {
            borderBottom: `1px solid ${tokens.colorBrandStroke2Hover}`,
        },
        padding: tokens.spacingVerticalNone,
        ...shorthands.margin(tokens.spacingVerticalNone, tokens.spacingHorizontalXS),
        alignItems: 'start',
    },
    toolbarDiv: {
        ...shorthands.borderRadius('50%'),
        pointerEvents: 'none',
        height: '50%',
        alignSelf: 'center',
        paddingLeft: tokens.spacingHorizontalS,
        paddingRight: tokens.spacingHorizontalS,
        '::before': {
            ...shorthands.borderColor(tokens.colorNeutralForeground4),
        },
        '::after': {
            ...shorthands.borderColor(tokens.colorNeutralForeground4),
        },
        opacity: 0.5,
    },
    inputContainer: {
        display: 'inline-block',
    },
});
