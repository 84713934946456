import {NavActionsInput} from './Navigation.types';
import {useContext, useEffect, useCallback} from 'react';
import NavigationContext from './Context';

interface useNavActionProps {
    actions?: Array<any>;
    clearOnUnmount?: boolean;
}

const defaultProps: useNavActionProps = {
    actions: [],
    clearOnUnmount: true,
};

// A
const useNavActions = (props: useNavActionProps = defaultProps) => {
    const {actions, clearOnUnmount} = props;
    const {navActions, setNavActions, toggableActions, setToggableActions} =
        useContext(NavigationContext);

    const setActions = useCallback(
        (nextActions?: NavActionsInput) => {
            // we want to include a key attribute with each node that we will be adding to the navigation bar
            // this is because we want to be able to remove the node from the navigation bar
            // without having to re-render the entire navigation bar
            const keyActions = nextActions?.map((action, index) => {
                if (typeof action === 'object' && action !== null) {
                    return {...action, key: index};
                }
                return action;
            });

            if (!keyActions) {
                setNavActions([]);
            } else {
                setNavActions(Array.isArray(keyActions) ? keyActions : [keyActions]);
            }
        },
        [setNavActions],
    );

    // run the first time this is mounted only
    useEffect(() => {
        if (actions) {
            setActions(actions);
        }
    }, []);

    // if the items defined in the prop change update the items
    useEffect(() => {
        // setActions(actions);
        // Only need to run when this hook unmounts and if clearOnUnmount is true
        return () => {
            if (clearOnUnmount) {
                setActions([]);
            }
        };
    }, [clearOnUnmount]);

    return {navActions, setNavActions: setActions, toggableActions, setToggableActions};
};

export default useNavActions;
