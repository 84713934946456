import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Spinner,
} from '@fluentui/react-components';
import {useEffect, useMemo, useState} from 'react';
import useClasses from './DeleteCapacityConfirmation.styles';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import {Intent} from '@/components/ui/Toasts/ToastNotification.types';
import useAzureCapacityDependencyLinkParts from '@/util/useAzureCapacityDependencyLinkParts';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useGetWorkspaceByName, useUpdateWorkspace, Workspace} from '@/api/workspaces';
import {DismissIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {ARIA_LABELS, CONTROL_LABELS, STATIC_CONTENT} from '../ChangeCapacity.constants';
import {
    Capacity,
    useDeleteCapacity,
    useGetCapacities,
    useGetCapacityByName,
} from '@/api/capacities';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import useButtonClasses from '@/components/ui/Buttons/Button.styles';

interface DeleteCapacityConfirmationProps {
    isDeleteCapacityConfirmDialogOpen: boolean;
    onDeleteCapacityConfirmDialogClose: () => void;
    workspace?: Workspace;
    previousCapacity?: Capacity;
}

export default function DeleteCapacityConfirmation(props: DeleteCapacityConfirmationProps) {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    const {workspaceName, capacityId, capacityName} = useWorkspaceState();

    // multi-workspaces
    const buttonClasses = useButtonClasses();
    //debugger;
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    // find which capacity is linked to props.workspaceName
    // get capacity name from workspace
    const {update: providerUpdate} = useWorkspaceState();
    const {isDeleteCapacityConfirmDialogOpen, onDeleteCapacityConfirmDialogClose} = props;
    const tenantId = getCurrentTenantId();
    const {getSubscriptionId, getResourceGroupName} = useAzureCapacityDependencyLinkParts({
        capacityId: (isWorkspacesTestingEnabled ? props.previousCapacity?.id : capacityId) || '',
        tenantId,
    });

    const {subscriptionId, resourceGroupName} = useMemo(() => {
        return {
            subscriptionId: getSubscriptionId(),
            resourceGroupName: getResourceGroupName(),
        };
    }, [getSubscriptionId, getResourceGroupName]);

    const {
        mutate: delinkCapacityFromWorkspace,
        isSuccess: isDelinkCapacitySuccess,
        isError: isDelinkCapacityError,
        isLoading: isDelinkCapacityInProgress,
    } = useUpdateWorkspace();

    const {
        mutate: deleteCapacityFromARM,
        isSuccess: isDeleteCapacitySuccess,
        isError: isDeleteCapacityError,
        isLoading: isDeleteCapacityInProgress,
    } = useDeleteCapacity();

    //Orchestrate deletion of capacity after user clicks on 'Yes' --> First delink and then delete from Azure.
    const deleteCapacity = () => {
        // base capacity deleted on workspace passed in
        if (isWorkspacesTestingEnabled && props.workspace?.name && props.previousCapacity?.name) {
            delinkCapacityFromWorkspace(
                {
                    name: props.workspace?.name,
                    capacity: null,
                },
                {
                    onSuccess: (data) => {
                        deleteCapacityFromARM(
                            {
                                subscriptionId,
                                resourceGroupName,
                                capacityName: props.previousCapacity?.name ?? '',
                            },
                            {
                                onSuccess: async () => {
                                    await providerUpdate();
                                },
                                onSettled: () => {
                                    onDeleteCapacityConfirmDialogClose();
                                },
                            },
                        );
                    },
                    onError: () => {
                        onDeleteCapacityConfirmDialogClose();
                    },
                },
            );
        }
        // original flow
        if (workspaceName && capacityName && !isWorkspacesTestingEnabled) {
            delinkCapacityFromWorkspace(
                {
                    name: workspaceName,
                    capacity: null,
                },
                {
                    onSuccess: (data) => {
                        deleteCapacityFromARM(
                            {
                                subscriptionId,
                                resourceGroupName,
                                capacityName,
                            },
                            {
                                onSuccess: async () => {
                                    await providerUpdate();
                                },
                                onSettled: () => {
                                    onDeleteCapacityConfirmDialogClose();
                                },
                            },
                        );
                    },
                    onError: () => {
                        onDeleteCapacityConfirmDialogClose();
                    },
                },
            );
        }
    };
    if (props.workspace?.name && isWorkspacesTestingEnabled) {
        return (
            <>
                <Dialog
                    open={isDeleteCapacityConfirmDialogOpen}
                    onOpenChange={onDeleteCapacityConfirmDialogClose}
                >
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle
                                action={
                                    <DialogTrigger action="close">
                                        <Button
                                            appearance="subtle"
                                            aria-label={t('ButtonLabels.Close')}
                                            icon={<DismissIcon />}
                                            onClick={onDeleteCapacityConfirmDialogClose}
                                        />
                                    </DialogTrigger>
                                }
                            >
                                <div className={classes.titleContainer}>
                                    <div>
                                        {t('Workspaces.Form.DeleteCapacity', {
                                            0: props.previousCapacity?.name,
                                        })}
                                    </div>
                                </div>
                            </DialogTitle>
                            <DialogContent className={classes.contentSection}>
                                <div>
                                    {t('Workspaces.Form.DeleteCapacityDescription', {
                                        0: props.previousCapacity?.name,
                                    })}
                                </div>
                            </DialogContent>
                            <DialogActions className={classes.actions}>
                                <div className={classes.rightActions}>
                                    <Button
                                        className={buttonClasses.redButton}
                                        onClick={deleteCapacity}
                                    >
                                        {isDelinkCapacityInProgress ||
                                        isDeleteCapacityInProgress ? (
                                            <Spinner size="tiny" />
                                        ) : (
                                            t('Workspaces.Form.DeleteCapacityButton')
                                        )}
                                    </Button>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button
                                            appearance="secondary"
                                            onClick={onDeleteCapacityConfirmDialogClose}
                                        >
                                            {t('Workspaces.Form.CancelDeleteCapacityButton')}
                                        </Button>
                                    </DialogTrigger>
                                </div>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
                {isDelinkCapacitySuccess && isDeleteCapacitySuccess && (
                    <ToastNotification
                        intent={Intent.Success}
                        message={t(STATIC_CONTENT.DELETE_CAPACITY_SUCCESS)}
                        sessionId={''}
                    />
                )}
                {isDeleteCapacityError || isDelinkCapacityError ? (
                    <ToastNotification
                        intent={Intent.Error}
                        message={t(STATIC_CONTENT.DELETE_CAPACITY_ERROR)}
                        sessionId={''}
                    />
                ) : null}
            </>
        );
    }
    return (
        <>
            <Dialog
                open={isDeleteCapacityConfirmDialogOpen}
                onOpenChange={onDeleteCapacityConfirmDialogClose}
            >
                <DialogSurface>
                    <DialogTrigger disableButtonEnhancement>
                        <Button
                            data-test-id="delete-capacity-dialog-close-button"
                            className={classes.closeButton}
                            appearance="transparent"
                            aria-label={t(ARIA_LABELS.CLOSE_DELETE_CAPACITY_DIALOG)}
                            onClick={onDeleteCapacityConfirmDialogClose}
                        >
                            <DismissIcon className={classes.dismissIcon} />
                        </Button>
                    </DialogTrigger>
                    <DialogBody>
                        <DialogTitle>
                            {t(STATIC_CONTENT.DELETE_SECURITY_FABRIC_CAPACITY_HEADING)}
                        </DialogTitle>
                        <DialogContent className={classes.dialogContentSection}>
                            {t(STATIC_CONTENT.DELETE_CAPACITY_CONFIRMATION_MESSAGE, {capacityName})}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                data-test-id="confirm-delete-capacity"
                                appearance="primary"
                                onClick={deleteCapacity}
                                disabled={isDelinkCapacityInProgress || isDeleteCapacityInProgress}
                            >
                                {isDelinkCapacityInProgress || isDeleteCapacityInProgress ? (
                                    <Spinner size="tiny" />
                                ) : (
                                    t(CONTROL_LABELS.YES)
                                )}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            {isDelinkCapacitySuccess && isDeleteCapacitySuccess && (
                <ToastNotification
                    intent={Intent.Success}
                    message={t(STATIC_CONTENT.DELETE_CAPACITY_SUCCESS)}
                    sessionId={''}
                />
            )}
            {isDeleteCapacityError || isDelinkCapacityError ? (
                <ToastNotification
                    intent={Intent.Error}
                    message={t(STATIC_CONTENT.DELETE_CAPACITY_ERROR)}
                    sessionId={''}
                />
            ) : null}
        </>
    );
}
