import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {RunAgentTriggerRequest, AgentsRequestQueryParameters} from './agents.types';

export default function useRunAgentTrigger(requestParams: AgentsRequestQueryParameters) {
    const {customFetch} = useFetch();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (request: RunAgentTriggerRequest) => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<Response>(
                `/agents/${request.agentId}/triggers/${
                    request.triggerName
                }/executions?${queryParams.toString()}`,
                {
                    method: 'POST',
                    body: JSON.stringify(request.body),
                },
            );
        },
        onSuccess: (_, request) => {
            queryClient.refetchQueries({
                queryKey: [
                    'agents',
                    request.agentId,
                    'triggers',
                    request.triggerName,
                    'runs',
                    requestParams,
                ],
            });
        },
    });
}
