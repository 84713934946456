import {useMutation, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {Agent, AgentsRequestQueryParameters, PatchAgentRequest} from './agents.types';

export default function usePatchAgent(requestParams: AgentsRequestQueryParameters) {
    const {customFetch} = useFetch();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (request: PatchAgentRequest) => {
            const queryParams = new URLSearchParams(requestParams);
            return await customFetch<Agent>(
                `/agents/${request.agentId}?${queryParams.toString()}`,
                {
                    method: 'PATCH',
                    body: JSON.stringify(request.body),
                },
            );
        },
        onSuccess: (_, request) => {
            queryClient.refetchQueries({
                queryKey: ['agents', request.agentId, requestParams],
            });
            queryClient.refetchQueries({
                queryKey: ['agents', requestParams],
                exact: true,
            });
        },
    });
}
