import {makeStyles, tokens} from '@fluentui/react-components';
export default makeStyles({
    messageBarContainer: {
        display: 'flex',
        width: '100%',
        textAlign: 'left',
        flexDirection: 'column',
        position: 'relative',
        marginTop: tokens.spacingVerticalM,
    },
    messageBarBody: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
    },
    messageContent: {
        display: 'in-line',
    },
});
