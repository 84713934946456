import React, {useCallback} from 'react';
import {useGetAccount} from '@/api/accounts';
import {PutWorkspaceRequest, CreateGeoWorkspaceRequest, Workspace} from './workspaces.types';
import {getAccountGeoCodes} from './util';
import usePutWorkspace from './usePutWorkspace';
import useEnableGeo from '@/api/accounts/useEnableGeo';
import {AccountDetails} from '../accounts/accounts.types';
import {
    formatWorkspaceToSessionData,
    setCurrentWorkspaceCache,
} from '@/components/sections/workspaces/WorkspacesProvider';

const managementUrlBase = '.api.securityplatform.microsoft.com';
import {useCreateWorkspaceSettings} from '@/api/workspaces';
import {getCurrentTenantId} from '@/util/msal/authConfig';

interface getPodUrlOptions {
    geoCode: string;
    account: AccountDetails;
    name: string;
}

/** Utility function that takes a pod and geo and returns a full TargetWorkspace object */
function buildTargetWorkspace({geoCode, account, name}: getPodUrlOptions): PutWorkspaceRequest {
    const geoPod = account.pods?.find((pod) => pod.geo === geoCode);
    if (!geoPod) {
        throw new Error('Geo is not enabled in the account');
    }
    const targetWorkspace: PutWorkspaceRequest = {
        name: name,
        dataStorageLocation: geoPod.geo,
        path: `pods/${geoPod.podId}/workspaces/${name}`,
        managementUrl: `${geoPod.geoCode}${managementUrlBase}`,
    };
    if (targetWorkspace.capacity?.referenceName === '') {
        delete targetWorkspace.capacity;
    }
    console.log('buildTargetWorkspace:', targetWorkspace);
    return targetWorkspace;
}

function useCreateFREWorkspace() {
    const {data: account, refetch: refetchAccount} = useGetAccount({
        onError: (error) => {
            console.error('Failed to get account', error);
            throw error;
        },
    });

    const putWorkspace = usePutWorkspace();
    const enableGeo = useEnableGeo();
    const {mutateAsync: createSettings} = useCreateWorkspaceSettings();

    const needsGeoEnablement = useCallback(
        (targetWorkspace: PutWorkspaceRequest, account: AccountDetails) => {
            if (!account.pods) {
                return true;
            }
            const targetGeo = targetWorkspace.dataStorageLocation;
            return !account.pods.some((pod) => pod.geo === targetGeo);
        },
        [],
    );

    const createWorkspaceSettings = useCallback(
        async (targetWorkspace: PutWorkspaceRequest) => {
            try {
                const tenantId = getCurrentTenantId();
                const data = await createSettings({
                    workspaceSettings: {
                        tenantId: tenantId,
                        workspaceId: targetWorkspace.name,
                        freRequirements: {
                            allowProductImprovement: undefined,
                            allowModelImprovement: undefined,
                        },
                    },
                });
                console.log('createWorkspaceSettings success:', data);
                return data;
            } catch (error) {
                console.error('Error creating workspace settings:', error);
                throw error;
            }
        },
        [createSettings],
    );

    const createWorkspace = useCallback(
        (targetWorkspace: PutWorkspaceRequest): Promise<Workspace> => {
            return new Promise((resolve, reject) => {
                putWorkspace.mutate(
                    {request: targetWorkspace, targetWorkspace},
                    {
                        onError: reject,
                        onSuccess: (data: Workspace) => {
                            console.log('createWorkspace success:', data);
                            // Since this hook is only used for FRE workspace creation we need to set
                            // session storage information for the workspace in order for the follow up
                            // workspace related calls to work
                            const workspaceSessionData = formatWorkspaceToSessionData(data);
                            console.log('workspace sessionData:', workspaceSessionData);
                            setCurrentWorkspaceCache(workspaceSessionData);
                            try {
                                // Create workspace settings
                                createWorkspaceSettings(data);
                                return resolve(data);
                            } catch (e) {
                                console.error('Error creating workspace settings:', e);
                                reject(e);
                            }
                            //
                        },
                    },
                );
            });
        },
        [createWorkspaceSettings, putWorkspace],
    );

    const handleCreate = useCallback(
        async (
            targetWorkspace: CreateGeoWorkspaceRequest,
        ): Promise<CreateGeoWorkspaceRequest | void> => {
            try {
                const {data: account} = await refetchAccount();
                if (!account) {
                    throw new Error(
                        'No account found when trying to create workspace, geo could not be checked',
                    );
                }
                const targetGeo = targetWorkspace.dataStorageLocation;
                const needsGeo = needsGeoEnablement(targetWorkspace, account);
                if (needsGeo) {
                    await new Promise<void>((resolve, reject) => {
                        enableGeo.mutateAsync(
                            {geoName: targetWorkspace.dataStorageLocation || ''},
                            {
                                onError: reject,
                                onSuccess: (data) => resolve(),
                            },
                        );
                    });
                    const result = await refetchAccount();
                    if (!result.data) {
                        throw new Error('No account found after enabling geo');
                    }

                    const putWorkspaceRequest = buildTargetWorkspace({
                        geoCode: targetGeo,
                        account: result.data,
                        name: targetWorkspace.name,
                    });
                    await createWorkspace(putWorkspaceRequest);
                } else {
                    const putWorkspaceRequest = buildTargetWorkspace({
                        geoCode: targetGeo,
                        account: account,
                        name: targetWorkspace.name,
                    });
                    await createWorkspace(putWorkspaceRequest);
                }

                return targetWorkspace;
            } catch (error) {
                console.error('Error creating workspace:', error);
                throw error;
            }
        },
        [createWorkspace, enableGeo, needsGeoEnablement, refetchAccount],
    );

    return handleCreate;
}

export default useCreateFREWorkspace;
