export type AvailableTrial = {
    trialId: string;
    eligibleTenantId: string;
    trial: Trial;
};

export type AvailableTrialsResponse = {
    content?: any;
    value: AvailableTrial[];
    nextLink?: any;
    count?: any;
};

export type Trial = {
    trialType: TrialType;
    trialSource: string;
    freeSCUs: number;
    defaultSCUs: number;
    defaultOverageSCUs: number;
    trialDuration: string;
    trialActivationDeadline: string;
    trialActivatedDateTime?: string | null;
    workspaceId: string;
    tags: string[];
};

export enum TrialType {
    FLTTrial = 'FLTTrial',
    PLGTrial = 'PLGTrial',
    PublicPreview = 'PublicPreview',
    Other = 'Other',
}
