import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    grid: {
        ...shorthands.padding(tokens.spacingHorizontalS, tokens.spacingHorizontalS),
        minHeight: '100%',
    },
    gridHeaderCell: {
        fontWeight: '600',
    },
    gridBody: {
        maxHeight: '50vh',
        overflow: 'auto',
    },
    skeleton: {
        height: '45px',
    },
});
