import React, {useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getParamsFromPath} from '../util';
import {MedeinaRoutesMap} from '../../MedeinaRoutes';
import useCrumbClasses from '../Breadcrumbs.styles';
import AgentBreadcrumb from '@/components/sections/agents/overview/AgentBreadcrumb';
import AgentRunBreadcrumb from '@/components/sections/agents/run/AgentRunBreadcrumb';
import {Button, mergeClasses} from '@fluentui/react-components';

interface AgentsBreadcrumbProps {
    segment: string;
    renderOnlyLast?: boolean;
}

function AgentsBreadcrumbs(props: AgentsBreadcrumbProps) {
    const {renderOnlyLast} = props;
    const classes = useCrumbClasses();
    const location = useLocation();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const label = t('Breadcrumbs.agents');

    const params = useMemo(
        () =>
            getParamsFromPath(
                MedeinaRoutesMap[props.segment as keyof typeof MedeinaRoutesMap].path,
                location.pathname,
            ),
        [location.pathname, props.segment],
    );

    if (renderOnlyLast) {
        return (
            <>
                {params?.runId ? (
                    <AgentRunBreadcrumb agentId={params.agentId} runId={params.runId} />
                ) : params?.agentId ? (
                    <AgentBreadcrumb agentId={params.agentId} />
                ) : (
                    label
                )}
            </>
        );
    }

    return (
        <>
            {/** Agents Library Page Breadcrumb */}
            <li className={classes.crumb}>
                <Button
                    className={mergeClasses(classes.link, classes.linkAgents)}
                    onClick={() => {
                        navigate(MedeinaRoutesMap.agentsLibrary.path);
                    }}
                    aria-label={label}
                    size="medium"
                    appearance="subtle"
                >
                    {label}
                </Button>
            </li>
            {/** Agents Page Breadcrumb */}
            {params?.agentId && (
                <>
                    <li role="separator" className={classes.spacer}>
                        /
                    </li>
                    <li className={classes.crumb}>
                        <Button
                            className={mergeClasses(classes.link, classes.linkAgents)}
                            onClick={() => {
                                navigate(`/agents/${params.agentId}`);
                            }}
                            size="medium"
                            appearance="subtle"
                        >
                            <AgentBreadcrumb agentId={params.agentId} />
                        </Button>
                    </li>
                </>
            )}
            {/** Agents Run Page Breadcrumb */}
            {params?.runId && (
                <>
                    <li role="separator" className={classes.spacer}>
                        /
                    </li>
                    <AgentRunBreadcrumb agentId={params.agentId} runId={params.runId} />
                </>
            )}
        </>
    );
}

export default AgentsBreadcrumbs;
