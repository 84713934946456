import {ParagraphContent} from '@/components/ui/Text';
import MedeinaVariables from '@/util/variables';
import {
    USAGE_MONITORING_DESCRIPTION_WORKSPACES,
    USAGE_MONITORING_DESCRIPTION_WORKSPACES_OVERAGE,
    USAGE_MONITORING_DESCRIPTION,
    LEARN_MORE_LINK,
    USAGE_MONITORING_DESCRIPTION_OVERAGE,
    ButtonLabels,
} from './UsageDashboard.constants';
import UsageDashboardTitle from './UsageDashboardTitle';
import {Divider, Link} from '@fluentui/react-components';
import MedeinaFeatures from '@/util/features';
import {useFeatureFlag} from '@/api/user';
import {useTranslation} from 'react-i18next';
import useClasses from './UsageDashboard.styles';
import {useMemo} from 'react';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import useAzureCapacityDependencyLinkParts from '@/util/useAzureCapacityDependencyLinkParts';

interface UsageDashboardHeadingProps {
    lastUpdatedAt: Date | number;
    isOverageEnabled: boolean;
    isNewMswUiEnabled?: boolean;
}

export default function UsageDashboardHeading(props: UsageDashboardHeadingProps) {
    const {t: tAdmin} = useTranslation('admin');
    const classes = useClasses();

    const {capacityId} = useWorkspaceState();
    const tenantId = getCurrentTenantId();

    const {getBillingTabUrlOnSubscriptionPage} = useAzureCapacityDependencyLinkParts({
        capacityId: capacityId ?? '',
        tenantId: tenantId,
    });

    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    const dashboardHeading = useMemo(() => {
        if (props.isNewMswUiEnabled) {
            return tAdmin(USAGE_MONITORING_DESCRIPTION_WORKSPACES_OVERAGE);
        } else if (props.isOverageEnabled) {
            return tAdmin(USAGE_MONITORING_DESCRIPTION_OVERAGE);
        } else if (isWorkspacesTestingEnabled) {
            return tAdmin(USAGE_MONITORING_DESCRIPTION_WORKSPACES);
        }
        return tAdmin(USAGE_MONITORING_DESCRIPTION);
    }, [isWorkspacesTestingEnabled, props.isOverageEnabled, props.isNewMswUiEnabled]);

    return (
        <>
            <UsageDashboardTitle lastUpdatedAt={props.lastUpdatedAt} />
            <div className={classes.infoContainer}>
                <ParagraphContent data-testid="dashboard-content">
                    {dashboardHeading}{' '}
                    {!props.isNewMswUiEnabled && (
                        <Link href={MedeinaVariables.ManageCapacityLearnUri} target="_blank">
                            {tAdmin(LEARN_MORE_LINK)}
                        </Link>
                    )}
                </ParagraphContent>
                {props.isNewMswUiEnabled && (
                    <div className={classes.dashboardHeaderLinks}>
                        <Link href={MedeinaVariables.ManageCapacityLearnUri} target="_blank">
                            {tAdmin(LEARN_MORE_LINK)}
                        </Link>{' '}
                        <Divider vertical={true}></Divider>{' '}
                        <Link href={getBillingTabUrlOnSubscriptionPage()}>
                            {tAdmin(ButtonLabels.VIEW_AZURE_BILLING)}
                        </Link>
                    </div>
                )}
            </div>
        </>
    );
}
