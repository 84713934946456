import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentActivityEmptyState.styles';
import {EmptyState} from '@sfe/react';
import {Button, Subtitle1, Image} from '@fluentui/react-components';
import {Power24Regular, Send24Regular, Pause24Regular} from '@fluentui/react-icons';
import {Agent, AgentDefinition} from '@/api/agents';
import useAgentStatus from '../../useAgentStatus';

interface AgentActivityEmptyStateProps {
    agent: Agent;
    definition: AgentDefinition;
    onEnableTrigger: (enabled: boolean) => void;
    onRunTrigger: () => void;
}

const imagePath = '/images/features/agents/';
const defaultImage = `${imagePath + 'EmptyState.svg'}`;

export default function AgentActivityEmptyState(props: AgentActivityEmptyStateProps) {
    const {onEnableTrigger, onRunTrigger, agent, definition} = props;
    const classes = useClasses();
    const {t: tAgents} = useTranslation('agents');

    const handleEnableTrigger = useCallback(
        (enabled: boolean) => {
            onEnableTrigger(enabled);
        },
        [onEnableTrigger],
    );

    const {isAutomaticTrigger, isTriggerActive} = useAgentStatus(agent, definition);

    return (
        <div className={classes.container}>
            <EmptyState
                data-testid="agent-activity-empty-state"
                heading={
                    <div className={classes.emptyStateSection}>
                        {!isAutomaticTrigger ? (
                            <>
                                <Subtitle1>
                                    {tAgents('Agent.Activity.Empty.ManualOnlyHeaderLine1')}
                                </Subtitle1>
                                <Subtitle1>
                                    {tAgents('Agent.Activity.Empty.ManualOnlyHeaderLine2')}
                                </Subtitle1>
                            </>
                        ) : isTriggerActive ? (
                            <>
                                <Subtitle1>
                                    {tAgents('Agent.Activity.Empty.AutomaticEnabledHeaderLine1')}
                                </Subtitle1>
                            </>
                        ) : (
                            <>
                                <Subtitle1>
                                    {tAgents('Agent.Activity.Empty.AutomaticHeaderLine1')}
                                </Subtitle1>
                            </>
                        )}
                    </div>
                }
                description={{
                    as: 'div',
                    children: (
                        <div className={classes.emptyStateSection}>
                            {isAutomaticTrigger && (
                                <>
                                    {isTriggerActive ? (
                                        <>
                                            <Button
                                                icon={<Pause24Regular />}
                                                className={classes.emptyStateButton}
                                                appearance="transparent"
                                                onClick={() => {
                                                    handleEnableTrigger(false);
                                                }}
                                            >
                                                {tAgents('Agent.Activity.Empty.PauseButton')}
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                icon={<Power24Regular />}
                                                className={classes.emptyStateButton}
                                                appearance="transparent"
                                                disabled={!agent.triggers?.[0]?.enabled}
                                                onClick={() => {
                                                    handleEnableTrigger(true);
                                                }}
                                            >
                                                {tAgents('Agent.Activity.Empty.RunOnTriggerButton')}
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                            <div>
                                <Button
                                    icon={<Send24Regular />}
                                    className={classes.emptyStateButton}
                                    disabled={!agent.triggers?.[0]?.enabled}
                                    appearance="transparent"
                                    onClick={onRunTrigger}
                                >
                                    {tAgents('Agent.Activity.Empty.RunOneTimeButton')}
                                </Button>
                            </div>
                        </div>
                    ),
                }}
                image={
                    <Image
                        fit="default"
                        alt={tAgents('Agent.Activity.Empty.Image')}
                        aria-hidden
                        src={defaultImage || ''}
                        data-testid="agent-activity-empty-image"
                    />
                }
                actions={null}
            />
        </div>
    );
}
