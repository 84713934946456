import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

const minCardSize = 348;

export default makeStyles({
    loadingbox: {
        display: 'flex',
        width: '100%',
        minHeight: '50vh',
        justifyContent: 'center',
    },
    gridContainer: {
        display: 'flex',
        ...shorthands.padding(tokens.spacingVerticalXXL, tokens.spacingHorizontalNone),
        gap: tokens.spacingVerticalXXL,
        flexWrap: 'wrap',
    },
    emptyStateContainer: {
        display: 'flex',
        width: '100%',
        minHeight: '50vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
