import AgentOverview from '@/components/sections/agents/overview/AgentOverview';
import {useParams, Params} from 'react-router-dom';

interface AgentParams extends Params {
    agentId: string;
}

export default function AgentPage() {
    const {agentId} = useParams() as AgentParams;
    return <AgentOverview agentId={agentId} />;
}
