import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {Tenant} from './user.types';
import MedeinaVariables from '@/util/variables';

export default function useGetUserTenants(options: UseQueryOptions<Tenant[]> = {}) {
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['userTenants'],
        queryFn: async () => {
            const response = await customFetch<Response>(
                `${MedeinaVariables.ArmUri}/tenants?api-version=2020-01-01`,
                {
                    method: 'GET',
                    scopes: {scopes: ['https://management.azure.com/user_impersonation']},
                },
                true,
            );
            const data = await response.json();
            return data.value as Tenant[];
        },
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        ...options,
    });
}
