import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground4,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        rowGap: tokens.spacingVerticalL,
        width: '400px',
    },

    // Menu header
    menuHeader: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: '46px',
        justifyContent: 'space-between',
        marginLeft: '1px',
        ...shorthands.padding(0, tokens.spacingHorizontalXXL),
    },
    menuHeaderLogo: {
        height: '20px',
    },
    menuHeaderTitle: {
        alignItems: 'center',
        display: 'flex',
        fontSize: tokens.fontSizeBase300,
        columnGap: tokens.spacingHorizontalM,
    },
    menuHeaderTitleText: {
        alignItems: 'center',
        columnGap: tokens.spacingHorizontalS,
        display: 'flex',
        whiteSpace: 'nowrap',
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
    },

    // Menu content
    menuContent: {
        display: 'flex',
        flexGrow: '1',
        paddingLeft: tokens.spacingHorizontalS,
        flexDirection: 'column',
        ...shorthands.flex(1, 1, 'auto'),
    },
    menuBadge: {
        paddingLeft: tokens.spacingHorizontalL,
    },
    badgeBackground: {
        backgroundColor: tokens.colorNeutralBackground1,
    },

    // Menu footer
    menuFooter: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalM,
    },

    // User card in footer
    userCard: {
        backgroundColor: tokens.colorNeutralBackground1,
        position: 'relative',
        boxShadow: 'none',
        ...shorthands.padding('0px'),
    },
    userCardHeader: {
        ...shorthands.padding(tokens.spacingHorizontalM, tokens.spacingHorizontalL),
        paddingTop: tokens.spacingVerticalL,
        paddingBottom: tokens.spacingVerticalXS,
        gridTemplateColumns: 'min-content auto min-content',

        '& > .fui-CardHeader__header': {
            ...shorthands.overflow('hidden'),
        },
        '& > .fui-CardHeader__description': {
            ...shorthands.overflow('hidden'),
        },
    },
    userCardFooter: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
    userCardName: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightSemibold,
        ...shorthands.overflow('hidden'),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    userCardMetadata: {
        fontSize: tokens.fontSizeBase100,
        fontWeight: tokens.fontWeightRegular,
        ...shorthands.overflow('hidden'),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    drawerBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: tokens.spacingVerticalXXL,
        paddingBottom: tokens.spacingVerticalXXL,
        backgroundColor: tokens.colorTransparentBackground,
        backgroundImage: 'none',
    },
    settingsButton: {
        width: '100%',
        ...shorthands.outline('none'),
        ...shorthands.border('none'),
        ...shorthands.padding(tokens.spacingHorizontalM, tokens.spacingHorizontalL),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        justifyContent: 'flex-start',
    },
});
