import {PromptBarSubmitOptions} from '@/components/ui';
import {useNavigate} from 'react-router-dom';

import {useUserState} from '@/api/user/user';
import useGetUserDetails from '@/api/user/useGetUserDetails';
import {subtractHoursFromDate} from '@/util/subtractHoursFromDate';
import {CreateSessionWithPromptbookRequest, useCreateSessionWithPrompt} from '@/api/sessions';
import useCreateSessionWithPromptbook from '@/api/sessions/useCreateSessionWithPromptbook';
import {useCallback} from 'react';
import {featureFlags} from '@/util/hasStaticFeatureFlags';
import useCreatePromptAndEvaluation from '@/api/combined/useCreatePromptAndEvaluation';
import {useApplyPromptbook} from '@/api/promptbooks';
import {SourceType} from '@/api/sessions';
import {PromptBarSubmitError} from '@/components/ui';
import {PromptBarSubmitResponse} from '@/components/ui/PromptBar/PromptBar.types';
import {ClientApiError} from '@/api/api';

export interface PromptbarSubmitType {
    onSubmit?: (param: PromptBarSubmitOptions) => void;
    isErrorCreateSessionWithPrompt: boolean;
    resetCreateSessionWithPrompt: () => void;
    errorCreateSessionWithPrompt: Promise<unknown> | unknown;
    promptbookLoading: boolean;
    promptbookApplySuccess: boolean;
    errorCreateSessionWithPromptbook: Promise<unknown> | unknown;
}

export interface usePromptbarSubmitProps {
    sessionId?: string;
    onSubmitSuccess?: () => void;
    onSubmitError?: (error?: PromptBarSubmitError) => void;
}

/**
 * Common hook for creating a prompt with promptbar related options
 * This hook can create a prompt with a new session or can add a prompt to an existing session
 * Optional callbacks for success and error handling
 * @param usePromptbarSubmitProps
 * @returns PromptbarSubmitType
 */
const usePromptbarSubmit = (props?: usePromptbarSubmitProps): PromptbarSubmitType => {
    const {sessionId, onSubmitSuccess, onSubmitError} = props || {};
    const {toggleDailyTip} = useUserState();
    const {data: userDetails} = useGetUserDetails();

    /**
     * Prompt creation hooks for promptbar when there is no sessionId passed in
     */
    const {
        mutate: createSessionWithPrompt,
        isError: isErrorCreateSessionWithPrompt,
        reset: resetCreateSessionWithPrompt,
        error: errorCreateSessionWithPrompt,
    } = useCreateSessionWithPrompt();
    const {
        mutate: createSessionWithPromptbook,
        isLoading: createSessionWithPromptbookLoading,
        isSuccess: promptbookWithSessionApplySuccess,
        error: errorCreateSessionWithPromptbook,
        failureReason,
    } = useCreateSessionWithPromptbook();

    /**
     * Prompt creation hooks for promptbar when there is a sessionId passed in
     */
    const {
        mutate: createPromptAndEvaluation,
        isError: isCreatePromptAndEvaluationError,
        reset: resetPromptAndEvaluation,
        error: errorCreatePromptAndEvaluation,
    } = useCreatePromptAndEvaluation();
    const {
        mutate: applyPromptbook,
        isLoading: promptbookApplyLoading,
        isSuccess: promptbookApplySuccess,
        isError: isApplyPromptbookError,
        error: errorApplyPromptbook,
    } = useApplyPromptbook();

    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    const featureFlagsParams = featureFlags();
    const skillsetsParams = params.get('skillsets');

    /** Always respond the same way no matter how we created the prompt */
    const handleErrors = useCallback(
        (error: ClientApiError) => {
            // validate that the error is a client error
            if (error?.response) {
                const status = error?.response?.status;
                if (status === 429) {
                    onSubmitError?.({
                        ...error,
                        capacityMaxedOut: true,
                    });
                } else {
                    onSubmitError?.(error);
                }
            }
        },
        [onSubmitError],
    );

    const handleCreateInSession = useCallback(
        (options: PromptBarSubmitOptions) => {
            const {skillsets, featureFlags, ...prompt} = options;
            // creating session with prompt only for cases when it is not promptbook
            if (sessionId) {
                if (!prompt.promptbookId) {
                    createPromptAndEvaluation(
                        {
                            sessionId,
                            source: SourceType.Immersive,
                            ...prompt,
                        },
                        {
                            onSuccess: () => {
                                onSubmitSuccess?.();
                            },
                            onError: (error) => {
                                console.error({error});
                                handleErrors(error as ClientApiError);
                            },
                        },
                    );
                } else {
                    // if it is promptbook
                    // creating the body for the apply promptbook request
                    const promptbookBody = {
                        content: prompt.content,
                        promptbookId: prompt.promptbookId,
                        inputs: prompt.inputs,
                        promptbookPrompts: prompt.promptbookPrompts,
                        skillInputs: prompt.skillInputs,
                    };
                    applyPromptbook(
                        {
                            sessionId: sessionId,
                            promptbookId: promptbookBody?.promptbookId,
                            inputs: promptbookBody?.inputs,
                            overriddenPrompts: promptbookBody?.promptbookPrompts,
                            source: SourceType.Immersive,
                            skillInputs: promptbookBody?.skillInputs,
                        },
                        {
                            onSuccess: () => {
                                onSubmitSuccess?.();
                            },
                            onError: (error) => {
                                handleErrors(error as ClientApiError);
                            },
                        },
                    );
                }
            }
        },
        [applyPromptbook, createPromptAndEvaluation, handleErrors, onSubmitSuccess, sessionId],
    );

    const handleCreateWithNewSession = useCallback(
        (options: PromptBarSubmitOptions) => {
            const {skillsets, featureFlags, ...prompt} = options;
            // creating session with prompt only for cases when it is not promptbook
            if (!prompt.promptbookId) {
                createSessionWithPrompt(
                    {skillsets, featureFlags, prompt, defaultAgent: prompt.defaultAgent},
                    {
                        onSuccess: (session) => {
                            // Only include params if they're defined.
                            const params = [
                                skillsetsParams && `skillsets=${skillsetsParams}`,
                                featureFlagsParams && `featureFlags=${featureFlagsParams}`,
                            ].filter((param) => !!param);

                            // Push a URL change to go to the session.
                            navigate(
                                `/sessions/${session.sessionId}${
                                    params && params.length > 0 ? `?${params.join('&')}` : ''
                                }`,
                            );
                        },
                        onError: (error) => {
                            handleErrors(error as ClientApiError);
                        },
                    },
                );
            } else {
                // creating the body for the promptbook apply.
                prompt.promptbookPrompts = prompt.promptbookPrompts?.filter(
                    (prompt) => prompt.content != null || prompt.skillName != null,
                );
                const promptbook: CreateSessionWithPromptbookRequest = {
                    content: prompt.content,
                    promptbook: {
                        promptbookId: prompt.promptbookId,
                        overriddenPrompts: prompt.promptbookPrompts,
                        inputs: prompt.inputs,
                        skillInputs: prompt.skillInputs,
                    },
                    skillsets: skillsets,
                    featureFlags: featureFlags,
                };
                createSessionWithPromptbook(promptbook, {
                    onSuccess: (session) => {
                        // Only include params if they're defined.
                        const params = [
                            skillsetsParams && `skillsets=${skillsetsParams}`,
                            featureFlagsParams && `featureFlags=${featureFlagsParams}`,
                        ].filter((param) => !!param);

                        // Push a URL change to go to the session.
                        navigate(
                            `/sessions/${session.sessionId}${
                                params && params.length > 0 ? `?${params.join('&')}` : ''
                            }`,
                        );
                    },
                    onError: (error) => {
                        handleErrors(error as ClientApiError);
                    },
                });
            }
        },
        [
            createSessionWithPrompt,
            createSessionWithPromptbook,
            featureFlagsParams,
            handleErrors,
            navigate,
            skillsetsParams,
        ],
    );

    // Create a new session with its first interaction on submit.
    const onSubmit = useCallback(
        (submitOptions: PromptBarSubmitOptions) => {
            // Before prompt creation, check if user is new/inactive and set showDailyTip accordingly.
            toggleDailyTip(
                !!userDetails?.lastPromptExecutedAt
                    ? new Date(userDetails?.lastPromptExecutedAt) <
                          subtractHoursFromDate(new Date(), 12)
                    : true,
            );
            if (!sessionId) {
                handleCreateWithNewSession(submitOptions);
            } else {
                handleCreateInSession(submitOptions);
            }
        },
        [
            toggleDailyTip,
            userDetails?.lastPromptExecutedAt,
            sessionId,
            handleCreateWithNewSession,
            handleCreateInSession,
        ],
    );

    return {
        onSubmit,
        isErrorCreateSessionWithPrompt:
            isErrorCreateSessionWithPrompt || isCreatePromptAndEvaluationError,
        resetCreateSessionWithPrompt: resetCreateSessionWithPrompt || resetPromptAndEvaluation,
        errorCreateSessionWithPrompt:
            errorCreateSessionWithPrompt || errorCreatePromptAndEvaluation || null,
        promptbookLoading: createSessionWithPromptbookLoading || promptbookApplyLoading,
        promptbookApplySuccess: promptbookWithSessionApplySuccess || promptbookApplySuccess,
        errorCreateSessionWithPromptbook:
            errorCreateSessionWithPromptbook || errorApplyPromptbook || null,
    };
};

export default usePromptbarSubmit;
