import {
    EvaluationUserInputFields,
    UserResponse,
    InputComponent,
    useSubmitEvaluationInput,
    EvaluationInputType,
} from '@/api/evaluations';
import {Button} from '@fluentui/react-components';
import {useEffect, useState} from 'react';
import UserInputComponent from './UserInputComponent';
import useClasses from './EvaluationUserInputPanel.styles';
import {useTranslation} from 'react-i18next';
import {Markdown} from '@/components/ui';
import {FailedIcon} from '@/components/ui/icons';
import {debounce} from 'lodash-es';

interface EvaluationUserInputPanelProps {
    evaluationUserInputFields: EvaluationUserInputFields;
    evaluationId: string;
    promtId: string;
    sessionId: string;
}

export default function EvaluationUserInputPanel({
    evaluationUserInputFields,
    evaluationId,
    promtId,
    sessionId,
}: EvaluationUserInputPanelProps) {
    const classes = useClasses();
    const {t} = useTranslation('session');
    const [requiredInputs, setRequiredInputs] = useState<InputComponent[]>();

    const [userResponse, setUserResponse] = useState<UserResponse>({
        values: {},
    });

    const {mutate: evaluationInput} = useSubmitEvaluationInput();

    useEffect(() => {
        if (evaluationUserInputFields !== null && evaluationUserInputFields !== undefined) {
            setRequiredInputs(evaluationUserInputFields?.inputs?.filter((input) => input.required));
            const defaultValues =
                evaluationUserInputFields?.inputs?.reduce((acc, input) => {
                    acc[input.id] = input.defaultValue || '';
                    return acc;
                }, {} as {[key: string]: string}) || {};
            setUserResponse({
                ...userResponse,
                values: defaultValues,
            });
        }
    }, [evaluationUserInputFields]);

    const [insufficientInputs, setInsufficientInputs] = useState<boolean>(true);
    const [isActionPerformed, setIsActionPerformed] = useState<boolean>(false);
    const onsubmitClick = () => {
        evaluationInput(
            {
                sessionId: sessionId,
                promptId: promtId,
                evaluationId: evaluationId,
                input: {
                    inputType: EvaluationInputType.PassThroughAndProceed,
                    input: userResponse.values,
                },
            },
            {
                onSuccess: () => {
                    setIsActionPerformed(true);
                },
            },
        );
    };

    const oncancelClick = () => {
        // Call API to cancel action
    };

    useEffect(() => {
        const allRequiredInputsPresent = requiredInputs?.every((input) => {
            const value = userResponse.values[input.id];
            return value !== null && value !== undefined && value !== '';
        });
        if (allRequiredInputsPresent) {
            setInsufficientInputs(false);
        } else {
            setInsufficientInputs(true);
        }
    }, [userResponse]);

    const debouncedInputChange = debounce((id, value) => {
        setUserResponse({
            ...userResponse,
            values: {
                ...userResponse.values,
                [id]: value,
            },
        });
    }, 300);

    const isCancleVisible = false;

    return (
        <div>
            {evaluationUserInputFields?.title && (
                <h2 className={classes.title} data-testid="user-input-title">
                    {evaluationUserInputFields?.title}
                </h2>
            )}
            {evaluationUserInputFields?.description && (
                <Markdown>{evaluationUserInputFields.description}</Markdown>
            )}
            {evaluationUserInputFields?.inputs?.map((input, index) => (
                <UserInputComponent
                    key={index}
                    inputComponent={input}
                    onInputChange={debouncedInputChange}
                />
            ))}

            <div className={classes.buttonContainer}>
                <Button
                    appearance="primary"
                    onClick={() => onsubmitClick()}
                    disabled={insufficientInputs || isActionPerformed}
                    data-testid="user-input-submit-button"
                    aria-label={t('EvaluationUserInputFields.AriaLabels.Submit')}
                >
                    {t('EvaluationUserInputFields.Submit')}
                </Button>
                {isCancleVisible && (
                    <Button
                        appearance="outline"
                        onClick={() => oncancelClick()}
                        icon={<FailedIcon />}
                        disabled={isActionPerformed}
                        data-testid="user-input-cancel-button"
                        aria-label={t('EvaluationUserInputFields.AriaLabels.Cancel')}
                    >
                        {t('EvaluationUserInputFields.Cancel')}
                    </Button>
                )}
            </div>
        </div>
    );
}
