import {useGetCapacities} from '@/api/capacities';
import {useGetWorkspacesForAdminFre} from '@/api/workspaces';
import {TOUR_STEPS} from './AdminTour.types';
import useGetTenantInfoForAdminFre from '@/api/tenant/useGetTenantInfoForAdminFre';
import {useGetAccount} from '@/api/accounts';
import {FidelisApiError, FidelisErrorType} from '@/api/api';
import MedeinaFeatures from '@/util/features';
import {Capacity} from '@/api/capacities/capacities.types';

export default function useManageTransitionDetails() {
    const shouldRunOnRender = false;

    const {refetch: refetchTenantInfo} = useGetTenantInfoForAdminFre({
        enabled: shouldRunOnRender,
    });

    const {refetch: refetchWorkspaces} = useGetWorkspacesForAdminFre({
        enabled: shouldRunOnRender,
    });

    const {refetch: refetchCapacities} = useGetCapacities({
        enabled: shouldRunOnRender,
    });

    const {refetch: refetchAccount} = useGetAccount({
        enabled: shouldRunOnRender,
    });

    const validatePreSwitchCapacitySetup = (): Promise<TOUR_STEPS> => {
        return new Promise(async (resolve, reject) => {
            try {
                const [tenantInfoResponse, workspaceResponse, capacitiesResponse, accountResponse] =
                    await Promise.all([
                        refetchTenantInfo(),
                        refetchWorkspaces(),
                        refetchCapacities(),
                        refetchAccount({
                            cancelRefetch: !MedeinaFeatures.MultiWorkspaceEnabled,
                        }),
                    ]);

                // If Fre requirements are met, redirect to the home page
                if (Boolean(tenantInfoResponse?.data?.freRequirements?.freRequirementsMet)) {
                    resolve(TOUR_STEPS.HOME);
                }

                if (MedeinaFeatures.MultiWorkspaceEnabled) {
                    const error = accountResponse.error as FidelisApiError;
                    if (error?.errorType === FidelisErrorType.ACCOUNT_GEO_NOT_EXISTS) {
                        // If the account is not present or geo isn't configured, then we should redirect to the Tenant Creation stage
                        resolve(TOUR_STEPS.Welcome);
                    } else if (accountResponse.isError) {
                        // If there is an error other than account not present, then we should reject the promise
                        reject();
                    }

                    if (!Boolean(workspaceResponse?.data?.value?.[0]?.name)) {
                        // If there is no default workspace, then we should redirect to the Welcome screen for workspace setup
                        resolve(TOUR_STEPS.Welcome);
                    }
                }

                // Check if there is no tenant info
                if (!tenantInfoResponse?.data) {
                    resolve(TOUR_STEPS.Welcome);
                } else {
                    const capacities = capacitiesResponse?.data?.value;
                    // Check if there are no capacities
                    if (!capacities || capacities.length === 0) {
                        resolve(TOUR_STEPS.CreateCapacity);
                    }
                }
                // Default case, resolve to None
                resolve(TOUR_STEPS.None);
            } catch (error) {
                reject();
            }
        });
    };

    const validatePreCapacityOnCreateSetup = (): Promise<TOUR_STEPS> => {
        return new Promise(async (resolve, reject) => {
            try {
                const [tenantInfoResponse, workspaceResponse, accountResponse] = await Promise.all([
                    refetchTenantInfo(),
                    refetchWorkspaces(),
                    refetchAccount({
                        cancelRefetch: !MedeinaFeatures.MultiWorkspaceEnabled,
                    }),
                ]);

                // If Fre requirements are met, redirect to the home page
                if (Boolean(tenantInfoResponse?.data?.freRequirements?.freRequirementsMet)) {
                    resolve(TOUR_STEPS.HOME);
                }

                if (MedeinaFeatures.MultiWorkspaceEnabled) {
                    const error = accountResponse.error as FidelisApiError;
                    if (error?.errorType === FidelisErrorType.ACCOUNT_GEO_NOT_EXISTS) {
                        // If the account is not present or geo isn't configured, then we should redirect to the Tenant Creation stage
                        resolve(TOUR_STEPS.Welcome);
                    } else if (accountResponse.isError) {
                        // If there is an error other than account not present, then we should reject the promise
                        reject();
                    }

                    if (!Boolean(workspaceResponse?.data?.value?.[0]?.name)) {
                        // If there is no default workspace, then we should redirect to the Welcome screen for workspace setup
                        resolve(TOUR_STEPS.Welcome);
                    }

                    if (!Boolean(workspaceResponse?.data?.value?.[0]?.name)) {
                        // If there is no default workspace, then we should redirect to the Welcome screen for workspace setup
                        resolve(TOUR_STEPS.Welcome);
                    }
                }

                // Check if the current step is CreateCapacity and there is no tenant info
                if (!tenantInfoResponse?.data) {
                    resolve(TOUR_STEPS.Welcome);
                }
                // Default case, resolve to None
                resolve(TOUR_STEPS.None);
            } catch (error) {
                reject();
            }
        });
    };

    const validatePostWorkspaceSetup = (): Promise<TOUR_STEPS> => {
        return new Promise(async (resolve, reject) => {
            try {
                const [
                    tenantInfoResponse,
                    workspacesResponse,
                    capacitiesResponse,
                    accountResponse,
                ] = await Promise.all([
                    refetchTenantInfo(),
                    refetchWorkspaces(),
                    refetchCapacities(),
                    refetchAccount({
                        cancelRefetch: !MedeinaFeatures.MultiWorkspaceEnabled,
                    }),
                ]);

                // Access the responses of each API call
                const tenantInfo = tenantInfoResponse.data;
                const workspaces = workspacesResponse.data;
                const capacities = capacitiesResponse.data;

                const hasCapacities = capacities?.count ?? 0 > 0;
                const hasWorkspaces = workspaces?.count ?? 0 > 0;
                const isWorkpaceAttachedToCapacity = Boolean(
                    workspaces?.value?.[0]?.capacity?.referenceName,
                );
                const isTenantRecordAvailable = Boolean(tenantInfo);

                // If Fre requirements are met, redirect to the home page
                if (Boolean(tenantInfo?.freRequirements?.freRequirementsMet)) {
                    resolve(TOUR_STEPS.HOME);
                }

                if (MedeinaFeatures.MultiWorkspaceEnabled) {
                    const error = accountResponse.error as FidelisApiError;
                    if (error?.errorType === FidelisErrorType.ACCOUNT_GEO_NOT_EXISTS) {
                        // If the account is not present or geo isn't configured, then we should redirect to the Tenant Creation stage
                        resolve(TOUR_STEPS.Welcome);
                    } else if (accountResponse.isError) {
                        // If there is an error other than account not present, then we should reject the promise
                        reject();
                    }
                }

                // Determining redirection overrides irrespective of FRE stage if Tenant Record is available
                // It could be that there need not be an override
                // If there are no capacities, then we should redirect to the capacity setup screen,
                if (isTenantRecordAvailable) {
                    if (!hasCapacities) {
                        resolve(TOUR_STEPS.CreateCapacity);
                    } else {
                        // Conditions while capacities are present and tenant record is available

                        if (hasWorkspaces && !isWorkpaceAttachedToCapacity) {
                            // If a workspace is present and is not attached to a capacity,
                            // then we should redirect to the capacity selection screen
                            resolve(TOUR_STEPS.SelectCapacity);
                        } else if (!hasWorkspaces) {
                            // If there are no workspaces, then we should redirect to the workspace setup screen
                            resolve(TOUR_STEPS.Welcome);
                        } else {
                            // If there are workspaces, capacities and they are attached to a capacity, then we should not redirect to any particular step
                            resolve(TOUR_STEPS.None);
                        }
                    }
                } else {
                    // If there is no tenant record, then we should redirect to the welcome screen
                    resolve(TOUR_STEPS.Welcome);
                }
            } catch (error) {
                reject();
            }
        });
    };

    const validateWelcomeScreenTransition = (): Promise<TOUR_STEPS> => {
        return new Promise(async (resolve, reject) => {
            try {
                const [accountDetails, workspaceDetails] = await Promise.all([
                    refetchAccount(),
                    refetchWorkspaces(),
                ]);
                if (MedeinaFeatures.MultiWorkspaceEnabled) {
                    if (Boolean(accountDetails.error)) {
                        if (accountDetails.error instanceof FidelisApiError) {
                            if (
                                (accountDetails.error as FidelisApiError).errorType ===
                                FidelisErrorType.ACCOUNT_GEO_NOT_EXISTS
                            ) {
                                // If the account is not present or geo isn't configured,
                                // then we should redirect to the location setup screen
                                resolve(TOUR_STEPS.SelectLocation);
                                return;
                            }
                        }

                        // If there is an error other than account not present, then we should reject the promise
                        reject();
                        return;
                    } else if (!Boolean(workspaceDetails?.data?.value?.[0]?.name)) {
                        // If there is no default workspace, then we should redirect to the Welcome screen for workspace setup
                        resolve(TOUR_STEPS.SelectLocation);
                        return;
                    }
                }

                // The premise here is that if account is present, capacities API should not throw an error
                const tourStep = await determineCapacityStep();
                resolve(tourStep as TOUR_STEPS);
            } catch (error) {
                reject();
            }
        });
    };

    const validateMultiWorkspaceWelcomeScreenTransition = (): Promise<TOUR_STEPS> => {
        return new Promise(async (resolve, reject) => {
            try {
                const [accountDetails, workspaceDetails] = await Promise.all([
                    refetchAccount(),
                    refetchWorkspaces(),
                ]);

                if (Boolean(accountDetails.error)) {
                    if (accountDetails.error instanceof FidelisApiError) {
                        if (
                            (accountDetails.error as FidelisApiError).errorType ===
                            FidelisErrorType.ACCOUNT_GEO_NOT_EXISTS
                        ) {
                            // If the account is not present or geo isn't configured,
                            // then we should redirect to the location setup screen
                            resolve(TOUR_STEPS.SelectLocation);
                            return;
                        }
                    }

                    // If there is an error other than account not present, then we should reject the promise
                    reject();
                    return;
                } else if (!Boolean(workspaceDetails?.data?.value?.[0]?.name)) {
                    // If there is no default workspace, then we should redirect to the Welcome screen for workspace setup
                    resolve(TOUR_STEPS.SelectLocation);
                    return;
                }

                // The premise here is that if account is present, capacities API should not throw an error
                const tourStep = await determineCapacityStep();
                resolve(tourStep as TOUR_STEPS);
            } catch (error) {
                reject();
            }
        });
    };

    const getStepAfterLocationSelection = (): Promise<TOUR_STEPS> => {
        return new Promise(async (resolve, reject) => {
            try {
                const tourStep = await determineCapacityStep();
                resolve(tourStep as TOUR_STEPS);
            } catch (error) {
                reject();
            }
        });
    };

    const determineCapacityStep = async (): Promise<
        TOUR_STEPS.CreateCapacity | TOUR_STEPS.SelectCapacity
    > => {
        return new Promise(async (resolve, reject) => {
            try {
                const capacities = await refetchCapacities();
                if (Boolean(capacities.error)) {
                    reject(capacities.error);
                    return;
                }

                const filteredCapacities = capacities?.data?.value?.filter(
                    (x: Capacity) => x.id !== '',
                );
                if (!filteredCapacities || filteredCapacities?.length === 0) {
                    resolve(TOUR_STEPS.CreateCapacity);
                } else {
                    resolve(TOUR_STEPS.SelectCapacity);
                }
            } catch (error) {
                reject();
            }
        });
    };

    return {
        validatePostWorkspaceSetup,
        validatePreSwitchCapacitySetup,
        validatePreCapacityOnCreateSetup,
        validateWelcomeScreenTransition,
        getStepAfterLocationSelection,
        validateMultiWorkspaceWelcomeScreenTransition,
    };
}
