import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    image: {
        height: '100%',
        width: '100%',
    },
    skeleton: {
        height: tokens.fontSizeBase500,
        width: '50%',
    },
    dismissColor: {
        color: tokens.colorStatusDangerForeground3,
        height: '100%',
        width: '100%',
    },
});
