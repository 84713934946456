import {useQuery, useQueryClient} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {GetSkillsetRequest} from './skills.types';

export default function useGetSkillsetCode(body: GetSkillsetRequest) {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    return useQuery({
        queryKey: ['skillset-code', body.skillset],
        queryFn: async () => {
            if (!body.skillset || body.skillset === '') {
                throw new Error('Skillset name is required');
            }

            const queryParams = '?scope=User';
            const skillsetCode = await customFetch<Response>(
                `/skillsets/${body.skillset}${queryParams}`,
                {
                    method: 'GET',
                },
                true,
            );

            if (!skillsetCode.ok) {
                const error = {
                    code: skillsetCode.status,
                    message: skillsetCode.statusText,
                };
                throw error;
            }

            return await skillsetCode.text();
        },
    });
}
