import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    closeButton: {
        position: 'absolute',
        right: '-15px',
    },
    dialogContentSection: {
        marginBottom: '36px',
        marginTop: tokens.spacingVerticalS,
    },
    dismissIcon: {
        height: '24px',
        width: '24px',
    },
    titleSection: {
        marginBottom: tokens.spacingVerticalMNudge,
        display: 'block',
    },
    contentSection: {
        marginBottom: tokens.spacingVerticalM,
    },
    titleContainer: {
        display: 'flex',
    },
    rightActions: {
        display: 'flex',
        marginLeft: 'auto',
        gap: '0.5rem', // Space between right buttons
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: tokens.spacingVerticalXXXL,
    },
});
