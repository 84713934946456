import React from 'react';
import {ApplicationInsights} from '@microsoft/1ds-analytics-web-js';
import {MsalProvider} from '@azure/msal-react';
import MedeinaFeatures from '@/util/features';
import MsalAuthenticationTemplate from '@/components/msal/MsalAuthenticationTemplate';
import {hasStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';

interface MsalAuthenticationProviderProps {
    msalInstance?: any;
    children: React.ReactNode;
}

/** A feature flagged implementation of the original authentication provider
 *
 */
function MsalAuthenticationProvider(props: MsalAuthenticationProviderProps) {
    const isAuth2Enabled = hasStaticFeatureFlag(MedeinaFeatures.EnableAuth2Provider);
    return (
        <MsalProvider instance={props.msalInstance}>
            <MsalAuthenticationTemplate>{props.children}</MsalAuthenticationTemplate>
        </MsalProvider>
    );
}

export default MsalAuthenticationProvider;
