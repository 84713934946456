import {PromptbookPrompts, PromptInputs, PromptMode} from '@/api/promptbooks';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import useClasses from './PromptbookPrompt.styles';
import {
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Switch,
    ToolbarButton,
    Tooltip,
    mergeClasses,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {
    AcceptIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    DeleteIcon,
    DismissIcon,
    EditIcon,
    SkillIcon,
} from '@/components/ui/icons';
import {EMPTY_PROMPTBOOK_PROMPT_CONTENT} from './Promptbook.types';
import {MoreHorizontalIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import PromptbookPromptForm from './PromptbookPromptForm';
import {SkillDescriptor} from '@/api/skills';
import DirectSkillsMenu from '../../ui/PromptBar/PromptBarMenu';
import {PromptType} from '@/api/prompts';
import Textarea from '@/components/ui/Textarea';
import ReactDOM from 'react-dom';
export interface PromptbookPromptProps {
    promptDefn: PromptbookPrompts;
    totalItems: number;
    // Optional index for use in lists
    index?: number;
    promptMode: PromptMode;
    onPromptDeleted?: (index: number) => void;
    onPromptMovedDown?: (index: number) => void;
    onPromptMovedUp?: (index: number) => void;
    onPromptEdited?: (index: number, promptDefn: PromptbookPrompts) => void;
    onPromptDataLoaded?: (index: number, promptDefn: PromptbookPrompts) => void;
}

export default function PromptbookPrompt(props: PromptbookPromptProps) {
    const isDirectSkillInvocationEnabled = useFeatureFlag(
        MedeinaFeatures.EnableDirectSkillsInPromptbook,
    );
    const isPromptbookContinueOnFailureEnabled = useFeatureFlag(
        MedeinaFeatures.EnablePromptbookContinueOnFailure,
    );
    const classes = useClasses();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const editIconRef = useRef<HTMLButtonElement>(null);
    const [editMode, setEditMode] = useState(props.promptDefn.editMode ?? false);
    const [tempPromptDefn, setTempPromptDefn] = useState<PromptbookPrompts>(props.promptDefn);
    const [promptDefn, setPromptDefn] = useState<PromptbookPrompts>(props.promptDefn);
    const [skillListOpen, setSkillListOpen] = useState<boolean>(false);
    const inputWrapperRef = useRef<HTMLDivElement>(null);
    const {t} = useTranslation('promptbooks');
    // handlers for restoring focus when switching from edit mode back to normal mode
    const restoreFocusSource = useRestoreFocusSource();
    const restoreFocusTarget = useRestoreFocusTarget();
    const promptRef = useRef<HTMLDivElement | null>(null);
    const modalView =
        props.promptMode == PromptMode.Modal || props.promptMode == PromptMode.Promptbar;

    // Used to adjust position of menu based on viewport.
    const calculateMenuPosition = () => {
        if (promptRef.current) {
            const rect = promptRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const menuWidthOffset = modalView ? -20 : -520;
            const topOffsetFactor = rect.top / viewportHeight;
            // Offset is lower near the top of the viewport and higher as it goes down.
            const menuHeightOffset = -20 + topOffsetFactor * 500;

            const x = rect.left - menuWidthOffset;
            const y = rect.top - menuHeightOffset;

            setMenuPosition({x, y});
        }
    };
    const [menuPosition, setMenuPosition] = useState({x: 0, y: 0});

    const handleEditPrompt = (promptDefn: PromptbookPrompts) => {
        setEditMode(true);
        setTempPromptDefn(promptDefn);
        isDirectSkillInvocationEnabled && calculateMenuPosition();
    };

    const handleDeletePrompt = (index: number) => {
        if (index > -1) {
            props.onPromptDeleted?.(index);
        }
    };

    const handlePromptMoveUp = (index: number) => {
        if (index > -1) {
            props.onPromptMovedUp?.(index);
        }
        isDirectSkillInvocationEnabled && calculateMenuPosition();
    };

    const handlePromptMoveDown = (index: number) => {
        if (index > -1) {
            props.onPromptMovedDown?.(index);
        }
        isDirectSkillInvocationEnabled && calculateMenuPosition();
    };

    const handleResetSkill = (index: number) => {
        const newDefn: PromptbookPrompts = {
            ...tempPromptDefn!,
            skillName: undefined,
            skill: undefined,
            skillInputDescriptors: [],
            promptInputs: promptDefn.promptInputs ?? {},
            skillInputs: {},
            plugins: [],
            checkValidity: false,
            promptType: PromptType.Prompt,
            continueOnFailure: false,
        };
        setTempPromptDefn(newDefn);
    };

    // Handle skill selection.
    const handleSelect = (skill: SkillDescriptor) => {
        const skillset = skill?.skillsetName;
        const newDefn: PromptbookPrompts = {
            ...tempPromptDefn!,
            promptType: PromptType.Skill,
            skillName: skill?.name ?? skill?.displayName,
            skill: skill,
            skillInputs: skill?.inputs.reduce((values, input) => {
                if (input.required) {
                    values[input.name] = '';
                }
                return values;
            }, {} as PromptInputs),
            plugins: skillset ? [skillset] : [],
            skillInputDescriptors: skill?.inputs,
        };
        setTempPromptDefn(newDefn);
        setSkillListOpen(false);
        textAreaRef.current?.focus();
    };

    const handleAcceptEditPrompt = (index: number) => {
        setPromptDefn(tempPromptDefn);
        props.onPromptEdited?.(index, tempPromptDefn);
        setEditMode(false);
    };

    const handleClosePromptEditing = () => {
        setTempPromptDefn(props.promptDefn);
        setEditMode(false);
    };

    useEffect(() => {
        if (editMode && textAreaRef && textAreaRef.current) {
            textAreaRef.current.focus();
        } else if (!editMode && editIconRef && editIconRef.current) {
            editIconRef.current.focus();
        }
    }, [editMode]);

    const handleInputChange = (inputs: PromptInputs, type: string) => {
        const newDefn: PromptbookPrompts = {
            ...tempPromptDefn!,
            [type]: inputs,
        };
        setTempPromptDefn(newDefn);
        props.onPromptEdited?.(props.index ?? -1, newDefn);
    };

    const handleContinueOnFailureChange = (continueOnFailure: boolean) => {
        const newDefn: PromptbookPrompts = {
            ...tempPromptDefn!,
            continueOnFailure: continueOnFailure,
        };
        setTempPromptDefn(newDefn);
    };

    // Calculate what the menu height should be on first render
    useEffect(() => {
        calculateMenuPosition();
        window.addEventListener('resize', calculateMenuPosition);
        return () => {
            window.removeEventListener('resize', calculateMenuPosition);
        };
    }, []);

    useEffect(() => {
        tempPromptDefn.inputs = {...tempPromptDefn.skillInputs, ...tempPromptDefn.promptInputs};
    }, [tempPromptDefn]);

    const getDocumentIdForPortal = () => {
        switch (props.promptMode) {
            case PromptMode.Modal:
                return document.getElementById('promptbook-create-form')!;
            case PromptMode.Promptbar:
                return document.getElementById('promptbar-promptbook-form')!;
            case PromptMode.Session:
                return document.getElementById('main-content')!;
            default:
                return document.getElementById('main-content')!;
        }
    };

    function renderEditModeContent() {
        return (
            <div
                className={mergeClasses(classes.flexContainerColumn, classes.promptLabel)}
                data-test-id={'edit-duplicate-prompt'}
                {...restoreFocusSource}
                ref={promptRef}
            >
                <Textarea
                    className={mergeClasses(classes.flexGrow, classes.promptTextArea)}
                    style={
                        isDirectSkillInvocationEnabled
                            ? {width: modalView ? '420px' : '470px', borderWidth: '0px'}
                            : {width: modalView ? '460px' : '510px', borderWidth: '0px'}
                    }
                    placeholder={t(EMPTY_PROMPTBOOK_PROMPT_CONTENT)}
                    value={tempPromptDefn.content}
                    data-testid="edit-prompt-textarea"
                    onChange={(e) => {
                        setTempPromptDefn({
                            ...tempPromptDefn!,
                            content: e.target.value,
                        });
                    }}
                    autosize
                    size="medium"
                    ref={textAreaRef}
                />
                {tempPromptDefn.skillName && (
                    <div className={classes.skill}>
                        <Button
                            appearance="outline"
                            shape="rounded"
                            icon={<SkillIcon className={classes.skillIcon} />}
                            onClick={() => handleResetSkill(props.index ?? -1)}
                            data-testid="prompt-remove-skill-button"
                        >
                            {tempPromptDefn.skillName}
                            <DismissIcon className={classes.dismissIcon} />
                        </Button>
                    </div>
                )}
                <div className={classes.promptbookInputActionsContainer}>
                    {renderToolbarContent()}
                </div>
            </div>
        );
    }

    function renderNormalModeContent() {
        return (
            <div className={mergeClasses(classes.flexGrow, classes.flexContainerRow)}>
                <div className={classes.promptNumber}>{props.index ? props.index! + 1 : 1}</div>
                <div className={classes.promptFormWrapper}>
                    <PromptbookPromptForm
                        promptContent={props.promptDefn.content}
                        skillName={props.promptDefn.skillName}
                        skillInputDescriptors={props.promptDefn.skillInputDescriptors ?? []}
                        skillInputs={props.promptDefn.skillInputs ?? {}}
                        promptInputs={props.promptDefn.promptInputs ?? {}}
                        checkValidity={props.promptDefn.checkValidity ?? false}
                        isModal={props.promptMode == PromptMode.Modal ? true : false}
                        onInputsChange={(inputs, type) => handleInputChange(inputs, type)}
                    />
                </div>
            </div>
        );
    }

    function renderPromptOptions() {
        return (
            <div
                className={mergeClasses(
                    classes.flexContainerRow,
                    classes.flexGrow,
                    classes.promptOptionsContainer,
                )}
            >
                <ToolbarButton
                    className={editMode ? classes.toolBarButton : classes.toolBarButtonPrompt}
                    appearance="subtle"
                    data-testid="edit-prompt"
                    icon={<EditIcon />}
                    aria-label={t('EditPrompt')}
                    role="button"
                    ref={editIconRef}
                    onClick={() => handleEditPrompt(props.promptDefn)}
                />
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <Button
                            icon={<MoreHorizontalIcon />}
                            data-testid="more-options"
                            appearance="subtle"
                            className={
                                editMode ? classes.toolBarButton : classes.toolBarButtonPrompt
                            }
                            aria-label={t('SessionOptionsButton')}
                        />
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem
                                icon={<DeleteIcon />}
                                data-testid="delete-prompt"
                                onClick={() => {
                                    handleDeletePrompt(props.index ?? -1);
                                }}
                            >
                                {t('Delete')}
                            </MenuItem>
                            {props.index !== 0 && (
                                <MenuItem
                                    icon={<ArrowUpIcon />}
                                    data-testid="move-up-prompt"
                                    onClick={() => {
                                        handlePromptMoveUp(props.index ?? -1);
                                    }}
                                >
                                    {t('Move Up')}
                                </MenuItem>
                            )}
                            {props.index !== props.totalItems - 1 && (
                                <MenuItem
                                    icon={<ArrowDownIcon />}
                                    data-testid="move-down-prompt"
                                    onClick={() => {
                                        handlePromptMoveDown(props.index ?? -1);
                                    }}
                                >
                                    {t('Move Down')}
                                </MenuItem>
                            )}
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
        );
    }

    function renderToolbarContent() {
        return (
            <div
                className={mergeClasses(
                    classes.flexContainerRow,
                    classes.flexGrow,
                    classes.toolBarContainer,
                )}
            >
                {isDirectSkillInvocationEnabled && (
                    <>
                        <Tooltip
                            content={t('AddSystemCapability')}
                            relationship="label"
                            positioning="above"
                        >
                            <ToolbarButton
                                appearance="subtle"
                                icon={<SkillIcon />}
                                className={classes.toolBarButton}
                                aria-label={t('ChooseSkill')}
                                data-testid="choose-skill-prompt"
                                onClick={() => {
                                    calculateMenuPosition();
                                    setSkillListOpen(!skillListOpen);
                                }}
                            />
                        </Tooltip>
                        {ReactDOM.createPortal(
                            <div
                                className={classes.directSkillsMenuDiv}
                                style={{
                                    maxHeight: '500px',
                                    left: `${menuPosition.x}px`,
                                    top: `${menuPosition.y}px`,
                                }}
                            >
                                <DirectSkillsMenu
                                    data-testid="skill-menu"
                                    ref={inputWrapperRef}
                                    open={skillListOpen}
                                    onSkillSelect={(skill) =>
                                        handleSelect(skill as SkillDescriptor)
                                    }
                                    onHide={() => setSkillListOpen(false)}
                                    directSkillMenu={true}
                                />
                            </div>,
                            getDocumentIdForPortal(),
                        )}
                        <div className={classes.verticalLine} />
                    </>
                )}
                {isPromptbookContinueOnFailureEnabled && (
                    <>
                        <Menu persistOnItemClick={true}>
                            <MenuTrigger>
                                <Button
                                    className={classes.toolBarButton}
                                    icon={<MoreHorizontalIcon />}
                                    data-testid="more-prompt-options"
                                    appearance="subtle"
                                    aria-label={t('PromptOptionsButton')}
                                />
                            </MenuTrigger>
                            <MenuPopover>
                                <MenuList>
                                    {isPromptbookContinueOnFailureEnabled && (
                                        <>
                                            <MenuItem data-testid="continue-on-failure">
                                                <Switch
                                                    label={t('ContinueOnFailure')}
                                                    checked={tempPromptDefn.continueOnFailure}
                                                    onChange={(_, data) => {
                                                        handleContinueOnFailureChange(data.checked);
                                                    }}
                                                />
                                            </MenuItem>
                                        </>
                                    )}
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </>
                )}
                <ToolbarButton
                    className={classes.toolBarButton}
                    appearance="subtle"
                    data-testid="accept-edit-prompt"
                    aria-label={t('AcceptEdit')}
                    icon={<AcceptIcon />}
                    onClick={() => handleAcceptEditPrompt(props.index ?? -1)}
                />
                <ToolbarButton
                    className={classes.toolBarButton}
                    appearance="subtle"
                    data-testid="close-edit-prompt"
                    aria-label={t('CloseEdit')}
                    icon={<DismissIcon />}
                    onClick={() => handleClosePromptEditing()}
                />
            </div>
        );
    }

    return (
        <>
            {props.promptDefn &&
                (editMode ? (
                    renderEditModeContent()
                ) : (
                    <div
                        className={mergeClasses(
                            classes.flexContainerRow,
                            classes.flexGrow,
                            classes.promptLabel,
                        )}
                        ref={promptRef}
                        role="group"
                        tabIndex={0}
                        data-test-id={'edit-duplicate-prompt'}
                        {...restoreFocusTarget}
                    >
                        {renderNormalModeContent()}
                        {renderPromptOptions()}
                    </div>
                ))}
        </>
    );
}
