export type AgentIdentity = {
    type?: string;
    capturedUserId: string | null;
    capturedUserTokenEstimatedExpiresOn: string | null;
    loginUrl: string | null;
    identityId: string | null;
    capturedObjectName: string | null;
};

export type AgentTrigger = {
    name?: string | null;
    description?: string | null;
    enabled: boolean;
    triggerId: string;
    identityId: string | null;
    lastUpdatedOn?: string | null;
    lastUpdatedBy?: string | null;
    settings: {[key: string]: string | null};
    pollPeriodSeconds?: number | null;
};

export type AgentRunStatus = 'Pending' | 'InProgress' | 'Completed' | 'Failed';

export type AgentScopeType = 'Workspace' | 'User';

export type AgentTriggerRun = {
    executionId?: string | null;
    triggerId: string;
    scopeId: string;
    scope: AgentScopeType;
    runId: string;
    sessionId: string;
    evaluationId: string;
    promptId: string;
    workspaceId: string;
    processSkillsetName: string | null;
    processSkillName: string | null;
    startTime: string | null;
    endTime: string | null;
    status: AgentRunStatus;
};

export type Agent = {
    agentDefinitionName: string;
    displayName?: string | null;
    agentId: string;
    enabled: boolean;
    settings: {[key: string]: string | null};
    triggers: Array<AgentTrigger>;
    identity?: AgentIdentity | null;
};

export type AgentDefinitionSettings = {
    name: string;
    label: string | null;
    description: string | null;
    hintText: string | null;
    required: boolean;
    defaultValue: string | null;
    value: string | null;
    acceptableValues: Array<string>;
};

export type AgentTriggerDefinition = {
    name: string;
    displayName?: string | null;
    description?: string | null;
    defaultPollPeriodSeconds?: number | null;
    fetchSkill?: string | null;
    processSkill: string;
    settings: Array<AgentDefinitionSettings>;
};

export type AgentDefinition = {
    name: string;
    displayName: string;
    description?: string | null;
    publisher?: string | null;
    product?: string | null;
    requiredSkillsets: Array<string>;
    triggers: Array<AgentTriggerDefinition>;
    singleInstance: boolean;
    promptSkill?: string | null;
    feedbackSkill?: string | null;
    settings: Array<AgentDefinitionSettings>;
};

export type AgentExecution = {
    status: AgentRunStatus;
    numRuns: number;
    runIds: Array<string>;
    fetchSkillInputs?: {[key: string]: string | null} | null;
    processSkillInputs?: object | null;
    executionId?: string | null;
    sessionId?: string | null;
    promptId?: string | null;
    evaluationId?: string | null;
    fetchSkillsetName?: string | null;
    fetchSkillName?: string | null;
    processSkillsetName?: string | null;
    processSkillName?: string | null;
    startTime?: string | null;
    endTime?: string | null;
};

export type AgentIdentityLogin = {
    loginUrl: string;
};

export enum AgentScopeTypes {
    GLOBAL = 'Global',
    WORKSPACE = 'Workspace',
    USER = 'User',
}

// Query request types
export type AgentRequest = {
    agentId?: string;
    requestParams: AgentsRequestQueryParameters;
};

export type AgentDefinitionRequest = {
    agentDefinitionName?: string;
};

export type AgentTriggerRequest = {
    agentId?: string;
    triggerName?: string | null;
    runId?: string | null;
    executionId?: string | null;
    requestParams: AgentsRequestQueryParameters;
};

export type AgentDefinitionsRequestQueryParameters = {
    scope: AgentScopeTypes;
    publisher?: string;
    product?: string;
};

export type AgentsRequestQueryParameters = {
    scope: AgentScopeTypes;
    agentDefinitionName?: string;
    publisher?: string;
    product?: string;
};

// Mutation request types
export type GetAgentRequest = {
    agentId: string;
};

export type CreateAgentRequest = {
    body: Partial<Omit<Agent, 'identity' | 'triggers'>> & {
        identity?: Partial<AgentIdentity> | null;
    } & {triggers?: Array<Partial<AgentTrigger>>};
};

export type PatchAgentRequest = {
    agentId: string;
    body: Partial<Omit<Agent, 'triggers'>> & {triggers?: Array<Partial<AgentTrigger>>};
};

export type RunAgentTriggerRequest = {
    agentId: string;
    triggerName: string;
    body: {Inputs?: {[key: string]: string | null}};
};
