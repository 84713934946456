import {
    Button,
    Caption1,
    Dialog,
    DialogActions,
    DialogBody,
    DialogSurface,
    Label,
    Link,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    Title3,
    mergeClasses,
} from '@fluentui/react-components';
import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {DismissIcon, PromptbookIcon, RunIcon} from '@/components/ui/icons';
import Textarea from '@/components/ui/Textarea';
import useClasses from './PromptBarPromptbookForm.styles';
import {PromptbookInputDescriptor, PromptbookPrompts, PromptMode} from '@/api/promptbooks';
import {PromptInputs} from '@/api/prompts';
import {PromptBarPromptbookFormProps} from './PromptBarPromptbookForm.types';
import PromptbookPromptList from '@/components/sections/promptbooks/PromptbookPromptList';
import {useTranslation} from 'react-i18next';
import {ApiError} from '@/api/api';
import {ErrorMessage} from '@/api/errors.types';
import {useErrorMessages} from '@/api/errors';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

export * from './PromptBarSkillForm.types';

export default function PromptBarPromptbookForm({
    promptbook,
    promptbookInputs,
    promptbookPrompts,
    onCancel,
    onChange,
    onPromptsChange,
    onSubmit,
    open,
    errorCreateSessionWithPromptbook,
}: PromptBarPromptbookFormProps) {
    const classes = useClasses();
    const inputRefs = useRef<{[key: string]: HTMLTextAreaElement | null}>({});
    const {t: tCommmon} = useTranslation('common');
    const {t} = useTranslation('promptbooks');
    const {t: tSession} = useTranslation('session');

    // Generate input defaults.
    const inputs = promptbook.promptbookinputs;
    const prompts = promptbook.prompts;

    const [inputValues, setInputValues] = useState<PromptInputs>(
        promptbookInputs ??
            inputs.reduce((values, input) => {
                return values;
            }, {} as PromptInputs),
    );

    const [promptValues, setPromptValues] = useState<PromptbookPrompts[]>(prompts);
    const [runState, setRunState] = useState<boolean>(false);
    const [closeDisabled, setCloseDisabled] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | string | undefined>(undefined);
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const [hasEmpty, setHasEmpty] = useState<boolean>(true);
    const [isSubmitPressed, setIsSubmitPressed] = useState<boolean>(false);
    const isDirectSkillInvocationEnabled = useFeatureFlag(
        MedeinaFeatures.EnableDirectSkillsInPromptbook,
    );
    const {getErrorMessage} = useErrorMessages();

    // Reset parent state initially, otherwise it could include stale or empty data.
    useEffect(() => {
        onChange?.(inputValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Focus the first input on mount.
    useEffect(() => {
        if (inputs && inputs.length > 0 && inputs[0].name) {
            inputRefs.current[inputs[0].name]?.focus();
        }
    }, []);

    useEffect(() => {
        if (
            errorCreateSessionWithPromptbook !== undefined &&
            errorCreateSessionWithPromptbook !== null
        ) {
            setRunState(false);

            if (
                errorCreateSessionWithPromptbook instanceof ApiError &&
                errorCreateSessionWithPromptbook.response
            ) {
                if (errorCreateSessionWithPromptbook.response?.status === 403) {
                    const errorMessage = tCommmon('Errors.NoPermissionForFeature');
                    setErrorMessage(errorMessage);
                } else if (errorCreateSessionWithPromptbook.response?.status === 429) {
                    // For capacity middleware errors, we are using the status code to get the error message.

                    const errorMessage = getErrorMessage(429);
                    setErrorMessage(errorMessage);

                    setRunState(false);
                } else {
                    errorCreateSessionWithPromptbook.response.text().then((e) => {
                        const parsedError = JSON.parse(e);
                        let errorMessage =
                            parsedError.detail ??
                            parsedError.message ??
                            parsedError.errorMessage ??
                            parsedError.value ??
                            e.toString();

                        setErrorMessage(errorMessage);
                        setCloseDisabled(false);
                    });
                }
            } else {
                setErrorMessage(errorCreateSessionWithPromptbook.toString());
            }
        } else {
            setErrorMessage(undefined);
        }
    }, [errorCreateSessionWithPromptbook]);

    // sets the input fields in prompts so it can be accessed by PromptbookPromptList
    const setPromptInputs = (
        prompts: PromptbookPrompts[],
        inputs: PromptbookInputDescriptor[],
    ): PromptbookPrompts[] => {
        if (!isDirectSkillInvocationEnabled) {
            return prompts;
        } else {
            prompts.map((prompt) => {
                const result: PromptInputs = {};
                const matchedInputs = inputs.filter((input) =>
                    prompt.content?.includes(`<${input.name}>`),
                );
                matchedInputs.forEach((value, index) => {
                    result[value.name] = '';
                });
                prompt.promptInputs = result;
            });
            return prompts;
        }
    };
    const updatedPrompts = setPromptInputs(prompts, inputs);

    // Update state based on form changes.
    const handleChange = useCallback(
        (ev: ChangeEvent<HTMLTextAreaElement>, input: PromptbookInputDescriptor) => {
            const nextValues = {...inputValues, [input.name]: ev.target.value};
            setInputValues(nextValues);
            onChange?.(nextValues);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange],
    );

    const handleRunClick = () => {
        // Check for empty inputs
        if (isDirectSkillInvocationEnabled) {
            setIsSubmitPressed(!isSubmitPressed);
            if (hasEmpty) return;
        }
        setRunState(true); // Disable the button when it is clicked
        onSubmit?.();
    };

    const checkForEmptyInputs = (promptDefn: PromptbookPrompts[]) => {
        let checkEmpty = false;
        promptDefn.forEach((prompt) => {
            if (prompt.content && prompt.content?.trim() !== '') {
                const matchedInputs = inputs.filter((input) =>
                    prompt.content?.includes(`<${input.name}>`),
                );
                matchedInputs.forEach((value, index) => {
                    if (prompt.promptInputs && prompt.promptInputs[value.name] === '') {
                        checkEmpty = true;
                        setHasEmpty(checkEmpty);
                        return;
                    }
                });
            } else if (prompt.promptType === 'Skill') {
                prompt.skillInputDescriptors?.forEach((value, index) => {
                    if (value.required && !prompt.skillInputs?.[value.name]) {
                        checkEmpty = true;
                        setHasEmpty(checkEmpty);
                        return;
                    }
                });
            }
        });
        setHasEmpty(checkEmpty);
    };

    const handlePromptListDataChange = (promptDefn: PromptbookPrompts[]) => {
        setPromptValues(promptDefn);
        onPromptsChange?.(promptDefn);
        if (isDirectSkillInvocationEnabled) {
            checkForEmptyInputs(promptDefn);
            // set the promptbook inputs
            const promptInputsObject = promptDefn.reduce((acc, prompt) => {
                return {
                    ...acc,
                    ...prompt.promptInputs,
                    ...prompt.skillInputs,
                };
            }, {} as PromptInputs);
            onChange?.(promptInputsObject);
        }
    };

    // Check if all input values are provided
    const isValidated = useMemo(() => {
        return isDirectSkillInvocationEnabled
            ? true
            : inputs && inputs.every((input) => !!inputValues[input.name]);
    }, [inputs, inputValues]);

    return (
        <Dialog modalType="modal" open={open}>
            <DialogSurface id="promptbar-promptbook-form" className={classes.root}>
                <DialogActions className={classes.buttonContainer}>
                    <Button
                        icon={<RunIcon />}
                        onClick={handleRunClick}
                        className={mergeClasses((!isValidated || runState) && classes.runDisabled)}
                        disabled={!isValidated || runState}
                        appearance="primary"
                    >
                        {t('SubmitButton')}
                    </Button>
                    <Button
                        appearance="transparent"
                        icon={<DismissIcon />}
                        onClick={onCancel}
                        aria-label="close"
                        disabled={closeDisabled}
                        className={classes.dismissIcon}
                    ></Button>
                </DialogActions>
                <DialogBody className={classes.promptbookBody}>
                    <div>
                        {errorMessage && (
                            <MessageBar intent="error" layout="multiline">
                                <MessageBarBody>
                                    {typeof errorMessage === 'string'
                                        ? errorMessage
                                        : errorMessage?.message}
                                    {errorMessage &&
                                        typeof errorMessage !== 'string' &&
                                        errorMessage?.learnMoreText &&
                                        errorMessage?.learnMoreUri && (
                                            <>
                                                {' '}
                                                <Link href={errorMessage.learnMoreUri}>
                                                    {errorMessage.learnMoreText}
                                                </Link>
                                            </>
                                        )}
                                </MessageBarBody>
                                <MessageBarActions
                                    containerAction={
                                        <Button
                                            onClick={() => setErrorMessage(undefined)}
                                            aria-label={tSession('DismissButton')}
                                            appearance="transparent"
                                            icon={<DismissIcon />}
                                        />
                                    }
                                />
                            </MessageBar>
                        )}
                    </div>
                    <div className={classes.flexcontainer}>
                        <div>
                            <PromptbookIcon className={classes.promptbookIcon} />
                        </div>
                        <div className={classes.promptbookBody}>
                            <div className={classes.promptbook}>
                                <Title3 tabIndex={0} data-testid="promptbook-title">
                                    {promptbook.name}
                                </Title3>
                                <br></br>
                                <Caption1 tabIndex={0}>{promptbook.description}</Caption1>
                            </div>
                            {!isDirectSkillInvocationEnabled && (
                                <div className={classes.inputs}>
                                    {inputs &&
                                        inputs.map((input) => (
                                            <>
                                                <Label
                                                    id={`${input.name}-label`}
                                                    className={classes.labelStyle}
                                                >
                                                    {input.description}
                                                </Label>
                                                <Textarea
                                                    key={input.name}
                                                    ref={(el) =>
                                                        (inputRefs.current[input.name] = el)
                                                    }
                                                    value={inputValues[input.name] ?? ''}
                                                    size="large"
                                                    appearance="outline"
                                                    className={classes.textareaWrapper}
                                                    autosize
                                                    onChange={(ev) => handleChange(ev, input)}
                                                    onSubmit={handleRunClick}
                                                    aria-labelledby={`${input.name}-label`}
                                                />
                                            </>
                                        ))}
                                </div>
                            )}
                            <div tabIndex={0}>
                                {t('PromptsCount', {promptsCount: prompts.length})}
                            </div>
                            <div className={classes.prompts}>
                                {prompts && (
                                    <PromptbookPromptList
                                        promptMode={PromptMode.Promptbar}
                                        promptDefns={updatedPrompts}
                                        submitPromptbookPressed={isSubmitPressed}
                                        onPromptDataChange={handlePromptListDataChange}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
