import React from 'react';
import {useTranslation} from 'react-i18next';
import useClasses from './AgentRunHeader.styles';
import {StatusLabel} from '@sfe/react-status-label';
import {Title2} from '@fluentui/react-components';
import {formatLocaleDate as format} from '@/util/locale';
import {AgentTriggerRun} from '@/api/agents';

interface AgentRunHeaderProps {
    triggerRun: AgentTriggerRun | undefined;
    isLoading: boolean;
}

export default function AgentRunHeader(props: AgentRunHeaderProps) {
    const {isLoading, triggerRun} = props;
    const classes = useClasses();

    const {t: tAgents} = useTranslation('agents');

    return (
        <div className={classes.root}>
            {!isLoading && triggerRun?.startTime && (
                <Title2 role="heading">{format(triggerRun.startTime, 'M/d/y, h:mm a')}</Title2>
            )}
            {!isLoading && triggerRun && (
                <div className={classes.headerStatus} data-testid={'agent-results-header-status'}>
                    <StatusLabel
                        className={classes.statusLabel}
                        status={
                            triggerRun.status === 'Completed'
                                ? 'success'
                                : triggerRun.status === 'Failed'
                                ? 'failed'
                                : triggerRun.status === 'InProgress'
                                ? 'inProgress'
                                : 'pending'
                        }
                        text={
                            triggerRun.status === 'Completed'
                                ? tAgents('Agent.Completed')
                                : triggerRun.status === 'Failed'
                                ? tAgents('Agent.Failed')
                                : triggerRun.status === 'InProgress'
                                ? tAgents('Agent.InProgress')
                                : tAgents('Agent.Pending')
                        }
                    />
                </div>
            )}
        </div>
    );
}
