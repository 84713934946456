import {CustomProperties, MedeinaTelemetryEvent} from '@/api/telemetry/telemetry.types';
import {
    PublicClientApplication,
    IPublicClientApplication,
    AuthError,
    InteractionRequiredAuthError,
    RedirectRequest,
    Configuration,
    LogLevel,
} from '@azure/msal-browser';
import MedeinaVariables from '@/util/variables';
import MedeinaFeatures from '@/util/features';
import {getCachedUrlParamFlags, getStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import {ApplicationInsights} from '@microsoft/1ds-analytics-web-js';
import {AnyMedeinaTelemetryEvent, MedeinaEvent} from '@/api/telemetry/telemetry.types';
import {MsalInitializationOptions} from '@/components/MedeinaBase/Authentication/Authentication.types';

const loggingEnabled = getStaticFeatureFlag('loggingEnabled');

// Determine the API scope based on feature flags.
// This is applied on page load for performance and to prevent potential conflicts with navigation.
const defaultTokenScopes = (() => {
    if (typeof window !== 'undefined' && window.location) {
        const flags = getCachedUrlParamFlags(true);
        // For Fidelis integration.
        if (MedeinaFeatures.Fidelis && flags?.includes('fidelis')) {
            return [MedeinaVariables.FidelisScopeUri];
        }
        if (MedeinaFeatures.Fidelis && flags?.includes('fidelisafd')) {
            return [MedeinaVariables.FidelisAfdScopeUri];
        }
    }

    return [MedeinaVariables.ScopeUri];
})();

export let currentTenantId = 'common';

export function setCurrentTenantId(tenantId: string) {
    currentTenantId = tenantId;
}

export function getCurrentTenantId() {
    return currentTenantId;
}

export let msalConfig = (tenantId: string): Configuration => {
    return {
        auth: {
            clientId: `${MedeinaVariables.ClientId}`,
            authority: `https://login.microsoftonline.com/${tenantId}`,
            redirectUri: '/',
            postLogoutRedirectUri: '/',
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
        },
        system: {
            tokenRenewalOffsetSeconds: 300,
        },
    };
};

/** Factory function to create a loggerCallback with appInsights */
export function authLogger(
    logLevel: LogLevel,
    message: string,
    containsPii: boolean,
    appInsights: ApplicationInsights,
) {
    if (containsPii) {
        return;
    }
    switch (logLevel) {
        case 1:
            appInsights.trackEvent({
                name: MedeinaEvent.MsalEvent,
                data: {message, name: MedeinaTelemetryEvent.Auth.MSALWarning} as CustomProperties,
            });
            return;
        case 0:
            appInsights.trackException({
                exception: new Error(message),
                properties: {name: MedeinaTelemetryEvent.Auth.MSALError},
            });
            return;
        default:
            return;
    }
}

export function isLoggedIn(instance: IPublicClientApplication) {
    return instance.getAllAccounts().length > 0;
}

export let msalInstance = new PublicClientApplication(msalConfig(currentTenantId));

/** New helper function to initialize our authentication with telemetry from appInsights */
export function initializeMsalInstance({appInsights, tenantId}: MsalInitializationOptions) {
    // Create a new object to avoid mutating the original config
    // and wire the logger to appInsights
    const baseConfig = msalConfig(tenantId);
    const configOptions: Configuration = {
        ...baseConfig,
        system: {
            ...baseConfig.system,
            loggerOptions: loggingEnabled
                ? {
                      logLevel: LogLevel.Warning,
                      loggerCallback: (logLevel, message, containsPii) =>
                          authLogger(logLevel, message, containsPii, appInsights),
                  }
                : {},
        },
    };

    return configOptions;
}

// For GA, we will always be logging into Medeina scope. This may change in the future.
export const msalLoginRequest = {
    scopes: [MedeinaVariables.ScopeUri],
};

export const msalTokenRequest = {
    scopes: defaultTokenScopes,
};

export function signInErrorHandler(error: AuthError, tokenRequest: RedirectRequest) {
    // Acquisition failed, send an interactive request instead.
    if (error instanceof InteractionRequiredAuthError) {
        // If there's a claims challenge, include it in the request.
        if (error.claims) {
            tokenRequest.claims = error.claims;
        }

        msalInstance.acquireTokenRedirect(tokenRequest);
    } else {
        console.error('unexpected error occurred during authentication', error);
        throw new Error(error.errorMessage);
    }
}

export function signOut(instance: IPublicClientApplication) {
    return instance
        .handleRedirectPromise()
        .then(() => {
            return instance.logoutRedirect();
        })
        .catch((error) => {
            console.error(error);
        });
}

export function signIn(instance: IPublicClientApplication) {
    return instance
        .handleRedirectPromise()
        .then(() => {
            const accounts = instance.getAllAccounts();
            if (accounts.length === 0) {
                return instance.loginRedirect(msalLoginRequest);
            }
            return Promise.resolve();
        })
        .catch((error) => {
            signInErrorHandler(error, msalLoginRequest);
        });
}

// A helper function to check if the user is currently signed in
// if the user is not signed in it won't throw an error and just return false
export function isUserSignedIn(instance: IPublicClientApplication) {
    try {
        const activeAccount = instance.getActiveAccount();
        let hasExpiredToken = true;
        let isMatchingTenant = false;
        if (activeAccount && activeAccount.idTokenClaims?.exp) {
            hasExpiredToken = activeAccount?.idTokenClaims?.exp * 1000 < Date.now();
            isMatchingTenant = activeAccount.tenantId === activeAccount.idTokenClaims?.tid;
        }
        return Boolean(activeAccount && isMatchingTenant && !hasExpiredToken);
    } catch (e) {
        return false;
    }
}
