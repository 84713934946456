import React, {useMemo} from 'react';
import {
    createDOMRenderer,
    GriffelRenderer,
    RendererProvider,
    TextDirectionProvider,
} from '@griffel/react';
import {AppStateProvider} from '@/api/app';
import {CustomFluentProvider} from '@/components/theme';
import useClasses, {useStaticStyles} from '@/components/App.styles';

/** Local imports */
import SafeHydrate from './SafeHydrate';
import {MedeinaBaseProps} from './MedeinaBase.types';
/**
 * Medeina application's base component that offers
 * - FluentProvider
 * - ApplicationState
 * - Theme
 * - Telemetry /w error catching - pending
 * @returns
 */
function MedeinaBase(props: MedeinaBaseProps) {
    useStaticStyles();
    const classes = useClasses();
    // Create and cache a Griffel renderer.
    const renderer = useMemo<GriffelRenderer>(() => createDOMRenderer(), []);
    return (
        <SafeHydrate>
            <RendererProvider renderer={renderer}>
                <AppStateProvider>
                    <CustomFluentProvider className={classes.root}>
                        {props.children}
                    </CustomFluentProvider>
                </AppStateProvider>
            </RendererProvider>
        </SafeHydrate>
    );
}

export default MedeinaBase;
