import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {DEFAULT_API_RETRY_COUNT, WORKSPACE_PROVIDER_DEFAULT_STALE_TIME} from '../api.constants';
import {useCustomPutWorkspace, Workspace} from '@/api/workspaces';
import useIsCapacityExpired from '@/api/capacities/useIsCapacityExpired';
import {Capacity} from '@/api/capacities';

interface AvailableCapacitiesResponse {
    attachedOwnedCapacities: Capacity[];
    attachedUnownedCapacities: Capacity[];
    unattachedCapacities: Capacity[];
}

function useGetAvailableCapacities(
    ownedWorkspaces?: string[],
    workspaces?: Workspace[],
    capacities?: Capacity[],
    useQueryOptions?: Pick<
        UseQueryOptions<AvailableCapacitiesResponse>,
        'enabled' | 'refetchOnMount'
    >,
) {
    const {customPutWorkspace} = useCustomPutWorkspace();
    const isCapacityExpired = useIsCapacityExpired();

    const nonExpiredCapacities =
        capacities?.filter((capacity) => !isCapacityExpired(capacity)) || [];

    const ownedWorkspacesObj = workspaces?.filter((workspace) =>
        ownedWorkspaces?.includes(workspace.name),
    );
    const ownedWorkspaceCapacityNames = ownedWorkspacesObj?.map(
        (workspace) => workspace.capacity?.referenceName,
    );
    const attachedOwnedCapacities = nonExpiredCapacities?.filter((capacity) =>
        ownedWorkspaceCapacityNames?.includes(capacity.name),
    );
    const unownedOrDetachedCapacities = nonExpiredCapacities?.filter(
        (capacity) => !ownedWorkspaceCapacityNames?.includes(capacity.name),
    );

    // Any owned workspace to use for whatif call.
    const anyOwnedWorkspace = ownedWorkspacesObj?.[0];

    return useQuery<AvailableCapacitiesResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['capacitiesWhatIf'],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async (): Promise<AvailableCapacitiesResponse> => {
            let attachedUnownedCapacities: Capacity[] = [];
            let unattachedCapacities: Capacity[] = [];
            //If we are missing any data we can't proceed, return empty object.
            if (!anyOwnedWorkspace) {
                return {} as AvailableCapacitiesResponse;
            }

            await Promise.all(
                // Only check the capacities we do not own or are not attached to a workspace.
                (unownedOrDetachedCapacities || []).map(async (capacity) => {
                    //Check if user is owner of workspace by simulating a workspace update
                    let response = await customPutWorkspace<Response>({
                        request: {
                            name: anyOwnedWorkspace.name,
                            capacity: {
                                type: 'CapacityReference',
                                referenceName: capacity.name,
                            },
                        },
                        targetWorkspace: anyOwnedWorkspace,
                        whatIfHeader: true,
                    });

                    if (!response?.ok) {
                        attachedUnownedCapacities.push(capacity);
                    } else {
                        unattachedCapacities.push(capacity);
                    }
                }),
            );

            return {
                attachedOwnedCapacities,
                attachedUnownedCapacities,
                unattachedCapacities,
            } as AvailableCapacitiesResponse;
        },
        ...useQueryOptions,
    });
}

export default useGetAvailableCapacities;
