import React, {useEffect, useState} from 'react';
import useClasses from '@/components/sections/workspaces/ManageWorkspaces/ManageWorkspaces.styles';
import {
    Menu,
    MenuList,
    MenuTrigger,
    MenuPopover,
    MenuGroup,
    MenuDivider,
    MenuGroupHeader,
    MenuItem,
    MenuButton,
    useRestoreFocusTarget,
    useRestoreFocusSource,
    Dialog,
    Toast,
    ToastTitle,
    Toaster,
    useId,
    useToastController,
} from '@fluentui/react-components';
import UserSettingsModal from '@/components/Navigation/UserSettingsModal';
import {DividerShort16Icon} from '@/components/ui/icons';
import {useNavigate} from 'react-router-dom';
import {
    AddIcon,
    CheckmarkIcon,
    DataUsageIcon,
    WorkspacesIcon,
    SettingsIcon,
} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {Workspace, useGetWorkspaces} from '@/api/workspaces';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {useGetUserInfo} from '@/api/app';
import SetPreferredWorkspaceDialog from '@/components/SetPreferredWorkspaceDialog';
import WorkspaceForm from '@/components/sections/workspaces/form/WorkspaceForm';
import ErrorSettingWorkspace from './ErrorSettingWorkspace';
import useGetCurrentWorkspace from '@/api/user/useGetCurrentWorkspace';
import useUpdateCurrentWorkspace from '@/api/user/useUpdateCurrentWorkspace';
import {writeToSessionStorage} from '@/util/sessionStorage.util';
import {WORKSPACE_NAMESPACE} from '@/components/sections/workspaces/WorkspacesProvider/workspaces.constants';
import {WorkspaceSessionData} from '@/components/sections/workspaces/WorkspacesProvider';
import useGetPreferredEmbeddedWorkspace from '@/api/user/useGetPreferredEmbeddedWorkspace';
import useIsOwner from '@/api/app/useGetIsOwner';
import useUpdatePreferredEmbeddedWorkspace from '@/api/user/useUpdatePreferredEmbeddedWorkspace';
import {useQueryClient} from '@tanstack/react-query';

/** Dropdown menu for Workspace selection */
function WorkspaceSelector() {
    const {t: tAdmin} = useTranslation('admin');
    const workspaceClasses = useClasses();
    // feature flag
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    // for switching workspaces
    const {data: workspaceName, isFetched: currentWorkspaceFetched} = useGetCurrentWorkspace({
        enabled: Boolean(isWorkspacesTestingEnabled),
    });
    const [selectedWorkspace, setSelectedWorkspace] = useState(workspaceName);
    useEffect(() => {
        setSelectedWorkspace(workspaceName);
    }, [workspaceName]);

    // Comment out for now to avoid spammed dialogs - TODO: uncomment for testing
    const {data: preferredWorkspace, isFetched} = useGetPreferredEmbeddedWorkspace({
        enabled: Boolean(isWorkspacesTestingEnabled),
    });

    const updateUserPreferences = useUpdateCurrentWorkspace();
    const {data: user} = useGetUserInfo();
    const {isAnyOwner} = useIsOwner();
    const {data: workspacesData, isFetched: workspacesDataFetched} = useGetWorkspaces();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const restoreFocusTargetAttribute = useRestoreFocusTarget();
    const restoreFocusSourceAttribute = useRestoreFocusSource();
    const updatePreferredEmbeddedWorkspace = useUpdatePreferredEmbeddedWorkspace();
    // toast
    const queryClient = useQueryClient();
    const toasterId = useId('toaster');
    const {dispatchToast} = useToastController(toasterId);
    const notifyCreate = () => {
        dispatchToast(
            <Toast>
                <ToastTitle>{tAdmin('Workspaces.Form.WorkspaceCreated')}</ToastTitle>
            </Toast>,
            {position: 'bottom', intent: 'success'},
        );
    };
    const handleCreateSuccess = () => {
        notifyCreate();
        queryClient.invalidateQueries(['workspaces']);
        queryClient.invalidateQueries(['workspaces-initial']);
    };

    useEffect(() => {
        if (workspacesData && workspacesDataFetched && currentWorkspaceFetched) {
            if (workspacesData?.value?.length > 0) {
                const currentWorkspaceExists = workspacesData?.value.some(
                    (workspace: Workspace) => workspace.name === workspaceName,
                );
                if (!currentWorkspaceExists || !workspaceName) {
                    handleMenuItemClick(workspacesData.value[0]);
                }
            }
        }
    }, [workspacesData, workspaceName, workspacesDataFetched, currentWorkspaceFetched]);
    useEffect(() => {
        if (workspacesData && isFetched && workspacesDataFetched) {
            if (workspacesData?.value?.length > 0) {
                const preferredWorkspaceExists = workspacesData?.value.some(
                    (workspace: Workspace) => workspace.name === preferredWorkspace,
                );
                if (!preferredWorkspaceExists || !preferredWorkspace) {
                    if (!preferredWorkspaceExists) {
                        updatePreferredEmbeddedWorkspace.mutate(null);
                    }
                    setPreferredWorkspaceDialogOpen(true);
                }
            }
        }
    }, [workspacesData, preferredWorkspace, isFetched, workspacesDataFetched]);
    const handleMenuItemClick = (workspace: Workspace) => {
        if (selectedWorkspace === workspace.name) {
            return;
        }
        if (workspace.path) {
            // remove old workspace from session storage
            sessionStorage.removeItem(WORKSPACE_NAMESPACE);
            // go to switching workspace loading page
            navigate(`/workspace/${workspace.name}`);
            const workspaceData = {
                name: workspace.name,
                path: workspace.path,
                managementUrl: workspace.managementUrl,
                podId: workspace.path?.split('/')[1],
            };
            updateUserPreferences.mutate(
                {
                    currentWorkspace: workspace.name,
                },
                {
                    onSuccess: () => {
                        setSelectedWorkspace(workspace.name);
                        writeToSessionStorage<WorkspaceSessionData>(
                            WORKSPACE_NAMESPACE,
                            workspaceData,
                        );
                        setErrorSwitchingWorkspaceDialogOpen(false);
                        navigate(`/`);
                        setTimeout(() => {
                            window.location.reload();
                        }, 100);
                    },
                    onError: (error: any) => {
                        setErrorSwitchingWorkspaceDialogOpen(true);
                        navigate(`/`);
                        return;
                    },
                },
            );
        } else {
            setErrorSwitchingWorkspaceDialogOpen(true);
        }
    };
    const [workspaceDialogOpen, setWorkspaceDialogOpen] = useState(false);
    // preferred workspace alert
    const [isPreferredWorkspaceDialogOpen, setPreferredWorkspaceDialogOpen] =
        useState<boolean>(false);
    const [isSetErrorSwitchingWorkspaceDialogOpen, setErrorSwitchingWorkspaceDialogOpen] =
        useState<boolean>(false);
    const navigate = useNavigate();

    return isWorkspacesTestingEnabled ? (
        <>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <DividerShort16Icon />
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <MenuButton
                            appearance="subtle"
                            size="medium"
                            data-testid="workspace-selector-button"
                        >
                            {selectedWorkspace}
                        </MenuButton>
                    </MenuTrigger>
                    <MenuPopover className={workspaceClasses.menuPopover}>
                        <MenuList>
                            <MenuGroup>
                                <MenuGroupHeader>
                                    {tAdmin('ManageWorkspaces.MenuButtons.Workspaces')}
                                </MenuGroupHeader>
                                {workspacesData?.value?.map(
                                    (workspace: Workspace, index: number) => (
                                        <MenuItem
                                            key={index}
                                            icon={
                                                workspace.name === selectedWorkspace ? (
                                                    <CheckmarkIcon />
                                                ) : (
                                                    <div className={workspaceClasses.fillerIcon} />
                                                )
                                            }
                                            onClick={() => handleMenuItemClick(workspace)}
                                        >
                                            {workspace.name}
                                        </MenuItem>
                                    ),
                                )}
                            </MenuGroup>

                            {(isAnyOwner || user?.isAdmin) && (
                                <>
                                    <MenuDivider />
                                    <MenuGroup>
                                        <MenuItem
                                            icon={<WorkspacesIcon />}
                                            onClick={() => navigate('/manage-workspaces')}
                                        >
                                            {tAdmin(
                                                'ManageWorkspaces.MenuButtons.ManageWorkspaces',
                                            )}
                                        </MenuItem>
                                    </MenuGroup>
                                </>
                            )}
                            {(user?.isAdmin || user?.isGlobalAdmin) && (
                                <MenuGroup>
                                    <MenuItem
                                        icon={<DataUsageIcon />}
                                        onClick={() => navigate('/usage-monitoring')}
                                    >
                                        {tAdmin('ManageWorkspaces.MenuButtons.CapacityUsage')}
                                    </MenuItem>
                                    <MenuItem
                                        icon={<AddIcon />}
                                        data-testid="new-workspace-button"
                                        onClick={() => setWorkspaceDialogOpen(true)}
                                    >
                                        {tAdmin('ManageWorkspaces.MenuButtons.NewWorkspace')}
                                    </MenuItem>
                                </MenuGroup>
                            )}
                            <MenuDivider />
                            <MenuGroup>
                                <MenuItem
                                    icon={<SettingsIcon />}
                                    onClick={() => setSettingsOpen(true)}
                                    {...restoreFocusTargetAttribute}
                                >
                                    {tAdmin('ManageWorkspaces.MenuButtons.SetPreferredWorkspace')}
                                </MenuItem>
                            </MenuGroup>
                        </MenuList>
                    </MenuPopover>
                </Menu>
                <SetPreferredWorkspaceDialog
                    isSetPreferredWorkspaceDialogOpen={isPreferredWorkspaceDialogOpen}
                    onClose={() => {
                        setPreferredWorkspaceDialogOpen(false);
                    }}
                ></SetPreferredWorkspaceDialog>
                <Dialog
                    open={workspaceDialogOpen}
                    onOpenChange={(event, data) => setWorkspaceDialogOpen(data.open)}
                    {...restoreFocusSourceAttribute}
                >
                    <WorkspaceForm
                        mode="create"
                        onClose={() => {
                            setWorkspaceDialogOpen(false);
                        }}
                        onCreate={handleCreateSuccess}
                    />
                </Dialog>

                <ErrorSettingWorkspace
                    isOpen={isSetErrorSwitchingWorkspaceDialogOpen}
                    onClose={() => {
                        setErrorSwitchingWorkspaceDialogOpen(false);
                    }}
                    title={tAdmin('ManageWorkspaces.Errors.SwitchWorkspaces')}
                ></ErrorSettingWorkspace>
                <UserSettingsModal
                    data-testid="SettingsButton"
                    open={settingsOpen}
                    setOpen={setSettingsOpen}
                    setMenuOpen={setOpen}
                    {...restoreFocusSourceAttribute}
                />
                <Toaster toasterId={toasterId} />
            </div>
        </>
    ) : null;
}

export default WorkspaceSelector;
