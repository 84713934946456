import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {Subscription} from './user.types';
import {RESOURCE_SCOPES} from '../api.constants';
import MedeinaVariables from '@/util/variables';
import {FeaturedFetch} from '../api.types';

async function fetchAllSubscriptions(
    customFetch: FeaturedFetch,
    url: string,
    accumulated: Subscription[] = [],
): Promise<Subscription[]> {
    try {
        const response = await customFetch<Response>(
            url,
            {
                method: 'GET',
                scopes: RESOURCE_SCOPES.ARM,
            },
            true,
        );

        if (!response.ok) {
            throw new Error(`Failed to fetch subscriptions ${response.status}`);
        }

        const data = await response.json();
        const subscriptions = accumulated.concat(data.value as Subscription[]);

        if (data.nextLink) {
            return fetchAllSubscriptions(customFetch, data.nextLink, subscriptions);
        }
        return subscriptions;
    } catch (error) {
        // Returning data till nth level when it fails at n+1 level.
        return accumulated;
    }
}
export default function useGetUserSubscriptions() {
    const {customFetch} = useFetch();

    return useQuery({
        queryKey: ['userSubscriptions'],
        queryFn: () =>
            fetchAllSubscriptions(
                customFetch,
                `${MedeinaVariables.ArmUri}/subscriptions?api-version=2022-12-01`,
            ),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
    });
}
