import {useEffect, useMemo} from 'react';
import {Alert} from '@fluentui/react-components/unstable';
import {
    useGetEvaluation,
    EvaluationResultType,
    CreateEvaluationRunningTimeoutError,
    useCreateEvaluation,
    EvaluationState,
    EvaluationType,
} from '@/api/evaluations';
import useClasses from './Evaluation.styles';
import {EvaluationProps, EvaluationResultComponents} from './Evaluation.types';
import SuccessEvaluationResult from './SuccessEvaluationResult';
import ErrorEvaluationResult from './ErrorEvaluationResult';
import UnknownEvaluationResult from './UnknownEvaluationResult';
import {FailedIcon, RerunIcon} from '@/components/ui/icons';
import {Button, Divider, Spinner} from '@fluentui/react-components';
import useCancelEvaluation from '@/api/evaluations/useCancelEvaluation';
import {useTranslation} from 'react-i18next';
import {useGetPrompts} from '@/api/prompts';

const Components: EvaluationResultComponents = {
    [EvaluationResultType.Success]: SuccessEvaluationResult,
    [EvaluationResultType.Error]: ErrorEvaluationResult,
    [EvaluationResultType.Unknown]: UnknownEvaluationResult,
};

export default function Evaluation(props: EvaluationProps) {
    const {sessionId, promptId, evaluationId, isReadOnly, isAgentEvaluation = false} = props;
    const {t} = useTranslation('session');
    const classes = useClasses();
    const {data: evaluation, error} = useGetEvaluation({sessionId, promptId, evaluationId});
    const {refetch} = useGetPrompts({sessionId});

    const isEvaluationRunning = !!evaluation && evaluation.state === EvaluationState.Running;
    const isEvaluationContentStreaming = !!evaluation?.result?.content;

    // For a newly created evaluation, ask the user to re-run if the evaluation was stuck in a Created state.
    const {mutate: createEvaluation} = useCreateEvaluation();
    const isTimeout = !!error && error instanceof CreateEvaluationRunningTimeoutError;

    // Determine which child interaction component should be used.
    const EvaluationResultComponent = useMemo(() => {
        // Use the fallback component by default.
        let Component = UnknownEvaluationResult;
        if (evaluation?.result?.resultType && Components[evaluation.result.resultType]) {
            Component = Components[evaluation.result.resultType];
        }
        if (
            evaluation?.evaluationType == EvaluationType.WaitForInput &&
            evaluation.result?.resultType != EvaluationResultType.Error
        ) {
            Component = Components[EvaluationResultType.Success];
        }
        if (evaluation?.state === EvaluationState.Pending) {
            refetch();
        }
        return Component;
    }, [evaluation]);

    const {mutate: cancelEvaluation} = useCancelEvaluation();

    const handleCancelClick = () => {
        if (evaluationId) cancelEvaluation({sessionId, promptId, evaluationId});
    };

    const rerunString = t('Rerun');
    return (
        <div className={classes.root}>
            {isTimeout && !isReadOnly ? (
                <Alert
                    intent="error"
                    action={{
                        icon: <RerunIcon />,
                        children: rerunString,
                        onClick: () =>
                            createEvaluation({
                                sessionId,
                                promptId,
                            }),
                    }}
                >
                    {t('ErrorEvaluation')}
                </Alert>
            ) : (
                <EvaluationResultComponent {...props} />
            )}
            {isEvaluationRunning && (
                <>
                    {isAgentEvaluation && (
                        <div className={classes.loadingAgent}>
                            <Spinner
                                size="extra-small"
                                label={t('RunningAgent')}
                                data-testid="agent-evaluation-loading"
                            />
                        </div>
                    )}
                    <div className={classes.cancelEvaluation}>
                        <Button
                            appearance={'outline'}
                            icon={<FailedIcon />}
                            onClick={handleCancelClick}
                            className={classes.cancelbutton}
                            data-testid="cancel-evaluation-button"
                        >
                            {t('CancelEvaluation')}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}
